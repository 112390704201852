// React
import { useDispatch, useSelector } from 'react-redux';
import { PopoverBody } from 'reactstrap';
import * as seismicGridSlice from 'redux/seismicGrid';

import { toHex } from 'types/common';

// Components
import HeaderButton from 'components/flexLayout/HeaderButton';
import Select from 'components/dropdown/Select';
import ColorPicker from 'components/dropdown/ColorPicker';
import Slider from 'components/dropdown/Slider';
import { Option } from 'components/Select';
import Toggle from '../../dropdown/Toggle';

import { TICKS_PRESETS } from '../../../services/Constants';

function SeismicGridSetting() {
    const dispatch = useDispatch();

    const ticks = useSelector(seismicGridSlice.getTicks);
    const color = useSelector(seismicGridSlice.getColor);
    const opacity = useSelector(seismicGridSlice.getOpacity);
    const visible = useSelector(seismicGridSlice.isVisible);
    const showLabels = useSelector(seismicGridSlice.getLabelVisibility);

    const changeTicks = (value: seismicGridSlice.Ticks) => {
        dispatch(seismicGridSlice.setTicks(value));
    };

    return (
        <HeaderButton
            toggle={{
                name: 'Grid Visibility',
                icon: 'fas fa-ruler-combined',
                checked: visible,
                onChange: (v) => dispatch(seismicGridSlice.setVisibility(v)),
            }}
            popover={{
                name: 'Grid Settings',
                content: (
                    <PopoverBody>
                        <ul>
                            <Toggle
                                title="Show Labels"
                                checked={showLabels}
                                onChange={(v) => dispatch(seismicGridSlice.setLabelsVisibility(v))}
                            />
                            <ColorPicker
                                title="Color"
                                color={color}
                                onChange={(c) => dispatch(seismicGridSlice.setColor(toHex(c)))}
                            />
                            <Slider
                                value={opacity * 100}
                                title="Opacity"
                                icon="fas fa-eye-slash"
                                min={0}
                                max={100}
                                step={1}
                                onChange={(v) => dispatch(seismicGridSlice.setOpacity(v / 100))}
                                unit="%"
                            />
                            <Select
                                title="X Ticks"
                                value={{ label: `${ticks?.x}m`, value: ticks?.x }}
                                onChange={(v) =>
                                    changeTicks({
                                        x: (v as Option<number>).value,
                                        y: (v as Option<number>).value,
                                        z: ticks?.z ?? 0,
                                    })
                                }
                                options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({
                                    label: `${x}m`,
                                    value: x,
                                }))}
                                className="narrow"
                            />
                            <Select
                                title="Z Ticks"
                                value={{ label: `${ticks?.z}m`, value: ticks?.z }}
                                onChange={(v) =>
                                    changeTicks({ x: ticks?.x, y: ticks?.y, z: (v as Option<number>).value })
                                }
                                options={TICKS_PRESETS.sort((a, b) => a - b).map((z) => ({
                                    label: `${z}m`,
                                    value: z,
                                }))}
                                className="narrow"
                            />
                        </ul>
                    </PopoverBody>
                ),
            }}
        />
    );
}

export default SeismicGridSetting;
