const DashboardPane = (props: {
    title?: string;
    viewport?: boolean;
    collapse?: boolean;
    chin?;
    childClassName?: string;
    children?;
}) => (
    <div className={`dashboard-pane ${props.viewport ? 'viewport' : ''} ${props.collapse ? 'collapsing' : ''}`}>
        {props.title ? <span>{props.title}</span> : null}
        <div className={props.childClassName}>{props.children}</div>
        {props.chin ? <div className="chin">{props.chin}</div> : null}
    </div>
);

export default DashboardPane;
