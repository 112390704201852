import { FILTER_DISPLAY_MODE } from 'services/Constants';
import { HasVectorStyle, LayerState, VectorStyle } from 'types/LayerState';
import { useDispatch } from 'react-redux';
import * as layersSlice from 'redux/layers';
// import * as datasetsSlice from 'redux/datasets';
import { Color } from 'three';
import Dataset from 'types/Dataset';
import { toHex, toColor } from 'types/common';
import { useAppSelector } from 'store';
import Slider from 'components/dropdown/Slider';
import Input from 'components/dropdown/Input';
import ColorPicker from 'components/dropdown/ColorPicker';
import Title from 'components/dropdown/Title';

export type Props = {
    layer: LayerState & HasVectorStyle;
};

type Getter<T = number> = () => T;
type Setter<T = number> = (v: T) => void;

type SimpleProps = {
    getter: Getter;
    setter: Setter;
};

const PointSizeSetting = (props: SimpleProps) => {
    return (
        <Input
            title="Point Size"
            min={1}
            max={100}
            step={1}
            value={props.getter()}
            onChange={(v) => props.setter(v)}
            unit="m"
            icon="fas fa-circle-o"
        />
    );
};

const LineWidthSettings = (props: SimpleProps) => {
    return (
        <Input
            title="Line Width"
            min={1}
            max={100}
            step={1}
            value={props.getter()}
            onChange={(v) => props.setter(v)}
            unit="m"
            icon="fas fa-horizontal-rule"
        />
    );
};

const FillOpacitySettings = (props: SimpleProps) => {
    return (
        <Slider
            title="Opacity"
            min={0}
            max={100}
            step={1}
            value={Math.round(props.getter() * 100)}
            onChange={(v) => props.setter(v / 100)}
            unit="%"
            icon="fas fa-low-vision"
        />
    );
};

const BorderOpacitySettings = (props: SimpleProps) => {
    return (
        <Slider
            title="Border Opacity"
            min={0}
            max={100}
            step={1}
            value={Math.round(props.getter() * 100)}
            onChange={(v) => props.setter(v / 100)}
            unit="%"
            icon="fas fa-low-vision"
        />
    );
};

const BorderWidthSettings = (props: SimpleProps) => {
    return (
        <Input
            title="Width"
            min={0}
            max={5}
            step={0.1}
            value={props.getter()}
            onChange={(v) => props.setter(v)}
            unit="m"
        />
    );
};

function StyleEditor(props: {
    style: VectorStyle;
    setLineWidth(v: number): void;
    setPointSize(v: number): void;
    setFillOpacity(v: number): void;
    setFillColor(v: Color): void;
    setBorderOpacity(v: number): void;
    setBorderWidth(v: number): void;
    setBorderColor(v: Color): void;
}) {
    const { style } = props;
    return (
        <>
            <Title title="Layer Style" />
            <LineWidthSettings getter={() => style.lineWidth} setter={(v) => props.setLineWidth(v)} />
            <PointSizeSetting getter={() => style.pointSize} setter={(v) => props.setPointSize(v)} />
            <hr />
            <Title title="Fill" />
            <FillOpacitySettings getter={() => style.fillOpacity} setter={(v) => props.setFillOpacity(v)} />
            <ColorPicker title="Color" color={toColor(style.fillColor)} onChange={(v) => props.setFillColor(v)} />
            <hr />
            <Title title="Border" />
            <BorderOpacitySettings getter={() => style.borderOpacity} setter={(v) => props.setBorderOpacity(v)} />
            <BorderWidthSettings getter={() => style.borderWidth} setter={(v) => props.setBorderWidth(v)} />
            <ColorPicker title="Color" color={toColor(style.borderColor)} onChange={(v) => props.setBorderColor(v)} />
        </>
    );
}

export const FilterMode = (props: { dataset: Dataset } & React.StyleHTMLAttributes<HTMLDivElement>) => {
    const dispatch = useDispatch();

    const layer = useAppSelector(layersSlice.get<HasVectorStyle>(props.dataset.id));
    const filter = useAppSelector(layersSlice.getFeatureFilter(layer));

    const style = filter.style;
    const filterMode = filter.mode;

    const changeFilterMode = (value: FILTER_DISPLAY_MODE) => {
        dispatch(layersSlice.setFeatureFilterMode({ layer, value }));
    };

    return (
        <>
            <div className="row" {...props} key={`vector-filtermode-${props.dataset.id}`}>
                <label htmlFor="vector-filtermode" className="col-sm-6 col-form-label">
                    <i className="fal fa-filter fa-fw" /> Filter mode
                </label>
                <div className="col-sm-6">
                    <div className="select-group">
                        <select
                            id="vector_filtermode"
                            className="form-select"
                            value={filter.mode}
                            onChange={(e) => changeFilterMode(e.target.value as FILTER_DISPLAY_MODE)}
                        >
                            <option key="vector-filtermode-hide" value={FILTER_DISPLAY_MODE.HIDE}>
                                {FILTER_DISPLAY_MODE.HIDE}
                            </option>
                            <option key="vector-filtermode-style" value={FILTER_DISPLAY_MODE.STYLE}>
                                {FILTER_DISPLAY_MODE.STYLE}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            {filterMode === FILTER_DISPLAY_MODE.STYLE ? (
                <StyleEditor
                    style={style}
                    setBorderColor={(value) =>
                        dispatch(layersSlice.setFilterBorderColor({ layer, value: toHex(value) }))
                    }
                    setBorderOpacity={(value) => dispatch(layersSlice.setFilterBorderOpacity({ layer, value }))}
                    setLineWidth={(value) => dispatch(layersSlice.setFilterLineWidth({ layer, value }))}
                    setPointSize={(value) => dispatch(layersSlice.setFilterPointSize({ layer, value }))}
                    setBorderWidth={(value) => dispatch(layersSlice.setFilterBorderWidth({ layer, value }))}
                    setFillColor={(value) => dispatch(layersSlice.setFilterFillColor({ layer, value: toHex(value) }))}
                    setFillOpacity={(value) => dispatch(layersSlice.setFilterFillOpacity({ layer, value }))}
                />
            ) : null}
        </>
    );
};

const VectorSettings = (props: Props) => {
    const { layer } = props;

    const dispatch = useDispatch();

    const style = useAppSelector(layersSlice.getVectorStyle(layer));
    // const hasFilter = useAppSelector(layersSlice.hasFeatureFilter(layer));
    // const filter = useAppSelector(layersSlice.getFeatureFilter(layer));
    // const dataset = useAppSelector(datasetsSlice.get(layer.datasetId));

    return (
        <>
            <StyleEditor
                style={style}
                setBorderColor={(value) => dispatch(layersSlice.setBorderColor({ layer, value: toHex(value) }))}
                setBorderOpacity={(value) => dispatch(layersSlice.setBorderOpacity({ layer, value }))}
                setLineWidth={(value) => dispatch(layersSlice.setLineWidth({ layer, value }))}
                setPointSize={(value) => dispatch(layersSlice.setPointSize({ layer, value }))}
                setBorderWidth={(value) => dispatch(layersSlice.setBorderWidth({ layer, value }))}
                setFillColor={(value) => dispatch(layersSlice.setFillColor({ layer, value: toHex(value) }))}
                setFillOpacity={(value) => dispatch(layersSlice.setFillOpacity({ layer, value }))}
            />

            {/* Disabled while the attributes table has no filtering */}
            {/* {hasFilter ? (
                <>
                    <h5>Style options for filter: {filter.name} </h5>
                    <FilterMode dataset={dataset} />
                </>
            ) : (
                <div>
                    This layer does not have a filter. You can add one using the attribute table and style it here later
                </div>
            )} */}
            <hr />
        </>
    );
};

export default VectorSettings;
