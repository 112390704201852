import { useField } from 'formik';

type Props = {
    name: string;
    titled?: boolean;
    label?: string;
    long?: boolean;
    type?: string;
};

const BaseInput = (props: Props) => {
    const [field, meta] = useField(props);

    return (
        <div className="form-group">
            {props.titled ? props.label : undefined}
            {props.long ? (
                <textarea
                    className={meta.error ? 'is-invalid' : ''}
                    placeholder={!props.titled ? props.label : undefined}
                    {...field}
                />
            ) : (
                <input
                    type={props.type ?? 'text'}
                    className={meta.error ? 'is-invalid' : ''}
                    placeholder={!props.titled ? props.label : undefined}
                    {...field}
                />
            )}
            <div className="invalid-feedback">{meta.error}</div>
        </div>
    );
};

export default BaseInput;
