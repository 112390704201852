import { useEffect, useState } from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik, Form } from 'formik';
import BaseInput from 'components/forms/BaseInput';
import Select from 'components/forms/Select';
import { useAppDispatch, useAppSelector } from 'store';

import { getOrganizationsForCreatingDataset } from '../../redux/selectors';
import handleApiError from '../../services/Forms';

import { createCollection } from '../../redux/actions';

const AddCollection = ({ onClose, onCreation }) => {
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(getOrganizationsForCreatingDataset);

    const initialFormState = {
        name: '',
        organization_id: '',
        start_date: undefined,
        end_date: undefined,
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    useEffect(() => {
        setCurrentFormState(initialFormState);
    }, [organizations]);

    const validation = (values) => {
        const errors: { name?: string; organization_id?: string } = {};
        if (!values.name) errors.name = 'Required';
        if (!values.organization_id) errors.organization_id = 'Required';
        return errors;
    };

    const addCollection = (values, helpers) => {
        const data: { name: string; organization_id: string; start_date?: string; end_date?: string } = {
            name: values.name,
            organization_id: values.organization_id.value,
        };

        if (values.startDate) data.start_date = values.startDate;
        if (values.endDate) data.end_date = values.endDate;

        dispatch(createCollection(data))
            .then((collection) => {
                onClose();
                onCreation(collection);
            })
            .catch((err) => {
                handleApiError(err, helpers);
                helpers.setSubmitting(false);
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={addCollection}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, errors, isSubmitting, setFieldValue }) => (
                <Form>
                    <ModalHeader toggle={onClose}>Add collection</ModalHeader>
                    <ModalBody>
                        <BaseInput name="name" label="Name" titled />
                        <Select
                            name="organization_id"
                            placeholder="Organization"
                            options={[
                                ...organizations.map((e) => ({
                                    value: e.id,
                                    label: e.display_name,
                                })),
                            ]}
                            value={values.organization_id}
                            setFieldValue={setFieldValue}
                        />
                        <div className="form-group date-range">
                            Start Date
                            <input
                                type="date"
                                value={values.startDate}
                                max={values.endDate ? values.endDate : new Date().toISOString().split('T')[0]}
                                onChange={(choice) => setFieldValue('startDate', choice.target.value)}
                            />
                            End Date
                            <input
                                type="date"
                                value={values.endDate}
                                min={values.startDate}
                                max={new Date().toISOString().split('T')[0]}
                                onChange={(choice) => setFieldValue('endDate', choice.target.value)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <span className="error-text">{errors.submit}</span>
                        <button type="submit" className="pane-button large highlight" disabled={isSubmitting}>
                            Create <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default AddCollection;
