// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFooter, Button, Modal, ModalBody } from 'reactstrap';

// Redux
import { unrelateProjectDataset } from 'redux/actions';
import * as datasetsSlice from 'redux/datasets';

import Dataset from 'types/Dataset';

// Components
import { useAppSelector } from 'store';

export type Props = {
    id: string;
    dataset: Dataset;
};

const RemoveDataset = (props: Props) => {
    const { id, dataset } = props;
    const dispatch = useDispatch();
    const project = useAppSelector(datasetsSlice.currentProject);

    const [unrelateDatasetModal, setUnrelateDatasetModal] = useState(false);

    const toggleUnrelateDatasetModal = () => {
        setUnrelateDatasetModal(!unrelateDatasetModal);
    };
    const onUnrelateDataset = () => {
        dispatch(unrelateProjectDataset(project, dataset));
    };

    return (
        <>
            <Button
                id={`datasetbtn-remove-${id}`}
                className="borderless red"
                title={`Remove ${dataset.name} from project`}
                onClick={toggleUnrelateDatasetModal}
            >
                <i className="fas fa-trash-can" />
            </Button>

            <Modal
                isOpen={unrelateDatasetModal}
                toggle={() => toggleUnrelateDatasetModal()}
                keyboard={false}
                centered
                className="modal-confirm"
            >
                <ModalBody>
                    <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                    <span className="big-modal-text">Are you sure?</span>
                    <span className="small-modal-text">
                        The dataset, {dataset.name}, will be removed from this project.
                        <br />
                        It will still be available in the data library and other projects.
                    </span>
                    <button type="button" className="pane-button large highlight" onClick={onUnrelateDataset}>
                        Yes, Remove this Dataset
                    </button>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="pane-button large" onClick={() => toggleUnrelateDatasetModal()}>
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default RemoveDataset;
