// eslint-disable-next-line import/no-cycle
import { SET_DATASETS, ADD_DATASET, DELETE_DATASET, UPDATE_DATASET } from '../actionTypes';

const initialState = {
    list: [],
    status: 'unknown',
};

function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_DATASETS:
            return {
                ...state,
                list: payload,
                status: 'set',
            };
        case ADD_DATASET:
            return {
                ...state,
                list: [...state.list, payload],
                status: 'set',
            };
        case DELETE_DATASET:
            return {
                ...state,
                list: state.list.filter((x) => x.id !== payload.id),
            };
        case UPDATE_DATASET:
            return {
                ...state,
                list: [...state.list.filter((x) => x.id !== payload.id), payload],
            };
        default:
            return state;
    }
}

export default reducer;
