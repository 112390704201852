import * as giro3dSlice from 'redux/giro3d';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from 'store';
import AnnotationViewer from './annotationMenu/AnnotationViewer';
import FeatureInfo from './featureInfo/FeatureInfo';
import InspectDatasetMenu from './datasetsMenu/sourceFilesMenu/InspectDatasetMenu';

const ActiveSelection = () => {
    const selection = useAppSelector(giro3dSlice.getSelection, shallowEqual);

    switch (selection?.type) {
        case 'dataset':
            return <InspectDatasetMenu datasetId={selection.objectId} />;
        case 'annotation':
            return <AnnotationViewer annotationId={selection.objectId} />;
        case 'feature':
            return <FeatureInfo />;
        default:
            return <span className="tabContent">No Active Selection</span>;
    }
};

export default ActiveSelection;
