import { nanoid } from '@reduxjs/toolkit';

export type Props = {
    checked: boolean;
    title: string;
    onChange: (checked: boolean) => void;
    experimental?: boolean;
};

function Toggle(props: Props) {
    const id = nanoid();
    return (
        <li className={props.experimental ? 'experimental' : ''}>
            <label htmlFor={id} className="cursor">
                <div>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            id={id}
                            checked={props.checked}
                            onChange={(e) => props.onChange(e.target.checked)}
                        />
                    </label>
                </div>
                <div>
                    <label htmlFor={id}>{props.title}</label>
                </div>
            </label>
        </li>
    );
}

export default Toggle;
