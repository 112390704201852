import { createSelector, PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Geometry } from 'geojson';
import type GeometryObject from 'giro3d_extensions/GeometryObject';
import { GEOMETRY_TYPE } from 'giro3d_extensions/DrawTool';
import { DRAWN_TOOL, DRAWTOOL_MODE } from 'services/Constants';
import { RootState } from 'store';

export type State = {
    tool: DRAWN_TOOL;
    mode: DRAWTOOL_MODE;
    type: GEOMETRY_TYPE;
    geometry: Geometry;
    pendingGeometry: GeometryObject;
};

const initialState: State = {
    tool: null,
    mode: null,
    type: null,
    geometry: null,
    pendingGeometry: null,
};

const self = (store: RootState) => store.drawTool;

// Selectors
export const getTool = createSelector(self, (s) => s.tool);
export const getMode = createSelector(self, (s) => s.mode);
export const getType = createSelector(self, (s) => s.type);
export const getGeometry = createSelector(self, (s) => s.geometry);
export const getPendingGeometry = createSelector(self, (s) => s.pendingGeometry);

const slice = createSlice({
    name: 'drawTool',
    initialState,
    reducers: {
        setTool: (state, action: PayloadAction<DRAWN_TOOL>) => {
            state.tool = action.payload;
        },
        setMode: (state, action: PayloadAction<DRAWTOOL_MODE>) => {
            state.mode = action.payload;
        },
        setType: (state, action: PayloadAction<GEOMETRY_TYPE>) => {
            state.type = action.payload;
        },
        setGeometry: (state, action: PayloadAction<Geometry>) => {
            state.geometry = action.payload;
            state.pendingGeometry = null;
        },
        setPendingGeometry: (state, action: PayloadAction<GeometryObject>) => {
            state.geometry = null;
            state.pendingGeometry = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const setTool = slice.actions.setTool;
export const setMode = slice.actions.setMode;
export const setType = slice.actions.setType;
export const setGeometry = slice.actions.setGeometry;
export const setPendingGeometry = slice.actions.setPendingGeometry;
