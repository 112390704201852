export type Props = {
    title: string;
    icon?: string;
    experimental?: boolean;
};

const Title = (props: Props) => (
    <li className={props.experimental ? 'experimental' : ''}>
        <label>
            <div>
                <i className={props.icon} />
            </div>
            <div className="title">{props.title}</div>
        </label>
    </li>
);

export default Title;
