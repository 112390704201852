import TabHeader from 'components/flexLayout/TabHeader';
import { useMountEffect } from 'components/utils';
import { useAppDispatch, useAppSelector } from 'store';
import Grid from 'components/giro3d/seismic/SeismicGrid';
import { getService } from 'ServiceContainer';
import * as giro3dSlice from 'redux/giro3d';
import * as datasetsSlice from 'redux/datasets';
import * as seismicViewSlice from 'redux/seismicView';
import * as layersSlice from 'redux/layers';
import { loadSeismicView, setClickedDataset, setSeismicDataset, unloadSeismicView } from 'redux/actions';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { useEffect, useState } from 'react';
import { useEventBus } from 'EventBus';
import { getBaseName, SourceFile } from 'types/SourceFile';
import { hasSeismicPlane } from 'types/LayerState';
import { organizeLines, isBackwards, groupName } from 'services/seismicSorter';
import { DEFAULT_GIRO3D_SETTINGS, ORDER_MODE } from 'services/Constants';
import SeismicGridSetting from '../settingsMenu/SeismicGridSettings';

const orderFiles = (
    files: SourceFile[],
    orderMode: ORDER_MODE,
    direction: number,
    crossDirection: number,
    inlineTolerance: number,
    crosslineTolerance: number,
    inlineTerm: string,
    crosslineTerm: string
) => {
    if (orderMode === ORDER_MODE.LINE) {
        const ordered = organizeLines(files, direction, crossDirection, inlineTolerance, crosslineTolerance);
        return [...ordered.inline, ...ordered.crossline, ...ordered.other];
    }
    if (orderMode === ORDER_MODE.NAME) {
        const ordered = groupName(files, inlineTerm, crosslineTerm);
        return [...ordered.inline, ...ordered.crossline, ...ordered.other];
    }
    return files;
};

const SeismicViewer = ({ seismicRef, inspectorRef }) => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const seismicView = getService('SeismicViewManager');
    const seismicViewReady = useAppSelector(giro3dSlice.isSeismicViewInitialized);
    const project = useAppSelector(datasetsSlice.currentProject);

    const reversed = useAppSelector(seismicViewSlice.isReversed);
    const maintainDirection = useAppSelector(seismicViewSlice.getMaintainDirection);
    const scale = useAppSelector(seismicViewSlice.getZScale);
    const snapPosition = useAppSelector(seismicViewSlice.getSnapPosition);

    const dataset = useAppSelector(datasetsSlice.getSeismicDataset);

    const layer = useAppSelector(layersSlice.get(dataset.id));
    const inlineDirection = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getInlineDirection(layer)) : 0;
    const inlineTolerance = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getInlineTolerance(layer)) : 0;
    const crosslineDirection = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getCrosslineDirection(layer)) : 0;
    const crosslineTolerance = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getCrosslineTolerance(layer)) : 0;
    const inlineTerm = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getInlineTerm(layer)) : '';
    const crosslineTerm = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getCrosslineTerm(layer)) : '';
    const lineOrder = hasSeismicPlane(layer) ? useAppSelector(layersSlice.getLineOrderMode(layer)) : ORDER_MODE.FILE;

    const sourcefiles = orderFiles(
        useAppSelector(datasetsSlice.getSourceFiles(dataset.id)),
        lineOrder,
        inlineDirection,
        crosslineDirection,
        inlineTolerance,
        crosslineTolerance,
        inlineTerm,
        crosslineTerm
    );
    const sourcefile = useAppSelector(datasetsSlice.getSeismicSourceFile);

    const [lastDirectionReverse, setLastDirectionReverse] = useState(false);
    useEffect(() => {
        if (
            sourcefile &&
            lastDirectionReverse !==
                isBackwards(sourcefile, inlineDirection, crosslineDirection, inlineTolerance, crosslineTolerance)
        ) {
            setLastDirectionReverse(!lastDirectionReverse);
            if (maintainDirection) dispatch(seismicViewSlice.reversed(!reversed));
        }
    }, [sourcefile?.id]);

    useMountEffect(
        () => dispatch(loadSeismicView(seismicRef.current, inspectorRef.current, project)),
        () => dispatch(unloadSeismicView())
    );

    useEffect(() => {
        if (dataset && sourcefile) dispatch(setSeismicDataset(dataset, sourcefile));
    }, [sourcefile]);

    const fileIndex = sourcefiles?.indexOf(sourcefile);

    const selectFile = (index: number) => {
        if (index < 0 || index >= sourcefiles.length) throw new Error('File out of range');

        const newFile = sourcefiles[index];

        eventBus.dispatch('highlight-file', { dataset: dataset.id, file: newFile.id, scroll: true });
        dispatch(setClickedDataset(dataset.id, newFile.id));
        dispatch(datasetsSlice.setSeismicDataset({ dataset: dataset.id, sourceFile: newFile.id }));
    };

    return (
        <>
            <TabHeader
                left={
                    <>
                        <HeaderButton
                            toggle={{
                                onChange: (value) => dispatch(seismicViewSlice.reversed(value)),
                                checked: reversed,
                                icon: 'fas fa-arrows-left-right',
                                name: 'Reverse View',
                            }}
                        />
                        <HeaderButton
                            toggle={{
                                icon: 'fas fa-arrow-right-arrow-left',
                                name: 'Maintain Direction',
                                checked: maintainDirection,
                                onChange: (v) => dispatch(seismicViewSlice.maintainDirection(v)),
                            }}
                        />
                        <HeaderButton
                            toggle={{
                                icon: 'fas fa-arrows-left-right-to-line',
                                name: 'Snap to Between Lines',
                                checked: snapPosition,
                                onChange: (v) => dispatch(seismicViewSlice.snapPosition(v)),
                            }}
                        />
                    </>
                }
                center={<span>{`${dataset.name} - ${getBaseName(sourcefile)}`}</span>}
                right={
                    <>
                        <SeismicGridSetting key="grid-settings" />
                        <HeaderButton
                            key="zScale"
                            slider={{
                                unit: '%',
                                min: 50,
                                max: 1000,
                                step: 10,
                                value: Math.round(scale * 100),
                                onChange: (v) =>
                                    dispatch(seismicViewSlice.zScale(v / 100 || DEFAULT_GIRO3D_SETTINGS.Z_SCALE)),
                                replaceNaN: 50,
                                icon: 'fas fa-arrows-up-down',
                            }}
                        />
                        <HeaderButton
                            button={{
                                name: 'Previous Line',
                                icon: 'fas fa-arrow-left',
                                onClick: () => selectFile(fileIndex - 1),
                                disabled: fileIndex === 0,
                            }}
                            key="next-line"
                        />
                        {fileIndex + 1}/{sourcefiles?.length ?? 1}
                        <HeaderButton
                            button={{
                                name: 'Next Line',
                                icon: 'fas fa-arrow-right',
                                onClick: () => selectFile(fileIndex + 1),
                                disabled: fileIndex === (sourcefiles?.length ?? 1) - 1,
                            }}
                            key="previous-line"
                        />
                    </>
                }
            />
            <div className="giro3d-seismic-container" ref={seismicRef} />
            {seismicViewReady ? <Grid instance={seismicView.getInstance()} /> : null}
        </>
    );
};

const SeismicViewerWrapper = ({ seismicRef, inspectorRef }) => {
    const dataset = useAppSelector(datasetsSlice.getSeismicDataset);
    if (dataset) return <SeismicViewer seismicRef={seismicRef} inspectorRef={inspectorRef} />;
    return <div className="tabContent">Select a Seismic dataset to begin viewing...</div>;
};

export default SeismicViewerWrapper;
