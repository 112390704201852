import { Box3, Color } from 'three';

import AxisGrid, { TickOrigin } from '@giro3d/giro3d/entities/AxisGrid';
import type Instance from '@giro3d/giro3d/core/Instance';
import Extent from '@giro3d/giro3d/core/geographic/Extent';

import { genericEqualityFn } from 'components/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as giro3dSlice from 'redux/giro3d';
import * as gridSlice from 'redux/grid';
import { TICKS_PRESETS } from 'services/Constants';
import { useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';

export type Props = {
    instance: Instance;
};

/**
 * Computes the best ticks from the given spatial parameters.
 * @param extent The extent.
 * @param floorLevel The floor elevation.
 * @param ceilingLevel The ceiling elevation.
 * @returns The computed ticks.
 */
function computeTicksFor3DView(extent: Extent, floorLevel: number, ceilingLevel: number): gridSlice.Ticks {
    const dims = extent.dimensions();

    const length = Math.max(dims.x, dims.y);

    // We wish around 5 ticks per axis
    let hSize = length / 5;
    let vSize = Math.abs(ceilingLevel - floorLevel) / 5;

    // Let's find the closest tick preset that match our desired tick size
    // so that we have nice, readable numbers.
    hSize = TICKS_PRESETS.sort((a, b) => Math.abs(a - hSize) - Math.abs(b - hSize))[0];
    vSize = TICKS_PRESETS.sort((a, b) => Math.abs(a - vSize) - Math.abs(b - vSize))[0];

    return {
        x: hSize,
        y: hSize,
        z: vSize,
    };
}

function Grid(props: Props) {
    const { instance } = props;

    const dispatch = useDispatch();

    const [axisGrid, setAxisGrid] = useState<AxisGrid>(
        instance.getObjects().find((o) => o instanceof AxisGrid) as AxisGrid
    );

    const volume = useAppSelector(giro3dSlice.getVolume, genericEqualityFn<Box3>);
    const project = useAppSelector(datasetsSlice.currentProject);

    // Note: in 3D mode, if those values are null, they are computed using the volume
    // and fed back to the redux state. However, we don't do this for the 2D view.
    const ticks = useAppSelector(gridSlice.getTicks);
    const ceiling = useAppSelector(gridSlice.getCeilingLevel);
    const floor = useAppSelector(gridSlice.getFloorLevel);

    const showCeiling = useAppSelector(gridSlice.getCeilingVisibility);
    const showSides = useAppSelector(gridSlice.getSideVisibility);
    const color = useAppSelector(gridSlice.getColor, genericEqualityFn<Color>);
    const opacity = useAppSelector(gridSlice.getOpacity);
    const visible = useAppSelector(gridSlice.isVisible);
    const showLabels = useAppSelector(gridSlice.getLabelVisibility);
    const zScale = useAppSelector(giro3dSlice.getZScale);

    function cleanup() {
        const entity = instance.getObjects().find((o) => o instanceof AxisGrid) as AxisGrid;
        if (entity) {
            instance.remove(entity);
            setAxisGrid(null);
        }
    }

    function updateGrid(entity: AxisGrid) {
        if (!volume || volume.isEmpty()) {
            entity.visible = false;
        } else {
            // 5% margin on each side of the extent, so that the edges of
            // the grid are not hidden by the datasets.
            const verticalMargin = 10;
            const horizontalMargin = 0.05;

            const computedFloor = (volume.min.z - verticalMargin) / zScale;
            const computedCeiling = (volume.max.z + verticalMargin) / zScale;

            const gridExtent = Extent.fromBox3(instance.referenceCrs, volume).withRelativeMargin(horizontalMargin);

            entity.volume.extent = gridExtent;
            entity.visible = visible;
            entity.volume.ceiling = ceiling ?? computedCeiling;
            entity.volume.floor = floor ?? computedFloor;
            if (ceiling == null || floor == null) {
                dispatch(gridSlice.setFloorLevel(computedFloor));
                dispatch(gridSlice.setCeilingLevel(computedCeiling));
            }

            entity.object3d.scale.setZ(zScale);
            entity.object3d.updateMatrixWorld();
            props.instance.notifyChange(entity.object3d);
            entity.color = color;
            entity.showLabels = showLabels;

            const computeTicks = computeTicksFor3DView;
            entity.ticks = ticks ?? computeTicks(gridExtent, entity.volume.floor, entity.volume.ceiling);

            if (!ticks) dispatch(gridSlice.setTicks(entity.ticks));

            entity.showCeilingGrid = showCeiling;
            entity.showSideGrids = showSides;
            entity.opacity = opacity;

            entity.refresh();
        }
    }

    function update() {
        if (!axisGrid) {
            const entity = new AxisGrid({
                origin: TickOrigin.Relative,
                ticks,
                volume: {
                    ceiling: 0,
                    floor: 0,
                    extent: new Extent(instance.referenceCrs, 0, 1, 0, 1),
                },
            });

            instance.add(entity);
            setAxisGrid(entity);
            updateGrid(entity);
        } else updateGrid(axisGrid);

        instance.notifyChange(axisGrid);
    }

    useEffect(cleanup, [project]);

    useEffect(() => {
        update();
    }, [volume, opacity, color, visible, ceiling, floor, ticks, showCeiling, showSides, showLabels, zScale]);

    // This is a renderless component. We don't create any DOM element,
    // however we are still "rendering" stuff in the 3D view.
    return null;
}

export default Grid;
