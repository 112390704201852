import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { useEventBus } from 'EventBus';
import UppyService from '../../services/UppyService';
import { CompletedUpload, InProgressUpload } from './Upload';
import { getAllDatasets } from '../../redux/selectors';

const UploadsMenu = () => {
    const eventBus = useEventBus();

    const [uploading, setUploading] = useState(UppyService.getUploading());
    const [complete, setComplete] = useState(UppyService.getComplete());
    const [progress, setProgress] = useState('0 of 0 Uploads Complete');
    const [filesByDataset, setFilesByDataset] = useState({});

    const updateUppy = () => {
        const uploadingDatasets = UppyService.getUploading();
        setUploading(uploadingDatasets);
        setComplete(UppyService.getComplete());
        setProgress(
            `${Object.keys(complete).length} of ${Object.keys(uploadingDatasets).length + Object.keys(complete).length} Uploads Complete`
        );
        const files = {};
        Object.keys(uploadingDatasets).forEach((datasetId) => {
            files[datasetId] = uploadingDatasets[datasetId].getFiles();
        });
        setFilesByDataset(files);
    };

    function subscribe() {
        eventBus.subscribe('uppy-change', updateUppy);
    }

    function unsubscribe() {
        eventBus.unsubscribe('uppy-change', updateUppy);
    }

    useEffect(() => {
        subscribe();
        updateUppy();
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setProgress(
            `${Object.keys(complete).length} of ${Object.keys(uploading).length + Object.keys(complete).length} Uploads Complete`
        );
    }, [Object.keys(uploading).length, Object.keys(complete).length]);

    const datasets = useSelector(getAllDatasets);

    return (
        <div className="tabContent">
            <div className="input-row">
                {progress}
                <div className="grow" />
                <button
                    type="button"
                    className="pane-button highlight"
                    id="clear-uploads"
                    disabled={Object.keys(complete).length === 0}
                    onClick={UppyService.clear}
                    title="Clear completed uploads"
                >
                    <i className="fas fa-trash-can" />
                </button>
                {Object.keys(complete).length === 0 ? (
                    <UncontrolledTooltip target="clear-uploads">
                        There are no completed uploads to clear.
                    </UncontrolledTooltip>
                ) : null}
            </div>
            <ul className="list">
                {Object.keys(filesByDataset).map((datasetId) => (
                    <InProgressUpload
                        dataset={datasets.find((d) => d.id === datasetId)}
                        files={filesByDataset[datasetId]}
                        key={`upload-${datasetId}`}
                    />
                ))}
                {Object.keys(complete).map((datasetId) => (
                    <CompletedUpload
                        dataset={datasets.find((d) => d.id === datasetId)}
                        files={complete[datasetId]}
                        key={`upload-complete-${datasetId}`}
                    />
                ))}
            </ul>
        </div>
    );
};

export default UploadsMenu;
