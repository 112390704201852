import { nanoid } from '@reduxjs/toolkit';
import BaseSelect, { Props } from 'components/Select';

function Select<T>(props: Props<T> & { title: string; icon?: string; experimental?: boolean }) {
    const id = nanoid();
    return (
        <li className={props.experimental ? 'experimental' : ''}>
            <label htmlFor={id} className="cursor">
                <div>
                    <i className={props.icon} />
                </div>
                <div>
                    <label htmlFor={id}>{props.title}</label>
                </div>
                <BaseSelect {...props} />
            </label>
        </li>
    );
}

export default Select;
