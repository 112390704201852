import { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Annotation, { AnnotationGeometry } from 'types/Annotation';
import { GeometryWithCRS } from 'types/common';
import Project from 'types/Project';
import * as geojsonUtils from 'geojsonUtils';
import * as wktUtils from 'wktUtils';
import type { Geometry } from 'geojson';

export type Props = {
    annotations: Annotation[];
    project: Project;
};

type Axis = 0 | 1 | 2;

const AnnotationExport = (props: Props) => {
    const { project, annotations } = props;

    const csvLink = useRef<CSVLink>();

    const [modalOpen, setModalOpen] = useState(false);
    const [filename, setFilename] = useState('');
    const [data, setData] = useState([]);

    function generateFileName() {
        const date = new Date();
        return `${project.name}-${date
            .getUTCFullYear()
            .toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false })}${date
            .getUTCMonth()
            .toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })}${date.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${date
            .getUTCHours()
            .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}${date
            .getUTCMinutes()
            .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
    }

    const generateCoordinate = (axis: Axis, geometry: AnnotationGeometry) => {
        switch (geometry.type) {
            case 'Point':
                return geometry.coordinates[axis];
            case 'LineString':
                return (
                    (Math.min(...geometry.coordinates.map((x) => x[axis])) +
                        Math.max(...geometry.coordinates.map((x) => x[axis]))) /
                    2
                );
            case 'Polygon':
                return (
                    (Math.min(...geometry.coordinates[0].map((x) => x[axis])) +
                        Math.max(...geometry.coordinates[0].map((x) => x[axis]))) /
                    2
                );
            default:
                return 0;
        }
    };

    const generateWKT = (geometry: AnnotationGeometry) => {
        return wktUtils.convertToWKT(geometry);
    };

    const exportAnnotations = () => {
        setData(
            annotations.map((annotation) => ({
                name: annotation.name,
                type: annotation.ssdm_type ? annotation.ssdm_type : annotation.observation_type,
                crs: annotations[0].geometry.crs.properties.name,
                easting: generateCoordinate(0, annotation.geometry),
                northing: generateCoordinate(1, annotation.geometry),
                elevation: generateCoordinate(2, annotation.geometry),
                geometry: generateWKT(annotation.geometry),
                status: annotation.status,
                confidence: annotation.datasets.length, // Is there a better term for this? Could we just list the dataset names?
            }))
        );
        setFilename(generateFileName());
        setTimeout(() => csvLink.current.link.click());
    };

    function reprojectToWGS84(geometry: GeometryWithCRS): Geometry {
        const inputProjection = geometry.crs.properties.name;
        const outputProjection = 'EPSG:4326';

        const result = geojsonUtils.transform(geometry, inputProjection, outputProjection);

        return geojsonUtils.toStandardGeoJSON(result) as Geometry;
    }

    function annotationToFeature(annotation: Annotation) {
        return {
            type: 'Feature',
            properties: {
                name: annotation.name,
                description: annotation.description,
                created_at_utc: annotation.created_at_utc,
                observation_type: annotation.observation_type,
                ssdm_type: annotation.ssdm_type,
                status: annotation.status,
                unresolved_comments: annotation.unresolved_comments,
                confidence: annotation.datasets.length, // Is there a better term for this? Could we just list the dataset names?
            },
            geometry: reprojectToWGS84(
                annotation.geometry.type === 'Polygon'
                    ? (geojsonUtils.enforceRightHandRule(annotation.geometry) as GeometryWithCRS)
                    : annotation.geometry
            ),
        };
    }

    function generateJson(content: Annotation[]) {
        const fileData = JSON.stringify({
            type: 'FeatureCollection',
            features: content.map((annotation) => annotationToFeature(annotation)),
        });
        const blob = new Blob([fileData], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.download = `${generateFileName()}.geojson`;
        link.href = url;
        link.click();
    }

    return (
        <>
            <button
                type="button"
                className="pane-button"
                title="Export Annotations"
                onClick={() => setModalOpen(true)}
                disabled={!project || !annotations || annotations.length === 0}
            >
                <i className="fas fa-arrow-down-to-bracket" />
            </button>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} centered>
                <ModalHeader toggle={() => setModalOpen(false)}>Export Annotations from {project?.name}</ModalHeader>
                <ModalBody>
                    <p>Annotations can be exported in a various formats:</p>
                    <div className="row mb-3">
                        <div className="col-2">
                            <button
                                type="button"
                                title="Export Annotations"
                                onClick={() => exportAnnotations()}
                                className="pane-button large w-100"
                                disabled={!project || !annotations || annotations.length === 0}
                            >
                                <i className="fas fa-file-csv" />
                                <span style={{ whiteSpace: 'nowrap' }}>CSV</span>
                            </button>
                            <CSVLink data={data} filename={filename} ref={csvLink} hidden />
                        </div>
                        <div className="col">
                            The <code className="fw-bold">geometry</code> column stores information in the Well Known
                            Text (WKT) format.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <button
                                type="button"
                                title="Export Annotations"
                                onClick={() => generateJson(annotations)}
                                className="pane-button large w-100"
                                disabled={!project || !annotations || annotations.length === 0}
                            >
                                <i className="fas fa-brackets-curly" />
                                <span style={{ whiteSpace: 'nowrap' }}>GeoJSON</span>
                            </button>
                        </div>
                        <div className="col">
                            The coordinates in the annotations reference {project?.name}&apos;s CRS (IOGP:
                            {project?.projection}) but will be reprojected into WGS84 (EPSG:4326).
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default AnnotationExport;
