// OpenLayers - Map
import { Map, View } from 'ol';
import Draw, { createBox } from 'ol/interaction/Draw';
import Select from 'ol/interaction/Select';
import { defaults as defaultInteractions } from 'ol/interaction';
import { containsExtent, createEmpty, extend, getCenter } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { ScaleLine } from 'ol/control';
import { Style } from 'ol/style';
import { Group } from 'ol/layer';

// OpenLayers - Projections
import { fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

// Open-source overlays
import { useEventBus } from 'EventBus';
import * as overviewSlice from 'redux/overview';
import openSourceLayers from './OpenSourceLayers';

// Components
import { getFeatureStyle } from '../components/overview/style';
// eslint-disable-next-line import/no-cycle
import { OVERVIEW_MAP_LOADED } from '../redux/actionTypes';

let map;
let select;
let resizeSensor;
let itemById = {};
let vectorLayers = {};
let hoverEnabled = true;
const loaded = [];

const eventBus = useEventBus();

function _forAllFeatures(callback) {
    Object.keys(vectorLayers).forEach((layerId) => {
        const layer = vectorLayers[layerId];
        layer.getSource().forEachFeature(callback);
    });
}

function _createVectorLayer(title) {
    const layer = new VectorLayer({
        source: new VectorSource(),
        title,
        style: getFeatureStyle,
    });
    map.addLayer(layer);
    return layer;
}

function initproj4(additionalDefs = []) {
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
    proj4.defs(
        'EPSG:3857',
        '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
    );
    for (const def of additionalDefs) {
        proj4.defs(def[0], def[1]);
    }
    register(proj4);
}

function init(domElement, navigate, dispatch) {
    map = new Map({
        target: domElement.id,
        controls: [],
        layers: openSourceLayers,
        interactions: defaultInteractions({ doubleClickZoom: false }),
        view: new View({
            center: fromLonLat([0, 45]),
            zoom: 2,
            maxZoom: 8,
        }),
    });

    resizeSensor = new ResizeObserver(() => {
        map.updateSize();
    });
    resizeSensor.observe(domElement);

    dispatch({ type: OVERVIEW_MAP_LOADED, payload: map });

    vectorLayers.projects = _createVectorLayer('Projects');
    // vectorLayers.collections = _createVectorLayer('Collections');
    // vectorLayers.datasets = _createVectorLayer('Datasets');
    vectorLayers.highlighted = _createVectorLayer('Highlighted');

    map.on('pointermove', (evt) => {
        if (!evt.dragging) {
            map.getTargetElement().style.cursor =
                map
                    .getFeaturesAtPixel(map.getEventPixel(evt.originalEvent))
                    .filter((feature) => feature.getProperties().featurecla !== 'Coastline').length !== 0
                    ? 'pointer'
                    : '';
        }

        _forAllFeatures((olFeature) => olFeature.set('is_hovered', false));
        if (hoverEnabled) {
            map.forEachFeatureAtPixel(evt.pixel, (olFeature) => olFeature.set('is_hovered', true), {
                layerFilter: (layer) =>
                    layer === vectorLayers.projects ||
                    layer === vectorLayers.collections ||
                    layer === vectorLayers.datasets,
            });
        }

        eventBus.dispatch('mouse-coordinates', {
            coordinates: {
                x: evt.coordinate[0],
                y: evt.coordinate[1],
                z: undefined,
                picked: undefined,
            },
        });
    });

    select = new Select({
        layers: [vectorLayers.projects, vectorLayers.collections, vectorLayers.datasets, vectorLayers.highlighted],
        multi: true,
        style: false,
    });
    select.on('select', (e) => {
        // As we use multi, e.selected is not the same as select.getFeatures()
        // e.selected tracks the *changed* selection and not all the features selected

        // First update the styles of changed selection
        e.deselected.forEach((olFeature) => olFeature.set('is_selected', false));
        e.selected.forEach((olFeature) => olFeature.set('is_selected', true));

        // And apply selection
        if (select.getFeatures().getLength() === 0) {
            _doUnselectFeatures(dispatch);
            return;
        }

        // Fallback to mouse position
        e.selectedAt = e.selectedAt || e.mapBrowserEvent.coordinate;

        _doSelectFeature(select.getFeatures().item(0), e.selectedAt, dispatch);
    });

    map.addInteraction(select);

    map.on('dblclick', (evt) => {
        const olFeatures = map.getFeaturesAtPixel(evt.pixel);
        if (olFeatures.length !== 0) goToFeatures(olFeatures);
    });

    initScaleLine();
}

function initScaleLine() {
    const scaleLine = new ScaleLine({
        units: 'metric',
        minWidth: 100,
        maxWidth: 300,
        target: document.getElementById('status-bar-scale'),
    });
    map.addControl(scaleLine);
}

function deinit() {
    if (map) {
        Object.keys(vectorLayers).forEach((layerId) => {
            const layer = vectorLayers[layerId];
            map.removeLayer(layer);
        });
        for (const osLayer of openSourceLayers) {
            map.removeLayer(osLayer);
        }
    }
    itemById = {};
    vectorLayers = {};
    resizeSensor.disconnect();
    resizeSensor = null;
}

/**
 * Selects a feature:
 * * highlight the feature
 * * disables styling on hovering other features
 * * fits the feature into the view if not fully visible (to make sure the popup is visible)
 * * dispatches a *_SELECTED event (will be used by *Popup to display the correct popup)
 * @param {Feature} olFeature OpenLayers feature to select
 * @param {Array<Number>} at Coordinates where selection happend (to show the popup)
 * @param {CallableFunction} dispatch Dispatch function
 */
function _doSelectFeature(olFeature, at, dispatch) {
    // Disable hovering effect as it does not work well with itemsPopover (hovering is bubbled under the popover)
    hoverEnabled = false;
    _forAllFeatures((olFeat) => olFeat.set('is_hovered', false));

    // Fit feature into view if not fully visible
    if (!containsExtent(map.getView().calculateExtent(), olFeature.getGeometry().getExtent())) {
        if (!map.getView().getAnimating()) {
            goToFeatures([olFeature]);
        }
    }

    // Highlight
    _doHighlightFeature(olFeature);

    // Dispatch event to show popup
    const item = olFeature.get('parent_item');

    dispatch(overviewSlice.select({ selection: item, type: olFeature.get('type') }));

    // if (olFeature.get('type') === 'collection') {
    //     // dispatch({ type: COLLECTION_SELECTED, payload: { at, collection: item } });
    //     dispatch(loadCollectionDatasetsToOverview(item.id));
    // } else if (olFeature.get('type') === 'project') {
    //     // dispatch({ type: PROJECT_SELECTED, payload: { at, project: item } });
    //     dispatch(loadProjectDatasetsToOverview(item.id));
    // } else if (olFeature.get('type') === 'dataset') {
    //     // dispatch({ type: DATASET_SELECTED, payload: { at, dataset: item } });
    //     dispatch(fetchOrganizations());
    // }
}

/**
 * Deselects all features:
 * * unhighlights any feature highlighted
 * * enables styling on hovering
 * * dispatches an ITEM_UNSELECTED event (will be used by *Popup to hide itself)
 * @param {CallableFunction} dispatch Dispatch function
 */
function _doUnselectFeatures(dispatch) {
    hoverEnabled = true;
    _doUnhighlightFeature();
    dispatch(overviewSlice.select({ selection: undefined, type: undefined }));
    // dispatch({ type: ITEM_UNSELECTED });
}

/**
 * Highlights a feature:
 * * Lowlights all features
 * * Highlights this feature
 * * Hides this feature
 * * Clones this feature into the highlighted layer
 *
 * This assumes:
 * * Multiple items are selected and we want to highlight one of them programmatically
 * * Only one item is selected and thus we highlight it
 *
 * Can be called to change the highlighted feature (without the need of calling `_doUnhighlightFeature`).
 * @param {Feature} olFeature OpenLayer feature to highlight
 */
function _doHighlightFeature(olFeature) {
    _forAllFeatures((f) => f.set('is_lowlighted', true));
    olFeature.set('is_highlighted', true);

    const cloned = olFeature.clone();
    cloned.set('original', olFeature);
    _setOlFeatureVisible(olFeature, false);
    vectorLayers.highlighted.getSource().addFeatures([cloned]);
}

/**
 * Removes highlighting:
 * * Removes lowlights on all features
 * * If `olFeature` is provided, unhighlights it; otherwise traverse all features to unhighlight them
 * * If `olFeature` is provided, show it; otherwise traverse all features to show them
 * * Clears the highlighted layer
 *
 * It is necessary to call this function when we change the selection
 * @param {Feature=} olFeature OpenLayer feature to highlight
 */
function _doUnhighlightFeature(olFeature) {
    _forAllFeatures((f) => f.set('is_lowlighted', false));

    if (olFeature) {
        _setOlFeatureVisible(olFeature, true);
        olFeature.set('is_highlighted', false);
    } else {
        _forAllFeatures((f) => f.set('is_highlighted', false));
    }
    vectorLayers.highlighted.getSource().clear();
}

/**
 * Selects an item on the map.
 * This is equivalent to the user clicking on the item.
 * @param {collection|dataset|project} item Item to select
 * @param {Array<Number>} at Coordinates where selection happend (to show the popup)
 */
function selectItem(item, at) {
    const olFeature = getItem(item).olFeature;
    const deselected = [...select.getFeatures().getArray()];
    select.getFeatures().clear();
    select.getFeatures().push(olFeature);
    select.dispatchEvent({
        type: 'select',
        selected: [olFeature],
        deselected,
        selectedAt: at,
    });
}

/**
 * Unselects any selected item.
 * This is equivalent to the user clicking on the map without any item under the mouse.
 * @param {collection|dataset|project} item Item to unselect
 * @param {Array<Number>} at Coordinates where selection happend
 */
function unselectItem(item, at) {
    const deselected = [...select.getFeatures().getArray()];
    select.getFeatures().clear();
    select.dispatchEvent({
        type: 'select',
        selected: [],
        deselected,
        selectedAt: at,
    });
}

/**
 * Highlights an item on the map.
 * This should be called carefully, as it can easily mess with hovering&selection styles
 * @param {collection|dataset|project} item Item to highlight
 */
function highlightItem(item) {
    const { olFeature } = getItem(item);
    _doHighlightFeature(olFeature);
}

/**
 * Removed highlight on an item on the map.
 * @param {collection|dataset|project=} item Item to unhighlight
 */
function unhighlightItem(item) {
    const { olFeature } = item ? getItem(item) : {};
    _doUnhighlightFeature(olFeature);
}

// /**
//  * Loads a collection into the map
//  * @param {collection} _collection
//  * @returns {collection} Added collection (with `olFeature` field set)
//  */
// function loadCollection(_collection) {
//     if (!map) throw new Error('map is undefined');

//     const collection = { ..._collection }; // Copy arg so we can store here references without modifying the initial object (being used in redux)

//     if (collection.geometry) {
//         const olFeature = new GeoJSON().readFeature(collection.geometry, {
//             dataProjection: 'EPSG:4326', // Projection within GeoJSON - backend always returns "standard" 4326
//             featureProjection: map.getView().getProjection(),
//         });
//         olFeature.set('type', 'collection');
//         olFeature.set('parent_item', collection);
//         olFeature.set('layer', vectorLayers.collections);
//         _setOlFeatureVisible(olFeature, false);
//         vectorLayers.collections.getSource().addFeatures([olFeature]);

//         collection.olFeature = olFeature;
//     } else {
//         collection.olFeature = null;
//     }

//     itemById[collection.id] = collection;

//     return collection;
// }

/**
 * Loads a project into the map
 * @param {project} _project
 * @returns {project} Added project (with `olFeature` field set)
 */
function loadProject(_project) {
    if (!map) throw new Error('map is undefined');

    const project = { ..._project }; // Copy arg so we can store here references without modifying the initial object (being used in redux)

    if (project.geometry) {
        const olFeature = new GeoJSON().readFeature(project.geometry, {
            dataProjection: 'EPSG:4326', // Projection within GeoJSON - backend always returns "standard" 4326
            featureProjection: map.getView().getProjection(),
        });
        olFeature.set('type', 'project');
        olFeature.set('parent_item', project);
        olFeature.set('layer', vectorLayers.projects);

        // Add feature to project layer
        vectorLayers.projects.getSource().addFeatures([olFeature]);
        project.olFeature = olFeature;
    } else {
        project.olFeature = null;
    }

    itemById[project.id] = project;

    return project;
}

// /**
//  * Loads a dataset into the map
//  * @param {dataset} _dataset
//  * @returns {dataset} Added dataset (with `olFeature` field set)
//  */
// function loadDataset(_dataset) {
//     if (!map) throw new Error('map is undefined');

//     const dataset = { ..._dataset }; // Copy arg so we can store here references without modifying the initial object (being used in redux)

//     const olFeature = new GeoJSON().readFeature(dataset.geometry, {
//         dataProjection: `EPSG:${dataset.projection}`,
//         featureProjection: map.getView().getProjection(),
//     });
//     olFeature.set('type', 'dataset');
//     olFeature.set('parent_item', dataset);
//     olFeature.set('layer', vectorLayers.datasets);

//     // Add feature to datasets layer
//     vectorLayers.datasets.getSource().addFeatures([olFeature]);
//     dataset.olFeature = olFeature;

//     itemById[dataset.id] = dataset;

//     return dataset;
// }

/**
 * Returns the item object
 * @param {str|collection|dataset|project} itemOrId Item or ID
 * @returns {collection|dataset|project}
 */
function getItem(itemOrId) {
    let id = itemOrId;
    if (typeof itemOrId === 'object') {
        // assume id
        id = itemOrId.id;
    }
    return itemById[id];
}

/**
 * Fit map extent to display one or several layers
 * @param {Array<str>=} layerIds Layers ID (e.g. `projects`, `collections`, `datasets`, `highlighted`) - if none provided go to all
 * @param {Object} options OpenLayer's `View.fit` options
 */
function goToLayers(
    layerIds,
    options = {
        padding: [200, 200, 200, 200],
        duration: 2000,
    }
) {
    layerIds = layerIds || Object.keys(vectorLayers);
    let globalExtent = createEmpty();
    for (const layerId of layerIds) {
        const layer = vectorLayers[layerId];
        const extent = layer.getSource().getExtent();
        globalExtent = extend(globalExtent, extent);
    }

    map.getView().fit(globalExtent, options);
}

/**
 * Fit map extent to display one layer
 * @param {str=} layerId Layer ID (e.g. `projects`, `collections`, `datasets`, `highlighted`)
 * @param {Object} options OpenLayer's `View.fit` options
 */
function goToLayer(layerId, options) {
    goToLayers([layerId], options);
}

/**
 * Fit map extent to display one or several OpenLayer features
 * @param {Array<Feature>} olFeatures OpenLayers features to display
 * @param {Object} options OpenLayer's `View.fit` options
 */
function goToFeatures(
    olFeatures,
    options = {
        padding: [200, 200, 200, 200],
        duration: 2000,
    }
) {
    let globalExtent = createEmpty();
    for (const olFeature of olFeatures) {
        const extent = olFeature.getGeometry().getExtent();
        globalExtent = extend(globalExtent, extent);
    }

    map.getView().fit(globalExtent, options);
}

/**
 * Fit map extent to display one or several items
 * @param {Array<collection|dataset|project>} items Items to display
 * @param {Object} options OpenLayer's `View.fit` options
 */
function goToItems(items, options) {
    goToFeatures(
        items.map((i) => getItem(i).olFeature),
        options
    );
}

/**
 * Fit map extent to display and select one item
 * @param {collection|dataset|project} item Item to display
 * @param {Object} options OpenLayer's `View.fit` options
 */
function goToItem(item, options) {
    goToItems([item], options);
    selectItem(item, getCenter(item.olFeature.getGeometry().getExtent()));
}

function _setOlFeatureVisible(olFeature, visible) {
    if (visible) {
        olFeature.setStyle(null);
    } else {
        olFeature.setStyle(new Style(null));
    }
}

/**
 * Hides or displays an item
 * @param {collection|dataset|project} item Item to display
 * @param {boolean} visible Visibility
 */
function setItemVisible(item, visible) {
    const gotItem = getItem(item);
    if (gotItem) _setOlFeatureVisible(gotItem.olFeature, visible);
}

function selectPolygon(cb) {
    if (!map) return;

    const source = new VectorSource();
    const layer = new VectorLayer({
        source,
    });
    map.addLayer(layer);

    const draw = new Draw({
        type: 'Circle',
        geometryFunction: createBox(),
        source,
    });

    map.addInteraction(draw);
    draw.on('drawend', (event) => {
        const feature = event.feature;
        const json = JSON.parse(
            new GeoJSON().writeGeometry(feature.getGeometry(), {
                dataProjection: 'EPSG:4326',
                featureProjection: map.getView().getProjection(),
            })
        );

        map.removeInteraction(draw);
        map.removeLayer(layer);
        cb(json);
    });
}

function addLayer(layer, name) {
    let layers;
    let groupExists = false;
    map.getLayers().forEach((group) => {
        if (group.getProperties().title === name) {
            groupExists = true;
            layers = group.getLayers();
            layers.push(layer);
            group.setLayers(layers);
        }
    });

    if (!groupExists) {
        const group = new Group({
            title: name,
            layers: [layer],
            visible: true,
        });
        map.addLayer(group);
    }
}

function addLoadedCollection(id) {
    loaded.push(id);
}

function checkLoadedCollection(id) {
    return loaded.includes(id);
}

const overviewMapService = {
    initproj4,
    init,
    deinit,
    // loadCollection,
    loadProject,
    // loadDataset,
    goToLayers,
    goToLayer,
    getItem,
    goToItem,
    goToItems,
    highlightItem,
    setItemVisible,
    selectItem,
    selectPolygon,
    unhighlightItem,
    unselectItem,
    addLayer,
    addLoadedCollection,
    checkLoadedCollection,
    initScaleLine,
};

export default overviewMapService;
