import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequests } from '../../redux/actions';
import { getMembershipRequests } from '../../redux/selectors';
import AccessRequestItem from './AccessRequestItem';

const AccessRequests = () => {
    const dispatch = useDispatch();

    const requests = useSelector(getMembershipRequests);

    useEffect(() => dispatch(fetchRequests()), []);

    const renderedListItems = requests ? requests.map((request) => <AccessRequestItem request={request} />) : [];

    return (
        <>
            <div className="full-page-title">
                Access Requests<span className="faded-text">({renderedListItems ? renderedListItems.length : 0})</span>
            </div>
            <table>
                <tr className="access-admin-header">
                    <th>Name</th>
                    <th>Email</th>
                    <th>Organization</th>
                    <th>Actions</th>
                </tr>
                {renderedListItems}
            </table>
        </>
    );
};

export default AccessRequests;
