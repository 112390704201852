import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { REMOVE_MEMBERSHIP_REQUEST } from '../../redux/actionTypes';
import { ROLES } from '../../services/Constants';
import DosApi from '../../services/DosApi';

const AccessRequestItem = (props) => {
    const dispatch = useDispatch();
    const { request } = props;
    const user = request.user;
    const organization = request.organization;

    const acceptUser = () => {
        DosApi.updateMembership(request.id, { role: ROLES.MEMBER }).then((data) =>
            dispatch({ type: REMOVE_MEMBERSHIP_REQUEST, payload: data })
        );
    };

    const rejectUser = () => {
        DosApi.deleteMembership(request.id).then(() => {
            dispatch({ type: REMOVE_MEMBERSHIP_REQUEST, payload: request });
            // Update the users list but only when its on the right org; How?
        });
    };

    return (
        <tr className="access-item">
            <td className="user-name">
                {user.given_name} {user.family_name}
            </td>
            <td className="user-email">{user.email}</td>
            <td className="user-organisation">{organization.display_name}</td>
            <td>
                <div className="admin-actions">
                    <Button
                        className="borderless green"
                        id="remove-user"
                        title="Accept user"
                        onClick={() => acceptUser()}
                    >
                        <i className="fal fa-check" />
                    </Button>
                    <Button
                        className="borderless red"
                        id="remove-user"
                        title="Reject user"
                        onClick={() => rejectUser()}
                    >
                        <i className="fal fa-xmark" />
                    </Button>
                </div>
            </td>
        </tr>
    );
};
export default AccessRequestItem;
