// React
import React from 'react';

// Formik
import { Field, useField } from 'formik';

// Boostrap
import { FormFeedback } from 'reactstrap';
import Checkbox from 'components/Checkbox';

const BaseField = ({ label = undefined, className = undefined, id = undefined, ...props }) => {
    const [field, meta] = useField(props);
    if (!className) {
        className = props.as === 'select' ? 'form-select' : 'form-control';
        if (props.type === 'checkbox' || props.type === 'radio') {
            className = 'form-check';
        }
    }
    if (!id) {
        id = props.name;
    }

    if (meta.error && meta.touched) {
        className += ' is-invalid';
    }
    if (props.type === 'checkbox') {
        return (
            <div className="form-group">
                <div className="styled-checkbox">
                    <Checkbox label={label} id={id} {...props} />
                </div>
                {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
            </div>
        );
    }
    return (
        <div className="form-group">
            <Field {...field} {...props} id={id} className={className} />
            {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
        </div>
    );
};

export default BaseField;
