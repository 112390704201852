import { Vector4 } from 'three';

/**
 * A region of the plane in normalized coordinates (i.e in the [0, 1] range).
 */
class SeismicPlaneRegion {
    u: number;

    v: number;

    width: number;

    height: number;

    /**
     * @param u The U coordinate (along the plane line)
     * @param v The V coordinate (along the height)
     * @param width The width (along the plane line) of the region.
     * @param height The height of the region.
     */
    constructor(u?: number, v?: number, width?: number, height?: number) {
        /**
         *  The region origin in the horizontal axis, in normalized values
         *  (i.e a value of 0.5 means the corner is located at 50% of the length of the plane)
         */
        this.u = u;
        /**
         * The region origin in the vertical axis, in normalized values
         *  (i.e a value of 0.5 means the corner is located at 50% of the height of the plane)
         */
        this.v = v;
        /**
         * The width of the region, in normalized values
         *  (i.e a width of 1 is 100% of the width of the plane)
         */
        this.width = width;
        /** The height of the region, in normalized values
         * (i.e a height of 1 is 100% of the height of the plane)
         */
        this.height = height;
    }

    static full() {
        return new SeismicPlaneRegion(0, 0, 1, 1);
    }

    /**
     * Computes an offset/scale tuple that provides the transformation from the source to the destination region.
     * @param {SeismicPlaneRegion} source The destination region.
     * @param {SeismicPlaneRegion} dest The parent region.
     * @param {Vector4} target The vector where to put the result.
     * @returns {Vector4}
     */
    static getOffsetScale(source: SeismicPlaneRegion, dest: SeismicPlaneRegion, target: Vector4): Vector4 {
        const destW = dest.width;
        const destH = dest.height;

        const sourceW = source.width;
        const sourceH = source.height;

        const x = Math.round((1000 * (dest.u - source.u)) / sourceW) * 0.001;
        const y = Math.round((1000 * (dest.v - source.v)) / sourceH) * 0.001;

        const scaleX = Math.round((1000 * destW) / sourceW) * 0.001;
        const scaleY = Math.round((1000 * destH) / sourceH) * 0.001;

        return target.set(x, y, scaleX, scaleY);
    }

    /**
     * Returns a new region with the specific margin applied.
     * @param {number} marginU The margin on the U axis, in normalized values.
     * @param {number} marginV The margin on the V axis, in normalized values.
     * @returns {SeismicPlaneRegion} The new region.
     */
    withMargin(marginU: number, marginV: number): SeismicPlaneRegion {
        const u = this.u - marginU;
        const v = this.v - marginV;
        const w = this.width + marginU * 2;
        const h = this.height + marginV * 2;

        return new SeismicPlaneRegion(u, v, w, h);
    }

    /**
     * Clamps (clips) the region to the specified values.
     * @param u The U coordinate of the corner of the clipping region.
     * @param v The V coordinate of the corner of the clipping region.
     * @param w The width of the clipping region.
     * @param h The height of the clipping region.
     * @returns This region clamped to the specific region.
     */
    clamp(u: number, v: number, w: number, h: number): this {
        const clampedU = Math.max(this.u, u);
        const clampedV = Math.max(this.v, v);
        const uMax = Math.min(this.u + this.width, u + w);
        const vMax = Math.min(this.v + this.height, v + h);
        const clampedWidth = uMax - clampedU;
        const clampedHeight = vMax - clampedV;

        this.u = clampedU;
        this.v = clampedV;
        this.width = clampedWidth;
        this.height = clampedHeight;

        return this;
    }

    toString() {
        return `[${this.u.toFixed(3)}, ${this.v.toFixed(3)}, ${this.width.toFixed(3)}, ${this.height.toFixed(3)}]`;
    }

    /**
     * Clones this region.
     * @returns {SeismicPlaneRegion}
     */
    clone(): SeismicPlaneRegion {
        return new SeismicPlaneRegion().copy(this);
    }

    /**
     * @param {SeismicPlaneRegion} other The copy source.
     * @returns {this}
     */
    copy(other: SeismicPlaneRegion): this {
        this.u = other.u;
        this.v = other.v;
        this.width = other.width;
        this.height = other.height;
        return this;
    }

    /**
     * Splits this region into `uCount * vCount` sub-regions.
     * @param {number} uCount The horizontal subdivisions.
     * @param {number} vCount The vertical subdivisions.
     * @returns {SeismicPlaneRegion[]} The sub-regions.
     */
    split(uCount: number, vCount: number): SeismicPlaneRegion[] {
        if (uCount < 1 || vCount < 1) {
            throw new Error('Invalid subdivisions. Must be strictly positive.');
        }

        if (uCount === 1 && vCount === 1) {
            return [this.clone()];
        }

        const minX = this.u;
        const minY = this.v;
        const w = this.width / uCount;
        const h = this.height / vCount;

        const result = [];

        for (let x = 0; x < uCount; x++) {
            for (let y = 0; y < vCount; y++) {
                const u = minX + x * w;
                const v = minY + y * h;
                const extent = new SeismicPlaneRegion(u, v, w, h);
                result.push(extent);
            }
        }

        return result;
    }
}

export default SeismicPlaneRegion;
