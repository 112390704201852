import { useEffect, useState } from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik, Form } from 'formik';
import Select from 'components/forms/Select';
import BaseInput from 'components/forms/BaseInput';
import { useAppDispatch, useAppSelector } from 'store';
import { getOrganizationsForCreatingDataset, getProjections, getCollections } from '../../redux/selectors';
import handleApiError from '../../services/Forms';

import { updateDataset } from '../../redux/actions';
import { supportedDataTypes, supportedDataTypesLabels, supportedTypesLabels } from '../../services/Labels';
import HelpPanel from '../forms/HelpPanel';

const EditDataset = ({ onClose, openDataset }) => {
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(getOrganizationsForCreatingDataset);
    const collections = useAppSelector(getCollections);
    const projections = useAppSelector(getProjections);

    const initialFormState = {
        id: openDataset.id,
        type: { value: openDataset.type, label: supportedTypesLabels[openDataset.type] },
        datatype: { value: openDataset.datatype, label: supportedDataTypesLabels[openDataset.datatype] },
        name: openDataset.name,
        collection: openDataset.collection_id
            ? {
                  value: openDataset.collection_id,
                  label: collections.find((c) => c.id === openDataset.collection_id).name,
              }
            : undefined,
        projection: {
            value: openDataset.projection,
            label: projections.find((p) => p.srid === String(openDataset.projection)).title,
        },
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    useEffect(() => {
        setCurrentFormState(initialFormState);
    }, [organizations]);

    const validation = (values) => {
        const errors: { name?: string; type?: string; datatype?: string; projection?: string } = {};
        if (!values.name) errors.name = 'Required';
        if (!values.type) errors.type = 'Required';
        if (!values.datatype) errors.datatype = 'Required';
        if (!values.projection) errors.projection = 'Required';
        return errors;
    };

    const editDataset = (values, helpers) => {
        const formattedValues: {
            id: string;
            name: string;
            type: string;
            datatype: string;
            projection: string;
            collection_id?: string;
        } = {
            id: values.id,
            name: values.name,
            type: values.type.value,
            datatype: values.datatype.value,
            projection: values.projection.value,
        };
        if (values.collection) formattedValues.collection_id = values.collection.value;

        const formData = new FormData();
        formData.append('update', JSON.stringify(formattedValues));

        dispatch(updateDataset(formattedValues.id, formData))
            .then(onClose())
            .catch((err) => {
                handleApiError(err, helpers);
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={editDataset}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, errors, isSubmitting, setFieldValue }) => (
                <Form>
                    <ModalHeader>Edit {openDataset.name}</ModalHeader>
                    <ModalBody>
                        <HelpPanel>
                            Changing the projection will cause the dataset to be reprocessed. This will delete the
                            existing data processed by SCOPE.
                        </HelpPanel>
                        <Select
                            name="type"
                            options={[
                                ...Object.keys(supportedTypesLabels).map((e) => ({
                                    value: e,
                                    label: supportedTypesLabels[e],
                                })),
                            ]}
                            value={values.type}
                            setFieldValue={setFieldValue}
                            placeholder="Select... (Required)"
                        />
                        <Select
                            name="datatype"
                            options={
                                values.type
                                    ? [
                                          ...supportedDataTypes[values.type.value].map((e) => ({
                                              value: e,
                                              label: supportedDataTypesLabels[e],
                                          })),
                                      ]
                                    : []
                            }
                            value={values.datatype}
                            setFieldValue={setFieldValue}
                            placeholder={`Select... (${values.type ? 'Required' : 'Select type first'})`}
                        />
                        <BaseInput name="name" label="Enter name... (Required)" titled />
                        <Select
                            name="projection"
                            options={
                                projections
                                    ? projections.map((e) => ({
                                          value: e.srid,
                                          label: e.title,
                                      }))
                                    : []
                            }
                            placeholder="Projection (Required)"
                            setFieldValue={setFieldValue}
                            isSearchable
                            value={values.projection}
                        />
                        <Select
                            name="collection"
                            options={[
                                ...collections
                                    .filter(
                                        (collection) =>
                                            collection.user_permissions.update_collection &&
                                            collection.organization_id === values.organization?.value
                                    )
                                    .map((e) => ({
                                        value: e.id,
                                        label: e.name,
                                    })),
                            ]}
                            placeholder="Collection..."
                            setFieldValue={setFieldValue}
                            isSearchable
                            value={values.collection}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <span className="error-text">{errors.submit}</span>
                        <button type="submit" className="pane-button large highlight" disabled={isSubmitting}>
                            Apply <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default EditDataset;
