import { Button, UncontrolledTooltip } from 'reactstrap';
import { gotoAnnotation, selectAnnotation } from 'redux/annotationActions';
import { selectAnnotationForEdit, startAnnotationEdit, startAnnotationView } from 'redux/actions';
import * as annotationsSlice from 'redux/annotations';
import Annotation from 'types/Annotation';
import { ANNOTATION_CREATESTATE, EDITSTATE, PANE } from 'services/Constants';
import { useAppDispatch, useAppSelector } from 'store';
import { useEventBus } from 'EventBus';
import InlineDropMenu from '../../InlineDropMenu';
import Status from './Status';

type Props = {
    annotation: Annotation;
    deleteCallback: (annotation: Annotation) => void;
};

const AnnotationItem = (props: Props) => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();

    const annotation = props.annotation;
    const deleteCallback = props.deleteCallback;
    const activeAnnotation = useAppSelector(annotationsSlice.active) || { id: null };

    const createState = useAppSelector(annotationsSlice.createState);
    const editState = useAppSelector(annotationsSlice.editState);

    const toggleOpen = () => {
        if (activeAnnotation.id === annotation.id) dispatch(selectAnnotation(null));
        else {
            dispatch(selectAnnotation(annotation));
            eventBus.dispatch('create-pane', { paneType: PANE.SELECTION, showExisting: false });
        }
    };

    const triggerEdit = () => {
        dispatch(selectAnnotationForEdit(annotation));
    };
    const triggerEditGeometry = () => {
        dispatch(startAnnotationEdit(annotation));
    };
    const triggerViewGeometry = () => {
        dispatch(startAnnotationView(annotation));
    };
    const triggerGotoGeometry = () => {
        dispatch(gotoAnnotation(annotation));
    };

    return (
        <li className={`${annotation.id === activeAnnotation.id ? 'highlight' : null}`}>
            <div>
                <label htmlFor={`annotation-${annotation.id}`}>
                    <button type="button" onClick={toggleOpen} id={`annotation-${annotation.id}`} aria-label="None" />
                    {annotation.name}
                </label>
                <Status annotation={annotation} />
                <Button
                    className="borderless purple annotation-comments-icon full-color"
                    id="annotation-comments"
                    title="Comments"
                    onClick={() => {
                        if (activeAnnotation.id !== annotation.id) dispatch(selectAnnotation(annotation));
                    }}
                >
                    <i className="fas fa-message-lines" />
                    <span> {annotation.comment_count}</span>
                </Button>
                <InlineDropMenu id={`annotation-actions-${annotation.id}`}>
                    {annotation.user_permissions.manage ? (
                        <>
                            <Button
                                className="borderless light-blue"
                                title="Edit Geometry"
                                onClick={triggerEditGeometry}
                                disabled={editState !== EDITSTATE.NONE || createState !== ANNOTATION_CREATESTATE.NONE}
                            >
                                <i className="fas fa-draw-polygon" />
                            </Button>
                            <Button
                                className="borderless green"
                                title="Edit annotation"
                                onClick={triggerEdit}
                                disabled={editState !== EDITSTATE.NONE || createState !== ANNOTATION_CREATESTATE.NONE}
                            >
                                <i className="fas fa-pen" />
                            </Button>
                            <Button
                                className="borderless red"
                                title="Delete Annotation"
                                onClick={() => deleteCallback(annotation)}
                            >
                                <i className="fas fa-trash-can" />
                            </Button>
                            <Button
                                className="borderless light-blue"
                                title="Goto Annotation"
                                onClick={triggerGotoGeometry}
                            >
                                <i className="fas fa-location-arrow" />
                            </Button>
                        </>
                    ) : (
                        <Button
                            className="borderless light-blue"
                            id={`annotation-view-geom-${annotation.id}`}
                            title="View Geometry"
                            onClick={triggerViewGeometry}
                        >
                            <i className="fal fa-eye" />
                        </Button>
                    )}
                </InlineDropMenu>
                {editState !== EDITSTATE.NONE || createState !== ANNOTATION_CREATESTATE.NONE ? (
                    <UncontrolledTooltip target={`annotation-actions-${annotation.id}`}>
                        There is already an annotation action ongoing.
                    </UncontrolledTooltip>
                ) : null}
            </div>
        </li>
    );
};

export default AnnotationItem;
