import { Color } from 'three';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerializableColor, SerializablePlane, SerializableVector3, toHex, toColor, toVector3 } from 'types/common';
import { RootState } from 'store';
import { View } from 'types/serialization/View';

export type State = {
    center: SerializableVector3;
    invert: boolean;
    opacity: number;
    clippingPlane: SerializablePlane;
    visible: boolean;
    color: SerializableColor;
    azimuthDegrees: number;
};

const initialState: State = {
    visible: false,
    invert: false,
    center: undefined,
    clippingPlane: null,
    opacity: 0.1,
    color: toHex(new Color('cyan')),
    azimuthDegrees: 0,
};

const self = (state: RootState) => state.crossSections;

// Selectors
export const getCenter = createSelector(
    (s) => self(s).center,
    (center) => toVector3(center)
);
export const getVisibility = createSelector(self, (s) => s.visible);
export const getInvert = createSelector(self, (s) => s.invert);
export const getAzimuth = createSelector(self, (s) => s.azimuthDegrees);
export const getOpacity = createSelector(self, (s) => s.opacity);
export const getColor = createSelector(
    (s) => self(s).color,
    (color) => toColor(color)
);
export const getPlane = createSelector(self, (s) => s.clippingPlane);

export const slice = createSlice({
    name: 'cross-section',
    initialState,
    reducers: {
        reset: (state) => {
            state.azimuthDegrees = initialState.azimuthDegrees;
            state.color = initialState.color;
            state.invert = initialState.invert;
            state.opacity = initialState.opacity;
            state.visible = initialState.visible;
            state.clippingPlane = initialState.clippingPlane;
            state.center = initialState.center;
        },
        loadView: (state, action: PayloadAction<View>) => {
            const settings = action.payload.crossSections;

            if (settings) {
                state.visible = settings.enabled ?? state.visible;
                state.azimuthDegrees = settings.azimuth ?? state.azimuthDegrees;
                state.color = settings.color ?? state.color;
                state.opacity = settings.opacity ?? state.opacity;
                state.invert = settings.invert ?? state.invert;
                state.center = settings.center ?? state.center;
            }
        },
        center: (state, action: PayloadAction<SerializableVector3>) => {
            state.center = action.payload;
        },
        azimuth: (state, action: PayloadAction<number>) => {
            state.azimuthDegrees = action.payload;
        },
        opacity: (state, action: PayloadAction<number>) => {
            state.opacity = action.payload;
        },
        color: (state, action: PayloadAction<SerializableColor>) => {
            state.color = action.payload;
        },
        visible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
        invert: (state, action: PayloadAction<boolean>) => {
            state.invert = action.payload;
        },
        plane: (state, action: PayloadAction<SerializablePlane>) => {
            state.clippingPlane = action.payload;
        },
    },
});

// Actions
export const loadView = slice.actions.loadView;
export const setCenter = slice.actions.center;
export const setVisibility = slice.actions.visible;
export const setInvert = slice.actions.invert;
export const setAzimuth = slice.actions.azimuth;
export const setOpacity = slice.actions.opacity;
export const setColor = slice.actions.color;
export const setPlane = slice.actions.plane;
export const reset = slice.actions.reset;

export const reducer = slice.reducer;
