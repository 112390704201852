import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import type { Point } from 'geojson';
import DosApi from '../../services/DosApi';
import ThreejsGroupLayer from './ThreejsGroupLayer';
import { ConstructorParams as BaseConstructorParams } from './Layer';
import { ZSCALE_BEHAVIOR } from '../../services/Constants';
import IsClickable from './IsClickable';

export interface ConstructorParams extends BaseConstructorParams {
    documents: string[];
}

class DocumentLayer extends ThreejsGroupLayer implements IsClickable {
    readonly isClickable = true;
    private xpos: number;
    private ypos: number;
    private zpos: number;
    private documents: string[];
    private label: CSS2DObject;

    constructor(params: ConstructorParams) {
        super(params);
        this.documents = params.documents;
        this.settings.zscaleEffect = ZSCALE_BEHAVIOR.COUNTERACT;

        this.assignInitialValues();
    }

    async clickHandler() {
        this.documents.forEach((url) => DosApi.openDocument(url));
    }

    protected override async initOnce() {
        await super.initOnce();
        const footprint = (await this.getFootprint()) as Point;
        const coords = footprint.coordinates;
        this.xpos = coords[0];
        this.ypos = coords[1];
        this.zpos = coords[2];

        this.initObject();
        this.initialized = true;
    }

    private initObject() {
        const point = document.createElement('div');
        point.style.position = 'absolute';
        point.style.borderRadius = '50%';
        point.style.width = '1rem';
        point.style.height = '1rem';
        point.style.backgroundColor = '#070607';
        point.style.border = '2px solid #8888ec';
        point.style.font = '14px futura-pt';
        point.style.fontWeight = 'bold';
        point.style.pointerEvents = 'auto';
        point.style.cursor = 'pointer';
        point.style.opacity = `${this.settings.opacity}`;

        point.addEventListener('click', () => this.clickHandler());

        this.label = new CSS2DObject(point);
        this.label.position.set(this.xpos, this.ypos, this.zpos);
        this.label.visible = this.getVisibility();

        this.object3d.add(this.label);

        this.object3d.updateMatrix();
        this.object3d.updateMatrixWorld(true);
        this.object3d.userData.datasetId = this.datasetId;
        this.notifyLayerChange();
    }

    getOpacity() {
        return this.settings.opacity;
    }

    setOpacity(value: number) {
        this.settings.opacity = value;
        if (this.label) {
            this.label.element.style.opacity = `${value}`;
        }
    }

    getClickableElement() {
        return this.get3dElement();
    }
}

export default DocumentLayer;
