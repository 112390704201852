import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getError } from '../redux/selectors';
import { ERROR_HIDE } from '../redux/actionTypes';

const ErrorModal = () => {
    const err = useSelector(getError);
    const dispatch = useDispatch();
    const [errorShown, setErrorShown] = useState(false);

    const toggleError = () => {
        dispatch({
            type: ERROR_HIDE,
        });
        setErrorShown(false);
    };

    let message;
    let details;
    if (err) {
        if (err instanceof Error) {
            if (err.response) {
                details = `Error from backend ${err.response.status}: ${err.response.statusText}`;
                if (err.response.status === 422) {
                    message = 'Some fields are invalid';
                    details = err.response.data.detail.map((field) => `${field.loc[1]}: ${field.msg}`).join(', ');
                } else if (err.response.data && err.response.data.detail) {
                    message = err.response.data.detail;
                } else if (err.response.data && err.response.data.message) {
                    message = err.response.data.message;
                } else if (err.response.data) {
                    message = err.response.data;
                } else {
                    message = err.response.statusText;
                }
            } else {
                message = err.modal_message ? err.modal_message : err.message;
                details = err.stack ? <code>{err.stack}</code> : undefined;
            }
        } else if (typeof err === 'string') {
            message = err;
        } else {
            message = err.toString();
        }
    }

    return (
        <Modal isOpen={!!err} toggle={toggleError} keyboard={false} centered>
            <ModalHeader>Error</ModalHeader>
            <ModalBody>
                {message}
                {details !== undefined ? (
                    <label htmlFor="error-dropdown" className="error-detail-label dropdown-arrow">
                        <span>{errorShown ? 'Hide' : 'Show'} Details</span>
                        <input type="checkbox" id="error-dropdown" onChange={(e) => setErrorShown(e.target.checked)} />
                        <div className="arrow" />
                    </label>
                ) : null}
                {details !== undefined && errorShown ? <p className="text-secondary p-3">{details}</p> : null}
            </ModalBody>
        </Modal>
    );
};

export default ErrorModal;
