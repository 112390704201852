import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Annotation, { AnnotationComment, AnnotationId } from 'types/Annotation';
import { User } from 'types/User';
import { useAppDispatch } from 'store';
import Select, { Option } from 'components/Select';
import { updateComment, updateReply } from '../../../redux/actions';
import { getProjectUsers } from '../../../redux/selectors';

export type Props = {
    annotation: Annotation;
    comment: AnnotationComment;
    parent_id?: AnnotationId;
    onSubmit?: () => void;
    close?: () => void;
};

const CommentEditForm = (props: Props) => {
    const dispatch = useAppDispatch();
    const [submitting, setSumbitting] = useState(false);
    const [mentioning, setMentioning] = useState(false);

    const users: User[] = useSelector(getProjectUsers);

    const ref = useRef<HTMLDivElement>();

    const submit = () => {
        setSumbitting(true);
        if (ref.current.innerText === '') {
            setSumbitting(false);
            if (props.onSubmit) props.onSubmit();
        } else if (props.parent_id)
            dispatch(
                updateReply(props.annotation, props.parent_id, props.comment.id, {
                    content: document.getElementById(`edit-field-${props.comment.id}`).innerText,
                })
            ).finally(() => {
                if (props.onSubmit) props.onSubmit();
            });
        else
            dispatch(
                updateComment(props.annotation, props.comment.id, {
                    content: document.getElementById(`edit-field-${props.comment.id}`).innerText,
                })
            ).finally(() => {
                if (props.onSubmit) props.onSubmit();
            });
    };

    return (
        <div className={`comment-form ${props.parent_id ? 'indent' : ''}`}>
            <div className="comment-close-container">
                <button type="button" className="btn-edit-comment-close" onClick={props.close}>
                    <i className="fas fa-xmark-large" />
                </button>
            </div>
            <div
                placeholder="Edit comment..."
                className={`comment-field ${submitting ? 'faded-text' : ''}`}
                role="textbox"
                id={`edit-field-${props.comment.id}`}
                aria-label="comment edit field"
                contentEditable={!submitting}
                suppressContentEditableWarning
                ref={ref}
            >
                {props.comment.content}
            </div>
            <div className="comment-control-row">
                <div className="comment-controls">
                    <Button
                        className="borderless"
                        id="mention"
                        title="Mention"
                        onClick={() => setMentioning(!mentioning)}
                    >
                        <i className="comment-control fal fa-at" />
                    </Button>
                    {mentioning ? (
                        <Select
                            options={users.map((user) => ({
                                value: user.id,
                                label: `${user.given_name} ${user.family_name}`,
                            }))}
                            placeholder="Select a user to mention..."
                            autoFocus
                            onChange={(val) => {
                                ref.current.textContent += `@(${(val as Option<string>).value} | ${(val as Option<string>).label}) `;
                                setMentioning(false);
                            }}
                            isSearchable
                            className="mentionselect"
                        />
                    ) : null}
                </div>
                <Button className="borderless" id="send" title="Send" onClick={submit}>
                    <i className="comment-send fal fa-paper-plane-top" />
                </Button>
            </div>
        </div>
    );
};

export default CommentEditForm;
