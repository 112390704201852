import { Formik, Form } from 'formik';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Annotation, { AnnotationFilter, isFiltered } from 'types/Annotation';
import { SsdmType, UUID } from 'types/common';
import { User } from 'types/User';
import { useAppDispatch, useAppSelector } from 'store';
import * as annotationSlice from 'redux/annotations';
import Checkbox from 'components/Checkbox';
import Dataset from 'types/Dataset';
import { getSSDMTypes, getUsernames } from '../../../redux/selectors';
import { DEFAULT_ANNOTATION_FILTER } from '../../../services/Constants';

import Select from '../../forms/Select';

export type Props = {
    close: () => void;
    authors: string[];
    datasets: Dataset[];
};

const AnnotationFilters = (props: Props) => {
    const dispatch = useAppDispatch();

    const ssdmTypes = useAppSelector(getSSDMTypes);
    const usernames = useAppSelector<User[]>(getUsernames);
    const filters = useAppSelector<AnnotationFilter>(annotationSlice.filter);
    const annotations = useAppSelector<Annotation[]>(annotationSlice.list);

    const generateOption = (id: UUID) => ({
        label: usernames.find((x) => x.id === id)
            ? `${usernames.find((x) => x.id === id).given_name} ${usernames.find((x) => x.id === id).family_name}`
            : 'Unknown',
        value: id,
    });

    const getSSDMGroupName = (ssdmGroup: string) => {
        const words = ssdmGroup
            .substring(ssdmGroup.indexOf('_') + 1)
            .toLowerCase()
            .split('_');

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substring(1);
        }

        return words.join(' ');
    };

    const ssdmGrouped = ssdmTypes.reduce((array: SsdmType[], x: SsdmType) => {
        if (!array[x.parent_type]) {
            array[x.parent_type] = [];
        }
        array[x.parent_type].push({ label: `${x.name} ${x.display_name}`, value: x.name });
        return array;
    }, {});
    const ssdmOptions = [];
    Object.keys(ssdmGrouped).forEach((x) => ssdmOptions.push({ label: getSSDMGroupName(x), options: ssdmGrouped[x] }));

    return (
        <Formik
            initialValues={filters}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
                if (!values.object) values.ssdmTypes = [];
                dispatch(annotationSlice.setAnnotationFilter(values));
                props.close();
            }}
        >
            {({ values, setFieldValue, handleChange, handleBlur, setValues }) => (
                <Form>
                    <ModalHeader toggle={() => props.close()}>
                        Filter options{' '}
                        <span className="faded-text green">
                            ({annotations.filter((annotation) => !isFiltered(values, annotation)).length})
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <Checkbox
                            id="observation"
                            value="observation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.observation}
                            label="Observation"
                            info="An observation is used to annotate parts of the data that are unidentified or erroneous."
                        />
                        <Checkbox
                            id="object"
                            value="object"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.object}
                            label="Object"
                            info="An object is something that can be classified by the SSDM standard."
                        />
                        <hr />
                        <div className="button-row">
                            <button
                                type="button"
                                className={`pane-button large grow ${values.point ? 'highlight' : ''}`}
                                onClick={() => setFieldValue('point', !values.point)}
                            >
                                <i className="fas fa-circle-small" />
                                Point
                            </button>
                            <button
                                type="button"
                                className={`pane-button large grow ${values.line ? 'highlight' : ''}`}
                                onClick={() => setFieldValue('line', !values.line)}
                            >
                                <i className="fas fa-dash" />
                                Line
                            </button>
                            <button
                                type="button"
                                className={`pane-button large grow ${values.polygon ? 'highlight' : ''}`}
                                onClick={() => setFieldValue('polygon', !values.polygon)}
                            >
                                <i className="fas fa-draw-polygon" />
                                Polygon
                            </button>
                        </div>
                        <hr />
                        {values.object ? (
                            <Select
                                name="ssdmTypes"
                                isMulti
                                options={ssdmOptions}
                                placeholder="Select SSDM types"
                                multiValueCounterMessage="Selected SSDM type"
                                value={values.ssdmTypes}
                                setFieldValue={setFieldValue}
                            />
                        ) : null}

                        <Select
                            name="datasets"
                            isMulti
                            options={props.datasets.map((x) => ({ 'label': x.name, 'value': x.id }))}
                            placeholder="Select dataset"
                            multiValueCounterMessage="Selected dataset"
                            value={values.datasets}
                            setFieldValue={setFieldValue}
                        />
                        <div className="form-group date-range">
                            Date Range
                            <input
                                type="date"
                                value={values.startDate}
                                max={values.endDate}
                                onChange={(choice) => setFieldValue('startDate', choice.target.value)}
                            />
                            <input
                                type="date"
                                value={values.endDate}
                                min={values.startDate}
                                onChange={(choice) => setFieldValue('endDate', choice.target.value)}
                            />
                        </div>
                        <Select
                            name="authors"
                            isMulti
                            options={props.authors.map((id) => generateOption(id))}
                            placeholder="Select author"
                            multiValueCounterMessage="Selected author"
                            value={values.authors}
                            setFieldValue={setFieldValue}
                        />
                        <Checkbox
                            id="unresolvedComments"
                            value="unresolvedComments"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.unresolvedComments}
                            label="Has unresolved comments"
                        />
                        <hr />
                        <Checkbox
                            name="status"
                            id="in_progress"
                            value="in_progress"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.status.includes('in_progress')}
                            label="In progress"
                        />
                        <Checkbox
                            name="status"
                            id="complete"
                            value="complete"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.status.includes('complete')}
                            label="Complete"
                        />
                        <Checkbox
                            name="status"
                            id="archived"
                            value="archived"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.status.includes('archived')}
                            label="Archived"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="pane-button large"
                            onClick={() => {
                                setValues(DEFAULT_ANNOTATION_FILTER);
                            }}
                        >
                            Reset
                        </button>
                        <button type="submit" className="pane-button large highlight">
                            Apply
                        </button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default AnnotationFilters;
