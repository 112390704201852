import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import Feature from './Feature';

interface FeatureData {
    title: string;
    description?: string;
    videoId?: string;
}

interface ReleaseProps {
    date: string;
    version: string;
    description: string;
    features: FeatureData[];
    improvements?: string[];
}

const Release: React.FC<ReleaseProps> = ({ date, version, description, features, improvements }) => (
    <section className="release-container mb-4">
        <header className="release-header">
            <h2 className="release-title">Version {version}</h2>
            <span className="release-date">{new Date(date).toLocaleDateString()}</span>
        </header>
        <article className="release-description">
            <p>{description}</p>
        </article>

        <section className="features-section">
            <h5 className="release-note-subtitle">New Features</h5>
            {features.map((feature) => (
                <Feature
                    key={`${feature.title}-${nanoid()}`}
                    title={feature.title}
                    description={feature.description}
                    videoId={feature.videoId}
                />
            ))}
        </section>

        {improvements && improvements.length > 0 && (
            <section className="improvements-section">
                <h5 className="release-note-subtitle">Improvements & Bugfixes</h5>
                <ul className="improvements-list">
                    {improvements.map((improvement) => (
                        <li key={`release-improvement-${nanoid()}`}>{improvement}</li>
                    ))}
                </ul>
            </section>
        )}
    </section>
);

export default Release;
