// React
import Toggle from 'components/dropdown/Toggle';
import { useDispatch, useSelector } from 'react-redux';

import * as giro3dSlice from 'redux/giro3d';

// Components

function InspectorSetting() {
    const dispatch = useDispatch();

    const visible = useSelector(giro3dSlice.showInspector);

    return (
        <Toggle title="Show inspector" checked={visible} onChange={(v) => dispatch(giro3dSlice.setShowInspector(v))} />
    );
}

export default InspectorSetting;
