import React from 'react';
import { Form } from 'reactstrap';

import logo from '../../images/symbol.svg';

export default function LoginLoader() {
    return (
        <div className="login-bg">
            <Form inline="true" className="login-form">
                <img src={logo} alt="Argeo logo" />
                <div className="login-footer">
                    <i className="fas fa-spinner fa-pulse" />
                    <div>Authenticating user</div>
                </div>
            </Form>
        </div>
    );
}
