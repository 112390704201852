// eslint-disable-next-line import/no-cycle
import { createSelector } from '@reduxjs/toolkit';
import { COLLECTIONS, ORGANIZATIONS_TYPES } from './actionTypes';

export const getCollections = (store) => (store && store.collections ? store.collections.collections : []);
export const getCollectionsStatus = (store) => (store && store.collections ? store.collections.status : undefined);

export const getProjects = (store) => (store && store.projects ? store.projects.projects : []);
export const getProjectsStatus = (store) => (store && store.projects ? store.projects.status : undefined);

export const getAllDatasets = (store) =>
    store && store.allDatasets && store.allDatasets.list ? store.allDatasets.list : [];

export const getUser = (store) => store && store.authentication && store.authentication.user;
export const getCanUserAccessAdminPage = createSelector(
    (state) => state.authentication.user,
    (user) =>
        user.is_admin ||
        user.memberships.filter(
            (membership) =>
                membership.organization.user_permissions.create_memberships ||
                membership.organization.user_permissions.delete_memberships
        ).length !== 0
);
export const getCanUserAccessRequests = createSelector(
    (state) => state.authentication.user,
    (user) =>
        user.is_admin ||
        user.memberships.filter((membership) => membership.organization.user_permissions.create_memberships).length !==
            0
);
export const isFetchingUser = (store) => store && store.fetchingUser && store.fetchingUser.requested;

export const isRegistering = (store) => store && store.registration && store.registration.requested;

export const getUserEligible = (store) => store && store.userEligible;

export const getVectorEditState = (store) => (store && store.vector ? store.vector.editState : undefined);

export const getApiHealthCheck = (store) => (store && store.apiHealthCheck ? store.apiHealthCheck : undefined);

export const getOrganizations = (store) => (store && store.organizations ? store.organizations.result : undefined);

export const getOrganizationsForCreatingCollection = createSelector(
    (s) => s.organizations.result,
    (organizations) => (organizations ? organizations.filter((o) => o.user_permissions.create_collections) : [])
);
export const getOrganizationsForCreatingDataset = createSelector(
    (s) => s.organizations.result,
    (organizations) => (organizations ? organizations.filter((o) => o.user_permissions.create_datasets) : [])
);
export const getOrganizationsForCreatingProject = createSelector(
    (s) => s.organizations.result,
    (organizations) => (organizations ? organizations.filter((o) => o.user_permissions.create_projects) : [])
);

export const getSSDMTypes = (store) => (store && store.ssdm ? store.ssdm.result : undefined);

export const getHelpMessage = (store) => store && store.help.message;

export const getError = (store) => store && store.error.error;

export const getReleaseInfoShow = (store) => store && store.release.releaseShow;
export const getReleaseInfoShownAt = (store) => store && store.release.releaseShown;

export const getOverviewMap = (store) => (store && store.overviewMap ? store.overviewMap.map : undefined);

export const getSelectedItems = (store) => (store && store.overviewMap ? store.overviewMap.selectedItems : undefined);

export const getSelectedCollection = (store) =>
    store && store.overviewMap ? store.overviewMap.selectedCollection : undefined;

export const getSelectedProject = (store) =>
    store && store.overviewMap ? store.overviewMap.selectedProject : undefined;

export const getSelectedDataset = (store) =>
    store && store.overviewMap ? store.overviewMap.selectedDataset : undefined;

export const getMeasurementCoordinates = (store) =>
    store && store.overviewMap ? store.overviewMap.measurementCoords : undefined;

export const getMeasurementResult = (store) =>
    store && store.overviewMap ? store.overviewMap.measurementResult : undefined;

export const getOverviewMapResolution = (store) =>
    store && store.overviewMap ? store.overviewMap.resolution : undefined;

export const getOverviewMapFeatureInfo = (store) =>
    store && store.overviewMap ? store.overviewMap.featureInfo : undefined;

export const getNotifications = (store) => (store && store.notifications ? store.notifications.list : undefined);
export const getActiveNotification = (store) => (store && store.notifications ? store.notifications.active : null);

export const getProjections = (store) => (store && store.projections ? store.projections.result : undefined);

export const getVersionInfo = (store) => (store && store.version ? store.version : undefined);

export const getProjectUsers = (store) => (store && store.projects ? store.projects.users : undefined);

export const getUsernames = (store) => (store && store.users ? store.users.usernames : undefined);
export const getQueuedUsernames = (store) => (store && store.users ? store.users.requestedUsernames : undefined);

export const getPage = (store) => (store && store.navigation ? store.navigation.page : undefined);

export const getSettingsDropdown = (store) => (store && store.settings ? store.settings.dropdown : undefined);

export const getMembershipsToAdministrate = (store) => (store && store.users ? store.users.memberships : undefined);
export const getUsersToAdministrate = (store) => (store && store.users ? store.users.users : undefined);
export const getMembershipRequests = (store) => (store && store.users ? store.users.requests : undefined);

export const getLibraryLoaded = (store) =>
    store && store.allDatasets && store.collections && store.organizations
        ? store.allDatasets.status === 'set' &&
          store.collections.status === COLLECTIONS.SUCCESS &&
          store.organizations.status === ORGANIZATIONS_TYPES.SUCCESS
        : false;

export const getSeismicViewEnabled = (store) => (store && store.seismicView ? store.seismicView.enabled : undefined);

export const getApiKeys = (store) => (store && store.apiKeys ? store.apiKeys.list : undefined);
export const getAllApiKeys = (store) => (store && store.apiKeys ? store.apiKeys.adminList : undefined);

export const getUppyShown = (store) => (store && store.uppy ? store.uppy.shown : undefined);
