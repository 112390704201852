import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { View } from 'types/serialization/View';

export type State = {
    reversed: boolean;
    maintainDirection: boolean;
    zScale: number;
    snapPosition: boolean;
};

const initialState: State = {
    reversed: false,
    maintainDirection: true,
    zScale: 1,
    snapPosition: false,
};

const self = (store: RootState) => store.seismicView;

// Selectors
export const isReversed = createSelector(self, (s) => s.reversed);
export const getMaintainDirection = createSelector(self, (s) => s.maintainDirection);
export const getZScale = createSelector(self, (s) => s.zScale);
export const getSnapPosition = createSelector(self, (s) => s.snapPosition);

const slice = createSlice({
    name: 'seismicGrid',
    initialState,
    reducers: {
        reset: () => initialState,
        loadView: (state, action: PayloadAction<View>) => {
            const settings = action.payload.seismicView;

            if (settings) {
                state.reversed = settings.reversed ?? state.reversed;
                state.maintainDirection = settings.maintainDirection ?? state.maintainDirection;
                state.zScale = settings.zScale ?? state.zScale;
                state.snapPosition = settings.snap ?? state.snapPosition;
            }
        },
        reversed: (state, action: PayloadAction<boolean>) => {
            state.reversed = action.payload;
        },
        maintainDirection: (state, action: PayloadAction<boolean>) => {
            state.maintainDirection = action.payload;
        },
        zScale: (state, action: PayloadAction<number>) => {
            state.zScale = action.payload;
        },
        snapPosition: (state, action: PayloadAction<boolean>) => {
            state.snapPosition = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const loadView = slice.actions.loadView;
export const reversed = slice.actions.reversed;
export const maintainDirection = slice.actions.maintainDirection;
export const zScale = slice.actions.zScale;
export const snapPosition = slice.actions.snapPosition;
