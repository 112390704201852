import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'redux/selectors';
import ApiKey from './ApiKey';

const ProfileContent = () => {
    const user = useSelector(getUser);

    return user ? (
        <div className="tabContent">
            <div className="map-pane-centred-fill centred-text">
                <div className="map-pane-jumbo-icon">
                    <i className="fal fa-user-circle" />
                </div>
                <div className="map-pane-title">
                    {user.given_name} {user.family_name}
                    <div className="map-pane-title-sub">{user.email}</div>
                    <div className="map-pane-title-sub">
                        {user.memberships.map((m) => m.organization.display_name).join(', ')}
                    </div>
                </div>
            </div>
            <div className="map-pane-centred-fill">
                <ApiKey />
            </div>
        </div>
    ) : (
        <span>Not logged in</span>
    );
};

export default ProfileContent;
