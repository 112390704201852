// eslint-disable-next-line import/no-cycle
import { HELP_SHOW, HELP_HIDE } from '../actionTypes';

function helpReducer(state = { message: null }, action) {
    const { type, payload } = action;

    switch (type) {
        case HELP_SHOW:
            return {
                ...state,
                message: payload,
            };
        case HELP_HIDE:
            return {
                ...state,
                message: null,
            };
        default:
            return state;
    }
}

export default helpReducer;
