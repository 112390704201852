import * as THREE from 'three';
import { DEFAULT_CABLE_PIPELINE_SETTINGS } from 'services/Constants';
import LineLayer, { ConstructorParams } from './LineLayer';

export default class CableLayer extends LineLayer {
    readonly isCableLayer = true;

    constructor(params: ConstructorParams) {
        super(params);

        this.type = 'CableLayer';

        this.settings.overlayColor = new THREE.Color(DEFAULT_CABLE_PIPELINE_SETTINGS.OVERLAY_COLOR);

        this.settings.radius = DEFAULT_CABLE_PIPELINE_SETTINGS.RADIUS;
    }
}
