import { UncontrolledTooltip } from 'reactstrap';
import { DatasetId, SourceFileId } from 'types/common';
import { DATASETS_STATES, SOURCE_FILE_STATES } from 'services/Constants';
import { layerStateLabels } from '../services/Labels';

interface Props {
    id: DatasetId | SourceFileId;
    state: DATASETS_STATES | SOURCE_FILE_STATES;
    state_msg: string;
}

const StateIndicator = (props: Props) => {
    function stateIndicator(state: DATASETS_STATES | SOURCE_FILE_STATES) {
        switch (state) {
            case 'active':
                return <i className="fas fa-check icon-green no-hover" />;
            case 'empty':
                return <i className="fas fa-empty-set no-hover" />;
            case 'uploading':
                return <i className="fas fa-file-upload" />;
            case 'converting':
            case 'deleting':
            case 'ingesting':
            case 'ingested':
            case 'validating':
                return <i className="fas fa-spinner fa-pulse no-hover" />;
            case 'conversion_failed':
            case 'validation_failed':
            case 'deletion_failed':
            default:
                return <i className="fas fa-triangle-exclamation icon-red no-hover" />;
        }
    }
    return (
        <div id={`state-${props.id}`} className="state-indicator">
            {stateIndicator(props.state)}
            <UncontrolledTooltip placement="top" target={`state-${props.id}`}>
                {layerStateLabels[props.state]}
                <div>{props.state_msg}</div>
            </UncontrolledTooltip>
        </div>
    );
};

export default StateIndicator;
