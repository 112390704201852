import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import type Dataset from 'types/Dataset';
import useResizeObserver, { ObservedSize, ResizeHandler } from 'use-resize-observer';
import { useState } from 'react';
import DatasetListItemSettings from './DatasetListItemSettings';

export type Props = {
    target: string;
    dataset: Dataset;
    isOpen: boolean;
    onToggle: () => void;
};

function PopoverContent(props: { dataset: Dataset; onResize: ResizeHandler }) {
    const { dataset, onResize } = props;
    const { ref } = useResizeObserver<HTMLDivElement>({
        onResize,
    });

    return (
        <div ref={ref}>
            <DatasetListItemSettings dataset={dataset} />
        </div>
    );
}

export default function DatasetSettingsPopover(props: Props) {
    const { dataset, target, isOpen } = props;
    const [currentSize, setCurrentSize] = useState<ObservedSize>(null);

    function onResize(size: ObservedSize) {
        if (size.height !== currentSize?.height || size.width !== currentSize?.width) setCurrentSize(size);
    }

    return (
        <Popover
            placement="right"
            isOpen={isOpen}
            target={target}
            toggle={props.onToggle}
            trigger="legacy"
            fade={false}
            key={`${currentSize?.width}-${currentSize?.height}`}
        >
            <PopoverHeader>{`Parameters for ${dataset?.name}`}</PopoverHeader>
            <PopoverBody>
                <PopoverContent dataset={dataset} onResize={(size) => onResize(size)} />
            </PopoverBody>
        </Popover>
    );
}
