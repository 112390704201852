// eslint-disable-next-line import/no-cycle
import {
    PROJECT_CREATED,
    PROJECT_DELETED,
    PROJECT_LOADED,
    PROJECT_LOADING,
    PROJECT_LOADING_FAILED,
    PROJECT_UPDATED,
    PROJECT_REMOVED,
    PROJECT_USERS,
    PROJECTS,
} from '../actionTypes';
import { PROJECT_STATES } from '../../services/Constants';

const initialState = {
    projects: [],
    status: PROJECTS.UNKNOWN,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PROJECT_CREATED:
            // payload: project
            return {
                ...state,
                projects: [...state.projects, payload],
            };
        case PROJECT_DELETED:
            // payload: project
            return {
                ...state,
                projects: state.projects.filter((p) => p.id !== payload.id),
            };
        case PROJECT_LOADED:
            // payload: project
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project.id !== payload.id) return project;
                    return {
                        ...payload,
                        olstate: PROJECT_STATES.LOADED,
                    };
                }),
            };
        case PROJECT_UPDATED:
            // payload: project
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project.id !== payload.id) return project;
                    return {
                        ...payload,
                        olstate: project.olstate,
                        olstate_msg: project.olstate_msg,
                    };
                }),
            };
        case PROJECT_LOADING:
            // payload: project
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project.id !== payload.id) return project;
                    return {
                        ...project,
                        olstate: PROJECT_STATES.LOADING,
                    };
                }),
            };
        case PROJECT_LOADING_FAILED:
            // payload: {project, reason}
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project.id !== payload.project.id) return project;
                    return {
                        ...project,
                        olstate: PROJECT_STATES.LOADING_FAILED,
                        olstate_msg: payload.reason,
                    };
                }),
            };
        case PROJECT_REMOVED:
            // payload: project
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project.id !== payload.id) return project;
                    return {
                        ...project,
                        olstate: undefined,
                    };
                }),
            };
        case PROJECT_USERS:
            // payload: usernames[] (id, given_name, family_name)
            return {
                ...state,
                users: payload,
            };
        case PROJECTS.SUCCESS:
            // payload: projects[]
            return {
                ...state,
                projects: payload,
                status: PROJECTS.SUCCESS,
            };
        case PROJECTS.REQUEST:
            // payload: null
            return {
                ...state,
                status: PROJECTS.REQUEST,
            };
        default:
            return state;
    }
}
