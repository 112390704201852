import { useDispatch } from 'react-redux';
import { HasAttributes, LayerState } from 'types/LayerState';

import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Select from 'components/dropdown/Select';
import { Option } from 'components/Select';

export type Props = {
    layer: LayerState & HasAttributes;
};

function AttributeSelector(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();
    const allAttributes = useAppSelector(layersSlice.getAttributes(layer));

    const activeAttribute = useAppSelector(layersSlice.getActiveAttribute(layer));
    function changeActiveAttribute(value: string) {
        dispatch(layersSlice.setActiveAttribute({ layer, value }));
    }

    if (allAttributes.length === 0 || activeAttribute == null) {
        return null;
    }

    return (
        <Select
            title="Property"
            value={{ value: activeAttribute.id, label: activeAttribute.name }}
            options={allAttributes.map((att) => ({ value: att.id, label: att.name }))}
            onChange={(v) => changeActiveAttribute((v as Option<string>).value)}
            icon="fas fa-table"
        />
    );
}

export default AttributeSelector;
