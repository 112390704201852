import { Style, Fill, Stroke, RegularShape } from 'ol/style';
import { getWidth as getExtentWidth, getCenter } from 'ol/extent';
import Point from 'ol/geom/Point';

const datasetColor = [45, 255, 75];
const collectionColor = [248, 15, 128];
const projectColor = [75, 45, 255];

function computeStyleFor(baseColor) {
    return {
        // is_hovered
        true: {
            // is_selected
            true: {
                fill: new Fill({
                    color: [...baseColor, 0.6],
                }),
                stroke: new Stroke({
                    width: 5,
                    color: [...baseColor, 1],
                }),
            },
            false: {
                fill: new Fill({
                    color: [...baseColor, 0.4],
                }),
                stroke: new Stroke({
                    width: 2,
                    color: [...baseColor, 1],
                }),
            },
        },
        false: {
            // is_selected
            true: {
                fill: new Fill({
                    color: [...baseColor, 0.6],
                }),
                stroke: new Stroke({
                    width: 5,
                    color: [255, 255, 255, 1],
                }),
            },
            false: {
                fill: new Fill({
                    color: [...baseColor, 0.2],
                }),
                stroke: new Stroke({
                    width: 2,
                    color: [0, 0, 0, 1],
                }),
            },
        },
    };
}

const styles = {
    'dataset': computeStyleFor(datasetColor),
    'collection': computeStyleFor(collectionColor),
    'project': computeStyleFor(projectColor),
};
const lowlightedStyle = {
    fill: new Fill({
        color: [0, 0, 0, 0.2],
    }),
    stroke: new Stroke({
        width: 2,
        color: [0, 0, 0, 1],
    }),
};

function _getStyle(feature, resolution, stroke, fill) {
    const st = [];
    const widthThreshold = 7;

    // Point style, only if resolution and geometry defines the need
    const ext = feature.getGeometry().getExtent();
    const pointGeom = new Point(getCenter(ext));
    const width = getExtentWidth(ext);
    if (width < resolution * widthThreshold) {
        st.push(
            new Style({
                image: new RegularShape({
                    radius: widthThreshold,
                    points: 4,
                    angle: Math.PI / 4,
                    stroke,
                    fill,
                }),
                geometry: pointGeom,
            })
        );
    } else {
        // Polygon style
        st.push(new Style({ stroke, fill }));
    }
    return st;
}

// eslint-disable-next-line import/prefer-default-export
export function getFeatureStyle(feature, resolution) {
    const featureType = feature.get('type');

    // Assign hovered style if hovered or highlighted
    const isHovered = (feature.get('is_hovered') || feature.get('is_highlighted')) ?? false;

    // Assign selected style if selected
    const isSelected = feature.get('is_selected') ?? false;

    // Override with lowlighted style if lowlighted & not hovered or highlighted
    const isLowlighted = (feature.get('is_lowlighted') ?? false) && !isHovered;

    const { stroke, fill } = isLowlighted ? lowlightedStyle : styles[featureType][isHovered][isSelected];

    return _getStyle(feature, resolution, stroke, fill);
}
