/**
 * Build the reducer for updating the state from async actions generated by the 2 other methods.
 *
 * Example usage:
 *
 * import { RETICULATE_SPLINE_TYPES } from '../actionTypes.js';
 *
 * const splineReticulationReducer = buildReducer(RETICULATE_SPLINE_TYPES);
 *
 * combineReducers({
 *     ...,
 *     splineReticulation: splineReticulationReducer
 * });
 *
 * this will generate a state of this form:
 *
 * {
 *      ...,
 *      splineReticulation: {
 *          requested: false,
 *          inError: false,
 *          result: {...}
 *      }
 * }
 *
 * So that the UI just needs to check:
 *
 * - requested to display spinners or loading bars,
 * - inError to give visual errors
 * - result (check if not null) to display result
 *
 * Note that as the TYPES.ERROR is standard, it's possible to use a common
 * reducer that will catch every action that ends with '/error' to display
 * error messages.
 *
 * @param {object} TYPES a TYPES object constructed by buildActionTypes
 * @param {boolean} keepResult whether or not we should store the result of the action
 *
 * @returns {function} a reducer function
 */
export default function buildReducer(TYPES, keepResult = true) {
    function reducer(
        state = {
            requested: false,
            inError: false,
            result: null,
            status: TYPES.UNKNOWN,
        },
        action
    ) {
        switch (action.type) {
            case TYPES.REQUEST:
                return { ...state, requested: true, status: TYPES.REQUEST };
            case TYPES.SUCCESS:
                return {
                    ...state,
                    requested: false,
                    inError: false,
                    result: keepResult ? action.payload : null,
                    status: TYPES.SUCCESS,
                };
            case TYPES.ERROR:
                return { ...state, requested: false, inError: true, status: TYPES.ERROR };
            case TYPES.NOT_FOUND:
                return {
                    ...state,
                    requested: false,
                    inError: false,
                    result: keepResult ? action.payload : null,
                    status: TYPES.NOT_FOUND,
                };
            case TYPES.CLEAR:
                return {
                    requested: false,
                    inError: false,
                    result: null,
                    status: TYPES.UNKNOWN,
                };
            default:
                return state;
        }
    }

    return reducer;
}
