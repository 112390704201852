import { useDispatch } from 'react-redux';
import { stopAnnotationView } from '../../../redux/actions';
import GeometryInfo from './GeometryInfo';

const ViewAnnotation = () => {
    const dispatch = useDispatch();

    const endViewing = () => {
        dispatch(stopAnnotationView());
    };

    return (
        <div className="tabContent">
            <div>
                You can move the points of the annotation but they will not be saved as you cannot edit this annotation.
            </div>
            <GeometryInfo />
            <hr />
            <div className="input-row">
                <button type="button" className="pane-button highlight" id="end-viewing" onClick={endViewing}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default ViewAnnotation;
