import { Vector3 } from 'three';

export default class Vector3Array {
    readonly buffer: Float32Array;

    constructor({ length }) {
        this.buffer = new Float32Array(length * 3);
    }

    /**
     * Sets the value of a vector in the array.
     * @param index The index in the array.
     * @param x The X coordinate.
     * @param y The Y coordinate.
     * @param z The Z coordinate.
     */
    set(index: number, x: number, y: number, z: number) {
        const i = index * 3;
        this.buffer[i + 0] = x;
        this.buffer[i + 1] = y;
        this.buffer[i + 2] = z;
    }

    /**
     * @param index The index.
     * @param target The target vector. If undefined, a new one is created.
     * @returns The updated target.
     */
    get(index: number, target: Vector3): Vector3 {
        const i = index * 3;
        if (!target) {
            target = new Vector3();
        }
        target.set(this.buffer[i + 0], this.buffer[i + 1], this.buffer[i + 2]);

        return target;
    }

    /**
     * Returns an unpacked array of vectors.
     */
    toArray(): Vector3[] {
        const length = this.length;
        const result = new Array<Vector3>(length);
        for (let i = 0; i < length; i++) {
            const element = this.get(i, new Vector3());
            result[i] = element;
        }

        return result;
    }

    get length() {
        return this.buffer.length / 3;
    }
}
