/* eslint-disable no-unused-vars */

import * as THREE from 'three';
import SeismicPlaneRegion from './SeismicPlaneRegion';

/**
 * A read request.
 */
export class ReadRequest {
    region: SeismicPlaneRegion;

    signal: AbortSignal;

    outputSize: THREE.Vector2;

    tileSizeMeters: THREE.Vector2;

    shouldAbort: () => boolean;

    /**
     * @param params
     * @param params.shouldAbort The source tile.
     * @param params.region The region of the request, in normalized coordinates.
     * @param params.tileSizeMeters The size of the tile, in meters.
     * @param params.outputSize The desired size of the generated texture, in pixels.
     * @param params.signal The abort signal.
     */
    constructor({
        region,
        tileSizeMeters,
        outputSize,
        signal,
        shouldAbort,
    }: {
        shouldAbort: () => boolean;
        region: SeismicPlaneRegion;
        tileSizeMeters: THREE.Vector2;
        outputSize: THREE.Vector2;
        signal: AbortSignal;
    }) {
        this.region = region;
        this.signal = signal;
        this.outputSize = outputSize;
        this.tileSizeMeters = tileSizeMeters;
        this.shouldAbort = shouldAbort;
    }
}

export function delay(ms: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => resolve(), ms);
    });
}

/* eslint-disable class-methods-use-this */
export default abstract class Source {
    preprocess(): Promise<void> {
        return Promise.resolve();
    }

    get height() {
        return -1;
    }

    get width() {
        return -1;
    }

    /**
     * Reads a region of the source
     * @param {ReadRequest} request The parameters.
     * @returns {Promise<{ texture: THREE.Texture, adjustedRegion: SeismicPlaneRegion }>} The texture and adjusted region.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    abstract read(request: ReadRequest): Promise<{ texture: THREE.DataTexture; adjustedRegion: SeismicPlaneRegion }>;

    abstract getTileSize(level: number): number;

    abstract get levels(): number;
}
