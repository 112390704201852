export function getApiUrl(): string {
    // @ts-expect-error _env_ is not defined
    return window._env_?.REACT_APP_API_BASE_URI ?? process.env?.REACT_APP_API_BASE_URI;
}

export function getOIDCAuthority(): string {
    // @ts-expect-error _env_ is not defined
    return window._env_?.REACT_APP_OIDC_AUTHORITY ?? process.env?.REACT_APP_OIDC_AUTHORITY;
}

export function getOIDCClientId(): string {
    // @ts-expect-error _env_ is not defined
    return window._env_?.REACT_APP_OIDC_CLIENT_ID ?? process.env?.REACT_APP_OIDC_CLIENT_ID;
}
