import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as datasetsSlice from 'redux/datasets';
import ToggleSwitch from 'components/ToggleSwitch';
import { supportedTypesLabels } from '../../services/Labels';
import InlineDropMenu from '../InlineDropMenu';
import StateIndicator from '../StateIndicator';
import { getUser } from '../../redux/selectors';
import { unrelateProjectDataset, relateProjectDataset } from '../../redux/actions';

const DatasetListItem = ({ dataset, setDatasetToDelete, setDatasetToInspect, project }) => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const projectDataset = useSelector(datasetsSlice.get(dataset.id));

    const changeStatus = async () => {
        if (projectDataset) {
            return dispatch(unrelateProjectDataset(project, dataset));
        }
        return dispatch(relateProjectDataset(project, dataset));
    };
    return (
        <tr className="library-list-item" key={`dataset-row-${dataset.id}`}>
            {project && (
                <td>
                    <ToggleSwitch
                        id={`dataset-include-${dataset.id}`}
                        checked={!!projectDataset}
                        onChange={changeStatus}
                        disabled={dataset.state !== 'active'}
                    />
                </td>
            )}
            <td className="list-item-title">{dataset.name}</td>
            <td>{supportedTypesLabels[dataset.type]}</td>
            <td>{dataset.projection}</td>
            <td>{dataset.organization}</td>
            <td>
                <StateIndicator id={dataset.id} state={dataset.state} state_msg={dataset.state_msg} />
            </td>
            <td>
                <InlineDropMenu id="library-dataset-actions">
                    <div id={`delete-${dataset.id}`}>
                        <Button
                            className="borderless red"
                            id="delete-dataset"
                            title={`Delete ${dataset.name}`}
                            onClick={() => setDatasetToDelete(dataset)}
                            disabled={!dataset.user_permissions.delete_dataset && !user.is_admin}
                        >
                            <i className="fal fa-trash-can" />
                        </Button>
                    </div>
                    {!dataset.user_permissions.delete_dataset && !user.is_admin ? (
                        <UncontrolledTooltip target={`delete-${dataset.id}`}>
                            You do not have the permissions to delete this dataset
                        </UncontrolledTooltip>
                    ) : null}
                    <div id={`inspect-${dataset.id}`}>
                        <Button
                            className="borderless green"
                            id="inspect-dataset"
                            title={`Edit ${dataset.name}`}
                            onClick={() => setDatasetToInspect(dataset)}
                        >
                            <i className="fal fa-info-circle" />
                        </Button>
                    </div>
                </InlineDropMenu>
            </td>
        </tr>
    );
};

export default DatasetListItem;
