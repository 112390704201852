import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Formik, Form } from 'formik';
import { DragDrop } from '@uppy/react';
import { UPPY_SHOWN } from 'redux/actionTypes';
import Select from 'components/forms/Select';
import BaseInput from 'components/forms/BaseInput';
import { getCollections, getOrganizationsForCreatingDataset, getProjections } from '../../../redux/selectors';
import { createDatasetInProject } from '../../../redux/actions';
import { LAYER_DATA_TYPES, LAYER_TYPES, PROCESSABLE_EXTENSIONS } from '../../../services/Constants';
import handleApiError from '../../../services/Forms';
import { supportedDataTypes, supportedDataTypesLabels, supportedTypesLabels } from '../../../services/Labels';
import parseFiles from '../../../services/ParseFiles';

import UppyService from '../../../services/UppyService';

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import UploadingInfo from '../forms/UploadingInfo';

const AddDataset = ({ project, collectionOpen, onClose }) => {
    const dispatch = useDispatch();
    const organizations = useSelector(getOrganizationsForCreatingDataset);
    const collections = useSelector(getCollections);
    const projections = useSelector(getProjections);

    const formikRef = useRef();

    const initialFormState = {
        type: undefined,
        datatype: undefined,
        name: '',
        organization: collectionOpen
            ? {
                  label: organizations.find((o) => o.id === collectionOpen.organization_id)?.name,
                  value: collectionOpen.organization_id,
              }
            : undefined,
        projection: undefined,
        collection: collectionOpen
            ? {
                  label: collections.find((o) => o.id === collectionOpen.id)?.name,
                  value: collectionOpen.id,
              }
            : undefined,
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(null);

    useEffect(() => setCurrentFormState(initialFormState), [organizations]);

    useEffect(() => {
        dispatch({ type: UPPY_SHOWN, payload: true });

        const uppy = UppyService.getInstance();
        setFiles(uppy.getFiles());
        uppy.on('files-added', () => {
            setFiles(uppy.getFiles());
            parseFiles(uppy.getFiles()).then((defaults) => {
                if (defaults.type) defaults.type = { label: supportedTypesLabels[defaults.type], value: defaults.type };
                if (defaults.datatype)
                    defaults.datatype = {
                        label: supportedDataTypesLabels[defaults.datatype],
                        value: defaults.datatype,
                    };
                if (defaults.projection)
                    defaults.projection = {
                        label: projections.find((p) => p.srid === defaults.projection)?.title,
                        value: defaults.projection,
                    };

                Object.keys(defaults).forEach((key) => {
                    formikRef.current.setFieldValue(key, defaults[key] || initialFormState[key]);
                });
                formikRef.current.setFieldError('parse', defaults.errors.parse);
                formikRef.current.setFieldError('files', defaults.errors.files);
            });
        });
        uppy.on('file-removed', () => setFiles(uppy.getFiles()));
        return () => {
            uppy.off('files-added');
            uppy.off('file-removed');
            dispatch({ type: UPPY_SHOWN, payload: false });
        };
    }, []);

    const validation = (values) => {
        const errors = {};
        if (files.length === 0) errors.files = 'Required';
        if (
            files.length > 1 &&
            (values.datatype === LAYER_DATA_TYPES.OBJECT3D ||
                values.datatype === LAYER_DATA_TYPES.VECTOR ||
                values.datatype === LAYER_DATA_TYPES.POINTCLOUD ||
                (values.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
                    (values.type === LAYER_TYPES.BATHYMETRY || values.type === LAYER_TYPES.HORIZON)))
        )
            errors.files = 'Multiple files not supported for this datatype';
        const extensions = [...new Set(files.map((file) => file.extension))];
        if (extensions.length !== 1) errors.files = 'Files must have the same extension';
        else if (!PROCESSABLE_EXTENSIONS.includes(extensions[0]))
            errors.files = `File is not processable: ${extensions[0]}`;
        if (!values.type) errors.type = 'Required';
        if (!values.datatype) errors.datatype = 'Required';
        else if (values.type && !supportedDataTypes[values.type.value].includes(values.datatype.value))
            errors.datatype = 'Datatype not supported by selected type';
        if (!values.name) errors.name = 'Required';
        if (!values.organization) errors.organization = 'Required';
        if (!values.projection) errors.projection = 'Required';
        else if (!projections.find((p) => p.srid === values.projection.value)) errors.projection = 'Unknown CRS';
        return errors;
    };

    const addDataset = (values, helpers) => {
        const formattedValues = {
            name: values.name,
            type: values.type.value,
            datatype: values.datatype.value,
            organization_id: values.organization.value,
            projection: values.projection.value,
        };

        if (values.collection) formattedValues.collection_id = values.collection.value;

        // If we're here, validate has passed and we know files is not undefined
        UppyService.getInstance().on('restriction-failed', (file, error) => {
            helpers.setFieldError('files', error.message);
            formikRef.current.setSubmitting(false);
        });
        dispatch(createDatasetInProject(formattedValues, project))
            .then((dataset) => {
                setUploading(dataset);
            })
            .catch((err) => {
                if (err.response && err.response.status === 409)
                    helpers.setFieldError('files', err.response.data.detail);
                else handleApiError(err, helpers);
            });
    };

    const clearFiles = (errors) => {
        delete errors.files;
        delete errors.parse;
        UppyService.clearFiles();
    };

    if (uploading) return <UploadingInfo dataset={uploading} onClose={onClose} />;

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={addDataset}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formikRef}
        >
            {({ values, errors, isSubmitting, setFieldValue }) => (
                <Form>
                    <ModalBody>
                        <DragDrop
                            className="form-group"
                            uppy={UppyService.getInstance()}
                            allowMultipleFiles
                            note="Drag a file or browse to upload"
                        />
                        <div className="form-group">
                            <div className="file-readout">
                                <span className="file-upload-list">{files.map((file) => file.name).join(', ')}</span>
                                {files.length !== 0 ? (
                                    <button
                                        type="button"
                                        className="pane-button danger"
                                        onClick={() => clearFiles(errors)}
                                    >
                                        <i className="fa fa-xmark" />
                                    </button>
                                ) : null}
                            </div>
                            {errors.parse
                                ? errors.parse.map((error) => <div className="error-text minor">{error}</div>)
                                : null}
                            <div className="error-text">{errors.files}</div>
                        </div>
                        <hr />
                        <BaseInput name="name" label="Name (Required)" value={values.name} titled />
                        <Select
                            name="type"
                            options={Object.keys(supportedDataTypes)
                                .filter((e) => {
                                    if (!values.datatype) return true;
                                    return supportedDataTypes[e].includes(values.datatype.value);
                                })
                                .map((e) => ({
                                    value: e,
                                    label: supportedTypesLabels[e],
                                }))}
                            placeholder="Type... (Required)"
                            setFieldValue={setFieldValue}
                            value={values.type}
                            isClearable
                        />
                        <Select
                            name="datatype"
                            options={
                                values.type
                                    ? [
                                          ...supportedDataTypes[values.type.value].map((e) => ({
                                              value: e,
                                              label: supportedDataTypesLabels[e],
                                          })),
                                      ]
                                    : []
                            }
                            placeholder={`Data Type... (${!values.type ? 'Select type first' : 'Required'})`}
                            setFieldValue={setFieldValue}
                            disabled={!values.type}
                            value={values.datatype}
                            isClearable
                        />
                        <Select
                            name="projection"
                            options={
                                projections
                                    ? projections.map((e) => ({
                                          value: e.srid,
                                          label: e.title,
                                      }))
                                    : []
                            }
                            placeholder="Projection (Required)"
                            setFieldValue={setFieldValue}
                            isSearchable
                            value={values.projection}
                        />
                        {collectionOpen ? null : (
                            <>
                                <hr />
                                <Select
                                    name="organization"
                                    options={[
                                        ...organizations.map((e) => ({
                                            value: e.id,
                                            label: e.display_name,
                                        })),
                                    ]}
                                    placeholder="Organization (Required)"
                                    setFieldValue={setFieldValue}
                                />
                                <Select
                                    name="collection"
                                    options={[
                                        ...collections
                                            .filter(
                                                (collection) =>
                                                    collection.user_permissions.update_collection &&
                                                    collection.organization_id === values.organization?.value
                                            )
                                            .map((e) => ({
                                                value: e.id,
                                                label: e.name,
                                            })),
                                    ]}
                                    placeholder={`Collection${
                                        values.organization === '' ? ' (Select organization first)' : ''
                                    }...`}
                                    setFieldValue={setFieldValue}
                                    disabled={!values.organization}
                                    isSearchable
                                />
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="pane-button large highlight" disabled={isSubmitting}>
                            Upload <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default AddDataset;
