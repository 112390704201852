import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

type State = {
    showAnnotationsInViewport: boolean;
    filterAnnotationByVisibility: boolean;
    showEmptyVectorRows: boolean;
};

const initialState: State = {
    showAnnotationsInViewport: true,
    filterAnnotationByVisibility: false,
    showEmptyVectorRows: true,
};

const self = (state: RootState) => state.settings;

// Selectors
export const getShowAnnotationsInViewport = createSelector(self, (s) => s.showAnnotationsInViewport);
export const getFilterAnnotationByVisibility = createSelector(self, (s) => s.filterAnnotationByVisibility);
export const getShowEmptyVectorRows = createSelector(self, (s) => s.showEmptyVectorRows);

export const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        reset: () => initialState,
        showAnnotationsInViewport: (state, action: PayloadAction<boolean>) => {
            state.showAnnotationsInViewport = action.payload;
        },
        filterAnnotationByVisibility: (state, action: PayloadAction<boolean>) => {
            state.filterAnnotationByVisibility = action.payload;
        },
        showEmptyVectorRows: (state, action: PayloadAction<boolean>) => {
            state.showEmptyVectorRows = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const showAnnotationsInViewport = slice.actions.showAnnotationsInViewport;
export const filterAnnotations = slice.actions.filterAnnotationByVisibility;
export const showEmptyVectorRows = slice.actions.showEmptyVectorRows;
