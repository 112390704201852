import { useAppDispatch, useAppSelector } from 'store';
import { DRAWN_TOOL } from 'services/Constants';
import * as drawToolSlice from 'redux/drawTool';
import * as annotationsSlice from 'redux/annotations';
import {
    cancelElevationProfile,
    clearMeasureDrawing,
    stopAnnotationCreation,
    stopAnnotationEdit,
    stopAnnotationView,
} from 'redux/actions';

const DisabledDrawTool = () => {
    const dispatch = useAppDispatch();
    const tool = useAppSelector(drawToolSlice.getTool);

    const annotationCreate = useAppSelector(annotationsSlice.createState);
    const annotationEdit = useAppSelector(annotationsSlice.editState);

    const cancelTool = () => {
        switch (tool) {
            case DRAWN_TOOL.ANNOTATION:
                if (annotationCreate) dispatch(stopAnnotationCreation());
                else if (annotationEdit) dispatch(stopAnnotationEdit());
                else dispatch(stopAnnotationView());
                break;
            case DRAWN_TOOL.ELEVATION_PROFILE:
                dispatch(cancelElevationProfile());
                break;
            case DRAWN_TOOL.MEASURE:
                dispatch(clearMeasureDrawing());
                break;
            default:
                break;
        }
    };

    const toolName = () => {
        switch (tool) {
            case DRAWN_TOOL.ANNOTATION:
                return 'annotation tool';
            case DRAWN_TOOL.ELEVATION_PROFILE:
                return 'elevation profile tool';
            case DRAWN_TOOL.MEASURE:
                return 'measurement tool';
            default:
                throw new Error('No tool is active. Check if this component should exist.');
        }
    };

    return (
        <>
            Tool disabled while the {toolName()} is active.
            <button type="button" className="pane-button highlight" onClick={cancelTool}>
                Discard Active Tool
            </button>
        </>
    );
};

export default DisabledDrawTool;
