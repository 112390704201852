import type ElevationRange from '@giro3d/giro3d/core/ElevationRange';
import { COLORMAP_NAMES, COLORMAP_BOUNDSMODE } from 'types/ColorMap';
import {
    DEFAULT_LAYER_SETTINGS,
    LAYER_DATA_TYPES,
    LAYER_TYPES,
    DEFAULT_ELEVATION_SETTINGS,
    DEFAULT_POINTCLOUD_SETTINGS,
    DEFAULT_BOREHOLE_SETTINGS,
    DEFAULT_CABLE_PIPELINE_SETTINGS,
    DEFAULT_TRACKLINE_SETTINGS,
    DEFAULT_SEGY_SETTINGS,
    DEFAULT_VECTOR_SETTINGS,
    FILTER_DISPLAY_MODE,
    LAYER_STATES,
} from 'services/Constants';
import { Color } from 'three';
import type ColorMap from 'types/ColorMap';
import Dataset, {
    SeismicDatasetProperties,
    type SingleBandCogDatasetProperties,
    type MosaicDatasetProperties,
    TrackDatasetProperties,
    LasDatasetProperties,
} from 'types/Dataset';
import {
    type HasProgress,
    type HasOpacity,
    type HasMasks,
    type CanShowInMinimap,
    type HasDraping,
    type LayerState,
    type IsPointCloud,
    type HasRadius,
    type HasAttributes,
    type HasSeismicPlane,
    type HasArrows,
    type HasAmplitude,
    type HasVectorStyle,
    type HasBorehole,
    type Attribute,
    type HasOverlayColor,
    type HasColoringMode,
    WellKnownAttributeNames,
    ColoringMode,
    VectorStyle,
    IsOrderable,
    HasFootprint,
    HasDataPointMode,
    DataPointMode,
    HasPath,
    HasOpacityCurve,
    CanToggleSourceFiles,
} from 'types/LayerState';
import { AttributeName, Unit, toHex } from 'types/common';
import { SourceFile } from 'types/SourceFile';
import { SourceFileState } from 'types/SourceFileState';
import { getDatasetColorHex } from 'services/DatasetColors';

function getCommonProperties(dataset: Dataset): LayerState {
    return {
        datasetId: dataset.id,
        visible: dataset.type === LAYER_TYPES.BATHYMETRY && dataset.state === LAYER_STATES.ACTIVE,
        datasetType: dataset.type,
        dataType: dataset.datatype,
    };
}

function getProgressProperties(dataset: Dataset): HasProgress {
    switch (dataset.type) {
        case LAYER_TYPES.DOCUMENT:
        case LAYER_TYPES.VESSEL:
            return undefined;
        default:
            return {
                hasProgress: true,
            };
    }
}

export function getMinMax(dataset: Dataset): ElevationRange {
    if (dataset.type !== LAYER_TYPES.BATHYMETRY && dataset.type !== LAYER_TYPES.HORIZON) {
        return undefined;
    }

    switch (dataset.datatype) {
        case LAYER_DATA_TYPES.SINGLEBANDCOG: {
            const props = dataset.properties as SingleBandCogDatasetProperties;
            return { min: props.min, max: props.max };
        }
        case LAYER_DATA_TYPES.MOSAIC: {
            const props = dataset.properties as MosaicDatasetProperties;
            return { min: props.min, max: props.max };
        }
        default:
            // We don't know that in advance
            return { min: 0, max: 0 };
    }
}

function getPointCloudProperties(dataset: Dataset): IsPointCloud {
    switch (dataset.datatype) {
        case LAYER_DATA_TYPES.POINTCLOUD:
            return {
                isPointCloud: true,
                pointCloudSize: DEFAULT_POINTCLOUD_SETTINGS.POINT_SIZE,
                sseThreshold: DEFAULT_POINTCLOUD_SETTINGS.SSE_THRESHOLD,
            };
        default:
            return undefined;
    }
}

function getRadiusProperties(dataset: Dataset): HasRadius {
    let radius: number;
    switch (dataset.type) {
        case LAYER_TYPES.TRACK:
        case LAYER_TYPES.EM:
            radius = DEFAULT_TRACKLINE_SETTINGS.THICKNESS;
            break;
        case LAYER_TYPES.PIPELINE:
        case LAYER_TYPES.CABLE:
            radius = DEFAULT_CABLE_PIPELINE_SETTINGS.RADIUS;
            break;
        case LAYER_TYPES.BOREHOLE:
            radius = DEFAULT_BOREHOLE_SETTINGS.RADIUS;
            break;
        default:
            return undefined;
    }

    return {
        hasRadius: true,
        radius,
    };
}

function makeColorMap(min: number, max: number, name = COLORMAP_NAMES.SPECTRAL): ColorMap {
    return {
        boundsMode: COLORMAP_BOUNDSMODE.DATA,
        customMax: max,
        customMin: min,
        discrete: false,
        invert: false,
        name,
    };
}

type AttributeMap = { attributes: Attribute[]; colorMaps: Record<AttributeName, ColorMap> };

function getWaterColumnAttributes(): AttributeMap {
    const attributes: Attribute[] = [];
    const colorMaps: Record<AttributeName, ColorMap> = {};

    // TODO can we have those values from the dataset itself ?
    // TODO this is temporary: we have to normalize the intensity in the byte range,
    // because currently py3dtiles does not support floating point attributes.
    const min = 0;
    const max = 255;

    const intensity: Attribute = {
        id: WellKnownAttributeNames.Intensity,
        name: WellKnownAttributeNames.Intensity,
        unit: Unit.dBm,
        min,
        max,
    };

    attributes.push(intensity);

    colorMaps[intensity.name] = makeColorMap(min, max, COLORMAP_NAMES.GRAYSCALE);

    return { attributes, colorMaps };
}

function getBathymetryAttributes(dataset: Dataset): AttributeMap {
    let min = 0;
    let max = 0;

    const range = getMinMax(dataset);

    if (range) {
        min = range.min;
        max = range.max;
    }

    const attributes: Attribute[] = [];
    const colorMaps: Record<AttributeName, ColorMap> = {};

    const elevation: Attribute = {
        id: WellKnownAttributeNames.Elevation,
        name: WellKnownAttributeNames.Elevation,
        min,
        max,
        unit: Unit.Meter,
    };

    colorMaps[elevation.name] = makeColorMap(min, max, COLORMAP_NAMES.SPECTRAL);

    attributes.push(elevation);

    switch (dataset.datatype) {
        case LAYER_DATA_TYPES.SINGLEBANDCOG:
        case LAYER_DATA_TYPES.MOSAIC:
            {
                const aspect: Attribute = {
                    id: WellKnownAttributeNames.Aspect,
                    name: WellKnownAttributeNames.Aspect,
                    min: 0,
                    max: 360,
                    unit: Unit.degrees,
                };

                colorMaps[aspect.name] = makeColorMap(0, 360, COLORMAP_NAMES.C_PHASE);

                const slope: Attribute = {
                    id: WellKnownAttributeNames.Slope,
                    name: WellKnownAttributeNames.Slope,
                    min: 0,
                    max: 90,
                    unit: Unit.degrees,
                };

                colorMaps[slope.name] = makeColorMap(0, 90, COLORMAP_NAMES.C_AMP);

                attributes.push(aspect);
                attributes.push(slope);
            }
            break;
        default:
            break;
    }

    return { attributes, colorMaps };
}

function getBackscatterAndSasAttributes(dataset: Dataset): HasAttributes {
    // RGB(A) datasets cannot be styled with colormaps
    if (dataset.datatype === LAYER_DATA_TYPES.MULTIBANDCOG) {
        return undefined;
    }

    const properties = dataset.properties as MosaicDatasetProperties | SingleBandCogDatasetProperties;

    const colorMaps = {};

    const intensity: Attribute = {
        id: WellKnownAttributeNames.Intensity,
        name: WellKnownAttributeNames.Intensity,
        min: properties.min,
        max: properties.max,
        unit: Unit.None,
    };

    colorMaps[intensity.name] = makeColorMap(properties.min, properties.max, COLORMAP_NAMES.GRAYSCALE);

    const gradient: Attribute = {
        id: WellKnownAttributeNames.Gradient,
        name: WellKnownAttributeNames.Gradient,
        min: 0,
        max: 90,
        unit: Unit.degrees,
    };

    colorMaps[gradient.name] = makeColorMap(0, 90, COLORMAP_NAMES.GRAYSCALE);

    const attributes = [intensity, gradient];

    return {
        hasAttributes: true,
        pinLegend: false,
        activeAttribute: intensity.id,
        attributes,
        colorMaps,
    };
}

function getSeismicAttributes(dataset: Dataset): AttributeMap {
    const properties = dataset.properties as SeismicDatasetProperties;

    const attribute: Attribute = {
        id: WellKnownAttributeNames.Seismic,
        name: WellKnownAttributeNames.Seismic,
        min: properties.sampleMinValue,
        max: properties.sampleMaxValue,
        unit: Unit.None,
    };

    const colorMap = {
        boundsMode: COLORMAP_BOUNDSMODE.DATA,
        customMax: properties.sampleMaxValue,
        customMin: properties.sampleMinValue,
        discrete: false,
        invert: false,
        name:
            // Set different default colormaps if SEG-Y is envelope or full waveform.
            properties.sampleMinValue < 0
                ? DEFAULT_SEGY_SETTINGS.COLORMAP_LESS_THAN_0
                : DEFAULT_SEGY_SETTINGS.COLORMAP_STANDARD,
    };

    const colorMaps = {};
    colorMaps[attribute.id] = colorMap;

    return { attributes: [attribute], colorMaps };
}

function getTrackAttributes(dataset: Dataset): AttributeMap {
    const properties = dataset.properties as TrackDatasetProperties;
    const propList = properties?.properties;

    if (!propList) return { attributes: [], colorMaps: {} };

    const attributes: Attribute[] = propList
        .filter((prop) => prop.numeric)
        .map((prop) => {
            return { id: prop.name, name: prop.name, min: prop.min, max: prop.max, unit: Unit.None };
        });

    const colorMaps = {};

    attributes.forEach((attribute) => {
        colorMaps[attribute.id] = {
            boundsMode: COLORMAP_BOUNDSMODE.DATA,
            customMax: attribute.max,
            customMin: attribute.min,
            discrete: false,
            invert: false,
            name: DEFAULT_CABLE_PIPELINE_SETTINGS.COLORMAP,
        };
    });

    return { attributes, colorMaps };
}

function getBoreholeAttributes(dataset: Dataset): AttributeMap {
    const properties = dataset.properties as LasDatasetProperties;
    const propList = properties?.properties;

    if (!propList) return { attributes: [], colorMaps: {} };

    const attributes: Attribute[] = propList.map((prop) => {
        return { id: prop.key, name: prop.name, min: prop.min, max: prop.max, unit: prop.unit ?? Unit.None };
    });

    const colorMaps = {};

    attributes.forEach((attribute) => {
        colorMaps[attribute.id] = {
            boundsMode: COLORMAP_BOUNDSMODE.DATA,
            customMax: attribute.max,
            customMin: attribute.min,
            discrete: false,
            invert: false,
            name: DEFAULT_CABLE_PIPELINE_SETTINGS.COLORMAP,
        };
    });

    return { attributes, colorMaps };
}

function getAttributeProperties(dataset: Dataset): HasAttributes {
    let attributeMap: AttributeMap;
    switch (dataset.type) {
        case LAYER_TYPES.WATER_COLUMN:
            attributeMap = getWaterColumnAttributes();
            break;
        case LAYER_TYPES.HORIZON:
        case LAYER_TYPES.BATHYMETRY:
            attributeMap = getBathymetryAttributes(dataset);
            break;
        case LAYER_TYPES.BOREHOLE:
            attributeMap = getBoreholeAttributes(dataset);
            break;
        case LAYER_TYPES.CABLE:
        case LAYER_TYPES.PIPELINE:
        case LAYER_TYPES.TRACK:
        case LAYER_TYPES.EM:
            attributeMap = getTrackAttributes(dataset);
            break;
        case LAYER_TYPES.SEISMIC:
            attributeMap = getSeismicAttributes(dataset);
            break;
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.SAS:
        case LAYER_TYPES.CAMERA:
            return getBackscatterAndSasAttributes(dataset);
        default:
            return undefined;
    }

    return {
        pinLegend: false,
        hasAttributes: true,
        attributes: attributeMap.attributes,
        colorMaps: attributeMap.colorMaps,
        activeAttribute: attributeMap.attributes.length > 0 ? attributeMap.attributes[0].id : undefined,
    };
}

function getOverlayColorProperties(dataset: Dataset): HasOverlayColor {
    let overlayColor: Color;

    switch (dataset.type) {
        case LAYER_TYPES.WATER_COLUMN:
        case LAYER_TYPES.HORIZON:
        case LAYER_TYPES.BATHYMETRY:
            if (dataset.datatype === LAYER_DATA_TYPES.POINTCLOUD) {
                overlayColor = DEFAULT_POINTCLOUD_SETTINGS.ELEVATION_OVERLAY_COLOR;
            } else {
                overlayColor = DEFAULT_ELEVATION_SETTINGS.OVERLAY_COLOR;
            }
            break;
        case LAYER_TYPES.BOREHOLE:
            overlayColor = DEFAULT_BOREHOLE_SETTINGS.OVERLAY_COLOR;
            break;
        case LAYER_TYPES.CABLE:
        case LAYER_TYPES.PIPELINE:
        case LAYER_TYPES.TRACK:
        case LAYER_TYPES.EM:
            overlayColor = DEFAULT_CABLE_PIPELINE_SETTINGS.OVERLAY_COLOR;
            break;
        default:
            return undefined;
    }

    return {
        hasOverlayColor: true,
        overlayColor: toHex(overlayColor),
    };
}

function getSeismicProperties(dataset: Dataset): HasSeismicPlane {
    switch (dataset.type) {
        case LAYER_TYPES.SEISMIC:
            return {
                isSeismic: true,
                staticCorrection: DEFAULT_SEGY_SETTINGS.STATIC_CORRECTION,
                filterTransparency: DEFAULT_SEGY_SETTINGS.FILTER_TRANSPARENCY,
                intensityFilter: DEFAULT_SEGY_SETTINGS.INTENSITY_FILTER,
                speedModuleMs: DEFAULT_SEGY_SETTINGS.SPEEDMODULE_MS,
                seismicOffset: DEFAULT_SEGY_SETTINGS.OFFSET,
                activeFile: DEFAULT_SEGY_SETTINGS.ACTIVE_FILE,
                inlineDirection: DEFAULT_SEGY_SETTINGS.INLINE_DIRECTION,
                crosslineDirection: DEFAULT_SEGY_SETTINGS.CROSSLINE_DIRECTION,
                inlineTolerance: DEFAULT_SEGY_SETTINGS.DIRECTION_TOLERANCE,
                crosslineTolerance: DEFAULT_SEGY_SETTINGS.DIRECTION_TOLERANCE,
                lineOrderMode: DEFAULT_SEGY_SETTINGS.ORDER_MODE,
                inlineTerm: DEFAULT_SEGY_SETTINGS.SEARCH_TERM,
                crosslineTerm: DEFAULT_SEGY_SETTINGS.SEARCH_TERM,
            };
        default:
            return undefined;
    }
}

function getArrowProperties(dataset: Dataset): HasArrows {
    switch (dataset.type) {
        case LAYER_TYPES.TRACK:
        case LAYER_TYPES.EM:
            return {
                hasArrows: true,
                arrowScale: DEFAULT_TRACKLINE_SETTINGS.ARROW_SCALE,
                arrowSpacing: DEFAULT_TRACKLINE_SETTINGS.ARROW_SPACING,
                showArrows: DEFAULT_TRACKLINE_SETTINGS.ARROWS,
            };
        default:
            return undefined;
    }
}

function getAmplitudeProperties(dataset: Dataset): HasAmplitude {
    switch (dataset.type) {
        case LAYER_TYPES.TRACK:
        case LAYER_TYPES.EM:
            return {
                hasAmplitude: true,
                // Since attributes are not known in advance,
                // we cannot populate this map.
                perAttributeAmplitudeSettings: {},
            };
        default:
            return undefined;
    }
}

function getBoreholeProperties(dataset: Dataset): HasBorehole {
    switch (dataset.type) {
        case LAYER_TYPES.BOREHOLE:
            return {
                hasBorehole: true,
                variableRadii: false,
            };
        default:
            return undefined;
    }
}

function getOpacityProperties(dataset: Dataset): HasOpacity {
    switch (dataset.type) {
        case LAYER_TYPES.DOCUMENT:
        case LAYER_TYPES.VESSEL:
            return undefined;
        default:
            return {
                hasOpacity: true,
                opacity: DEFAULT_LAYER_SETTINGS.OPACITY,
            };
    }
}

function getMaskProperties(dataset: Dataset): HasMasks {
    if (dataset.type !== LAYER_TYPES.BATHYMETRY && dataset.type !== LAYER_TYPES.HORIZON) {
        return undefined;
    }
    if (dataset.datatype === LAYER_DATA_TYPES.POINTCLOUD) {
        return undefined;
    }
    return {
        isMaskable: true,
        masks: [],
    };
}

function getFootprintProperties(dataset: Dataset): HasFootprint {
    switch (dataset.type) {
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.SAS:
        case LAYER_TYPES.CAMERA:
            return {
                hasFootprint: true,
                showFootprint: false,
                showFootprintLabels: false,
                footprintColor: getDatasetColorHex(dataset.id),
            };
        default:
            return undefined;
    }
}

function getMinimapProperties(dataset: Dataset): CanShowInMinimap {
    let isMinimapSupported = false;

    switch (dataset.type) {
        case LAYER_TYPES.BATHYMETRY:
        case LAYER_TYPES.HORIZON:
        case LAYER_TYPES.SAS:
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.SEISMIC:
        case LAYER_TYPES.CAMERA:
            isMinimapSupported = true;
            break;
        case LAYER_TYPES.VECTOR:
            // Note that dataset.datatype is not the same as dataset.type
            isMinimapSupported = dataset.datatype === LAYER_DATA_TYPES.VECTOR;
            break;
        default:
            isMinimapSupported = false;
            break;
    }

    if (isMinimapSupported) {
        return {
            canShowInMinimap: true,
            showInMinimap: true,
        };
    }

    return undefined;
}

function getDrapingProperties(dataset: Dataset): HasDraping {
    switch (dataset.type) {
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.SAS:
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.CAMERA:
        case LAYER_TYPES.VECTOR:
            return {
                hasDraping: true,
                isDraped: true,
                enableClippingRange: false,
                clippingRange: { min: -Infinity, max: +Infinity },
                zOffset: 0,
            };
        default:
            return undefined;
    }
}

const defaultVectorStyle: VectorStyle = {
    borderColor: toHex(DEFAULT_VECTOR_SETTINGS.BORDER_COLOR),
    fillColor: toHex(DEFAULT_VECTOR_SETTINGS.FILL_COLOR),
    borderOpacity: DEFAULT_VECTOR_SETTINGS.BORDER_OPACITY,
    pointSize: DEFAULT_VECTOR_SETTINGS.POINT_SIZE,
    fillOpacity: DEFAULT_VECTOR_SETTINGS.FILL_OPACITY,
    lineWidth: DEFAULT_VECTOR_SETTINGS.LINE_WIDTH,
    borderWidth: DEFAULT_VECTOR_SETTINGS.BORDER_WIDTH,
};

function getVectorStyleProperties(dataset: Dataset): HasVectorStyle {
    switch (dataset.type) {
        case LAYER_TYPES.VECTOR:
            return {
                hasVectorStyle: true,
                vectorFeaturesReady: false,
                vectorStyle: defaultVectorStyle,
                featureFilter: {
                    features: [],
                    mode: FILTER_DISPLAY_MODE.HIDE,
                    style: defaultVectorStyle,
                    name: null,
                },
            };
        default:
            return undefined;
    }
}

function getColoringModePropertiesForCogs(dataset: Dataset): HasColoringMode {
    if (dataset.datatype === LAYER_DATA_TYPES.MULTIBANDCOG) {
        return undefined;
    }

    return {
        hasColoringMode: true,
        coloringModes: [ColoringMode.Colormap],
        currentColoringMode: ColoringMode.Colormap,
    };
}

function getDataPointMode(dataset: Dataset): HasDataPointMode {
    switch (dataset.type) {
        case LAYER_TYPES.PIPELINE:
            return {
                hasHasDataPointMode: true,
                availableDataPointModes: [DataPointMode.Continuous, DataPointMode.Anode],
                currentDataPointMode: DataPointMode.Continuous,
            };
        default:
            return undefined;
    }
}

function getColoringModeProperties(dataset: Dataset): HasColoringMode {
    let coloringModes: ColoringMode[];
    let currentColoringMode: ColoringMode;
    switch (dataset.type) {
        case LAYER_TYPES.SAS:
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.SEISMIC:
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.CAMERA:
            return getColoringModePropertiesForCogs(dataset);
        case LAYER_TYPES.BATHYMETRY:
        case LAYER_TYPES.HORIZON:
        case LAYER_TYPES.WATER_COLUMN:
            coloringModes = [ColoringMode.OverlayColor, ColoringMode.Colormap];
            currentColoringMode = ColoringMode.Colormap;
            break;
        case LAYER_TYPES.BOREHOLE:
        case LAYER_TYPES.CABLE:
        case LAYER_TYPES.PIPELINE:
        case LAYER_TYPES.TRACK:
        case LAYER_TYPES.EM:
            coloringModes = [ColoringMode.OverlayColor, ColoringMode.Colormap];
            currentColoringMode = ColoringMode.OverlayColor;
            break;
        case LAYER_TYPES.VECTOR:
        default:
            return undefined;
    }

    return {
        hasColoringMode: true,
        currentColoringMode,
        coloringModes,
    };
}

function getOrderingProperties(dataset: Dataset): IsOrderable {
    switch (dataset.datatype) {
        case LAYER_DATA_TYPES.SINGLEBANDCOG:
        case LAYER_DATA_TYPES.MULTIBANDCOG:
        case LAYER_DATA_TYPES.MOSAIC:
            if ([LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(dataset.type)) return undefined;
            return { isOrderable: true };
        case LAYER_DATA_TYPES.VECTOR:
            if (dataset.type === LAYER_TYPES.VECTOR) return { isOrderable: true };
            return undefined;
        default:
            return undefined;
    }
}

function getPathProperties(dataset: Dataset): HasPath {
    if (dataset.datatype === LAYER_DATA_TYPES.VECTOR) {
        switch (dataset.type) {
            case LAYER_TYPES.PIPELINE:
            case LAYER_TYPES.CABLE:
            case LAYER_TYPES.TRACK:
            case LAYER_TYPES.EM:
                return { hasPath: true, pathLoaded: false };
            default:
                return undefined;
        }
    }
    return undefined;
}

function getOpacityCurveAttributes(dataset: Dataset): HasOpacityCurve {
    if (dataset.datatype === LAYER_DATA_TYPES.POINTCLOUD) {
        switch (dataset.type) {
            case LAYER_TYPES.WATER_COLUMN:
                return { hasOpacityCurve: true };
            default:
                return undefined;
        }
    }

    return undefined;
}

export function getCanToggleSourceFileProperties(
    datasetDataype: LAYER_DATA_TYPES,
    datasetType: LAYER_TYPES
): CanToggleSourceFiles {
    switch (datasetDataype) {
        // Never multifile
        case LAYER_DATA_TYPES.OBJECT3D:
        case LAYER_DATA_TYPES.VECTOR:
        case LAYER_DATA_TYPES.POINTCLOUD: // Is this true? (Watercolumn?)
            return undefined;

        // Sometimes multifile
        case LAYER_DATA_TYPES.SINGLEBANDCOG:
            // No multifile for elevations
            if ([LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(datasetType)) return undefined;
            return { canToggleSourceFiles: true };

        // Always multifile
        case LAYER_DATA_TYPES.MULTIBANDCOG:
        case LAYER_DATA_TYPES.MOSAIC:
        case LAYER_DATA_TYPES.SEGY:
        case LAYER_DATA_TYPES.LAS:
            return { canToggleSourceFiles: true };

        default:
            return undefined;
    }
}

/**
 * Builds a `LayerState`, along with all the relevant traits, from the specified dataset.
 */
export default function buildLayerState(dataset: Dataset): LayerState {
    return {
        ...getCommonProperties(dataset),
        ...getProgressProperties(dataset),
        ...getOpacityProperties(dataset),
        ...getCanToggleSourceFileProperties(dataset.datatype, dataset.type),
        ...getMinimapProperties(dataset),
        ...getFootprintProperties(dataset),
        ...getMaskProperties(dataset),
        ...getDrapingProperties(dataset),
        ...getPointCloudProperties(dataset),
        ...getRadiusProperties(dataset),
        ...getAttributeProperties(dataset),
        ...getSeismicProperties(dataset),
        ...getArrowProperties(dataset),
        ...getAmplitudeProperties(dataset),
        ...getVectorStyleProperties(dataset),
        ...getBoreholeProperties(dataset),
        ...getOverlayColorProperties(dataset),
        ...getColoringModeProperties(dataset),
        ...getDataPointMode(dataset),
        ...getOrderingProperties(dataset),
        ...getPathProperties(dataset),
        ...getOpacityCurveAttributes(dataset),
    };
}

function getDefaultSourceFileVisibility(dataset: Dataset): boolean {
    switch (dataset.type) {
        case LAYER_TYPES.BACKSCATTER:
        case LAYER_TYPES.SAS:
        case LAYER_TYPES.ATTRIBUTE:
        case LAYER_TYPES.SEISMIC:
        case LAYER_TYPES.CAMERA:
            // Non-bathymetry rasters are not displayed by default. We display their footprint instead.
            return false;
        default:
            return true;
    }
}

export function buildSourceFileState(dataset: Dataset, sourceFile: SourceFile): SourceFileState {
    return {
        id: sourceFile.id,
        visible: getDefaultSourceFileVisibility(dataset),
        datasetId: dataset.id,
    };
}
