// src/utils/releaseUtils.ts
import versionInfo from './versionInfo.json';

interface Feature {
    title: string;
    description?: string;
    videoId?: string;
}

interface Release {
    date: string;
    version: string;
    description: string;
    features: Feature[];
    improvements?: string[];
}

/**
 * Retrieves all releases sorted by date in descending order.
 * @returns Array of Release objects sorted latest first.
 */
export const getSortedReleases = (): Release[] => {
    if (versionInfo.releases && versionInfo.releases.length > 0) {
        return versionInfo.releases.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
    }
    return [];
};

/**
 * Retrieves the latest release based on the sorted releases.
 * @returns The latest Release object or null if no releases exist.
 */
export const getLatestRelease = (): Release | null => {
    const sortedReleases = getSortedReleases();
    return sortedReleases.length > 0 ? sortedReleases[0] : null;
};

/**
 * Retrieves the latest release date as a Date object.
 * @returns The latest release date or Unix epoch if no releases exist.
 */
export const getLatestReleaseDate = (): Date => {
    const latestRelease = getLatestRelease();
    return latestRelease ? new Date(latestRelease.date) : new Date(0);
};
