// React
import React, { useState, useEffect } from 'react';

const DragAndDropArea = ({ showFeedback, onDrop }) => {
    const [isDragging, setIsDragging] = useState(false);
    // For some reason dragLeave occurs while still on the window but only twice when leaving
    let outCount = 0;

    const handleDragEnter = (e) => {
        outCount = 0;
        e.preventDefault();
        setIsDragging(showFeedback);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(showFeedback);
    };

    const handleDragLeave = () => {
        outCount++;
        if (outCount === 2) setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFiles = Array.from(e.dataTransfer.files);
        if (onDrop) onDrop(droppedFiles);
    };

    useEffect(() => {
        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);
        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
        };
    }, [showFeedback]);

    return (
        <div className={`large-file-dropzone ${isDragging ? 'dragging' : ''}`} onDrop={handleDrop}>
            <div>
                <i className="fal fa-file-arrow-up" />
                <br />
                Upload Dataset
            </div>
        </div>
    );
};

export default DragAndDropArea;
