import { CanShowInMinimap, LayerState } from 'types/LayerState';
import { useDispatch } from 'react-redux';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Toggle from 'components/dropdown/Toggle';

export type Props = {
    layer: LayerState & CanShowInMinimap;
};

const MinimapVisibilitySettings = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const isVisibleInMap = useAppSelector(layersSlice.isShownInMinimap(props.layer));

    function showInMinimap(value: boolean) {
        dispatch(layersSlice.showInMinimap({ layer, value }));
    }

    return (
        <>
            <Toggle checked={isVisibleInMap} title="Show on minimap" onChange={(v) => showInMinimap(v)} />
            <hr />
        </>
    );
};

export default MinimapVisibilitySettings;
