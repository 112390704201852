import { type Color, type Object3D } from 'three';
import { type LineCoordinates } from './Source';

export type CreateOptions = {
    coordinates: LineCoordinates;
    opacity: number;
    color: Color;
    radiusFn?: (index: number) => number;
};

export default interface GeometryBuilder {
    create(opts: CreateOptions): Object3D;

    /**
     * Applies the same color to all vertices of the object.
     * @param object The object to update.
     * @param color The new color.
     */
    applySolidColor(object: Object3D, color: Color): void;

    /**
     * Applies a distinct color to every vertex of the object.
     * @param object The object to update.
     * @param colorFunc The color function callback called for each point in the curve.
     */
    applyVertexColors(object: Object3D, colorFunc: (vertexIndex: number, target: Color) => Color): void;

    /**
     * Disposes the object.
     */
    dispose(object: Object3D): void;

    setBrightness(object: Object3D, brightness: number): void;

    setRadius(radius: number): void;

    showInFront(feature: Object3D, inFront: boolean): void;
}

export abstract class AbstractGeometryBuilder<T extends Object3D> implements GeometryBuilder {
    /**
     * Applies the same color to all vertices of the object.
     * @param object The object to update.
     * @param color The new color.
     */
    abstract applySolidColor(object: T, color: Color): void;

    /**
     * Applies a distinct color to every vertex of the object.
     * @param object The object to update.
     * @param colorFunc The color function callback called for each point in the curve.
     */
    abstract applyVertexColors(object: T, colorFunc: (vertexIndex: number, target: Color) => Color): void;

    abstract create(opts: CreateOptions): T;

    /**
     * Disposes the object.
     */
    abstract dispose(object: T): void;

    abstract setBrightness(object: T, brightness: number): void;

    abstract setRadius(radius: number): void;

    abstract showInFront(feature: Object3D, inFront: boolean): void;
}
