import HeaderButton from 'components/flexLayout/HeaderButton';
import { PopoverBody } from 'reactstrap';
import InspectorSetting from './InspectorSetting';
import ResolutionSetting from './ResolutionSetting';

function AdvancedSetting() {
    return (
        <HeaderButton
            popover={{
                name: 'Advanced Settings',
                icon: 'fas fa-flask-gear',
                content: (
                    <PopoverBody>
                        <ul>
                            <ResolutionSetting />
                            <InspectorSetting />
                        </ul>
                    </PopoverBody>
                ),
            }}
        />
    );
}

export default AdvancedSetting;
