import type { LineString, Point, Polygon } from 'geojson';
import { SSDM_CLASSIFICATION_TYPES } from 'services/Constants';
import type { DatasetId, GeometryWithCRS, ProjectId, Timestamp, UUID } from './common';

// Note: those definitions are taken from the schemas.py in the API repository.
// They must be updated whenever the API changes.

export type AnnotationId = UUID;
export type CommentId = UUID;

export type AnnotationStatus = 'in_progress' | 'complete' | 'archived';

export type BaseComment = {
    id: CommentId;
    author_id: UUID;
    timestamp: Timestamp;
    annotation_id: AnnotationId;
    content: string;
    resolved: boolean;
    parent_id: UUID;
    mentions: UUID[];
    user_permissions: Record<string, boolean>;
};

export type AnnotationCommentReply = BaseComment & { comment_id: CommentId };

export function isAnnotationCommentReply(obj: BaseComment): obj is AnnotationCommentReply {
    if ((obj as AnnotationCommentReply).comment_id != null) {
        return true;
    }

    return false;
}

export type AnnotationComment = BaseComment & { replies?: AnnotationCommentReply[] };

export type ObservationType = 'uso' | 'flaw';

export type AnnotationGeometry = GeometryWithCRS<Point> | GeometryWithCRS<LineString> | GeometryWithCRS<Polygon>;

type Annotation = {
    project_id: ProjectId;
    name: string;
    id: AnnotationId;
    description?: string;
    user_permissions: Record<string, boolean>;
    observation_type?: ObservationType;
    ssdm_type?: SSDM_CLASSIFICATION_TYPES;
    created_by_id?: UUID;
    created_at_utc?: Timestamp;
    status?: AnnotationStatus;
    geometry: AnnotationGeometry;
    comment_count?: number;
    unresolved_comments?: boolean;
    comments: Array<AnnotationComment>;
    datasets: Array<DatasetId>;
};

export type AnnotationFilter = {
    observation: boolean;
    object: boolean;
    point: boolean;
    line: boolean;
    polygon: boolean;
    authors: { label: string; value: string }[];
    datasets: { label: string; value: DatasetId }[];
    startDate: Timestamp;
    endDate: Timestamp;
    status: AnnotationStatus[];
    ssdmTypes: { label: string; value: SSDM_CLASSIFICATION_TYPES }[];
    unresolvedComments: boolean;
};

export function isFiltered(filter: AnnotationFilter, annotation: Annotation): boolean {
    if (!filter.observation && annotation.observation_type !== null) return true;
    if (!filter.point && annotation.geometry.type === 'Point') return true;
    if (!filter.line && annotation.geometry.type === 'LineString') return true;
    if (!filter.polygon && annotation.geometry.type === 'Polygon') return true;
    if (!filter.object && annotation.ssdm_type !== null) return true;
    if (filter.authors.length !== 0 && !filter.authors.map((option) => option.value).includes(annotation.created_by_id))
        return true;
    if (filter.startDate && new Date(annotation.created_at_utc).valueOf() < new Date(filter.startDate).valueOf())
        return true;
    if (
        filter.endDate &&
        new Date(annotation.created_at_utc).valueOf() >= new Date(filter.endDate).valueOf() + 86400000 // + 1 Day
    )
        return true;
    if (
        filter.datasets.length !== 0 &&
        filter.datasets.map((option) => option.value).filter((value) => annotation.datasets.includes(value)).length ===
            0
    )
        return true;
    if (!filter.status.includes(annotation.status)) return true;
    if (
        filter.object &&
        annotation.ssdm_type &&
        filter.ssdmTypes.length !== 0 &&
        !filter.ssdmTypes.map((option) => option.value).includes(annotation.ssdm_type)
    )
        return true;
    if (filter.unresolvedComments && annotation.unresolved_comments === false) return true;
    return false;
}

export default Annotation;
