import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { deleteApiKey, fetchAllApiKeys } from '../../redux/actions';
import { getAllApiKeys, getUsernames } from '../../redux/selectors';

const ApiKeyItem = (props) => {
    const dispatch = useDispatch();
    const { apiKey } = props;

    const usernames = useSelector(getUsernames);
    const user = usernames.find((x) => x.id === apiKey.user_id);
    const username = user ? `${user.given_name} ${user.family_name}` : apiKey.user_id;

    const deleteKey = () => dispatch(deleteApiKey(apiKey.id));

    return (
        <tr className="access-item">
            <td>{apiKey.prefix}</td>
            <td>{apiKey.created_at}</td>
            <td>{username}</td>
            <td>
                <div className="admin-actions">
                    <Button className="borderless red" id="delete-key" title="Delete API key" onClick={deleteKey}>
                        <i className="fal fa-trash-can" />
                    </Button>
                </div>
            </td>
        </tr>
    );
};

const ApiKeys = () => {
    const dispatch = useDispatch();

    const apiKeys = useSelector(getAllApiKeys);

    useEffect(() => dispatch(fetchAllApiKeys()), []);

    const renderedListItems = apiKeys ? apiKeys.map((apiKey) => <ApiKeyItem apiKey={apiKey} />) : [];

    return (
        <>
            <div className="full-page-title">
                API Keys<span className="faded-text">({renderedListItems ? renderedListItems.length : 0})</span>
            </div>
            <table>
                <tr className="access-admin-header">
                    <th>Prefix</th>
                    <th>Created At</th>
                    <th>User</th>
                    <th>Actions</th>
                </tr>
                {renderedListItems}
            </table>
        </>
    );
};

export default ApiKeys;
