import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Spinner, Table, ModalHeader } from 'reactstrap';
import { SourceFile, getBaseName } from 'types/SourceFile';
import Dataset from 'types/Dataset';
import { useAppDispatch, useAppSelector } from 'store';
import StateIndicator from 'components/StateIndicator';
import { useMountEffect } from 'components/utils';
import * as datasetsSlice from 'redux/datasets';
import * as layersSlice from 'redux/layers';
import { LAYER_STATES } from 'services/Constants';
import { showError } from '../../../redux/actions';
import DosApi from '../../../services/DosApi';

export type Props = {
    dataset: Dataset;
};

const SourceFileTable = (props: Props) => {
    const { dataset } = props;
    const dispatch = useAppDispatch();
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(dataset.id));
    const clickedSourceFile = useAppSelector(datasetsSlice.getClickedSourceFile);

    useMountEffect(() => {
        if (!sourcefiles)
            DosApi.fetchDatasetSourcefiles(dataset.id)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                })
                .catch((err) => console.log(err));
    });

    const [modalDelete, setModalDelete] = useState(false);
    const [currentDeletedItem, setCurrentDeletedItem] = useState<SourceFile>(undefined);

    const toggleDelete = (item: SourceFile) => {
        setCurrentDeletedItem(item);
        setModalDelete(!modalDelete);
    };

    const deleteFile = (file: SourceFile) => {
        DosApi.deleteDatasetSourcefiles(dataset.id, [file.id])
            .then(() => {
                dispatch(
                    datasetsSlice.removeDatasetSourceFile({
                        datasetId: dataset.id,
                        sourceFileId: currentDeletedItem.id,
                    })
                );
                dispatch(
                    layersSlice.deleteSourcefiles({
                        dataset,
                        sourceFiles: [currentDeletedItem],
                    })
                );
                dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.CONVERTING }));
            })
            .catch((err) => showError(dispatch, err))
            .finally(() => {
                setModalDelete(false);
                setCurrentDeletedItem(undefined);
            });
    };

    return (
        <>
            <ModalHeader>Manage {dataset.name} source files</ModalHeader>
            <ModalBody>
                {sourcefiles === null ? (
                    <Spinner animation="border" />
                ) : (
                    <Table size="sm" className="content-middle">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th>State</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourcefiles.map((file) => (
                                <tr
                                    key={`sourcefileTable-row-${file.id}`}
                                    className={`${clickedSourceFile === file.id ? 'highlighted' : ''}`}
                                >
                                    <td>{getBaseName(file)}</td>
                                    <td>
                                        <StateIndicator id={file.id} state={file.state} state_msg={file.state_msg} />
                                    </td>
                                    <td>
                                        <Button
                                            className="borderless red"
                                            id={`sourcefilebtn-delete-${file.id}`}
                                            title={`Delete ${getBaseName(file)}`}
                                            onClick={() => toggleDelete(file)}
                                        >
                                            <i className="fal fa-trash-alt" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </ModalBody>
            <Modal
                isOpen={modalDelete}
                toggle={() => toggleDelete(undefined)}
                keyboard={false}
                centered
                className="modal-confirm"
            >
                <ModalBody>
                    <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                    <span className="big-modal-text">Are you sure?</span>
                    <span className="small-modal-text">
                        The file, {getBaseName(currentDeletedItem)}, will be removed from this dataset for all projects.
                    </span>
                    <button
                        type="button"
                        className="pane-button large highlight"
                        onClick={() => deleteFile(currentDeletedItem)}
                    >
                        Yes, Remove this File
                    </button>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="pane-button large" onClick={() => toggleDelete(undefined)}>
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default SourceFileTable;
