const handleApiError = (err, helpers) => {
    if (err.response) {
        if (err.response.status === 422) {
            for (const field of err.response.data.detail) {
                helpers.setFieldError(field.loc[1], field.msg);
            }
        } else if (err.response.data && err.response.data.detail) {
            helpers.setFieldError('submit', err.response.data.detail);
        } else if (err.response.data && err.response.data.message) {
            helpers.setFieldError('submit', err.response.data.message);
        } else if (err.response.data) {
            helpers.setFieldError('submit', err.response.data);
        } else {
            helpers.setFieldError('submit', err.response.statusText);
        }
    } else if (err.request) {
        console.log(err);
        console.log(err.request);
        helpers.setFieldError('submit', err.message);
    } else {
        helpers.setFieldError('submit', err.message);
    }
};

export default handleApiError;
