// eslint-disable-next-line import/no-cycle
import {
    COLLECTION_CREATED,
    COLLECTION_ADDED,
    COLLECTION_LOADING,
    COLLECTION_LOADING_FAILED,
    COLLECTION_UPDATED,
    COLLECTION_REMOVED,
    COLLECTIONS,
    COLLECTION_DELETED,
} from '../actionTypes';
import { COLLECTION_STATES } from '../../services/Constants';

const initialState = {
    collections: [],
    status: COLLECTIONS.UNKNOWN,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case COLLECTION_CREATED:
            // payload: collection
            return {
                ...state,
                collections: [...state.collections, payload],
            };
        case COLLECTION_ADDED:
            // payload: collection
            return {
                ...state,
                collections: state.collections.map((collection) => {
                    if (collection.id !== payload.id) return collection;
                    return {
                        ...payload,
                        olstate: COLLECTION_STATES.LOADED,
                    };
                }),
            };
        case COLLECTION_UPDATED:
            // payload: collection
            return {
                ...state,
                collections: state.collections.map((collection) => {
                    if (collection.id !== payload.id) return collection;
                    return {
                        ...payload,
                        olstate: collection.olstate,
                        olstate_msg: collection.olstate_msg,
                    };
                }),
            };
        case COLLECTION_LOADING:
            // payload: collection
            return {
                ...state,
                collections: state.collections.map((collection) => {
                    if (collection.id !== payload.id) return collection;
                    return {
                        ...collection,
                        olstate: COLLECTION_STATES.LOADING,
                    };
                }),
            };
        case COLLECTION_LOADING_FAILED:
            // payload: {collection, reason}
            return {
                ...state,
                collections: state.collections.map((collection) => {
                    if (collection.id !== payload.collection.id) return collection;
                    return {
                        ...collection,
                        olstate: COLLECTION_STATES.LOADING_FAILED,
                        olstate_msg: payload.reason,
                    };
                }),
            };
        case COLLECTION_REMOVED:
            // payload: collection
            return {
                ...state,
                collections: state.collections.map((collection) => {
                    if (collection.id !== payload.id) return collection;
                    return {
                        ...collection,
                        olstate: undefined,
                    };
                }),
            };
        case COLLECTIONS.SUCCESS:
            // payload: collections
            return {
                ...state,
                collections: payload,
                status: COLLECTIONS.SUCCESS,
            };
        case COLLECTIONS.REQUEST:
            // payload: null
            return {
                ...state,
                status: COLLECTIONS.REQUEST,
            };
        case COLLECTION_DELETED:
            return {
                ...state,
                collections: state.collections.filter((collection) => collection.id !== payload.id),
            };
        default:
            return state;
    }
}
