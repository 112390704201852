import type { UploadResult, UppyFile } from '@uppy/core';
import type Dataset from 'types/Dataset';

type InProgressProps = {
    dataset: Dataset;
    files: Array<UppyFile<Record<string, unknown>, Record<string, unknown>>>;
};

export const InProgressUpload = ({ dataset, files }: InProgressProps) => (
    <li className="upload-group">
        <div className="upload-group-title">{dataset.name}</div>
        <ul className="upload-group-list">
            {files.map((file) => (
                <li key={`file-${file.id}`}>
                    <label htmlFor={file.id}>{file.name}</label>
                    <progress id={file.id} value={file.progress.percentage} max={100}>
                        {file.progress.percentage}%
                    </progress>
                </li>
            ))}
        </ul>
    </li>
);

type CompletedProps = {
    dataset: Dataset;
    files: UploadResult<Record<string, unknown>, Record<string, unknown>>;
};

export const CompletedUpload = ({ dataset, files }: CompletedProps) => (
    <li className="upload-group complete">
        <div className="upload-group-title">{dataset.name}</div>
        <ul className="upload-group-list">
            {files.successful.map((file) => (
                <li key={`file-${file.id}`}>
                    <i className="fas fa-check icon-green" />
                    <label>{file.name}</label>
                </li>
            ))}
            {files.failed.map((file) => (
                <li key={`file-${file.id}`}>
                    <i className="fas fa-x icon-red" />
                    <label>{file.name}</label>
                </li>
            ))}
        </ul>
    </li>
);
