import { useEffect } from 'react';

type Action = () => void;

/**
 * Registers an effect when the component mounts, with an optional action to execute on unmounting.
 * @param onMount The action to execute when the component mounts.
 * @param onUnmount The (optional) action to execute when the component unmount.
 */
// eslint-disable-next-line import/prefer-default-export
export const useMountEffect = (onMount: Action, onUnmount?: Action) =>
    useEffect(() => {
        onMount();
        return onUnmount;
    }, []);

export interface Equatable {
    equals(right: unknown): boolean;
}

/**
 * A generic equality comparer function for any type that has an equals(other) method,
 * which is the case of many types in THREE.js.
 */
export function genericEqualityFn<T extends Equatable>(left: T, right: T) {
    if (left == null && right == null) {
        return true;
    }
    if (left == null || right == null) {
        return false;
    }
    return left.equals(right);
}

export function sineWave(t: number, frequency: number): number {
    const period = Math.PI * 2;
    const xOffset = Math.PI / 2;
    const yOffset = 1;
    const yScale = 0.5;

    return yScale * (Math.sin(t * period * frequency - xOffset) + yOffset);
}
