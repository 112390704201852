import DrawTool, { DRAWTOOL_EVENT_TYPE, DRAWTOOL_STATE } from 'giro3d_extensions/DrawTool';
import GeometryObject, { GEOMETRY_TYPE } from 'giro3d_extensions/GeometryObject';
import { Dispatch } from 'store';
import { Vector3 } from 'three';
import { AnnotationGeometry } from 'types/Annotation';
import { Integer } from 'types/common';

import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { EventBus } from 'EventBus';
import Disposable from '@giro3d/giro3d/core/Disposable';
import type Instance from '@giro3d/giro3d/core/Instance';
import * as drawToolSlice from '../redux/drawTool';
import GetPointAtCallback from './GetPointAtCallback';
import { DRAWTOOL_MODE } from './Constants';

export default interface DrawingManager extends Disposable {
    get state(): DRAWTOOL_STATE;
    get mode(): DRAWTOOL_MODE;

    get drawObject(): GeometryObject | null;

    setZScale(scale: number): void;

    reset(): void;
    end(): void;
    pause(): void;
    continue(): void;
    view(geometry: AnnotationGeometry): Promise<AnnotationGeometry>;
    edit(geometry: AnnotationGeometry): Promise<AnnotationGeometry>;

    startDrawing(type: GEOMETRY_TYPE, maxPoints?: number): Promise<AnnotationGeometry>;
    applyDrawing(): void;
    applyDrawingVector(): void;
    stopDrawing(): void;
    stopDrawingVector(): void;
    updateDrawnPointAt(index: Integer, coordinate: Vector3): void;
    deleteDrawnPoint(index: Integer): void;
}

export class DrawingManagerImpl implements DrawingManager {
    private _drawTool: DrawTool;
    private _instance: Instance;
    private _dispatch: Dispatch;
    private _pendingDrawing: GeometryObject | CSS2DObject;
    private _getPointAt: GetPointAtCallback;
    private _eventBus: EventBus;

    get drawObject(): GeometryObject {
        return this._drawTool?.drawObject;
    }

    get state() {
        return this._drawTool.state;
    }

    get mode() {
        return this._drawTool.mode;
    }

    setZScale(scale: number): void {
        this._drawTool.setZScale(scale);
    }

    edit(geometry: AnnotationGeometry): Promise<AnnotationGeometry> {
        return this._drawTool.editAsAPromise(geometry);
    }

    view(geometry: AnnotationGeometry): Promise<AnnotationGeometry> {
        return this._drawTool.viewAsAPromise(geometry);
    }

    end() {
        this._drawTool.end();
    }

    reset() {
        this._drawTool.reset();
    }

    dispose() {
        this._drawTool.dispose();
    }

    pause() {
        this._drawTool.pause();
    }

    continue() {
        this._drawTool.continue();
    }

    init(instance: Instance, dispatch: Dispatch, getPointAt: GetPointAtCallback, bus: EventBus) {
        this._instance = instance;
        this._dispatch = dispatch;
        this._getPointAt = getPointAt;
        this._eventBus = bus;

        this._drawTool = new DrawTool(this._instance, {
            getPointAt: this._getPointAt.bind(this),
            splicingHitTolerance: 10,
        });

        this._drawTool.addEventListener(DRAWTOOL_EVENT_TYPE.START, () => {
            this._dispatch(drawToolSlice.setPendingGeometry(this._drawTool.drawObject));
        });

        this._drawTool.addEventListener(DRAWTOOL_EVENT_TYPE.DRAWING, () => {
            this._dispatch(drawToolSlice.setPendingGeometry(this._drawTool.drawObject));
        });
    }

    updateDrawnPointAt(index: Integer, coordinate: Vector3) {
        this._drawTool.updatePointAt(index, coordinate);
    }

    deleteDrawnPoint(index: Integer) {
        this._drawTool.deletePoint(index);
    }

    applyDrawingVector() {
        this._drawTool.end();
    }

    stopDrawingVector() {
        this._drawTool.reset();
    }

    applyDrawing() {
        if (this._pendingDrawing) {
            this._pendingDrawing.removeFromParent();
            this._pendingDrawing = null;
            this._instance.notifyChange(this._instance.threeObjects);
        }
        this._drawTool.end();
    }

    stopDrawing() {
        if (this._pendingDrawing) {
            this._pendingDrawing.removeFromParent();
            this._pendingDrawing = null;
            this._instance.notifyChange(this._instance.threeObjects);
        }
        this._drawTool.reset();
    }

    startDrawing(geometryType: GEOMETRY_TYPE, maxPoints = Infinity) {
        if (this._drawTool.state !== DRAWTOOL_STATE.READY) {
            this._drawTool.reset();
        }

        this._eventBus.dispatch('start-drawing');

        // Prevent an accidental multipoint geometry
        if (geometryType === GEOMETRY_TYPE.POINT)
            this._drawTool.setOptions({
                getPointAt: this._getPointAt.bind(this),
                maxPoints: 1,
                splicingHitTolerance: 10,
            });
        else {
            this._drawTool.setOptions({ getPointAt: this._getPointAt.bind(this), maxPoints, splicingHitTolerance: 10 });
        }

        return this._drawTool
            .startAsAPromise(geometryType)
            .then((geometry) => {
                // set crs
                geometry.crs = {
                    'type': 'name',
                    'properties': {
                        'name': this._instance.referenceCrs,
                    },
                };
                if (geometryType === GEOMETRY_TYPE.POINT) {
                    const point = document.createElement('div');
                    point.style.position = 'absolute';
                    point.style.borderRadius = '50%';
                    point.style.width = '1.5rem';
                    point.style.height = '1.5rem';
                    point.style.backgroundColor = '#480f7399';
                    point.style.border = '2px solid #050405';
                    point.style.font = '14px futura-pt';
                    point.style.fontWeight = 'bold';
                    point.style.pointerEvents = 'auto';
                    point.style.cursor = 'pointer';

                    this._pendingDrawing = new CSS2DObject(point);

                    this._pendingDrawing.position.set(
                        geometry.coordinates[0],
                        geometry.coordinates[1],
                        geometry.coordinates[2]
                    );

                    this._instance.notifyChange(this._instance.threeObjects);
                    this._instance.threeObjects.add(this._pendingDrawing);
                    this._instance.threeObjects.updateMatrix();
                    this._instance.threeObjects.updateMatrixWorld(true);
                } else {
                    this._pendingDrawing = new GeometryObject(this._instance, {}, geometry);
                    this._instance.threeObjects.add(this._pendingDrawing);
                    this._instance.threeObjects.updateMatrix();
                    this._instance.threeObjects.updateMatrixWorld(true);
                    this._instance.notifyChange(this._instance.threeObjects);
                }
                return geometry;
            })
            .finally(() => {
                this._eventBus.dispatch('end-drawing');
                this._drawTool.setOptions({
                    getPointAt: this._getPointAt.bind(this),
                    maxPoints: Infinity,
                    splicingHitTolerance: 10,
                });
            });
    }
}
