import type { Geometry, LineString, Point, Polygon, Position } from 'geojson';

export function convertPoint(point: Point): string {
    const coords = point.coordinates;
    if (coords.length === 3) {
        return `POINT Z (${coords[0]} ${coords[1]} ${coords[2]})`;
    }

    return `POINT (${coords[0]} ${coords[1]})`;
}

function formatCoordinates(coordinates: Position[]): string {
    return coordinates.map((c) => `${c[0]} ${c[1]}`).join(',');
}

function formatCoordinatesZ(coordinates: Position[]): string {
    return coordinates.map((c) => `${c[0]} ${c[1]} ${c[2]}`).join(',');
}

export function convertLineString(lineString: LineString): string {
    const coords = lineString.coordinates;
    if (coords[0].length === 3) {
        return `LINESTRING Z (${formatCoordinatesZ(coords)})`;
    }

    return `LINESTRING (${formatCoordinates(coords)})`;
}

export function convertPolygon(polygon: Polygon): string {
    const coords = polygon.coordinates;
    if (coords[0][0].length === 3) {
        return `POLYGON Z ((${formatCoordinatesZ(coords[0])}))`;
    }

    return `POLYGON (${formatCoordinates(coords[0])})`;
}

export function convertToWKT(geojson: Geometry): string {
    switch (geojson.type) {
        case 'Point':
            return convertPoint(geojson as Point);
        case 'LineString':
            return convertLineString(geojson as LineString);
        case 'Polygon':
            return convertPolygon(geojson as Polygon);
        default:
            throw new Error('unsupported geometry');
    }
}
