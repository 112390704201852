// React
import { useDispatch } from 'react-redux';

// selector
import { useAppSelector } from 'store';
import * as overviewSlice from 'redux/overview';

// Redux
import { useRef, useState } from 'react';
import { useEventBus } from 'EventBus';
import { useMountEffect } from 'components/utils';
import MouseCoordinates from 'types/MouseCoordinates';
import { toLonLat } from 'ol/proj';
import Project from 'types/Project';
import ProjectInfo from './selection/ProjectInfo';

const MapUI = () => {
    const dispatch = useDispatch();
    const uiRef: React.MutableRefObject<HTMLDivElement> = useRef();
    const eventBus = useEventBus();

    const [coords, setCoords] = useState<number[]>();

    function onCoordinateChanged(arg: { coordinates: MouseCoordinates }) {
        setCoords(toLonLat([arg.coordinates.x, arg.coordinates.y]));
    }

    function subscribe() {
        eventBus.subscribe('mouse-coordinates', onCoordinateChanged);
    }

    function unsubscribe() {
        eventBus.unsubscribe('mouse-coordinates', onCoordinateChanged);
    }

    useMountEffect(subscribe, unsubscribe);

    const coordinatesShown = useAppSelector(overviewSlice.getCoordinatesShown);
    const selection = useAppSelector(overviewSlice.getSelection);

    return (
        <div className="overview-ui" ref={uiRef}>
            <div className="map-upper-ui">
                <div>
                    <button
                        type="button"
                        className={coordinatesShown ? 'highlight' : ''}
                        onClick={() => dispatch(overviewSlice.setCoordinatesShown(!coordinatesShown))}
                    >
                        <i className="fas fa-location-dot" />
                    </button>
                </div>

                {selection.type === 'project' ? (
                    <div>
                        <ProjectInfo project={selection.item as Project} />
                    </div>
                ) : null}
            </div>
            <div className="map-lower-ui">
                <div className="statusbar-stack" hidden={!coordinatesShown}>
                    <div className="map-statusbar">
                        <div id="status-bar-scale" className="map-statusbar-scaleline" />
                        <i className="fas fa-location-dot map-statusbar-marker mx-2" />
                        <div className="map-statusbar-position">
                            <div className="coordinate-component x">
                                x: <span id="status-bar-position-x">{coords ? coords[0].toFixed(2) : '0.00'}</span>
                            </div>
                            <div className="coordinate-component y">
                                y: <span id="status-bar-position-y">{coords ? coords[1].toFixed(2) : '0.00'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapUI;
