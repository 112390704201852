// React
import { useDispatch, useSelector } from 'react-redux';

import * as graphicsSettingsSlice from 'redux/graphicsSettings';

// Components
import { nanoid } from '@reduxjs/toolkit';
import { BareSettingSlider } from './inputs/SettingSlider';

function ResolutionSetting() {
    const id = nanoid();
    const dispatch = useDispatch();

    const mapSegments = useSelector(graphicsSettingsSlice.getMapSegments);

    return (
        <li>
            <label htmlFor={id}>
                <div>
                    <i className="fas fa-grid-4" />
                </div>
                <div>
                    <label htmlFor={id}>Geometric Resolution</label>
                </div>
                <div>
                    <BareSettingSlider
                        min={1}
                        max={7}
                        step={1}
                        value={Math.log2(mapSegments)}
                        onChange={(v) => dispatch(graphicsSettingsSlice.setMapSegments(2 ** v))}
                    />
                    <span>{mapSegments}</span>
                </div>
            </label>
        </li>
    );
}

export default ResolutionSetting;
