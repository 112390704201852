import { BufferGeometry, Curve, Float32BufferAttribute, QuadraticBezierCurve3, Vector2, Vector3 } from 'three';

export type RadiusFn = (t: number) => number;

/**
 * This class is the exact copy of three.js's TubeGeometry. The only difference is that
 * it supports variables radii.
 */
class VariableRadiusTubeGeometry extends BufferGeometry {
    type = 'VariableRadiusTubeGeometry';

    parameters: {
        path: Curve<Vector3>;
        tubularSegments: number;
        radius: RadiusFn;
        radialSegments: number;
        closed: boolean;
    };

    tangents: Vector3[];
    normals: Vector3[];
    binormals: Vector3[];

    constructor(
        path: Curve<Vector3> = new QuadraticBezierCurve3(
            new Vector3(-1, -1, 0),
            new Vector3(-1, 1, 0),
            new Vector3(1, 1, 0)
        ),
        tubularSegments = 64,
        radius: RadiusFn = () => 1,
        radialSegments = 8,
        closed = false
    ) {
        super();

        this.type = 'VariableRadiusTubeGeometry';

        this.parameters = {
            path,
            tubularSegments,
            radius,
            radialSegments,
            closed,
        };

        const frames = path.computeFrenetFrames(tubularSegments, closed);

        // expose internals

        this.tangents = frames.tangents;
        this.normals = frames.normals;
        this.binormals = frames.binormals;

        // helper variables

        const vertex = new Vector3();
        const normal = new Vector3();
        const uv = new Vector2();
        let P = new Vector3();

        // buffer

        const vertices = [];
        const normals = [];
        const uvs = [];
        const indices = [];

        // create buffer data

        generateBufferData();

        // build geometry

        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));

        // functions

        function generateBufferData() {
            for (let i = 0; i < tubularSegments; i++) {
                generateSegment(i);
            }

            // if the geometry is not closed, generate the last row of vertices and normals
            // at the regular position on the given path
            //
            // if the geometry is closed, duplicate the first row of vertices and normals (uvs will differ)

            generateSegment(closed === false ? tubularSegments : 0);

            // uvs are generated in a separate function.
            // this makes it easy compute correct values for closed geometries

            generateUVs();

            // finally create faces

            generateIndices();
        }

        function generateSegment(i) {
            // we use getPointAt to sample evenly distributed points from the given path

            P = path.getPointAt(i / tubularSegments, P);

            // retrieve corresponding normal and binormal

            const N = frames.normals[i];
            const B = frames.binormals[i];

            // generate normals and vertices for the current segment

            const rad = radius(i);

            for (let j = 0; j <= radialSegments; j++) {
                const v = (j / radialSegments) * Math.PI * 2;

                const sin = Math.sin(v);
                const cos = -Math.cos(v);

                // normal

                normal.x = cos * N.x + sin * B.x;
                normal.y = cos * N.y + sin * B.y;
                normal.z = cos * N.z + sin * B.z;
                normal.normalize();

                normals.push(normal.x, normal.y, normal.z);

                // vertex

                vertex.x = P.x + rad * normal.x;
                vertex.y = P.y + rad * normal.y;
                vertex.z = P.z + rad * normal.z;

                vertices.push(vertex.x, vertex.y, vertex.z);
            }
        }

        function generateIndices() {
            for (let j = 1; j <= tubularSegments; j++) {
                for (let i = 1; i <= radialSegments; i++) {
                    const a = (radialSegments + 1) * (j - 1) + (i - 1);
                    const b = (radialSegments + 1) * j + (i - 1);
                    const c = (radialSegments + 1) * j + i;
                    const d = (radialSegments + 1) * (j - 1) + i;

                    // faces

                    indices.push(a, b, d);
                    indices.push(b, c, d);
                }
            }
        }

        function generateUVs() {
            for (let i = 0; i <= tubularSegments; i++) {
                for (let j = 0; j <= radialSegments; j++) {
                    uv.x = i / tubularSegments;
                    uv.y = j / radialSegments;

                    uvs.push(uv.x, uv.y);
                }
            }
        }
    }
}

export default VariableRadiusTubeGeometry;
