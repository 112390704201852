import {
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasDataPointMode,
    LayerState,
    DataPointMode,
} from 'types/LayerState';

import * as layersSlice from 'redux/layers';
import { useAppDispatch, useAppSelector } from 'store';
import Select from 'components/dropdown/Select';
import Title from 'components/dropdown/Title';
import { Option } from 'components/Select';

export type Props = {
    layer: LayerState & HasAttributes & HasDataPointMode & HasColoringMode;
};

function formatDataPointMode(mode: DataPointMode) {
    switch (mode) {
        case DataPointMode.Continuous:
            return 'Continuous';
        case DataPointMode.Anode:
            return 'Anode';
        default:
            throw new Error('invalid state');
    }
}

function DataPointModeSettings(props: Props) {
    const { layer } = props;

    const dispatch = useAppDispatch();

    const coloringMode = useAppSelector(layersSlice.getCurrentColoringMode(layer));
    const modes = useAppSelector(layersSlice.getDataPointModes(layer));
    const currentMode = useAppSelector(layersSlice.getCurrentDataPointMode(layer));

    function changeActiveMode(value: DataPointMode) {
        dispatch(layersSlice.setCurrentDataPointMode({ layer, value }));
    }

    if (modes.length === 0 || currentMode == null) {
        return null;
    }

    if (coloringMode !== ColoringMode.Colormap) {
        return null;
    }

    return (
        <>
            <Title title="This feature is experimental" experimental />
            <Select
                title="Data Point Mode"
                value={{ value: currentMode, label: formatDataPointMode(currentMode) }}
                options={modes.map((mode) => ({ value: mode, label: formatDataPointMode(mode) }))}
                onChange={(v) => changeActiveMode((v as Option<DataPointMode>).value)}
                experimental
            />
            <hr />
        </>
    );
}

export default DataPointModeSettings;
