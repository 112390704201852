import ToolbarButton from 'components/ToolbarButton';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { getNotifications } from 'redux/selectors';
import { Notification } from 'types/Notification';
import NotificationsMenu from './NotificationsMenu';

const getMessage = (count: number) => {
    if (count === 0) return undefined;
    if (count > 99) return '#';
    return String(count);
};

const Notifications = () => {
    const [open, setOpen] = useState(false);
    const notifications: Notification[] = useAppSelector(getNotifications);
    const unreadCount = notifications ? notifications.filter((n) => !n.read).length : 0;

    return (
        <>
            <ToolbarButton
                title="Notifications"
                iconName={`fad fa-bell${unreadCount === 0 ? '' : '-ring'}`}
                id="notification-menu"
                onClick={() => setOpen(!open)}
                counter={getMessage(unreadCount)}
            />
            <NotificationsMenu open={open} toggle={() => setOpen(false)} />
        </>
    );
};

export default Notifications;
