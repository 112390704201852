const ApiErrors = {
    getErrorMessage: (err) => {
        if (err.response) {
            if (err.response.status === 422)
                return err.response.data.detail.map((field) => `${field.loc[1]} ${field.msg}`).join(' ');
            if (err.response.data && err.response.data.detail) return err.response.data.detail;
            if (err.response.data && err.response.data.message) return err.response.data.message;
            if (err.response.data) return err.response.data;
            return err.response.statusText;
        }
        if (err.request) {
            console.log(err);
            console.log(err.request);
        }
        return err.message;
    },
};
export default ApiErrors;
