import React from 'react';
import { showError } from '../redux/actions';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        error.modal_message =
            "An error occured in one of the page's components. The action you were performing may not have been saved. You can continue using the page but may have to reload to regain all functionality.";
        showError(this.props.dispatch, error);
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback ? this.props.fallback : null;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
