import VectorSource from '@giro3d/giro3d/sources/VectorSource';
import { Coordinate } from 'ol/coordinate';
import { Box3, Color } from 'three';
import * as layersSlice from 'redux/layers';

import { Feature } from 'ol';
import { DEFAULT_VECTOR_SETTINGS, FILTER_DISPLAY_MODE } from 'services/Constants';
import { VectorLayerStyle, stlyeBuilder } from 'services/VectorStyle';
import { OLFeatureId, ScopeColorLayer, toColor } from 'types/common';
import { Cache } from '@giro3d/giro3d/core/Cache';
import {
    HasAttributes,
    HasColoringMode,
    HasDraping,
    HasOpacity,
    HasOverlayColor,
    HasVectorStyle,
    LayerState,
} from 'types/LayerState';
import { Dispatch } from 'store';
import RasterLayer, { ConstructorParams as BaseConstructorParams } from './RasterLayer';
import LayerStateObserver from '../LayerStateObserver';
import { HostView } from '../Layer';

type TLayerState = LayerState &
    HasOpacity &
    HasOverlayColor &
    HasVectorStyle &
    HasDraping &
    HasColoringMode &
    HasAttributes;

interface ConstructorParams extends BaseConstructorParams<ScopeColorLayer> {
    source: VectorSource;
    dispatch: Dispatch;
}

const defaultLayerStyle: VectorLayerStyle = {
    overlayColor: new Color(DEFAULT_VECTOR_SETTINGS.FILL_COLOR),
    fillOpacity: DEFAULT_VECTOR_SETTINGS.FILL_OPACITY,
    borderColor: new Color(DEFAULT_VECTOR_SETTINGS.BORDER_COLOR),
    lineWidth: DEFAULT_VECTOR_SETTINGS.LINE_WIDTH,
    borderOpacity: DEFAULT_VECTOR_SETTINGS.BORDER_OPACITY,
    pointSize: DEFAULT_VECTOR_SETTINGS.POINT_SIZE,
    borderWidth: DEFAULT_VECTOR_SETTINGS.BORDER_WIDTH,
};

export default class VectorLayer extends RasterLayer<ScopeColorLayer, TLayerState> {
    private readonly _cache: Cache;
    source: VectorSource;
    layerStyle: VectorLayerStyle;
    private _filterStyle: VectorLayerStyle;
    private _filterMode: FILTER_DISPLAY_MODE;
    private _currentFilterFeatures: OLFeatureId[];
    private _loadingFeatures: boolean;

    /**
     * Creates an instance of VectorLayer.
     * @param params The parameters
     * @param params.source The vector source.
     * @param params.style The OpenLayers style.
     */
    constructor(params: ConstructorParams) {
        super(params);

        this._cache = new Cache({ maxNumberOfEntries: 512 });

        if (!(params.source instanceof VectorSource)) {
            throw new Error('invalid source');
        }
        this.source = params.source;

        this.layerStyle = { ...defaultLayerStyle };
        this._filterStyle = { ...defaultLayerStyle };
        this.source.setStyle(
            stlyeBuilder(
                this.layerStyle,
                this._filterStyle,
                this._cache,
                (id) => this._currentFilterFeatures.includes(id),
                this._filterMode
            )
        );

        this._filterMode = FILTER_DISPLAY_MODE.HIDE;
        this._currentFilterFeatures = [];
        this._loadingFeatures = false;

        this.assignInitialValues();
    }

    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    protected subscribeToAttributeChanges(observer: LayerStateObserver<TLayerState>): void {
        // Do nothing
    }

    protected subscribeToColorLayerSpecificStateChanges(observer: LayerStateObserver<TLayerState>): void {
        super.subscribeToColorLayerSpecificStateChanges(observer);

        observer.subscribe(layersSlice.getFeatureFilter(this._layerState), (v) => {
            this.setFilterMode(v.mode);
            this.setFilterFeatures(v.features);

            this.setPointSize(v.style.pointSize, true);
            this.setLineWidth(v.style.lineWidth, true);

            this.setBorderColor(toColor(v.style.borderColor), true);
            this.setBorderWidth(v.style.borderWidth, true);
            this.setBorderOpacity(v.style.borderOpacity, true);

            this.setFillOpacity(v.style.fillOpacity, true);
            this.setOverlayColor(toColor(v.style.fillColor), true);
        });

        observer.subscribe(layersSlice.getVectorStyle(this._layerState), (style) => {
            this.setPointSize(style.pointSize);
            this.setLineWidth(style.lineWidth);

            this.setBorderColor(toColor(style.borderColor));
            this.setBorderWidth(style.borderWidth);
            this.setBorderOpacity(style.borderOpacity);

            this.setFillOpacity(style.fillOpacity);
            this.setOverlayColor(toColor(style.fillColor));
        });
    }

    getFeatureBoundingBox(featureId: OLFeatureId) {
        if (!this.initialized) return null;

        const getBoundingBoxTmpBox3 = new Box3();
        // Raster or COG
        const min = -Infinity;
        const max = Infinity;
        const extent = this.source.getFeatureById(featureId).getGeometry().getExtent();
        getBoundingBoxTmpBox3.min.set(extent[0], extent[1], min); // min
        getBoundingBoxTmpBox3.max.set(extent[2], extent[3], max); // max
        return getBoundingBoxTmpBox3;
    }

    resetStyle(feature: Feature) {
        feature.setStyle(
            stlyeBuilder(
                this.layerStyle,
                this._filterStyle,
                this._cache,
                (id) => this._currentFilterFeatures.includes(id),
                this._filterMode
            )
        );
        this.source.update();
        this.notifyLayerChange();
    }

    getFeatures() {
        return this.source.getFeatures();
    }

    getFeatureById(featureId: OLFeatureId) {
        return this.source.getFeatureById(featureId);
    }

    updateFeatureCoordinates(featureId: OLFeatureId, coordinates: Coordinate[] | Coordinate[][]) {
        const geom = this.source.getFeatureById(featureId).getGeometry();
        // @ts-expect-error depending on geom type, the type of coordinates changes
        geom.setCoordinates(coordinates);
        this.notifyLayerChange();
    }

    private setFilterMode(mode: FILTER_DISPLAY_MODE) {
        this._filterMode = mode;
        this.invalidateStyle();
    }

    private setFilterFeatures(featureIds: OLFeatureId[]) {
        this._currentFilterFeatures = featureIds;
        this.invalidateStyle();
    }

    /**
     * Invalidates style cache.
     * Required after changing style parameters (color, point size, filters, etc.).
     */
    private invalidateStyle() {
        if (this.initialized) {
            this._cache.clear();
            this.source.update();
            this.notifyLayerChange();
        }
    }

    /**
     * Sets the point size (point diameter) in m
     * @param value Diameter in m
     * @param setFilterStyle `true` to set the style of filtered features, `false` for default one
     */
    private setPointSize(value: number, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.pointSize = value;
        this.invalidateStyle();
    }

    /**
     * Sets the line width in m
     * @param value Line width in m
     * @param setFilterStyle `true` to set the style of filtered features, `false` for default one
     */
    private setLineWidth(value: number, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.lineWidth = value;
        this.invalidateStyle();
    }

    /**
     * Sets the fill color
     * @param color Fill color
     * @param setFilterStyle `true` to set the style of filtered features, `false` for default one
     */
    protected setOverlayColor(color: Color, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.overlayColor = color;
        this.invalidateStyle();
    }

    private setBorderColor(color: Color, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.borderColor = color;
        this.invalidateStyle();
    }

    private setBorderWidth(value: number, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.borderWidth = value;
        this.invalidateStyle();
    }

    private setBorderOpacity(value: number, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.borderOpacity = value;
        this.invalidateStyle();
    }

    private setFillOpacity(value: number, setFilterStyle = false) {
        const style = setFilterStyle ? this._filterStyle : this.layerStyle;
        style.fillOpacity = value;
        this.invalidateStyle();
    }

    getLoading() {
        return this._loadingFeatures;
    }

    private async loadFeatures() {
        let idx = 0;
        this.source.forEachFeature((feature) => {
            feature.setId(idx);
            idx++;
        });
    }

    protected override async initOnce() {
        this._loadingFeatures = true;
        await super.initOnce();
        await this.loadFeatures();
        this._loadingFeatures = false;
        this.notifyLayerChange();
        this.initialized = true;

        // TODO: this is not ideal: this is used by the attribute table to know when the features
        // are ready. However, we have multiple Layers (main view, minimap, etc) that can dispatch
        // this action, leading to inconsistencies. We must have exactly one dispatch from the "main"
        // Layer.
        // If the minimap layer inits and dispatches this change, the menu will attempt to read the
        // main layer even if it has not inited. This will error out the menu.
        if (this._hostView === HostView.MainView) {
            this._dispatch(layersSlice.setVectorFeaturesReady({ layer: this._layerState, value: true }));
        }
    }

    // eslint-disable-next-line class-methods-use-this
    updateColormap() {
        // Nothing to do
    }
}
