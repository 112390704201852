import { useSelector } from 'react-redux';
import { useMountEffect } from 'components/utils';
import { useEventBus } from 'EventBus';
import { useState } from 'react';
import MouseCoordinates from 'types/MouseCoordinates';
import * as datasetsSlice from 'redux/datasets';

const Coordinates = () => {
    function format(v: number, z = false) {
        if (v === undefined) return 'N/A';
        if (z) return v.toFixed(3);
        return v.toFixed(0);
    }

    const [coords, setCoords] = useState<MouseCoordinates>();

    const eventBus = useEventBus();

    function onCoordinateChanged(arg: { coordinates: MouseCoordinates }) {
        setCoords(arg.coordinates);
    }

    function subscribe() {
        eventBus.subscribe('mouse-coordinates', onCoordinateChanged);
    }

    function unsubscribe() {
        eventBus.unsubscribe('mouse-coordinates', onCoordinateChanged);
    }

    useMountEffect(subscribe, unsubscribe);

    const project = useSelector(datasetsSlice.currentProject);

    const classNames = ['map-statusbar-position'];

    // Dim the value when no dataset is hovered
    if (!coords?.picked) {
        classNames.push('opacity-75');
    }

    return (
        <>
            {project ? `EPSG:${project.projection}` : ''}
            <i className="fas fa-location-dot map-statusbar-marker mx-2" />
            <div id="status-bar-position" className={classNames.join(' ')}>
                <div className="coordinate-component x">
                    x: <span id="status-bar-position-x">{format(coords?.x)}</span>
                </div>
                <div className="coordinate-component y">
                    y: <span id="status-bar-position-y">{format(coords?.y)}</span>
                </div>
                <div className="coordinate-component z coordinate-component-highlighted">
                    z: <span id="status-bar-position-z">{format(coords?.z, true)}</span>
                </div>
            </div>
        </>
    );
};

export default Coordinates;
