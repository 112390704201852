import Vector3Array from 'giro3d_extensions/Vector3Array';
import { Color, CylinderGeometry, Group, MathUtils, Vector3 } from 'three';
import Anode from './Anode';

const tmpVec3 = new Vector3();
const tmpNextVec3 = new Vector3();

export default class AnodeCollection extends Group {
    readonly isAnodeCollection = true;
    readonly type = 'AnodeCollection';

    private readonly _sharedGeometry: CylinderGeometry;

    constructor(radialSegments: number) {
        super();
        // We use a radius of one so that we can control the actual radius using the anode scale
        this._sharedGeometry = new CylinderGeometry(1, 1, 0.5, radialSegments, 1, false);
        this._sharedGeometry.computeBoundingBox();
        // Pre-computes data for the three-mesh-bvh package
        this._sharedGeometry.computeBoundsTree();
    }

    setRadius(radius: number) {
        this.traverseAnodes((anode) => anode.scale.set(radius, radius, radius));
        this.updateMatrixWorld(true);
    }

    update(options: {
        coordinates: Vector3Array;
        dataPoints: Map<string, Float32Array>;
        attributeName: string;
        lut: Color[];
        min: number;
        max: number;
        radius: number;
    }) {
        const { coordinates, dataPoints, attributeName, lut, min, max, radius } = options;

        const activeDataPoints = dataPoints.get(attributeName);
        if (coordinates.length !== activeDataPoints.length) {
            throw new Error('expected same length of arrays');
        }

        this.removeExistingAnodes();

        let lastPosition: Vector3 = null;

        for (let j = 0; j < coordinates.length; j++) {
            const dataPoint = activeDataPoints[j];

            // Ignore undefined or zero data points
            if (dataPoint) {
                const position = coordinates.get(j, tmpVec3);

                if (lastPosition && lastPosition.distanceToSquared(position) < 2) {
                    // Duplicated data point
                    continue;
                }

                const t = MathUtils.clamp(MathUtils.mapLinear(dataPoint, min, max, 0, 1), 0, 1);
                const colorIndex = Math.round(t * (lut.length - 1));
                const color = lut[colorIndex];

                const anode = this.createAnode(position, color);
                anode.scale.set(radius, radius, radius);

                // Let's orient the anode cylinder along the path direction
                let lookAtTarget: Vector3 = position;
                if (j < coordinates.length - 1) {
                    lookAtTarget = coordinates.get(j + 1, tmpNextVec3);
                } else if (j > 0) {
                    lookAtTarget = coordinates.get(j - 1, tmpNextVec3).negate();
                }

                anode.lookAt(lookAtTarget);
                anode.rotateX(Math.PI / 2);

                const tooltipProperties: Array<{ key: string; value: number; active: boolean }> = [];
                for (const [key, values] of dataPoints) {
                    const value = values[j];
                    tooltipProperties.push({ key, value, active: key === attributeName });
                }
                anode.setProperties(tooltipProperties);

                this.add(anode);

                if (!lastPosition) {
                    lastPosition = new Vector3();
                }
                lastPosition.copy(position);
            }
        }
    }

    setBrightness(brightness: number) {
        this.traverseAnodes((anode) => {
            anode.brightness = brightness;
        });
    }

    private createAnode(localPosition: Vector3, color: Color): Anode {
        const anode: Anode = new Anode(this._sharedGeometry, color);
        anode.position.copy(localPosition);

        this.add(anode);

        return anode;
    }

    /**
     * Applies the callback to every anode in the collection.
     */
    traverseAnodes(callback: (anode: Anode) => void) {
        this.traverse((o) => {
            if (o instanceof Anode) {
                callback(o);
            }
        });
    }

    setVisibility(visible: boolean) {
        this.visible = visible;
        this.traverseAnodes((anode) => anode.setVisibility(visible));
    }

    private removeExistingAnodes() {
        this.traverseAnodes((anode) => anode.dispose());
        this.clear();
    }

    /**
     * Disposes the entire anode collection and the shared geometry.
     */
    dispose() {
        this.removeExistingAnodes();
        this._sharedGeometry.dispose();
    }
}
