// eslint-disable-next-line import/no-cycle
import { ELIGIBLE_TYPES } from '../actionTypes';

const initialState = {
    eligible: undefined,
};

export default function reducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case ELIGIBLE_TYPES.SUCCESS:
            return {
                ...state,
                eligible: true,
            };
        case ELIGIBLE_TYPES.ERROR:
            return {
                ...state,
                eligible: false,
            };
        case ELIGIBLE_TYPES.UNKNOWN:
            return {
                ...state,
                eligible: undefined,
            };
        default:
            return state;
    }
}
