import { SeismicDatasetProperties } from 'types/Dataset';
import { SourceFile, getBaseName } from 'types/SourceFile';
import SeismicPlaneLayer, { ConstructorParams } from '../layers/seismic/SeismicPlaneLayer';
import LayerBuilder from './LayerBuilder';

export default class SeismicPlaneBuilder<TOutput extends SeismicPlaneLayer = SeismicPlaneLayer> extends LayerBuilder<
    SeismicDatasetProperties,
    TOutput
> {
    getParams(sourceFile: SourceFile): ConstructorParams {
        return {
            instance: this.getInstance(),
            name: getBaseName(sourceFile),
            layerManager: this._layerManager,
            sourcePath: sourceFile.source,
            dispatch: this._dispatch,
            properties: sourceFile.properties as SeismicDatasetProperties,
            datasetId: this.getDatasetId(),
            sourceFileId: sourceFile.id,
            getFootprint: () => sourceFile.geometry,
            crs: this.getDatasetProjectionAsString(),
            hostView: this._hostView,
        };
    }

    protected override buildLayer(sourceFile: SourceFile): Promise<TOutput> {
        const layer = new SeismicPlaneLayer(this.getParams(sourceFile));

        return Promise.resolve(layer as TOutput);
    }
}
