import { nanoid } from '@reduxjs/toolkit';

export type Props = {
    value: string;
    title: string;
    icon?: string;
    onChange: (v: string) => void;
    onBlur?: (v: string) => void;
};

function TextInput(props: Props) {
    const id = nanoid();
    return (
        <li>
            <label className="cursor">
                <div>
                    <i className={props.icon} />
                </div>
                <div>
                    <button type="button">{props.title}</button>
                </div>
                <div>
                    <input
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        onBlur={(e) => (props.onBlur ? props.onBlur(e.currentTarget.value) : null)}
                        id={id}
                    />
                </div>
            </label>
        </li>
    );
}

export default TextInput;
