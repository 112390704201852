// THREE
import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

import { CanShowInMinimap } from 'types/LayerState';

// Giro3d Extentions
import LayerManager from 'giro3d_extensions/LayerManager';
import Layer, { HostView } from 'giro3d_extensions/layers/Layer';

import * as datasetsSlice from 'redux/datasets';

import { EventBus } from 'EventBus';
import Dataset from 'types/Dataset';
import { SourceFile } from 'types/SourceFile';
import BaseGiro3dService from './BaseGiro3dService';
import MinimapControls from './MinimapControls';

const DEFAULT_MAP_SEGMENTS = 4;

class MinimapService extends BaseGiro3dService {
    private _cursorMarker: CSS2DObject;
    private _cameraMarker: CSS2DObject;
    private _onLayerInitialized: (e: { layer: string }) => void;

    constructor() {
        super({ hostView: HostView.MinimapView, canHover: false });
        this._inspectorTitle = 'Minimap Inspector';
        this._segments = DEFAULT_MAP_SEGMENTS;
    }

    initControls() {
        return new MinimapControls(this._instance, this.getPointAt.bind(this), this.getBoundingBox.bind(this));
    }

    protected override unsubscribeFromEventBus(bus: EventBus): void {
        super.unsubscribeFromEventBus(bus);

        bus.unsubscribe('layer-initialized', this._onLayerInitialized);
    }

    protected override subscribeToEventBus(bus: EventBus): void {
        super.subscribeToEventBus(bus);

        this._onLayerInitialized = this.onLayerInitialized.bind(this);

        bus.subscribe('layer-initialized', this._onLayerInitialized);
    }

    initLayerManager() {
        return new LayerManager({
            instance: this._instance,
            segments: this._segments,
            hillshading: this._enableHillshading,
            hillshadingIntensity: this._hillshadeIntensity,
            azimuth: this._lightDirection.azimuth,
            zenith: this._lightDirection.zenith,
            contourLines: false,
            projectExtent: this._stateObserver.select(datasetsSlice.getProjectExtent),
        });
    }

    async loadDataset(dataset: Dataset, sourceFiles: SourceFile[]): Promise<Layer[]> {
        const allLayers = await super.loadDataset(dataset, sourceFiles);

        const layerState = this.getLayerState<CanShowInMinimap>(dataset.id);

        for (const layer of allLayers) {
            layer.setMinimapVisibility(layerState.showInMinimap);
        }

        return allLayers;
    }

    init(domElem, inspectorDomElem, extent, dispatch) {
        super.init(domElem, inspectorDomElem, extent, dispatch, {
            camera: new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 100000000),
        });

        const cursorPoint = document.createElement('div');
        cursorPoint.style.position = 'absolute';
        cursorPoint.style.borderRadius = '50%';
        cursorPoint.style.width = '0.5rem';
        cursorPoint.style.height = '0.5rem';
        cursorPoint.style.backgroundColor = '#ff0000';
        cursorPoint.style.border = '1px solid #ffffff';
        cursorPoint.style.pointerEvents = 'none';

        const cameraPointContainer = document.createElement('div');
        cameraPointContainer.style.width = '0.5rem';
        cameraPointContainer.style.height = '0.5rem';

        const cameraPoint = document.createElement('div');
        cameraPoint.style.position = 'absolute';
        cameraPoint.style.borderRadius = '50% 0 50% 50%';
        cameraPoint.style.width = '0.5rem';
        cameraPoint.style.height = '0.5rem';
        cameraPoint.style.backgroundColor = '#0000ff';
        cameraPoint.style.border = '1px solid #ffffff';
        cameraPoint.style.pointerEvents = 'none';

        cameraPointContainer.appendChild(cameraPoint); // Child used so that rotation can be appied

        this._cursorMarker = new CSS2DObject(cursorPoint);
        this._cursorMarker.visible = true;
        this._instance.add(this._cursorMarker);

        this._cameraMarker = new CSS2DObject(cameraPointContainer);
        this._cameraMarker.visible = true;
        this._instance.add(this._cameraMarker);
    }

    onInteractionEnd() {
        if (this._interactionTimer !== null) {
            // There was already an end of interaction pending, cancel it
            clearTimeout(this._interactionTimer);
        }
        this._interactionTimer = setTimeout(this.doOnInteractionEnd.bind(this), 500);
    }

    deinit() {
        if (!this._instance) return; // nothing to do

        if (this._cameraMarker) this._cameraMarker.remove();
        if (this._cursorMarker) this._cursorMarker.remove();

        // reset initial values and let the garbage collector to its holy job
        this._cursorMarker = null;
        this._cameraMarker = null;

        super.deinit();
    }

    protected onLayerInitialized(): void {
        if (this._controls) {
            (this._controls as MinimapControls).lookFromTop();
        }
    }

    async postInit() {
        if (!this._instance) return; // nothing to do

        (this._controls as MinimapControls).lookFromTop();
    }

    setCursorMarker(cursor: { x: number; y: number; z: number; picked: boolean }, color?: string) {
        this._cursorMarker.visible = cursor.picked;
        if (cursor.picked) {
            const z = 0;
            this._cursorMarker.position.set(cursor.x, cursor.y, z);
        }

        this._cursorMarker.element.style.backgroundColor = color ?? '#ff0000';
        this._cursorMarker.updateMatrixWorld();
        this.notifyChange(this._cursorMarker);
    }

    setCameraMarker(camera: { x: number; y: number; z: number; rotation: number }) {
        const z = 0;

        this._cameraMarker.position.set(camera.x, camera.y, z);
        // @ts-expect-error child element has a style property
        this._cameraMarker.element.children[0].style.rotate = `${camera.rotation - Math.PI / 4}rad`; // 1/8 turn to align the corner forward

        this._cameraMarker.updateMatrixWorld();
        this.notifyChange(this._cameraMarker);
    }
}

export default MinimapService;
