import {
    Box3,
    BufferGeometry,
    Color,
    Line,
    LineBasicMaterial,
    Mesh,
    type MeshPhongMaterial,
    type Object3D,
    Vector3,
} from 'three';
import type { Point } from 'geojson';
import { ZSCALE_BEHAVIOR } from '../../services/Constants';
import ThreejsGroupLayer from './ThreejsGroupLayer';
import { ConstructorParams as BaseConstructorParams } from './Layer';

type Part = Mesh<BufferGeometry, MeshPhongMaterial | MeshPhongMaterial[]>;

export interface ConstructorParams extends BaseConstructorParams {
    loadVesselModel: () => Promise<Object3D>;
}

class VesselLayer extends ThreejsGroupLayer {
    private xpos: number;
    private ypos: number;
    private zpos: number;
    private line: Line<BufferGeometry, LineBasicMaterial>;
    private boundingBox: Box3;
    private readonly loadVesselModel: () => Promise<Object3D>;

    constructor(params: ConstructorParams) {
        super(params);
        this.settings.zscaleEffect = ZSCALE_BEHAVIOR.COUNTERACT;
        this.loadVesselModel = params.loadVesselModel;

        this.assignInitialValues();
    }

    private loadObject(object: Object3D) {
        // --------------------------------------------------------------------------
        // The stuff below here was commented out in the example code. Not sure what
        // it is, but I will leave it for now:
        // --------------------------------------------------------------------------

        // object.traverse(function (child) {
        //     if ((child as Mesh).isMesh) {
        //         // (child as Mesh).material = material
        //         if ((child as Mesh).material) {
        //             ((child as Mesh).material as MeshBasicMaterial).transparent = false
        //         }
        //     }
        // })

        // --------------------------------------------------------------------------
        // Add, place and orient AUV model:
        // --------------------------------------------------------------------------
        object.scale.set(0.1, 0.1, 0.1);
        object.position.set(this.xpos, this.ypos, this.zpos + 2.5);
        // object.rotation.set(Math.PI/2, 0, Math.PI)
        object.rotation.set(Math.PI / 2, Math.PI / 2, 0);
        object.userData.datasetId = this.datasetId; // necessary to be clickable
        object.userData.sourceFileId = this.sourceFileId;
        object.updateMatrix();
        object.updateMatrixWorld(true);
        this.object3d.add(object);
        this.object3d.name = 'VesselLayer';

        // --------------------------------------------------------------------------
        // Add a line through the AUV:
        // --------------------------------------------------------------------------
        const points = [new Vector3(0, +1000, 0), new Vector3(0, -1000, 0)];

        const lineMaterial = new LineBasicMaterial({
            color: '#fff',
            transparent: this.settings.opacity < 1,
            opacity: this.settings.opacity,
        });
        const geometry = new BufferGeometry().setFromPoints(points);
        const line = new Line(geometry, lineMaterial);
        line.name = 'Vessel path';
        this.line = line;
        this.line.position.set(this.xpos, this.ypos, this.zpos);
        this.object3d.add(line);
        this.object3d.updateMatrix();
        this.object3d.updateMatrixWorld(true);
    }

    getBoundingBox() {
        if (!this.initialized) return null;
        return this.boundingBox;
    }

    protected override getObjectOpacity() {
        // Return the opacity of the line
        return this.line.material.opacity;
    }

    protected override setBrightness(brightness: number) {
        this.object3d.traverse((m: Part) => {
            if (m.isMesh) {
                if (Array.isArray(m.material)) {
                    m.material.forEach((sm) => {
                        sm.emissiveIntensity = brightness;
                    });
                } else {
                    m.material.emissiveIntensity = brightness;
                }
            }
        });
    }

    protected override async initOnce() {
        await super.initOnce();
        const footprint = (await this.getFootprint()) as Point;
        const coords = footprint.coordinates;
        this.xpos = coords[0];
        this.ypos = coords[1];
        this.zpos = coords[2];
        const element3d = await this.loadVesselModel();
        element3d.name = 'Model';
        const WHITE = new Color('white');
        element3d.traverse((m: Part) => {
            if (m.isMesh) {
                m.geometry.computeBoundingBox();
                if (Array.isArray(m.material)) {
                    m.material.forEach((sm) => {
                        sm.emissive = WHITE;
                        sm.emissiveIntensity = 0;
                    });
                } else {
                    m.material.emissive = WHITE;
                    m.material.emissiveIntensity = 0;
                }
            }
        });
        this.loadObject(element3d);
        this.notifyLayerChange();

        const bbox = new Box3().setFromObject(element3d);
        const bboxSize = bbox.getSize(new Vector3());
        bbox.expandByVector(new Vector3(bboxSize.x * 1.5, bboxSize.y * 1.5, bboxSize.z * 1.5));
        this.boundingBox = bbox;

        this.initialized = true;
    }

    protected override onDispose() {
        super.onDispose();
        if (this.initialized) {
            this.line.material.dispose();
            this.line.geometry.dispose();
        }
    }
}

export default VesselLayer;
