import React from 'react';
import logo from '../../images/Argeo-logo.svg';

const Logo = () => (
    <div className="dashboard-logo">
        <img src={logo} alt="Argeo logo" />
        <span>SCOPE</span>
    </div>
);

export default Logo;
