import { useEventBus } from 'EventBus';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import * as giro3dSlice from 'redux/giro3d';
import { CONTROLS_MODE } from 'services/Controls';
import giro3dService from 'services/Giro3dService';
import { useAppSelector } from 'store';
import { PlayState } from 'types/common';

const LineFollowerMenu = () => {
    const dispatch = useDispatch();
    const eventBus = useEventBus();

    const mode = useAppSelector(giro3dSlice.getControlMode);
    const followCamera = useAppSelector(giro3dSlice.getFollowCamera);

    function setState(state: PlayState) {
        dispatch(giro3dSlice.setFollowCameraState(state));
        dispatch(
            giro3dSlice.setControlsMode(
                state === PlayState.Release ? CONTROLS_MODE.RELEASED_FOLLOW : CONTROLS_MODE.FOLLOW
            )
        );
    }

    function onKeyDown(e: KeyboardEvent) {
        switch (e.code) {
            case 'Space':
                dispatch(
                    giro3dSlice.setFollowCameraState(
                        followCamera.state === PlayState.Pause || followCamera.state === PlayState.Release
                            ? PlayState.Play
                            : PlayState.Pause
                    )
                );
                dispatch(giro3dSlice.setControlsMode(CONTROLS_MODE.FOLLOW));
                break;
            default:
            // do nothing
        }
    }
    useEffect(() => {
        if (mode === CONTROLS_MODE.FOLLOW || mode === CONTROLS_MODE.RELEASED_FOLLOW) {
            giro3dService.getInstance().viewport.addEventListener('keydown', onKeyDown);
            return () => giro3dService.getInstance()?.viewport.removeEventListener('keydown', onKeyDown);
        }
        return null;
    }, [mode, followCamera.state]);

    if (mode !== CONTROLS_MODE.FOLLOW && mode !== CONTROLS_MODE.RELEASED_FOLLOW) return null;

    const handleSliderChange = (event) => {
        if (followCamera.state === PlayState.Release) setState(PlayState.Pause);
        eventBus.dispatch('go-to-follow-progress', { progress: event.target.value / 5000 });
    };

    return (
        <div className="line-follow-menu">
            <div className="controls">
                <div>
                    <Button
                        type="button"
                        className={`borderless ${followCamera.state !== PlayState.Pause && followCamera.state !== PlayState.Release ? 'red' : 'green'} full-color`}
                        onClick={() =>
                            setState(
                                followCamera.state === PlayState.Pause || followCamera.state === PlayState.Release
                                    ? PlayState.Play
                                    : PlayState.Pause
                            )
                        }
                    >
                        <i
                            className={`fal fa-${followCamera.state !== PlayState.Pause && followCamera.state !== PlayState.Release ? 'pause' : 'play'}`}
                        />
                    </Button>
                    <Button
                        type="button"
                        className={`borderless  ${followCamera.state === PlayState.FastRewind ? 'full-color light-blue' : 'yellow'} ${followCamera.state === PlayState.Rewind ? 'full-color' : ''}`}
                        onClick={() =>
                            setState(followCamera.state === PlayState.Rewind ? PlayState.FastRewind : PlayState.Rewind)
                        }
                    >
                        <i className="fal fa-backward" />
                    </Button>
                    <Button
                        type="button"
                        className={`borderless  ${followCamera.state === PlayState.FastFastForward ? 'full-color light-blue' : 'yellow'} ${followCamera.state === PlayState.FastForward ? 'full-color' : ''}`}
                        onClick={() =>
                            setState(
                                followCamera.state === PlayState.FastForward
                                    ? PlayState.FastFastForward
                                    : PlayState.FastForward
                            )
                        }
                    >
                        <i className="fal fa-forward" />
                    </Button>
                </div>
                <div>
                    <Button
                        type="button"
                        className="borderless yellow"
                        onClick={() => dispatch(giro3dSlice.setFollowCameraModal(true))}
                    >
                        <i className="fal fa-gear" />
                    </Button>
                    <Button
                        type="button"
                        onClick={() => setState(PlayState.Release)}
                        disabled={mode === CONTROLS_MODE.RELEASED_FOLLOW}
                    >
                        Release Camera
                    </Button>
                </div>
            </div>
            <input
                type="range"
                min="0"
                max="5000"
                value={followCamera.progress * 5000}
                className="slider"
                onChange={handleSliderChange}
            />
        </div>
    );
};

export default LineFollowerMenu;
