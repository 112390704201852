import proj4 from 'proj4';
import { Las } from 'las-js';
import { GlobalCache, type Cache } from '@giro3d/giro3d/core/Cache';
import { Curve, Vector3 } from 'three';
import Fetcher from '@giro3d/giro3d/utils/Fetcher';
import type { Point } from 'geojson';
import Vector3Array from 'giro3d_extensions/Vector3Array';
import { LasDatasetProperties } from 'types/Dataset';
import Source, { type Description, LineCoordinates, type Property } from './Source';

function computePropertyRange(nodata: number, values: number[]): { min: number; max: number } {
    let min = +Infinity;
    let max = -Infinity;

    for (let i = 0; i < values.length; i++) {
        const v = values[i];
        if (v !== nodata) {
            min = Math.min(min, v);
            max = Math.max(max, v);
        }
    }

    return { min, max };
}

/**
 * Provides an API to access a borehole LAS source.
 */
export default class BoreholeLasSource implements Source {
    private readonly _converter: proj4.Converter;

    private readonly _url: string;

    private readonly _cache: Cache;

    private readonly _cacheKey: string;

    private _loaded = false;

    private _description: Description;

    private _properties: Map<string, Float32Array>;

    private _depths: number[];

    private readonly _location: Point;

    private _origin: Vector3;

    private _storedProperties: LasDatasetProperties;

    constructor(params: {
        location: Point;
        url: string;
        crsIn: string;
        crsOut: string;
        properties: LasDatasetProperties;
    }) {
        this._url = params.url;
        this._cache = GlobalCache;
        this._cacheKey = params.url;
        this._location = params.location;
        if (params.crsIn !== params.crsOut) {
            this._converter = proj4(params.crsIn, params.crsOut);
        }
        this._storedProperties = params.properties;
    }

    async initialize() {
        if (!this._loaded) {
            const body = await Fetcher.text(this._url);
            const lasFile = new Las(body, { loadFile: false });

            const headers = await lasFile.header();
            const depthIndex = headers.findIndex((h) => ['dept', 'depth'].includes(h.toLowerCase()));
            if (depthIndex === -1) {
                throw new Error('Error parsing Las file. Unable to find depth index.');
            }

            const properties: Map<string, Property> = new Map();
            const props = new Map<string, Float32Array>();

            this._depths = (await lasFile.column(headers[depthIndex])) as number[];

            const propkeys = headers.filter((_, idx) => idx !== depthIndex);
            const well = await lasFile.wellParams();
            const nodata = parseFloat(well.NULL.value);

            for (const key of propkeys) {
                /* eslint-disable no-await-in-loop */
                const values = (await lasFile.column(key)) as number[];

                props.set(key, new Float32Array(values));

                const storedProperty = this._storedProperties.properties.find((p) => p.key === key);
                if (storedProperty)
                    properties.set(key, {
                        name: storedProperty.name,
                        min: storedProperty.min,
                        max: storedProperty.max,
                    });
                else properties.set(key, { name: key, ...computePropertyRange(nodata, values) });
            }

            this._description = {
                properties,
            };

            this._properties = props;

            this._loaded = true;
        }
    }

    private async getOrigin(): Promise<Vector3> {
        if (!this._origin) {
            const point = this._location;
            const coords = point.coordinates;
            if (this._converter) {
                this._converter.forward(coords);
            }
            this._origin = new Vector3(coords[0], coords[1], coords[2]);
        }

        return this._origin;
    }

    async getDescription(): Promise<Description> {
        await this.initialize();
        return this._description;
    }

    // eslint-disable-next-line class-methods-use-this
    async getCurves(): Promise<Curve<Vector3>[]> {
        throw new Error('Method not implemented.');
    }

    private getLine(origin: Vector3): LineCoordinates {
        const points = new Vector3Array({ length: this._depths.length });

        for (let i = 0; i < this._depths.length; i++) {
            const depth = this._depths[i];
            points.set(i, 0, 0, -depth);
        }

        const line: LineCoordinates = {
            origin,
            points,
        };

        return line;
    }

    async getGeometries(): Promise<LineCoordinates[]> {
        await this.initialize();

        const key = `${this._cacheKey}-geometries`;
        const cached = this._cache.get(key);
        if (cached) {
            return cached as LineCoordinates[];
        }

        const origin = await this.getOrigin();

        const line = this.getLine(origin);

        const result = [line];

        this._cache.set(key, result);

        return result;
    }

    async getPropertyValue(name: string): Promise<Float32Array[]> {
        if (!this._loaded) {
            await this.initialize();
        }

        const values = this._properties.get(name);
        return [values];
    }

    // eslint-disable-next-line class-methods-use-this
    getPropertyValues(): Promise<Map<string, Float32Array[]>> {
        throw new Error('Method not implemented.');
    }
}
