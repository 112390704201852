import ErrorBoundary from 'components/ErrorBoundary';
import { useAppDispatch, useAppSelector } from 'store';
import * as annotationsSlice from 'redux/annotations';
import { ANNOTATION_CREATESTATE } from 'services/Constants';
import { stopAnnotationCreation, stopAnnotationEdit, stopAnnotationView } from 'redux/actions';
import { EDITSTATE } from 'redux/reducers/vector';
import { useEffect } from 'react';
import CreateAnnotation from './CreateAnnotation';
import EditAnnotation from './EditAnnotation';
import ViewAnnotation from './ViewAnnotation';

const AnnotationTool = () => {
    const dispatch = useAppDispatch();
    const createState = useAppSelector(annotationsSlice.createState);
    const editState = useAppSelector(annotationsSlice.editState);

    useEffect(() => {
        return () => {
            if (createState) dispatch(stopAnnotationCreation());
            if (editState) dispatch(stopAnnotationEdit());
        };
    }, []);

    if (createState !== ANNOTATION_CREATESTATE.NONE) {
        return (
            <ErrorBoundary
                dispatch={dispatch}
                fallback={
                    <div className="tabContent">
                        <span className="error-fallback-message">
                            <i className="fal fa-exclamation-triangle icon-red" />
                            An error occured in the CreateAnnotation component.
                            <i className="fal fa-exclamation-triangle icon-red" />
                        </span>
                        <button
                            type="button"
                            className="pane-button"
                            onClick={() => dispatch(stopAnnotationCreation())}
                        >
                            Back
                        </button>
                    </div>
                }
            >
                <CreateAnnotation />
            </ErrorBoundary>
        );
    }
    if (editState === EDITSTATE.VIEW) {
        return (
            <ErrorBoundary
                dispatch={dispatch}
                fallback={
                    <div className="tabContent">
                        <span className="error-fallback-message">
                            <i className="fal fa-exclamation-triangle icon-red" />
                            An error occured in the ViewAnnotation component.
                            <i className="fal fa-exclamation-triangle icon-red" />
                        </span>
                        <button type="button" className="pane-button" onClick={() => dispatch(stopAnnotationView())}>
                            Back
                        </button>
                    </div>
                }
            >
                <ViewAnnotation />
            </ErrorBoundary>
        );
    }
    if (editState !== EDITSTATE.NONE) {
        return (
            <ErrorBoundary
                dispatch={dispatch}
                fallback={
                    <div className="tabContent">
                        <span className="error-fallback-message">
                            <i className="fal fa-exclamation-triangle icon-red" />
                            An error occured in the EditAnnotation component.
                            <i className="fal fa-exclamation-triangle icon-red" />
                        </span>
                        <button type="button" className="pane-button" onClick={() => dispatch(stopAnnotationEdit())}>
                            Back
                        </button>
                    </div>
                }
            >
                <EditAnnotation />
            </ErrorBoundary>
        );
    }

    return <span className="tabContent">No active annotation tool.</span>;
};

export default AnnotationTool;
