import type { IsPointCloud, LayerState } from 'types/LayerState';
import { useDispatch } from 'react-redux';

import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import { DEFAULT_POINTCLOUD_SETTINGS } from 'services/Constants';
import Slider from 'components/dropdown/Slider';

type PointCloudLayer = LayerState & IsPointCloud;

export type Props = {
    layer: PointCloudLayer;
};

const PointCloudPointSizeSetting = ({ layer }: Props) => {
    const dispatch = useDispatch();
    const pointSize = useAppSelector(layersSlice.getPointCloudSize(layer));

    const setPointSize = (value: number) => {
        dispatch(layersSlice.setPointCloudSize({ layer, value }));
    };

    return (
        <Slider
            title="Point Size"
            icon="fas fa-circle-o"
            min={DEFAULT_POINTCLOUD_SETTINGS.MIN_POINT_SIZE}
            max={DEFAULT_POINTCLOUD_SETTINGS.MAX_POINT_SIZE}
            step={0.1}
            value={pointSize}
            onChange={(v) => setPointSize(v)}
        />
    );
};

const PointCloudSseThresholdSetting = ({ layer }: Props) => {
    const dispatch = useDispatch();
    const threshold = useAppSelector(layersSlice.getSseThreshold(layer));

    /**
     * For user-friendliness purposes, SSE threshold is reversely mapped to the point density.
     * Normally, the lower the denser, but it is more intuitive to have high values maps to higher densities,
     * hence the mirroring.
     * @param value
     */
    function reverse(v: number): number {
        return DEFAULT_POINTCLOUD_SETTINGS.MAX_SSE_THRESHOLD - v;
    }

    const setThreshold = (value: number) => {
        dispatch(layersSlice.setPointCloudSseThreshold({ layer, value }));
    };

    return (
        <Slider
            title="Point Density"
            icon="fas fa-arrow-up-right-dots"
            min={DEFAULT_POINTCLOUD_SETTINGS.MIN_SSE_THRESHOLD}
            max={DEFAULT_POINTCLOUD_SETTINGS.MAX_SSE_THRESHOLD}
            step={1}
            value={reverse(threshold)}
            onChange={(v) => setThreshold(reverse(v))}
        />
    );
};

const PointCloudSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;

    return (
        <>
            <PointCloudPointSizeSetting key={`datasetsettings-${id}-pointcloudpointsize`} layer={layer} />
            <PointCloudSseThresholdSetting key={`datasetsettings-${id}-pointcloudssethreshold`} layer={layer} />
            <hr />
        </>
    );
};

export default PointCloudSettings;
