// eslint-disable-next-line import/no-cycle
import { EDIT_VECTOR_STATE } from '../actionTypes';

export const CREATESTATE = {
    NONE: 'none',
    DRAWING: 'draw',
    DETAILS: 'details',
    SUMBIT: 'submit',
    COMPLETE: 'complete',
};
export const EDITSTATE = {
    NONE: 'none',
    DRAWING: 'draw',
    DETAILS: 'details',
    SUMBIT: 'submit',
    COMPLETE: 'complete',
};

const initialState = {
    list: [],
    active: null,
    createState: CREATESTATE.NONE,
    editState: EDITSTATE.NONE,
    geometry: null,
};

function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case EDIT_VECTOR_STATE:
            return {
                ...state,
                editState: payload,
            };
        default:
            return state;
    }
}

export default reducer;
