interface Props {
    id: string;
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

const ToggleSwitch = (props: Props) => (
    <label className="toggle-switch" htmlFor={props.id}>
        <input type="checkbox" {...props} title={props.id} />
        <span />
    </label>
);

export default ToggleSwitch;
