import React, { useEffect, useState, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getReleaseInfoShow, getReleaseInfoShownAt, getUser } from '../../redux/selectors';
import { RELEASE_HIDE, RELEASE_SHOW } from '../../redux/actionTypes';
import Release from './Release';
import { getLatestReleaseDate, getSortedReleases } from './releaseUtils';

const ReleaseModal = () => {
    const show = useSelector(getReleaseInfoShow);
    const shownAt = useSelector(getReleaseInfoShownAt);
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const releases = getSortedReleases();
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        if (show && releases.length > 0) {
            setCurrentIndex(0);
        }
    }, [show, releases]);

    useEffect(() => {
        const latestReleaseDate = getLatestReleaseDate();
        if (user && (!shownAt || new Date(shownAt) < latestReleaseDate)) {
            dispatch({ type: RELEASE_SHOW });
            setCurrentIndex(0);
        }
    }, [shownAt, user, dispatch, releases]);

    const toggleModal = useCallback(() => {
        dispatch({ type: RELEASE_HIDE });
    }, [dispatch]);

    const goToPrevious = useCallback(() => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }, []);

    const goToNext = useCallback(() => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, releases.length - 1));
    }, [releases.length]);

    const isFirstRelease = currentIndex === 0;
    const isLastRelease = currentIndex === releases.length - 1;
    const currentRelease = releases[currentIndex];

    if (releases.length === 0) {
        return (
            <Modal isOpen={show} toggle={toggleModal} centered>
                <ModalHeader toggle={toggleModal}>What is new in Argeo SCOPE</ModalHeader>
                <ModalBody>No release notes available at this time.</ModalBody>
            </Modal>
        );
    }

    if (!currentRelease) return null;

    return (
        <Modal isOpen={show} toggle={toggleModal} centered className="fixed-modal">
            <ModalHeader toggle={toggleModal}>What is new in Argeo SCOPE</ModalHeader>
            <ModalBody className="modal-body-scrollable">
                <Release
                    date={currentRelease.date}
                    version={currentRelease.version}
                    description={currentRelease.description}
                    features={currentRelease.features}
                    improvements={currentRelease.improvements}
                />
            </ModalBody>
            <ModalFooter className="d-flex flex-column align-items-center">
                <span className="mb-2">Click here to look at previous releases:</span>
                <div>
                    <Button
                        color="link"
                        onClick={goToPrevious}
                        disabled={isFirstRelease}
                        className="mr-2"
                        aria-label="Previous Release"
                    >
                        <i className="fa fa-arrow-left fa-lg" />
                    </Button>
                    <Button
                        color="link"
                        onClick={goToNext}
                        disabled={isLastRelease}
                        className="ml-2"
                        aria-label="Next Release"
                    >
                        <i className="fa fa-arrow-right fa-lg" />
                    </Button>
                </div>
                <span className="mt-2">{`Release ${currentIndex + 1} of ${releases.length}`}</span>
            </ModalFooter>
        </Modal>
    );
};

export default ReleaseModal;
