import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Collection } from 'types/Collection';
import Dataset from 'types/Dataset';
import Project from 'types/Project';

type Selectable = Project | Collection | Dataset | Record<string, string | number>;

export type State = {
    selectionType: string;
    selection: Selectable;
    coordinatesShown: boolean;
};

const initialState: State = {
    selectionType: undefined,
    selection: undefined,
    coordinatesShown: false,
};

// Selectors
const self = (store: RootState) => store.overview;

export const getSelection = createSelector(self, (s) => ({ item: s.selection, type: s.selectionType }));
export const getCoordinatesShown = createSelector(self, (s) => s.coordinatesShown);

const slice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        reset: () => initialState,
        select: (state, action: PayloadAction<{ selection: Selectable; type: string }>) => {
            state.selectionType = action.payload.type;
            state.selection = action.payload.selection;
        },
        setCoordinatesShown: (state, action: PayloadAction<boolean>) => {
            state.coordinatesShown = action.payload;
        },
    },
});

export const reducer = slice.reducer;

export const reset = slice.actions.reset;
export const select = slice.actions.select;
export const setCoordinatesShown = slice.actions.setCoordinatesShown;
