import React from 'react';
import DatasetListItem from './DatasetListItem';

const CollectionMenu = ({
    datasets,
    openDeletePrompt,
    setDatasetToInspect,
    filtersFunction,
    searchingFunction,
    sortingFunction,
    sortableHeader,
    project,
}) => {
    const renderedTableItems = datasets
        ? datasets
              .filter(filtersFunction)
              .filter(searchingFunction)
              .sort(sortingFunction)
              .map((dataset) => (
                  <DatasetListItem
                      dataset={dataset}
                      setDatasetToDelete={openDeletePrompt}
                      setDatasetToInspect={setDatasetToInspect}
                      key={dataset.id}
                      project={project}
                  />
              ))
        : null;

    return (
        <div className="full-page-table">
            <table>
                <thead>
                    <tr>
                        {project && <th>Included</th>}
                        {sortableHeader('Name', 'name')}
                        {sortableHeader('Type', 'type')}
                        {sortableHeader('Projection', 'projection')}
                        {sortableHeader('Organization', 'organization')}
                        {sortableHeader('State', 'state')}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{renderedTableItems}</tbody>
            </table>
        </div>
    );
};

export default CollectionMenu;
