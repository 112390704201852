import { Box3, Box3Helper, Vector3 } from 'three';
import Panel from '@giro3d/giro3d/gui/Panel';

class ControlsInspector extends Panel {
    /**
     * @param {GUI} gui The GUI.
     * @param {Instance} instance The Giro3D instance.
     * @param {Controls} controls Controls instance.
     */
    constructor(gui, instance, controls) {
        super(gui, instance, 'Controls');

        this.controls = controls;
        this.target = new Vector3();
        this.interactionPoint = new Vector3();
        this.controls.getTarget(this.target);
        this.controls.getInteractionPoint(this.interactionPoint);

        const target = this.gui.addFolder('Target');
        target.close();
        this._controllers.push(target.add(this.target, 'x'));
        this._controllers.push(target.add(this.target, 'y'));
        this._controllers.push(target.add(this.target, 'z'));

        const interactionPoint = this.gui.addFolder('Picked point');
        interactionPoint.close();
        this._controllers.push(interactionPoint.add(this.interactionPoint, 'x'));
        this._controllers.push(interactionPoint.add(this.interactionPoint, 'y'));
        this._controllers.push(interactionPoint.add(this.interactionPoint, 'z'));

        this._eventhandlers = {
            'control': () => {
                this.controls.getTarget(this.target);
                this.controls.getInteractionPoint(this.interactionPoint);
            },
        };

        this.addController(this.controls.cameraControls, 'polarAngle').name('Polar angle');
        this.addController(this.controls.cameraControls, 'azimuthAngle').name('Azimuth angle');

        this.bbox = new Box3();

        this.boxHelper = new Box3Helper(this.bbox, '#ffc0c0');
        this.boxHelper.name = 'bounding box';
        this.boxHelper.isHelper = true;
        this.boxHelper.isvolumeHelper = true;
        this.boxHelper.material.transparent = true;
        this.boxHelper.visible = false;
        instance.scene.add(this.boxHelper);

        this.needsUpdate = false;

        this.addController(this.boxHelper, 'visible')
            .name('Data bounding box')
            .onChange((v) => {
                this.boxHelper.visible = v;
                this.needsUpdate = true;
                this.notify();
            });

        this.controls.cameraControls.addEventListener('update', this._eventhandlers.control);
    }

    dispose() {
        this.boxHelper.removeFromParent();
        this.controls.cameraControls.removeEventListener('update', this._eventhandlers.control);

        super.dispose();
    }

    updateValues() {
        if (!this.needsUpdate) {
            return;
        }

        if (this.boxHelper.visible) {
            if (this.bbox) {
                this.boxHelper.box = this.bbox;
                this.bbox.getCenter(this.boxHelper.position);
                this.boxHelper.updateMatrixWorld();
            } else {
                this.boxHelper.position.set(0, 0, 0);
                this.boxHelper.updateMatrixWorld();
            }
        }

        this.needsUpdate = false;
    }

    setBoundingBox(bbox) {
        this.bbox = bbox?.clone() ?? null;
        this.needsUpdate = true;
        this.notify();
    }
}

export default ControlsInspector;
