// import { useState } from 'react';
// import MosaicWMTSLayer from 'giro3d_extensions/layers/raster/MosaicWMTSLayer';
// import { useGiro3DContext } from '../../Giro3DContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MosaicFileOutlineSetting = ({ dataset }) => {
    // Mosaics don't work under the current assumptions.
    return null;
    // const giro3dService = useGiro3DContext();
    // const layers = giro3dService.getLayersForDataset<MosaicWMTSLayer>(dataset);
    // if (layers.length === 0) {
    //     return null;
    // }

    // const layer = layers[0];

    // const [viewFileOutlines, setViewFileOutlines] = useState(layer.isShowingFileOutlines());

    // const changeViewFileOutlines = async (value) => {
    //     setViewFileOutlines(value);
    //     if (value) await layer.loadFileOutlines(false);
    //     else layer.setFileOutlinesVisibility(false);
    // };

    // return (
    //     <div className="select-group">
    //         <label htmlFor="showFileOutlines" className="col-md col-form-label">
    //             Debug files
    //             <input
    //                 id="showFileOutlines"
    //                 type="checkbox"
    //                 className="form-check-input"
    //                 checked={viewFileOutlines}
    //                 onChange={(e) => changeViewFileOutlines(e.target.checked)}
    //             />
    //         </label>
    //     </div>
    // );
};

export default MosaicFileOutlineSetting;
