import Dataset, { EmptyDatasetProperties } from 'types/Dataset';

import { SourceFile } from 'types/SourceFile';
import LayerBuilder from './LayerBuilder';
import DocumentLayer, { ConstructorParams } from '../layers/DocumentLayer';
import { BuildContext } from './LayerBuilderFactory';

export default class DocumentBuilder extends LayerBuilder<EmptyDatasetProperties, DocumentLayer> {
    constructor(dataset: Dataset, sourceFiles: SourceFile[], context: BuildContext) {
        super(dataset, sourceFiles, context);
        this.checkDatasetAttachments();
    }

    override async build(): Promise<DocumentLayer[]> {
        const footprint = await this.getDatasetFootprint();

        const params: ConstructorParams = {
            instance: this.getInstance(),
            layerManager: this._layerManager,
            dispatch: this._dispatch,
            datasetId: this.getDatasetId(),
            sourceFileId: this.getDatasetId(), // TODO
            getFootprint: () => footprint,
            documents: this.buildDatasetAttachmentUrls(),
            hostView: this._hostView,
        };

        const layer = new DocumentLayer(params);

        return [layer];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
    protected buildLayer(sourceFile: SourceFile): Promise<DocumentLayer> {
        throw new Error('unreachable code');
    }
}
