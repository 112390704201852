import { ScopeElevationLayer } from 'types/common';
import RasterLayer, { ConstructorParams } from './RasterLayer';

export default class ElevationCogLayer extends RasterLayer<ScopeElevationLayer> {
    constructor(params: ConstructorParams<ScopeElevationLayer>) {
        super({ supportsMasks: true, ...params });
        this.assignInitialValues();
    }

    setOpacity(opacity: number) {
        this.settings.opacity = opacity;
        if (this.initialized) {
            this._layerManager.setOpacity(this.get3dElement(), opacity);
        }
    }
}
