import { getOrganizations } from 'redux/selectors';
import { useAppSelector } from 'store';
import Project from 'types/Project';

const ProjectInfo = (props: { project: Project }) => {
    const organizations = useAppSelector(getOrganizations);

    return (
        <div className="tabContent">
            <table>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <h4>{props.project.name}</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>Organization</td>
                        <td>
                            {organizations?.find((o) => o.id === props.project.organization_id)?.display_name ??
                                'Loading...'}
                        </td>
                    </tr>
                    <tr>
                        <td>Projection</td>
                        <td>{props.project.projection}</td>
                    </tr>
                    {props.project.description ? (
                        <tr>
                            <td colSpan={2}>{props.project.description}</td>
                        </tr>
                    ) : null}
                </tbody>
            </table>
        </div>
    );
};

export default ProjectInfo;
