import { useState } from 'react';
import { Color } from 'three';

type Props = {
    color: Color;
    lightColor?: Color;
    darkColor?: Color;
    onChange: (arg0: Color) => void;
};

const ColorSelector: React.FC<Props> = ({ color, lightColor, darkColor, onChange }) => {
    const lightPreset = lightColor ?? new Color('white');
    const darkPreset = darkColor ?? new Color('black');

    const [waiter, setWaiter] = useState(null);

    function emit(hex: string) {
        // Ensures that the emitted color is a three.js color rather than a string.
        onChange(new Color(hex));
    }

    function waitToEmit(hex: string) {
        if (waiter) clearTimeout(waiter);
        setWaiter(setTimeout(() => emit(hex), 50));
    }

    return (
        <div className="color-pickers">
            <button
                type="button"
                className={`color-picker white ${lightPreset.equals(color) ? 'active' : ''}`}
                aria-label="white"
                onClick={() => emit('#ffffff')}
            />
            <button
                type="button"
                className={`color-picker black ${darkPreset.equals(color) ? 'active' : ''}`}
                aria-label="black"
                onClick={() => emit('#000000')}
            />
            <div
                className={`color-picker ${
                    !lightPreset.equals(color) && !darkPreset.equals(color) ? 'active' : 'rainbow'
                }`}
                style={{ backgroundColor: `#${color.getHexString()}` }}
            >
                <input
                    type="color"
                    id="color"
                    value={`#${color.getHexString()}`}
                    onChange={(e) => waitToEmit(e.target.value)}
                />
            </div>
        </div>
    );
};

export default ColorSelector;
