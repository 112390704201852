import { useAppDispatch, useAppSelector } from 'store';
import Dataset from 'types/Dataset';
import * as settingsSlice from 'redux/settings';
import ToggleSwitch from 'components/ToggleSwitch';
import * as giro3dSlice from '../../../redux/giro3d';
import * as datasetsSlice from '../../../redux/datasets';
import { SelectionItem } from '../../../redux/giro3d';

const FeatureReadout = (props: { item: SelectionItem; dataset: Dataset }) => {
    const dispatch = useAppDispatch();
    const showEmpty = useAppSelector(settingsSlice.getShowEmptyVectorRows);

    const properties = Object.entries(props.item.feature.getProperties()).filter(
        ([key]) => key !== 'geometry' && key !== 'geometryProperty'
    );

    const elements = [];

    properties.forEach(([key, value]) => {
        if (showEmpty || value)
            elements.push(
                <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                </tr>
            );
    });

    return (
        <>
            <div className="inspector-title">
                <span>Vector - {props.dataset.name}</span>
            </div>
            <div className="input-row">
                <ToggleSwitch
                    id="showEmpty"
                    checked={showEmpty}
                    onChange={(v) => dispatch(settingsSlice.showEmptyVectorRows(v.target.checked))}
                />
                Show Empty Rows
            </div>
            <table>
                <tbody>{elements}</tbody>
            </table>
        </>
    );
};

// const ItemRow = (props: { item: SelectionItem; at: Vector3 }) => {
//     const { item, at } = props;
//     const featureManager = getService('FeatureManager');
//     const selectItem = () => {
//         const { layer, feature } = item;
//         featureManager.selectFeature(layer, feature, at);
//     };

//     const properties = item.feature.getProperties();
//     const firstColumn = Object.keys(properties)
//         .filter((h) => h !== 'geometry')
//         .at(0);

//     return (
//         <li>
//             <button type="button" className="pane-button" onClick={selectItem}>
//                 {firstColumn ? properties[firstColumn] : item.feature.getId()}
//             </button>
//         </li>
//     );
// };

const FeatureInfo = () => {
    const item = useAppSelector(giro3dSlice.getSelectedItem);

    const dataset = useAppSelector(datasetsSlice.get(item?.layer));

    if (!item) return null;

    return (
        <div className="tabContent">
            <FeatureReadout item={item} dataset={dataset} />
        </div>
    );
};

export default FeatureInfo;
