import { Color } from 'three';
import ColorMap, { COLORMAP_BOUNDSMODE } from 'types/ColorMap';
import { Attribute } from 'types/LayerState';

export type Props = {
    id: string;
    colorMap: ColorMap;
    attribute: Attribute;
    lut: Color[];
};

function ColormapScaleBar(props: Props) {
    const { id, attribute, colorMap, lut } = props;
    const unit = attribute.unit;
    const minBound = colorMap.boundsMode === COLORMAP_BOUNDSMODE.DATA ? attribute.min : colorMap.customMin;
    const maxBound = colorMap.boundsMode === COLORMAP_BOUNDSMODE.DATA ? attribute.max : colorMap.customMax;

    return (
        <div className="scale-bar">
            {lut.map((color: Color, index: number) => {
                const uv = index / lut.length;
                const value = (maxBound - minBound) * uv + minBound;
                let label = `${value.toFixed(2)}${unit}`;
                if (colorMap.discrete) {
                    const uv2 = (index + 1) / lut.length;
                    const value2 = (maxBound - minBound) * uv2 + minBound;
                    label += ` to ${value2.toFixed(2)}${unit}`;
                }
                return (
                    <span
                        className="scale-bar-span"
                        style={{ backgroundColor: `#${color.getHexString()}` }}
                        key={`datasetsettings-${id}-colormap-${index.toString()}`}
                        title={label}
                    />
                );
            })}
        </div>
    );
}

export default ColormapScaleBar;
