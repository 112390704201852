import { UncontrolledTooltip } from 'reactstrap';

interface Props {
    id: string;
    message: string;
}

export const Info = (props: Props) => (
    <>
        <div id={props.id} className="tip info fas fa-square-info" />
        <UncontrolledTooltip target={props.id} placement="bottom">
            <span className="submenu-tooltip">{props.message}</span>
        </UncontrolledTooltip>
    </>
);

export const Warning = (props: Props) => (
    <>
        <div id={props.id} className="tip warning fas fa-triangle-exclamation" />
        <UncontrolledTooltip target={props.id} placement="bottom">
            <span className="submenu-tooltip">{props.message}</span>
        </UncontrolledTooltip>
    </>
);
