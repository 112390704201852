// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDraggable } from '@dnd-kit/core';

import { DatasetId } from 'types/common';

import { hasAttributes, HasDraping, hasDraping, LayerState } from 'types/LayerState';

import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

import { LAYER_DATA_TYPES, LAYER_STATES, LAYER_TYPES, PANE } from 'services/Constants';
import { setClickedDataset } from 'redux/actions';
import { useEventBus } from 'EventBus';
import { useAppSelector } from 'store';
import DosApi from 'services/DosApi';
import DatasetIndicator from './DatasetIndicator';
import RemoveDataset from './RemoveDataset';
import InlineDropMenu from '../../InlineDropMenu';
import ToggleSwitch from '../../ToggleSwitch';
import DatasetSettingsPopover from './DatasetSettingsPopover';

const selfRelevantEqual = (id: DatasetId) => (left: DatasetId, right: DatasetId) =>
    left === right || !(left === id || right === id);

export type handleMode = 'sort' | 'group';

export type Props = {
    id: string;
    datasetId: DatasetId;
    groupOpen: boolean;
    handleMode: handleMode;
};

const DatasetListItem = (props: Props) => {
    const { id, datasetId, groupOpen, handleMode } = props;
    const dispatch = useDispatch();

    const { attributes, listeners, setNodeRef, transform, transition } =
        handleMode === 'sort'
            ? useSortable({ id: datasetId })
            : { ...useDraggable({ id: datasetId }), transition: undefined };

    const clickedDataset = useAppSelector(datasetsSlice.getClickedDataset, selfRelevantEqual(datasetId));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const eventBus = useEventBus();

    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(datasetId));
    const state = dataset.state;

    const layerState = useAppSelector(layersSlice.get(datasetId));
    const supportsDraping = hasDraping(layerState) ?? false;
    const isVisible = useAppSelector(layersSlice.isVisibleSelf(layerState));
    const isDraped = useAppSelector(layersSlice.isDraped(layerState as LayerState & HasDraping)) ?? false;

    const setVisible = (value: boolean) => {
        dispatch(layersSlice.setVisibility({ layer: layerState, value }));
    };

    const selectDataset = () => {
        eventBus.dispatch('highlight-layer', { layer: datasetId });
        dispatch(setClickedDataset(datasetId));
    };

    const inspectDataset = () => {
        eventBus.dispatch('create-dataset-pane', {
            paneType: PANE.INSPECTOR,
            datasetId: dataset.id,
            showExisting: true,
        });
    };

    const viewSeismic = async () => {
        let sourcefileId = sourcefiles ? sourcefiles[0].id : undefined;
        if (!sourcefileId) {
            await DosApi.fetchDatasetSourcefiles(datasetId)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                    sourcefileId = sourceFiles[0].id;
                })
                .catch((err) => console.log(err));
        }
        eventBus.dispatch('highlight-file', { dataset: datasetId, file: sourcefileId, scroll: true });
        dispatch(setClickedDataset(datasetId, sourcefileId));
        dispatch(datasetsSlice.setSeismicDataset({ dataset: datasetId, sourceFile: sourcefileId }));
        eventBus.dispatch('create-pane', {
            paneType: PANE.SEISMIC,
            showExisting: true,
        });
    };

    if (transform) transform.scaleY = 1;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    function goToLayer() {
        eventBus.dispatch('go-to-layer', { layer: layerState.datasetId });
    }

    const handle = () => {
        switch (handleMode) {
            case 'sort':
                if (supportsDraping && !isDraped) {
                    // Layer is not drapped (reordering has no effect),
                    // let the user reorder it but display it has no effect
                    return (
                        <Button
                            id={`dataset-handle-${id}`}
                            className="borderless handle"
                            {...listeners}
                            {...attributes}
                        >
                            <span className="fa-stack" style={{ width: '18px' }}>
                                <i className="fas fa-grip-vertical fa-stack-1x" />
                                <i className="fas fa-slash fa-stack-1x" />
                            </span>
                            <UncontrolledTooltip placement="right" target={`dataset-handle-${id}`}>
                                The ordering does not affect the rendering of this layer as it is not draped on
                                bathymetry
                            </UncontrolledTooltip>
                        </Button>
                    );
                }
            // falls through
            case 'group':
                return (
                    <Button className="borderless handle" {...listeners} {...attributes}>
                        <i className="fas fa-grip-vertical" />
                    </Button>
                );
            default:
                return null;
        }
    };

    const disabled = state !== LAYER_STATES.LOADED && state !== LAYER_STATES.ACTIVE;

    return (
        <li
            className={`${groupOpen && clickedDataset === datasetId ? 'highlight' : ''}`}
            hidden={!groupOpen}
            ref={setNodeRef}
            style={style}
        >
            <div>
                {handle()}
                <label htmlFor={`dataset-name-${id}`} onDoubleClick={goToLayer}>
                    <button id={`dataset-name-${id}`} type="button" onClick={selectDataset}>
                        <span>{dataset.name}</span>
                    </button>
                </label>
                <DatasetIndicator id={id} dataset={dataset} />
                <InlineDropMenu id={`dataset-actions-${id}`}>
                    {dataset.user_permissions.update_dataset && <RemoveDataset id={id} dataset={dataset} />}
                    <Button
                        id={`datasetbtn-inspect-${id}`}
                        className="borderless green"
                        title={`Inspect ${dataset.name}`}
                        onClick={() => {
                            selectDataset();
                            inspectDataset();
                        }}
                    >
                        <i className="fas fa-info-circle" />
                    </Button>
                    {dataset.type === LAYER_TYPES.SEISMIC ? (
                        <Button
                            className="borderless light-blue"
                            id={`dataset-seismic-view-${id}`}
                            title={`View ${dataset.name} in 2D`}
                            onClick={() => {
                                viewSeismic();
                                inspectDataset();
                            }}
                            disabled={disabled}
                        >
                            2D
                        </Button>
                    ) : null}
                    {dataset.type === LAYER_TYPES.VECTOR ? (
                        <Button
                            className="borderless light-blue"
                            id={`dataset-attribute-table-${id}`}
                            title={`View ${dataset.name} in Table`}
                            onClick={() =>
                                eventBus.dispatch('create-dataset-pane', {
                                    paneType: PANE.ATTRIBUTE_TABLE,
                                    datasetId: dataset.id,
                                    showExisting: true,
                                })
                            }
                            disabled={disabled}
                        >
                            <i className="fas fa-table" />
                        </Button>
                    ) : null}
                    {dataset.datatype === LAYER_DATA_TYPES.VECTOR && hasAttributes(layerState) ? (
                        <Button
                            className="borderless light-blue"
                            id={`dataset-chart-${id}`}
                            title={`View ${dataset.name} in Chart`}
                            onClick={() =>
                                eventBus.dispatch('create-dataset-config-pane', {
                                    paneType: PANE.CHART,
                                    data: { datasetId: dataset.id },
                                    showExisting: true,
                                })
                            }
                            disabled={disabled}
                        >
                            <i className="fas fa-chart-line" />
                        </Button>
                    ) : null}
                    <Button
                        className="borderless yellow"
                        id={`dataset-settings-${id}`}
                        title={`${dataset.name} settings`}
                        onClick={() => {
                            selectDataset();
                            setSettingsOpen(true);
                        }}
                        disabled={disabled}
                    >
                        <i className="fas fa-gear" />
                    </Button>
                </InlineDropMenu>
                <ToggleSwitch
                    id={`visible-${id}`}
                    checked={isVisible}
                    onChange={(e) => setVisible(e.target.checked)}
                    disabled={disabled}
                />
                <DatasetSettingsPopover
                    dataset={dataset}
                    target={`dataset-actions-${id}`}
                    isOpen={settingsOpen}
                    onToggle={() => setSettingsOpen(false)}
                />
            </div>
        </li>
    );
};

export default DatasetListItem;
