import { Object3D } from 'three';

export default interface IsClickable {
    isClickable: true;
    clickHandler(): Promise<void>;
    getClickableElement(): Object3D;
}

export function isClickable(obj: unknown): obj is IsClickable {
    return (obj as IsClickable)?.isClickable;
}
