import { ImageTile, VectorTile } from 'ol';
import { Image, Group } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import ImageWMS from 'ol/source/ImageWMS';
import XYZ from 'ol/source/XYZ';
import { Stroke, Style } from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import { PMTilesVectorSource } from 'ol-pmtiles';
import HttpConfiguration from '@giro3d/giro3d/utils/HttpConfiguration';

// useGeographic();
async function tileLoadFunctionWithAuthentication(image, url) {
    const opts = {};
    HttpConfiguration.applyConfiguration(url, opts); // Reuse Giro3d mechanism to add authentication headers
    const response = await fetch(url, opts);
    if (response.status === 200) {
        if (image instanceof ImageTile) {
            const imageData = await response.blob();
            image.getImage().src = window.URL.createObjectURL(imageData);
        } else if (image instanceof VectorTile) {
            const imageData = await response.arrayBuffer();
            const features = image.getFormat().readFeatures(imageData, {
                extent: image.extent,
                featureProjection: image.projection,
            });
            image.setFeatures(features);
        } else {
            console.warn('Unsupported type of tiled data');
        }
    }
}

const apiUrl = window._env_.REACT_APP_API_BASE_URI
    ? window._env_.REACT_APP_API_BASE_URI
    : process.env.REACT_APP_API_BASE_URI;

const EMODNET_HUMAN_ACTIVITIES_URL = 'https://ows.emodnet-humanactivities.eu/geoserver/emodnet/ows?SERVICE=WMS&';

const EMOD_WIND_FARMS = new Image({
    title: 'Wind Farms (Polygons)',
    source: new ImageWMS({
        url: EMODNET_HUMAN_ACTIVITIES_URL,
        params: { 'format': 'image/png', 'layers': 'emodnet:windfarmspoly' },
        crossOrigin: 'anonymous',
    }),
    opacity: 1.0,
    visible: false,
    layerId: 'emodnet:windfarmspoly',
});

const EMOD_PIPELINES = new Image({
    title: 'Pipelines',
    source: new ImageWMS({
        url: EMODNET_HUMAN_ACTIVITIES_URL,
        params: { 'format': 'image/png', 'layers': 'emodnet:pipelines' },
        crossOrigin: 'anonymous',
    }),
    opacity: 1.0,
    visible: false,
    layerId: 'emodnet:pipelines',
});

const EMOD_LICESNSES = new Image({
    title: 'Active Licenses',
    source: new ImageWMS({
        url: EMODNET_HUMAN_ACTIVITIES_URL,
        params: { 'format': 'image/png', 'layers': 'emodnet:activelicenses' },
        crossOrigin: 'anonymous',
    }),
    opacity: 1.0,
    visible: false,
    layerId: 'emodnet:activelicenses',
});

const EMOD_PLATFORMS = new Image({
    title: 'Offshore Installations',
    source: new ImageWMS({
        url: EMODNET_HUMAN_ACTIVITIES_URL,
        params: { 'format': 'image/png', 'layers': 'emodnet:platforms' },
        crossOrigin: 'anonymous',
    }),
    opacity: 1.0,
    visible: false,
    layerId: 'emodnet:platforms',
});

const humanActivities = new Group({
    title: 'EMODNET human activities',
    layers: [EMOD_PIPELINES, EMOD_WIND_FARMS, EMOD_LICESNSES, EMOD_PLATFORMS],
    visible: false,
});

// Can add this once we display attributions correctly.
// const sentinel2Layer = new TileLayer({
//     source: new TileWMS({
//         url: 'https://tiles.maps.eox.at/wms?',
//         params: {
//             'LAYERS': 's2cloudless_3857',
//             'VERSION': '1.1.1',
//             'FORMAT': 'image/png',
//         },
//         serverType: 'geoserver',
//         attributions: 'Contains modified Copernicus Sentinel data 2023 / processed by EOX',
//     }),
//     visible: false,
//     opacity: 0.4,
//     title: 'Sentinel-2 Cloudless',
// });
// const openStreetMap = new TileLayer({
//     title: 'OSM',
//     source: new OSM(),
//     opacity: 1,
// });

const coastline = new VectorTileLayer({
    title: 'Coastlines',
    declutter: true,
    source: new PMTilesVectorSource({ url: '/coastline.pmtiles' }),
    style: new Style({ stroke: new Stroke({ color: [50, 50, 50], width: 1 }) }),
    visible: true,
});

const basemapUrl = `${apiUrl}/basemap/tiles/{z}/{x}/{-y}.png`;
const gebcoSource = new XYZ({
    url: basemapUrl,
    crossOrigin: 'anonymous',
    maxZoom: 6,
    minZoom: 2,
    visible: true,
});

gebcoSource.setTileLoadFunction(tileLoadFunctionWithAuthentication);

const gebcoBathymetry = new TileLayer({
    title: 'Gebco Bathymetry',
    source: gebcoSource,
    opacity: 1,
});

gebcoBathymetry.on('postrender', (event) => {
    const context = event.context;
    context.filter = 'brightness(1.5)';
    context.drawImage(context.canvas, 0, 0);
    context.filter = 'none'; // Reset the filter
});

const basemap = new Group({
    title: 'Basemap',
    layers: [gebcoBathymetry, coastline],
    visible: true,
});

const openSourceLayers = [basemap, humanActivities];

export default openSourceLayers;
