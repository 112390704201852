import { HasFootprint, LayerState } from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import { useAppDispatch, useAppSelector } from 'store';
import Toggle from 'components/dropdown/Toggle';

export type Props = {
    layer: LayerState & HasFootprint;
};

const FootprintSettings = (props: Props) => {
    const { layer } = props;
    const dispatch = useAppDispatch();

    const showLabels = useAppSelector(layersSlice.getFootprintLabelVisibility(props.layer));

    function setLabelVisibility(value: boolean) {
        dispatch(layersSlice.setFootprintLabelVisibility({ layer, value }));
    }

    return (
        <>
            <Toggle title="Show Filenames" checked={showLabels} onChange={(v) => setLabelVisibility(v)} />
            <hr />
        </>
    );
};

export default FootprintSettings;
