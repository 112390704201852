import { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { startAnnotationCreation } from 'redux/annotationActions';
import Annotation from 'types/Annotation';
import { getService } from 'ServiceContainer';
import { useAppDispatch, useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';
import * as settingsSlice from 'redux/settings';
import * as giro3dSlice from 'redux/giro3d';
import ApiErrors from 'services/ApiErrors';
import { ANNOTATION_CREATESTATE, DEFAULT_ANNOTATION_FILTER, EDITSTATE } from 'services/Constants';
import ToggleSwitch from 'components/ToggleSwitch';
import { useEventBus } from 'EventBus';
import { fetchSSDMTypes, checkForUsernames, fetchAnnotations } from '../../../redux/actions';
import DosApi from '../../../services/DosApi';
import AnnotationExport from './AnnotationExport';
import AnnotationFilters from './AnnotationFilters';
import AnnotationItem from './AnnotationItem';
import ErrorBoundary from '../../ErrorBoundary';

const AnnotationList = (props: { annotations: Annotation[]; deleteCallback: (a: Annotation) => void }) => {
    const { annotations, deleteCallback } = props;
    const dispatch = useAppDispatch();

    if (annotations.length === 0) {
        return (
            <span className="text-center mt-5">There are currently no annotations or they are hidden by filters.</span>
        );
    }

    return (
        <ul className="list">
            {annotations.map((annotation) => (
                <ErrorBoundary
                    key={annotation.id}
                    dispatch={dispatch}
                    fallback={
                        <li>
                            <div>
                                <span className="error-fallback-message">
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                    An error occured in this AnnotationItem component.
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                </span>
                            </div>
                        </li>
                    }
                >
                    <AnnotationItem annotation={annotation} deleteCallback={deleteCallback} />
                </ErrorBoundary>
            ))}
        </ul>
    );
};

const AnnotationMenu = () => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const annotationManager = getService('AnnotationManager');

    const project = useAppSelector(datasetsSlice.currentProject);
    const filters = useAppSelector(annotationsSlice.filter);
    const annotations = useAppSelector<Annotation[]>(annotationsSlice.list);

    const createState = useAppSelector(annotationsSlice.createState);
    const editState = useAppSelector(annotationsSlice.editState);

    const viewFilter = useAppSelector(settingsSlice.getFilterAnnotationByVisibility);
    const showAnnotations = useAppSelector(settingsSlice.getShowAnnotationsInViewport);
    const [annotationAuthors, setAnnotationAuthors] = useState<string[]>([]);
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);
    const selection = useAppSelector(giro3dSlice.getSelection);
    const activeAnnotation = useAppSelector(annotationsSlice.active);

    useEffect(() => {
        if (annotations) {
            const authors = [...new Set(annotations.map((annotation) => annotation.created_by_id))];
            setAnnotationAuthors(authors);
            dispatch(checkForUsernames(annotationAuthors));
        }
    }, [annotations]);

    useEffect(() => {
        dispatch(fetchSSDMTypes());
    }, []);

    const DELETE = {
        PROMPT: 'prompt',
        DELETING: 'deleting',
        CONFIRMATION: 'confirm',
        ERROR: 'error',
    };

    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [deleteState, setDeleteState] = useState(DELETE.PROMPT);
    const [errorText, setErrorText] = useState(null);
    const [annotationToDelete, setAnnotationToDelete] = useState(null);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);

    useEffect(() => {
        let newCount = 0;

        Object.keys(DEFAULT_ANNOTATION_FILTER).forEach((x) => {
            if (Array.isArray(filters[x])) {
                // Symetric difference
                newCount += DEFAULT_ANNOTATION_FILTER[x]
                    .filter((element) => !filters[x].includes(element))
                    .concat(filters[x].filter((element) => !DEFAULT_ANNOTATION_FILTER[x].includes(element))).length;
            } else if (filters[x] !== DEFAULT_ANNOTATION_FILTER[x]) newCount += 1;
        });
        setFiltersCount(newCount);
    }, [filters]);

    const openDeleteModal = (annotation: Annotation) => {
        setDeleteState(DELETE.PROMPT);
        setDeleteIsOpen(true);
        setAnnotationToDelete(annotation);
    };

    const cancelDelete = () => {
        setDeleteIsOpen(false);
    };

    const deleteAnnotation = () => {
        setDeleteState(DELETE.DELETING);
        eventBus.dispatch('close-annotation-panes', { annotationId: annotationToDelete.id });
        if (selection.objectId === annotationToDelete.id) dispatch(giro3dSlice.setSelection(undefined));
        if (activeAnnotation === annotationToDelete.id) dispatch(annotationsSlice.setActiveAnnotation(null));
        DosApi.deleteAnnotation(annotationToDelete.project_id, annotationToDelete.id)
            .then(async () => {
                fetchAnnotations(dispatch, annotationToDelete.project_id);
                annotationManager.removeAnnotation(annotationToDelete.id);
                setDeleteState(DELETE.CONFIRMATION);
                setTimeout(cancelDelete, 2000);
            })
            .catch((err) => {
                fetchAnnotations(dispatch, annotationToDelete.project_id);
                setErrorText(ApiErrors.getErrorMessage(err));
                setDeleteState(DELETE.ERROR);
            });
    };

    const deleteModalContent = () => {
        switch (deleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <button type="button" className="pane-button large highlight" onClick={deleteAnnotation}>
                                Yes, Delete this Annotation
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="pane-button large" onClick={cancelDelete}>
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting annotation...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Annotation deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="warning" onClick={cancelDelete}>
                                OK
                            </Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const beginCreation = () => {
        dispatch(startAnnotationCreation());
    };

    function filterAnnotations(list: Annotation[]) {
        return list.filter(
            (ann) => !annotationManager.isFiltered(ann) && (annotationManager.isInView(ann) || !viewFilter)
        );
    }

    function sortByDate(list: Annotation[]): Annotation[] {
        list.sort((a, b) => new Date(b.created_at_utc).getTime() - new Date(a.created_at_utc).getTime());

        return list;
    }

    // Should filter annotations by visibility and filters set in the filter selection
    const filter = (allAnnotations: Annotation[]) => {
        if (allAnnotations === undefined) {
            return [];
        }

        return sortByDate(filterAnnotations(allAnnotations));
    };

    const [filteredAnnotations, setFilteredAnnotations] = useState(filter(annotations));

    // Ensure that filtered annotations are updated
    // whenever the filter or camera position changes.
    useEffect(() => {
        setFilteredAnnotations(filter(annotations));
    }, [viewFilter, filters, annotations]);

    return (
        <div className="tabContent">
            <div className="input-row">
                <AnnotationExport project={project} annotations={annotations} />
                <button
                    type="button"
                    className={`pane-button ${filtersCount === 0 ? '' : 'alternate-highlight'}`}
                    onClick={() => setFiltersOpen(true)}
                >
                    <i className="fas fa-bars-filter" />
                </button>
                <div className="grow" />
                <button
                    id="add-annotation"
                    type="button"
                    className="pane-button highlight"
                    onClick={beginCreation}
                    disabled={
                        !project?.user_permissions.interact ||
                        editState !== EDITSTATE.NONE ||
                        createState !== ANNOTATION_CREATESTATE.NONE
                    }
                >
                    <i className="fas fa-plus" />
                </button>
                {!project?.user_permissions.interact ? (
                    <UncontrolledTooltip target="add-annotation">
                        You do not have the permissions to add annotations.
                    </UncontrolledTooltip>
                ) : null}
                {editState !== EDITSTATE.NONE || createState !== ANNOTATION_CREATESTATE.NONE ? (
                    <UncontrolledTooltip target="add-annotation">
                        There is already an annotation action ongoing.
                    </UncontrolledTooltip>
                ) : null}
            </div>
            <ul className="list">
                <div className="input-row">
                    <ToggleSwitch
                        id="showAnnotations"
                        checked={showAnnotations}
                        onChange={(v) => dispatch(settingsSlice.showAnnotationsInViewport(v.target.checked))}
                    />
                    Show annotations in viewport
                </div>
                <div className="input-row">
                    <ToggleSwitch
                        id="visibleAnnotations"
                        checked={viewFilter}
                        onChange={(v) => {
                            dispatch(settingsSlice.filterAnnotations(v.target.checked));
                            if (!v) dispatch(annotationsSlice.resetVisibilities());
                        }}
                    />
                    List visible annotaitons
                </div>
            </ul>
            <AnnotationList annotations={filteredAnnotations} deleteCallback={(a) => openDeleteModal(a)} />
            <Modal id="annotation" isOpen={deleteIsOpen} centered className="modal-confirm">
                <ModalHeader toggle={cancelDelete} />
                {deleteModalContent()}
            </Modal>
            <Modal isOpen={filtersOpen} toggle={() => setFiltersOpen(false)}>
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <>
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the AnotationFilters component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                            <button type="button" className="pane-button" onClick={() => setFiltersOpen(false)}>
                                Back
                            </button>
                        </>
                    }
                >
                    <AnnotationFilters
                        authors={annotationAuthors}
                        datasets={datasets}
                        close={() => setFiltersOpen(false)}
                    />
                </ErrorBoundary>
            </Modal>
        </div>
    );
};

export default AnnotationMenu;
