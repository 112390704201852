// eslint-disable-next-line import/no-cycle
import { SET_API_KEYS, REMOVE_API_KEY, SET_API_KEYS_ADMIN } from '../actionTypes';

const initialState = {
    list: [],
    adminList: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_API_KEYS:
            return {
                ...state,
                list: payload,
            };
        case REMOVE_API_KEY:
            return {
                ...state,
                list: state.list.filter((key) => key.id !== payload),
                adminList: state.adminList.filter((key) => key.id !== payload),
            };
        case SET_API_KEYS_ADMIN:
            return {
                ...state,
                adminList: payload,
            };
        default:
            return state;
    }
}
