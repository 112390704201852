import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { Form, Formik } from 'formik';

// Redux
import Select from 'components/forms/Select';
import BaseInput from 'components/forms/BaseInput';
import { PROJECT_CREATED, PROJECT_LOADING } from '../../redux/actionTypes';
import { getOrganizations, getProjections } from '../../redux/selectors';
import { fetchProjections, fetchOrganizations } from '../../redux/actions';

import DosApi from '../../services/DosApi';
import handleApiError from '../../services/Forms';
import ApiErrors from '../../services/ApiErrors';

const CreateProject = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { endCreate } = props;

    const organizations = useSelector(getOrganizations);
    const projections = useSelector(getProjections);

    const STATE = {
        PROCESSING: 'processing',
        COMPLETE: 'complete',
        ERROR: 'error',
    };
    const [modalState, setModal] = useState(null);
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        dispatch(fetchProjections());
        dispatch(fetchOrganizations());
    }, []);

    if (!organizations || !projections) return <Spinner animation="border" />;

    const modalContent = () => {
        switch (modalState) {
            case STATE.PROCESSING:
                return (
                    <>
                        <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                        <span className="big-modal-text">Creating project</span>
                    </>
                );
            case STATE.COMPLETE:
                return (
                    <>
                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                        <span className="big-modal-text">Project created</span>
                    </>
                );
            case STATE.ERROR:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => setModal(null)}>Okay</Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const validate = (values) => {
        const errors: {
            title?: string;
            organization?: string;
            projection?: string;
        } = {};
        if (!values.title) errors.title = 'Required';
        if (!values.organization) errors.organization = 'Required';
        if (!values.projection) errors.projection = 'Required';
        return errors;
    };

    const onSubmit = (values, helpers) => {
        setModal(STATE.PROCESSING);
        DosApi.createProject({
            name: values.title,
            description: values.description,
            organization_id: values.organization.value,
            projection: parseInt(values.projection.value, 10),
        })
            .catch((err) => {
                handleApiError(err, helpers);
                setErrorText(ApiErrors.getErrorMessage(err));
                setModal(STATE.ERROR);
                helpers.setSubmitting(false);
            })
            .then((data) => {
                if (data) {
                    setModal(STATE.COMPLETE);
                    setTimeout(endCreate, 2000);
                    dispatch({
                        type: PROJECT_LOADING,
                        payload: data,
                    });
                    dispatch({
                        type: PROJECT_CREATED,
                        payload: data,
                    });
                    const slug = data.name
                        .toString()
                        .normalize('NFD') // break accented characters into components
                        .replace(/[\u0300-\u036f]/g, '') // remove diacritics
                        .toLowerCase()
                        .replace(/\s+/g, '-') // spaces to dashes
                        .replace(/&/g, '-and-') // ampersand to and
                        .replace(/[^\w-]+/g, '') // remove non-words
                        .replace(/--+/g, '-') // collapse multiple dashes
                        .replace(/^-+/, '') // trim starting dash
                        .replace(/-+$/, ''); // trim ending dash;
                    navigate(`/project/${data.id}/${slug}`);
                }
            });
    };

    return (
        <>
            <ModalHeader>Create a new project</ModalHeader>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    organization: undefined,
                    projection: undefined,
                }}
                onSubmit={onSubmit}
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <BaseInput name="title" label="Project Name" titled />
                            <BaseInput name="description" label="Description" long titled />
                            <hr />
                            <Select
                                name="organization"
                                placeholder="Organization"
                                title="Organization"
                                value={values.organization}
                                options={organizations.map((e) => ({ label: e.display_name, value: e.id }))}
                                setFieldValue={setFieldValue}
                            />
                            <Select
                                name="projection"
                                placeholder="Projection"
                                title="Projection"
                                value={values.projection}
                                options={projections?.map((e) => ({ label: e.title, value: e.srid }))}
                                isSearchable
                                setFieldValue={setFieldValue}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                id="cancel-creation"
                                onClick={endCreate}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="pane-button large highlight"
                                id="complete-creation"
                                disabled={isSubmitting}
                            >
                                Create
                            </button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
            <Modal isOpen={modalState !== null} className="modal-confirm">
                <ModalHeader />
                <ModalBody>{modalContent()}</ModalBody>
                <ModalFooter />
            </Modal>
        </>
    );
};

export default CreateProject;
