import MapUI from './MapUI';

const Map = (props) => {
    return (
        <div className="map">
            <div className="map-container" id="map-container" ref={props.mapRef} />
            <MapUI />
        </div>
    );
};

export default Map;
