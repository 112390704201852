import { BufferAttribute, Color, Intersection, Raycaster, WebGLRenderer } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { DEFAULT_LINE_SETTINGS } from 'services/Constants';
import { AbstractGeometryBuilder, CreateOptions } from './GeometryBuilder';

const tmpColor = new Color();
const WHITE = new Color('white');
const BLACK = new Color('black');

export default class LineBuilder extends AbstractGeometryBuilder<Line2> {
    thickness: number;

    constructor(params: { thickness: number }) {
        super();
        this.thickness = params.thickness;
    }

    // eslint-disable-next-line class-methods-use-this
    create(opts: CreateOptions): Line2 {
        const geometry = new LineGeometry();
        geometry.setPositions(opts.coordinates.points.buffer);
        geometry.computeBoundingSphere();
        geometry.computeBoundingBox();

        const opacity = opts.opacity ?? 1;

        const material = new LineMaterial({
            vertexColors: false,
            transparent: opacity < 1,
            opacity,
            worldUnits: false,
            linewidth: this.thickness,
        });

        material.onBeforeRender = function onBeforeRender(renderer: WebGLRenderer) {
            const { width, height } = renderer.getRenderTarget() ?? renderer.getContext().canvas;
            material.resolution.set(width, height);
        };

        material.color = opts.color;
        material.userData.color = material.color;
        material.userData.vertexColors = false;
        material.userData.pointCount = opts.coordinates.points.length;

        const lineMesh = new Line2(geometry, material);
        lineMesh.position.copy(opts.coordinates.origin);
        lineMesh.updateMatrixWorld(true);
        lineMesh.name = 'LineBuilder';

        const baseRaycast = lineMesh.raycast;
        lineMesh.raycast = (raycaster: Raycaster, intersects: Intersection[]) => {
            const lineThreshold = raycaster.params.Line2?.threshold;

            raycaster.params.Line2 = {
                threshold: DEFAULT_LINE_SETTINGS.RAYCAST_THRESHOLD,
            };

            baseRaycast.bind(lineMesh)(raycaster, intersects);

            if (lineThreshold) {
                raycaster.params.Line2.threshold = lineThreshold;
            } else {
                raycaster.params.Line2 = undefined;
            }
        };

        return lineMesh;
    }

    // eslint-disable-next-line class-methods-use-this
    applyVertexColors(object: Line2, colorFunc: (index: number, target: Color) => Color) {
        const line = object;
        const geometry = line.geometry;

        const material = object.material;
        material.color = WHITE;
        material.userData.color = BLACK;
        material.userData.vertexColors = true;
        if (!material.vertexColors) {
            material.vertexColors = true;
            material.needsUpdate = true;
        }

        const vertexCount = material.userData.pointCount;
        const buffer = new BufferAttribute(new Float32Array(vertexCount * 3), 3);

        for (let i = 0; i < vertexCount; i++) {
            const color = colorFunc(i, tmpColor);
            const r = color.r;
            const g = color.g;
            const b = color.b;
            buffer.setXYZ(i, r, g, b);
        }

        geometry.setColors(buffer.array as Float32Array);
    }

    // eslint-disable-next-line class-methods-use-this
    setBrightness(object: Line2, brightness: number): void {
        const line = object;

        line.material.vertexColors = false;
        const solidColor: Color = line.material.userData.color;
        const newColor = new Color().lerpColors(solidColor, WHITE, brightness);
        line.material.color = newColor;
        if (brightness <= 0) {
            // TODO verter color compatibility
            line.material.vertexColors = line.material.userData.vertexColors;
            line.material.color = line.material.userData.vertexColors ? WHITE : solidColor;
        }
        line.material.needsUpdate = true;
    }

    // eslint-disable-next-line class-methods-use-this
    showInFront(object: Line2, inFront: boolean): void {
        const material = object.material;

        if (inFront) {
            material.depthTest = false;
            material.transparent = material.opacity < 1;
            object.renderOrder = 9999;
        } else {
            material.depthTest = false;
            material.transparent = true;
            object.renderOrder = 0;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    applySolidColor(object: Line2, color: Color): void {
        const line = object;
        const geometry = line.geometry;
        const material = line.material;
        material.color = color;
        material.userData.color = color;
        if (material.vertexColors) {
            material.vertexColors = false;
            material.needsUpdate = true;
        }
        material.userData.vertexColors = false;
        geometry.deleteAttribute('color');
    }

    setRadius(factor: number): void {
        this.thickness = factor;
    }

    // eslint-disable-next-line class-methods-use-this
    dispose(object: Line2): void {
        const line = object;
        line.geometry.dispose();
        line.material.dispose();
        object.removeFromParent();
    }
}
