import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { DEFAULT_GIRO3D_SETTINGS } from 'services/Constants';
import { RootState } from 'store';
import { SerializableColor, toHex, toColor } from 'types/common';
import { View } from 'types/serialization/View';

export type State = {
    hillshading: { enabled: boolean; azimuth: number; zenith: number; intensity: number };
    eyeDomeLighting: boolean;
    inpainting: boolean;
    mapSegments: number;
    contourLines: {
        enabled: boolean;
        color: SerializableColor;
        opacity: number;
        primaryInterval: number;
        secondaryInterval: number;
    };
};

const initialState: State = {
    hillshading: {
        enabled: DEFAULT_GIRO3D_SETTINGS.HILLSHADE,
        azimuth: DEFAULT_GIRO3D_SETTINGS.HILLSHADE_AZIMUTH,
        zenith: DEFAULT_GIRO3D_SETTINGS.HILLSHADE_ZENITH,
        intensity: DEFAULT_GIRO3D_SETTINGS.HILLSHADE_INTENSITY,
    },
    eyeDomeLighting: DEFAULT_GIRO3D_SETTINGS.POINTCLOUD_EDL,
    inpainting: DEFAULT_GIRO3D_SETTINGS.POINTCLOUD_INPAINTING,
    mapSegments: DEFAULT_GIRO3D_SETTINGS.MAP_SEGMENTS,
    contourLines: {
        enabled: DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES,
        color: toHex(DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_COLOR),
        opacity: DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_OPACITY,
        primaryInterval: DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_PRIMARY_INTERVAL,
        secondaryInterval: DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_SECONDARY_INTERVAL,
    },
};

const self = (store: RootState) => store.graphicsSettings;

// Selectors
const getHillshading = (store: RootState) => store.graphicsSettings.hillshading;

export const isHillshadingEnabled = createSelector(getHillshading, (hillshading) => hillshading.enabled);
export const getHillshadingIntensity = createSelector(getHillshading, (hillshading) => hillshading.intensity);
export const getHillshadingAzimuth = createSelector(getHillshading, (hillshading) => hillshading.azimuth);
export const getHillshadingZenith = createSelector(getHillshading, (hillshading) => hillshading.zenith);

export const isEyeDomeLightingEnabled = createSelector(self, (s) => s.eyeDomeLighting);
export const isInpaintingEnabled = createSelector(self, (s) => s.inpainting);

export const getMapSegments = createSelector(self, (s) => s.mapSegments);

const contourLines = (store: RootState) => store.graphicsSettings.contourLines;

export const isContourLinesEnabled = createSelector(contourLines, (s) => s.enabled);
export const getContourLineColor = createSelector(
    (s) => contourLines(s).color,
    (color) => toColor(color)
);
export const getContourLineOpacity = createSelector(contourLines, (s) => s.opacity);
export const getContourLinePrimaryInterval = createSelector(contourLines, (s) => s.primaryInterval);
export const getContourLineSecondaryInterval = createSelector(contourLines, (s) => s.secondaryInterval);

const slice = createSlice({
    name: 'graphicsSettings',
    initialState,
    reducers: {
        loadView: (state, action: PayloadAction<View>) => {
            const settings = action.payload;

            if (settings.contourlines) {
                const source = settings.contourlines;
                const dest = state.contourLines;

                dest.enabled = source.enabled ?? dest.enabled;
                dest.color = source.color ?? dest.color;
                dest.opacity = source.opacity ?? dest.opacity;
                dest.primaryInterval = source.primary ?? dest.primaryInterval;
                dest.secondaryInterval = source.secondary ?? dest.secondaryInterval;
            }

            if (settings.hillshading) {
                const source = settings.hillshading;
                const dest = state.hillshading;

                dest.enabled = source.enabled ?? dest.enabled;
                dest.azimuth = source.azimuth ?? dest.azimuth;
                dest.intensity = source.intensity ?? dest.intensity;
                dest.zenith = source.zenith ?? dest.zenith;
            }

            state.mapSegments = settings.mapresolution ?? state.mapSegments;

            if (settings.pointcloud) {
                const source = settings.pointcloud;
                state.eyeDomeLighting = source.edl ?? state.eyeDomeLighting;
                state.inpainting = source.inpainting ?? state.inpainting;
            }
        },
        enableHillshading: (state, action: PayloadAction<boolean>) => {
            state.hillshading.enabled = action.payload;
        },
        hillshadingAzimuth: (state, action: PayloadAction<number>) => {
            state.hillshading.azimuth = action.payload;
        },
        hillshadingZenith: (state, action: PayloadAction<number>) => {
            state.hillshading.zenith = action.payload;
        },
        hillshadeIntensity: (state, action: PayloadAction<number>) => {
            state.hillshading.intensity = action.payload;
        },
        resetHillshading: (state) => {
            state.hillshading.intensity = initialState.hillshading.intensity;
            state.hillshading.azimuth = initialState.hillshading.azimuth;
            state.hillshading.zenith = initialState.hillshading.zenith;
        },
        eyeDomeLighting: (state, action: PayloadAction<boolean>) => {
            state.eyeDomeLighting = action.payload;
        },
        inpainting: (state, action: PayloadAction<boolean>) => {
            state.inpainting = action.payload;
        },
        mapSegments: (state, action: PayloadAction<number>) => {
            state.mapSegments = action.payload;
        },
        enableContourLines: (state, action: PayloadAction<boolean>) => {
            state.contourLines.enabled = action.payload;
        },
        contourLineOpacity: (state, action: PayloadAction<number>) => {
            state.contourLines.opacity = action.payload;
        },
        contourLineColor: (state, action: PayloadAction<SerializableColor>) => {
            state.contourLines.color = action.payload;
        },
        contourLinePrimaryInterval: (state, action: PayloadAction<number>) => {
            state.contourLines.primaryInterval = action.payload;
        },
        contourLineSecondaryInterval: (state, action: PayloadAction<number>) => {
            state.contourLines.secondaryInterval = action.payload;
        },
        resetContourLines: (state) => {
            state.contourLines.color = initialState.contourLines.color;
            state.contourLines.opacity = initialState.contourLines.opacity;
            state.contourLines.primaryInterval = initialState.contourLines.primaryInterval;
            state.contourLines.secondaryInterval = initialState.contourLines.secondaryInterval;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const loadView = slice.actions.loadView;

export const enableHillshading = slice.actions.enableHillshading;
export const setHillshadingIntensity = slice.actions.hillshadeIntensity;
export const setHillshadeAzimuth = slice.actions.hillshadingAzimuth;
export const setHillshadeZenith = slice.actions.hillshadingZenith;
export const resetHillshading = slice.actions.resetHillshading;

export const enableEyeDomeLighting = slice.actions.eyeDomeLighting;
export const enableInpainting = slice.actions.inpainting;

export const setMapSegments = slice.actions.mapSegments;

export const enableContourLines = slice.actions.enableContourLines;
export const setContourLinesColor = slice.actions.contourLineColor;
export const setContourLinesOpacity = slice.actions.contourLineOpacity;
export const setContourLinesPrimaryInterval = slice.actions.contourLinePrimaryInterval;
export const setContourLinesSecondaryInterval = slice.actions.contourLineSecondaryInterval;
export const resetContourLines = slice.actions.resetContourLines;
