// src/components/Feature.tsx
import React, { useState } from 'react';
import { Collapse, Card, CardBody, Button } from 'reactstrap';
import Vimeo from '@u-wave/react-vimeo';

interface FeatureProps {
    title: string;
    description?: string;
    videoId?: string;
}

const Feature: React.FC<FeatureProps> = ({ title, description, videoId }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Card className="mb-2">
            <CardBody>
                <Button
                    color="link"
                    onClick={toggle}
                    className="feature-toggle"
                    aria-expanded={isOpen}
                    aria-controls={`feature-content-${title.replace(/\s+/g, '-')}`}
                >
                    <h5 className="mb-0">{title}</h5>
                </Button>
                <Collapse isOpen={isOpen} id={`feature-content-${title.replace(/\s+/g, '-')}`}>
                    {description && <p>{description}</p>}
                    {isOpen && videoId && (
                        <div className="feature-video">
                            <Vimeo
                                video={videoId}
                                width={640}
                                height={360}
                                responsive
                                controls
                                autoplay={false}
                                loop={false}
                            />
                        </div>
                    )}
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default Feature;
