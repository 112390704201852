import { Circle, Fill, Stroke, Style } from 'ol/style';
import { Color as ThreeColor } from 'three';
import { Color as OlColor } from 'ol/color';
import { Feature } from 'ol';
import { Cache } from '@giro3d/giro3d/core/Cache';
import { DEFAULT_VECTOR_SETTINGS, FILTER_DISPLAY_MODE } from './Constants';

const HIGHLIGHTED_COLOR = new ThreeColor('#67ce8e');
const HIGHLIGHTED_FILL_OPACITY = 0.4;
const HIGHLIGHTED_BORDER_OPACITY = 1;

const MINIMUM_SIZE = 0.25;

export type VectorLayerStyle = {
    pointSize: number;
    lineWidth: number;
    overlayColor: ThreeColor;
    borderColor: ThreeColor;
    borderOpacity: number;
    borderWidth: number;
    fillOpacity: number;
};

function toOlColor(color: ThreeColor, alpha: number): OlColor {
    if (!color) {
        return null;
    }
    // three.js colors are normalized (in the [0, 1] range),
    // whereas OpenLayers colors are in the [0, 255] range.
    const r = color.r * 255;
    const g = color.g * 255;
    const b = color.b * 255;

    return [r, g, b, alpha];
}

export const createStyle = (
    fillColor = DEFAULT_VECTOR_SETTINGS.FILL_COLOR,
    fillOpacity = DEFAULT_VECTOR_SETTINGS.FILL_OPACITY,
    borderColor = DEFAULT_VECTOR_SETTINGS.BORDER_COLOR,
    borderWidth = DEFAULT_VECTOR_SETTINGS.BORDER_WIDTH,
    borderOpacity = DEFAULT_VECTOR_SETTINGS.BORDER_OPACITY,
    pointRadius = DEFAULT_VECTOR_SETTINGS.POINT_SIZE / 2
): Style => {
    const fillColorArr = toOlColor(fillColor, fillOpacity);
    const borderColorArr = toOlColor(borderColor, borderOpacity);

    const fill = new Fill({ color: fillColorArr });
    const stroke = new Stroke({ color: borderColorArr, width: borderWidth });
    const image = new Circle({
        radius: pointRadius,
        fill,
        stroke: new Stroke({ color: borderColorArr, width: borderWidth }),
    });

    return new Style({
        stroke,
        fill,
        image,
    });
};

export function highlightedStyleBuilder(style: VectorLayerStyle) {
    return function highlightedStyle(feature: Feature, resolution: number): Style {
        // isLineString alters the stlying so that linestrings are styled as if they are polygons
        const type = feature.getGeometry().getType();
        const isLineString = type === 'LineString' || type === 'MultiLineString';

        const borderWidth = isLineString ? style.lineWidth / resolution : style.borderWidth / resolution;
        const pointRadius = style.pointSize / 2 / resolution;
        return createStyle(
            HIGHLIGHTED_COLOR,
            HIGHLIGHTED_FILL_OPACITY,
            HIGHLIGHTED_COLOR,
            borderWidth >= MINIMUM_SIZE ? borderWidth : MINIMUM_SIZE,
            isLineString ? HIGHLIGHTED_FILL_OPACITY : HIGHLIGHTED_BORDER_OPACITY,
            pointRadius >= MINIMUM_SIZE ? pointRadius : MINIMUM_SIZE
        );
    };
}

export function stlyeBuilder(
    style: VectorLayerStyle,
    filterStyle: VectorLayerStyle,
    cache: Cache,
    isFiltered: (id: string | number) => boolean,
    filterMode: FILTER_DISPLAY_MODE
) {
    /**
     * [Styling function for OpenLayers](https://openlayers.org/en/v6.15.1/apidoc/module-ol_style_Style.html#~StyleFunction).
     * Uses cache for better performances; use `_invalidateStyle` when changing style parameters.
     *
     * @param feature OpenLayers Feature
     * @param resolution Resolution (in m/px, assuming pixelRatio is 1 and CRS is in meters)
     * @returns Style for this feature
     */
    return function styleFunction(feature: Feature, resolution: number): Style {
        const filtered = isFiltered(feature.getId());
        const hidden = filtered && filterMode === FILTER_DISPLAY_MODE.HIDE;

        // isLineString alters the stlying so that linestrings are styled as if they are polygons
        const type = feature.getGeometry().getType();
        const isLineString = type === 'LineString' || type === 'MultiLineString';

        const key = hidden
            ? 'hidden'
            : (filtered ? 'filtered' : 'default') + (isLineString ? 'line' : '') + resolution.toFixed(2);

        const cached = cache.get(key);
        if (cached) {
            return cached as Style;
        }

        if (hidden) {
            const s = new Style({});
            cache.set(key, s);
            return s;
        }

        const selectedStyle = filtered ? filterStyle : style;

        // assuming pixelRatio of resolution is 1
        // and assuming CRS is in meters, style.lineWidth / resolution is the "exact" value in meters
        const borderColor = isLineString ? selectedStyle.overlayColor : selectedStyle.borderColor;
        const borderWidth = isLineString
            ? selectedStyle.lineWidth / resolution
            : selectedStyle.borderWidth / resolution;
        const pointRadius = selectedStyle.pointSize / 2 / resolution;
        const s = createStyle(
            selectedStyle.overlayColor,
            selectedStyle.fillOpacity,
            borderColor,
            borderWidth >= MINIMUM_SIZE ? borderWidth : MINIMUM_SIZE,
            isLineString ? selectedStyle.fillOpacity : selectedStyle.borderOpacity,
            pointRadius >= MINIMUM_SIZE ? pointRadius : MINIMUM_SIZE
        );
        cache.set(key, s);
        return s;
    };
}
