import BaseSelect, { Option } from 'components/Select';
import { useField } from 'formik';
import { GroupBase, OptionsOrGroups } from 'react-select';

type Props<T> = {
    name: string;
    isMulti?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    className?: string;
    options: OptionsOrGroups<Option<T>, GroupBase<Option<T>>>;
    title?: string;
    placeholder?: string;
    multiValueCounterMessage?: string;
    value?: Option<T>[] | Option<T>;
    setFieldValue;
    disabled?: boolean;
};

const Select = <T,>(props: Props<T> & { error? }) => {
    const [field, meta] = useField(props.name);

    return (
        <div className="form-group">
            {props.title}
            <BaseSelect
                className={`${props.className} ${meta.error ? 'is-invalid' : ''}`}
                onChange={(value) => props.setFieldValue(props.name, value)}
                onBlur={field.onBlur}
                {...props}
            />
            <div className="invalid-feedback">{meta.error}</div>
        </div>
    );
};

export default Select;
