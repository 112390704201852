// React
import { MutableRefObject } from 'react';

const Giro3dControls = (props: { compassRef: MutableRefObject<null>; hidden: boolean }) => (
    <div className="map-controls map-controls-row no-gap" hidden={props.hidden}>
        <div>
            <div ref={props.compassRef} />
        </div>
    </div>
);

export default Giro3dControls;
