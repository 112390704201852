import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import HttpConfiguration from '@giro3d/giro3d/utils/HttpConfiguration';

import { SourceFile } from 'types/SourceFile';
import { EmptyDatasetProperties } from 'types/Dataset';
import VesselLayer from '../layers/VesselLayer';
import LayerBuilder from './LayerBuilder';

export default class VesselBuilder extends LayerBuilder<EmptyDatasetProperties> {
    // eslint-disable-next-line class-methods-use-this
    private async loadVesselModel(sourceFile: SourceFile) {
        const url = this.buildUrl(sourceFile.links.download);
        const opts: { headers?: Record<string, string> } = {};
        HttpConfiguration.applyConfiguration(url, opts);
        // Authorizations for texture images are done by patching ImageLoader in LayerBuilder

        const fbxLoader = new FBXLoader();
        fbxLoader.setRequestHeader(opts.headers);

        const object = await fbxLoader.loadAsync(url);
        return object;
    }

    protected override buildLayer(sourceFile: SourceFile): Promise<VesselLayer> {
        const layer = new VesselLayer({
            instance: this.getInstance(),
            dispatch: this._dispatch,
            layerManager: this._layerManager,
            datasetId: this.getDatasetId(),
            sourceFileId: sourceFile.id,
            getFootprint: () => sourceFile.geometry,
            loadVesselModel: async () => this.loadVesselModel(sourceFile),
            hostView: this._hostView,
        });

        return Promise.resolve(layer);
    }
}
