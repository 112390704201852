import Entity3D from '@giro3d/giro3d/entities/Entity3D';
import type Context from '@giro3d/giro3d/core/Context';
import { Box3, Color, Group, MathUtils, Vector3, type Object3D } from 'three';
import { DatasetId, SourceFileId } from 'types/common';
import { type LineCoordinates } from './Source';
import type Source from './Source';
import type GeometryBuilder from './GeometryBuilder';

const BLACK = new Color('black');

function sample(lut: Color[], t: number, min: number, max: number) {
    const u = MathUtils.clamp(MathUtils.mapLinear(t, min, max, 0, 1), 0, 1);
    const lutIndex = Math.round(MathUtils.mapLinear(u, 0, 1, 0, lut.length - 1));
    return lut[lutIndex];
}

const tmpVector = new Vector3();

interface ColorMapParams {
    colors: Color[];
    min: number;
    max: number;
    propertyName: string;
}

export default class LinearEntity extends Entity3D {
    readonly type = 'LinearEntity' as const;
    readonly isLinearEntity = true as const;

    private readonly _source: Source;
    private readonly _geometryBuilder: GeometryBuilder;
    private readonly _features: Object3D[] = [];

    private _solidColor: Color;
    private _colorMapParams: ColorMapParams;
    private _colormapActive = false;
    private _variableRadii = false;

    constructor({
        datasetId,
        fileId,
        source,
        geometryBuilder,
        overlayColor,
    }: {
        datasetId: DatasetId;
        fileId: SourceFileId;
        source: Source;
        geometryBuilder: GeometryBuilder;
        overlayColor: Color;
    }) {
        super(new Group());
        this._source = source;
        this._geometryBuilder = geometryBuilder;
        this._solidColor = overlayColor;

        this.object3d.name = this.type;
        this.object3d.userData.datasetId = datasetId;
        this.object3d.userData.sourceFileId = fileId;
    }

    private buildObject(coords: LineCoordinates, radiusFn: (index: number) => number) {
        const obj = this._geometryBuilder.create({
            coordinates: coords,
            opacity: this.opacity,
            color: this._solidColor,
            radiusFn,
        });
        this.onObjectCreated(obj);
        obj.userData.datasetId = this.object3d.userData.datasetId;
        obj.userData.sourceFileId = this.object3d.userData.sourceFileId;
        return obj;
    }

    private async buildObjects() {
        const geometries = await this._source.getGeometries();

        let scalarProperties: Float32Array[] = null;

        if (this._colorMapParams) {
            const { propertyName } = this._colorMapParams;

            scalarProperties = await this._source.getPropertyValue(propertyName);
        }

        const getRadiusFn = (index: number) => {
            if (!this._colorMapParams) {
                return () => 1;
            }

            return this.getRadiusFunction(scalarProperties[index], this._colorMapParams.min, this._colorMapParams.max);
        };

        const newObjects = [];

        for (let i = 0; i < geometries.length; i++) {
            const geom = geometries[i];
            const newObj = this.buildObject(geom, getRadiusFn(i));
            newObjects.push(newObj);
        }

        return newObjects;
    }

    showInFront(v: boolean) {
        this._features.forEach((feature) => this._geometryBuilder.showInFront(feature, v));
    }

    setBrightness(brightness: number) {
        this._features.forEach((feature) => this._geometryBuilder.setBrightness(feature, brightness));
    }

    private async rebuild() {
        const newObjects = await this.buildObjects();
        if (this._colormapActive) {
            await this.applyColorMap(newObjects);
        } else {
            this.applySolidColor(newObjects);
        }

        this.dispose();

        this._features.push(...newObjects);
        newObjects.forEach((o) => this.object3d.add(o));

        this.object3d.updateMatrixWorld(true);

        this.notifyChange();
    }

    setColorMap(opts: ColorMapParams) {
        this._colorMapParams = opts;
        this._colormapActive = true;

        this.rebuild();
    }

    updateVisibility(): void {
        this.object3d.visible = this.visible;
        if (this._features.length === 0 && this.visible) {
            this.rebuild();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    getRadiusFunction(values: Float32Array, min: number, max: number) {
        if (!values || !this._variableRadii || !this._colormapActive) {
            return () => 1;
        }

        // This is the minimum radius for valid values, so that even
        // very small values can still produce a visible tube.
        const minRadius = 0.1;

        // This is the radius for missing values (e.g NaN), it makes
        // the cylinder disappear entirely.
        const invalidRadius = 0;

        const func = (index: number): number => {
            const value = values[index];
            if (value == null || Number.isNaN(value)) {
                return invalidRadius;
            }

            const rawNormalized = MathUtils.mapLinear(value, min, max, 0, 1);
            const normalized = MathUtils.clamp(rawNormalized, minRadius, 1);

            return normalized;
        };

        return func;
    }

    setVariableRadii(enabled: boolean) {
        this._variableRadii = enabled;
        this.rebuild();
    }

    private async applyColorMap(objects: Object3D[]) {
        const { propertyName, min, max, colors } = this._colorMapParams;

        const scalarProperties = await this._source.getPropertyValue(propertyName);

        for (let i = 0; i < objects.length; i++) {
            const values = scalarProperties[i];
            const sampleLUT = (index: number): Color => {
                if (values == null) {
                    return BLACK;
                }
                const value = values[index];
                if (Number.isNaN(value)) {
                    return BLACK;
                }
                return sample(colors, values[index], min, max);
            };

            this._geometryBuilder.applyVertexColors(objects[i], sampleLUT);
        }

        this.notifyChange(this);
    }

    setColor(color: Color) {
        this._solidColor = color;
        this._colormapActive = false;

        this.rebuild();

        this.notifyChange(this);
    }

    postUpdate(context: Context): void {
        const bbox = new Box3().setFromObject(this.object3d);
        const distance = context.distance.plane.distanceToPoint(bbox.getCenter(tmpVector));
        const radius = bbox.getSize(tmpVector).length() * 0.5;
        this._distance.min = Math.min(this._distance.min, distance - radius);
        this._distance.max = Math.max(this._distance.max, distance + radius);
    }

    setRadius(factor: number) {
        this._geometryBuilder.setRadius(factor);
        this.rebuild();
    }

    private applySolidColor(objects: Object3D[]) {
        const color = this._solidColor;
        this._colormapActive = false;
        objects.forEach((feature) => this._geometryBuilder.applySolidColor(feature, color));
    }

    dispose(): void {
        this._features.forEach((feature) => this._geometryBuilder.dispose(feature));
        this._features.length = 0;
    }
}
