import React from 'react';
import { Alert } from 'reactstrap';

const HelpPanel = ({ children }) => (
    <Alert color="dark" className="form-group">
        <i className="fas fa-info-square " role="img" aria-label="Info:" />
        <div>{children}</div>
    </Alert>
);

export default HelpPanel;
