import { Mesh, CylinderGeometry, MeshStandardMaterial, Color } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

const WHITE = new Color(0xffffff);
const BLACK = new Color(0);

const numberFormat = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 5,
    minimumFractionDigits: 0,
});

function createTooltip() {
    const div = document.createElement('div');
    div.style.opacity = '0%';
    div.style.pointerEvents = 'none';

    const clickable = document.createElement('div');
    clickable.style.pointerEvents = 'auto';
    clickable.style.width = '2rem';
    clickable.style.height = '2rem';
    clickable.style.position = 'absolute';
    clickable.style.left = 'calc(50% - 1rem)';
    clickable.style.top = 'calc(50% - 1rem)';
    clickable.style.zIndex = '1';
    // Uncomment to visualize clickable area
    // clickable.style.backgroundColor = 'red';

    div.appendChild(clickable);

    const inner = document.createElement('div');

    inner.className = 'tooltip show tooltip-inner text-start';
    inner.style.position = 'absolute';
    inner.style.top = '0px';

    div.appendChild(inner);

    return { div, inner, clickable };
}

class AnodeLabel extends CSS2DObject {
    readonly isAnodeLabel = true;
    readonly type = 'AnodeLabel';

    private readonly _innerDiv: HTMLDivElement;
    private _table: HTMLTableElement;

    constructor(onHover: (hover: boolean) => void) {
        const { div, inner, clickable } = createTooltip();
        super(div);
        this._innerDiv = inner;

        clickable.addEventListener('mouseenter', () => {
            div.style.opacity = '100%';
            onHover(true);
        });
        clickable.addEventListener('mouseleave', () => {
            div.style.opacity = '0%';
            onHover(false);
        });
    }

    dispose() {
        this.element.remove();
    }

    setProperties(properties: Array<{ key: string; value: number; active?: boolean }>) {
        this._table?.remove();
        this._table = document.createElement('table');
        this._table.className = 'table';

        function row(key: string, value: number, active?: boolean) {
            return `
                <tr>
                    <td>${active ? '<i class="fal fa-check icon-green" />' : ''}</td>
                    <td>${key}</td>
                    <td><b>${numberFormat.format(value)}</b></td>
                </tr>
            `;
        }

        this._table.innerHTML = `
            <tbody>
                    ${properties.map((p) => row(p.key, p.value, p.active)).join('\n')}
            </tbody>
            `;

        this._innerDiv.appendChild(this._table);
    }
}

export default class Anode extends Mesh<CylinderGeometry, MeshStandardMaterial> {
    readonly isAnode = true;
    readonly type = 'Anode';

    private readonly _tooltip: AnodeLabel;

    constructor(geometry: CylinderGeometry, color: Color) {
        super(geometry, new MeshStandardMaterial({ color }));

        this._tooltip = new AnodeLabel((hover) => {
            this.highlight = hover;
        });

        this.add(this._tooltip);
    }

    setVisibility(visible: boolean): void {
        this.visible = visible;
        this._tooltip.visible = visible;
    }

    setProperties(properties: Array<{ key: string; value: number }>) {
        this._tooltip.setProperties(properties);
    }

    set highlight(value: boolean) {
        this.material.emissive = value ? WHITE : BLACK;
    }

    set brightness(value: number) {
        this.material.emissive = WHITE;
        this.material.emissiveIntensity = value;
    }

    dispose() {
        this.material.dispose();
        this._tooltip.dispose();
    }
}
