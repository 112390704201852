import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getUser, getApiHealthCheck } from '../../redux/selectors';

const ApiHealthCheckAlert = () => {
    const [modal, setModal] = useState(true);
    const toggle = () => setModal(!modal);
    const apiHealth = useSelector(getApiHealthCheck);
    const user = useSelector(getUser);

    const userWarning = () => (
        <Modal isOpen toggle={toggle} centered className="modal-confirm">
            <ModalHeader />
            <ModalBody>
                <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                <span className="big-modal-text">System Unavailable</span>
                <span className="small-modal-text">Please come back at a later time</span>
            </ModalBody>
            <ModalFooter />
        </Modal>
    );

    const adminWarning = (error) => (
        <Modal isOpen toggle={toggle} centered className="modal-confirm">
            <ModalFooter />
            <ModalBody>
                <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                <span className="big-modal-text">System Unavailable</span>
                <span className="small-modal-text">{error}</span>
            </ModalBody>
            <ModalFooter />
        </Modal>
    );

    if (apiHealth?.backend_available === false) {
        // Only display the cause of the backend error if user is admin.
        return user?.is_admin ? adminWarning(apiHealth?.msg) : userWarning();
    }
    return null;
};

export default ApiHealthCheckAlert;
