import { Form, Formik } from 'formik';
import { shallowEqual } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import { SsdmType } from 'types/common';
import Annotation, { ObservationType } from 'types/Annotation';
import Dataset from 'types/Dataset';
import { GEOMETRY_TYPE } from 'giro3d_extensions/DrawTool';
import {
    createAnnotation,
    fetchSSDMTypes,
    relateAnnotationDatasets,
    setAnnotationDatasets,
    startAnnotationDrawing,
    stopAnnotationCreation,
} from 'redux/actions';
import { getSSDMTypes } from 'redux/selectors';

import * as datasetsSlice from 'redux/datasets';
import * as drawToolSlice from 'redux/drawTool';
import * as annotationsSlice from 'redux/annotations';

import { ANNOTATION_CREATESTATE, DRAWN_TOOL, PANE, SSDM_CLASSIFICATION_TYPES } from 'services/Constants';

import Radio from 'components/Radio';
import BaseInput from 'components/forms/BaseInput';
import { useEventBus } from 'EventBus';
import { useEffect } from 'react';
import Select from '../../forms/Select';
import GeometryInfo from './GeometryInfo';
import DisabledDrawTool from '../DisabledDrawTool';

const CreateAnnotation = () => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();

    useEffect(() => {
        dispatch(fetchSSDMTypes());
    }, []);

    const project = useAppSelector(datasetsSlice.currentProject);
    const createState = useAppSelector(annotationsSlice.createState);
    const SSDMTypes = useAppSelector(getSSDMTypes);
    const datasets = useAppSelector<Dataset[]>(datasetsSlice.getRenderableDatasets, shallowEqual);
    const geometryType = useAppSelector(drawToolSlice.getType);
    const currentTool = useAppSelector(drawToolSlice.getTool);

    const classificationOptions = [];

    Object.keys(SSDM_CLASSIFICATION_TYPES).forEach((type) => {
        classificationOptions.push({ label: SSDM_CLASSIFICATION_TYPES[type], options: [] });
    });

    SSDMTypes?.forEach((SSDM: SsdmType) => {
        if (
            (geometryType === 'Polygon' && SSDM.geom_type === 'POLYGON') ||
            (geometryType === 'LineString' && SSDM.geom_type === 'POLYLINE') ||
            (geometryType === 'Point' && SSDM.geom_type === 'POINT')
        ) {
            classificationOptions[Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(SSDM.parent_type)].options.push({
                label: `${SSDM.name} ${SSDM.display_name}`,
                value: SSDM.name,
            });
        }
    });

    const datasetOptions = datasets.map((dataset) => ({ label: dataset.name, value: dataset.id }));

    const cancelDraw = () => {
        dispatch(stopAnnotationCreation());
    };

    switch (createState) {
        case ANNOTATION_CREATESTATE.SELECT:
            return !currentTool || currentTool === DRAWN_TOOL.ANNOTATION ? (
                <div className="tabContent">
                    <span>Select a geometry type:</span>
                    <button
                        type="button"
                        className="pane-button highlight"
                        onClick={() => dispatch(startAnnotationDrawing(GEOMETRY_TYPE.POINT))}
                    >
                        <i className="fas fa-circle-small" />
                        Point
                    </button>
                    <button
                        type="button"
                        className="pane-button highlight"
                        onClick={() => dispatch(startAnnotationDrawing(GEOMETRY_TYPE.LINE))}
                    >
                        <i className="fas fa-dash" />
                        Line
                    </button>
                    <button
                        type="button"
                        className="pane-button highlight"
                        onClick={() => dispatch(startAnnotationDrawing(GEOMETRY_TYPE.POLYGON))}
                    >
                        <i className="fas fa-draw-polygon" />
                        Polygon
                    </button>
                    <hr />
                    <button type="button" className="pane-button" id="cancel-drawing" onClick={cancelDraw}>
                        Cancel
                    </button>
                </div>
            ) : (
                <div className="tabContent">
                    <DisabledDrawTool />
                </div>
            );
        case ANNOTATION_CREATESTATE.DRAWING:
            return !currentTool || currentTool === DRAWN_TOOL.ANNOTATION ? (
                <div className="tabContent">
                    {geometryType === GEOMETRY_TYPE.POINT ? 'Left click on the map to create a point.' : null}
                    {geometryType === GEOMETRY_TYPE.LINE
                        ? 'Left click on the map to create a line. Right click to finish the line. Hold space to enable splicing mode.'
                        : null}
                    {geometryType === GEOMETRY_TYPE.POLYGON
                        ? 'Left click on the map to create a polygon. Right click or click on the first point to close the polygon. Hold space to enable splicing mode.'
                        : null}
                    <GeometryInfo />
                    <hr />
                    <button type="button" className="pane-button" id="cancel-drawing" onClick={cancelDraw}>
                        Cancel
                    </button>
                </div>
            ) : (
                <div className="tabContent">
                    <DisabledDrawTool />
                </div>
            );
        case ANNOTATION_CREATESTATE.DETAILS:
        case ANNOTATION_CREATESTATE.SUMBIT:
        case ANNOTATION_CREATESTATE.COMPLETE:
            return (
                <div className="tabContent">
                    <Formik
                        initialValues={{
                            name: '',
                            description: '',
                            type: '',
                            observationType: '' as ObservationType,
                            classificationType: undefined,
                            datasets: [],
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={(values) => {
                            const errors: {
                                name?: string;
                                type?: string;
                                observationType?: string;
                                classificationType?: string;
                            } = {};
                            if (!values.name) errors.name = 'Required';
                            if (!values.type) errors.type = 'Required';
                            if (values.type === 'observation' && !values.observationType)
                                errors.observationType = 'Required';
                            if (values.type === 'object' && !values.classificationType)
                                errors.classificationType = 'Required';
                            return errors;
                        }}
                        onSubmit={(values) => {
                            const annotation: Partial<Annotation> = {
                                name: values.name,
                                description: values.description,
                                project_id: project.id,
                                observation_type:
                                    values.type === 'observation' ? (values.observationType as ObservationType) : null,
                                ssdm_type: values.type !== 'observation' ? values.classificationType.value : null,
                            };

                            const datasetIds = values.datasets.map((dataset) => dataset.value);

                            dispatch(createAnnotation(annotation))
                                .then((data) => {
                                    const createdAnnotation = data.payload;
                                    relateAnnotationDatasets(createdAnnotation, datasetIds);
                                    dispatch(setAnnotationDatasets(createdAnnotation.id, datasetIds));
                                    eventBus.dispatch('create-annotation-pane', {
                                        paneType: PANE.ANNOTATION,
                                        showExisting: true,
                                        annotationId: createdAnnotation.id,
                                    });
                                })
                                .then(cancelDraw);
                        }}
                    >
                        {({ isSubmitting, values, handleChange, handleBlur, errors, setFieldValue }) => (
                            <Form className="sideform">
                                <BaseInput name="name" label="Title of Annotation" />
                                <hr />
                                <Radio
                                    name="type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    options={[
                                        {
                                            id: 'observation',
                                            value: 'observation',
                                            label: 'Observation',
                                            checked: values.type === 'observation',
                                            info: 'An observation is used to annotate parts of the data that are unidentified or erroneous.',
                                        },
                                        {
                                            id: 'object',
                                            value: 'object',
                                            label: 'Object',
                                            checked: values.type === 'object',
                                            info: 'An object is something that can be classified by the SSDM standard.',
                                        },
                                    ]}
                                    error={errors.type}
                                />
                                {values.type === 'observation' ? (
                                    <>
                                        <hr />
                                        <Radio
                                            name="observationType"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            options={[
                                                {
                                                    id: 'uso',
                                                    value: 'uso',
                                                    label: 'USO',
                                                    checked: values.observationType === 'uso',
                                                },
                                                {
                                                    id: 'flaw',
                                                    value: 'flaw',
                                                    label: 'Flaw in Dataset',
                                                    checked: values.observationType === 'flaw',
                                                },
                                            ]}
                                            error={errors.observationType}
                                        />
                                    </>
                                ) : null}
                                {values.type === 'object' ? (
                                    <>
                                        <hr />
                                        <Select
                                            name="classificationType"
                                            value={values.classificationType}
                                            options={classificationOptions}
                                            placeholder="Classification"
                                            isSearchable
                                            setFieldValue={setFieldValue}
                                        />
                                    </>
                                ) : null}
                                <hr />
                                <Select
                                    name="datasets"
                                    isMulti
                                    options={datasetOptions}
                                    placeholder="Connect datasets"
                                    multiValueCounterMessage="Connected dataset"
                                    setFieldValue={setFieldValue}
                                />
                                <hr />
                                <BaseInput name="description" label="Description" long />
                                <hr />
                                <div className="input-row">
                                    <button
                                        type="button"
                                        className="pane-button"
                                        id="cancel-creation"
                                        onClick={cancelDraw}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="pane-button highlight"
                                        id="complete-creation"
                                        disabled={
                                            isSubmitting ||
                                            createState === ANNOTATION_CREATESTATE.SUMBIT ||
                                            createState === ANNOTATION_CREATESTATE.COMPLETE
                                        }
                                    >
                                        Save
                                    </button>
                                </div>
                                <Modal
                                    centered
                                    className="modal-confirm"
                                    isOpen={createState === ANNOTATION_CREATESTATE.COMPLETE}
                                >
                                    <ModalHeader />
                                    <ModalBody>
                                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                                        <span className="big-modal-text">Annotation created</span>
                                    </ModalBody>
                                    <ModalFooter />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                </div>
            );
        default:
            return <span>The state is {createState}</span>;
    }
};

export default CreateAnnotation;
