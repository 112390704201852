// React
import { useDispatch, useSelector } from 'react-redux';
import { PopoverBody } from 'reactstrap';
import * as graphicsSettingsSlice from 'redux/graphicsSettings';

// Components
import HeaderButton from 'components/flexLayout/HeaderButton';
import Slider from 'components/dropdown/Slider';

function HillshadeSetting() {
    const dispatch = useDispatch();

    const enableHillshading = useSelector(graphicsSettingsSlice.isHillshadingEnabled);
    const azimuth = useSelector(graphicsSettingsSlice.getHillshadingAzimuth);
    const zenith = useSelector(graphicsSettingsSlice.getHillshadingZenith);
    const intensity = useSelector(graphicsSettingsSlice.getHillshadingIntensity);

    return (
        <HeaderButton
            toggle={{
                name: 'Hillshading',
                icon: 'fas fa-mountains',
                checked: enableHillshading,
                onChange: (v) => dispatch(graphicsSettingsSlice.enableHillshading(v)),
            }}
            popover={{
                name: 'Hillshading Settings',
                content: (
                    <PopoverBody>
                        <ul>
                            <Slider
                                title="Azimuth"
                                unit="°"
                                min={0}
                                max={360}
                                step={1}
                                value={azimuth}
                                onChange={(v) => dispatch(graphicsSettingsSlice.setHillshadeAzimuth(v))}
                                icon="fas fa-compass"
                            />
                            <Slider
                                title="Zenith"
                                unit="°"
                                min={0}
                                max={90}
                                step={1}
                                value={zenith}
                                onChange={(v) => dispatch(graphicsSettingsSlice.setHillshadeZenith(v))}
                                icon="fas fa-angle-90"
                            />
                            <Slider
                                title="Intensity"
                                unit="%"
                                min={0}
                                max={100}
                                step={1}
                                value={Math.round(intensity * 100)}
                                onChange={(v) => dispatch(graphicsSettingsSlice.setHillshadingIntensity(v / 100))}
                                icon="fas fa-eclipse"
                            />
                        </ul>
                    </PopoverBody>
                ),
            }}
        />
    );
}

export default HillshadeSetting;
