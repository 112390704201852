import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { useSelector, useDispatch, Provider } from 'react-redux';
import { useIsAuthenticated, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useEventBus } from 'EventBus';

import store from './store';

import { fetchVersionInfo, fetchUser } from './redux/actions';
import { isFetchingUser, getUser, getApiHealthCheck } from './redux/selectors';

// Authorization:
import RegisterUser from './components/authentication/RegisterUser';

import { Login } from './components/login/Login';

import Project from './components/giro3d/Project';
import Overview from './components/overview/Overview';
import Admin from './components/admin/Admin';
import ApiHealthCheckAlert from './components/apihealthcheck/ApiHealthCheckAlert';
import ErrorModal from './components/ErrorModal';
import LibraryPage from './components/library/LibraryPage';

import './App.css';
import LoginLoader from './components/login/LoginLoader';
import ErrorBoundary from './components/ErrorBoundary';

import Giro3DProvider from './components/giro3d/Giro3DContext';
import UppyService from './services/UppyService';
import DosApi from './services/DosApi';

const MainContent = () => {
    const dispatch = useDispatch();
    const eventBus = useEventBus();
    const user = useSelector(getUser);
    const isAuthenticated = useIsAuthenticated();
    const fetchingUser = useSelector(isFetchingUser);
    const { instance, accounts } = useMsal();
    const apiHealth = useSelector(getApiHealthCheck);

    useEffect(() => UppyService.setEventBus(eventBus), []);

    useEffect(() => {
        const checkToken = () => user && DosApi.apiAcquireToken();
        window.addEventListener('focus', checkToken);
        document.addEventListener('visibilitychange', checkToken);
        return () => {
            window.removeEventListener('focus', checkToken);
            document.removeEventListener('visibilitychange', checkToken);
        };
    }, []);

    useEffect(() => {
        // Note: if user is null, we already fetched it, but the user is not registered
        //       don't fetch it again, that would create an infinite loop
        if (isAuthenticated && user === undefined && apiHealth.backend_available) {
            dispatch(fetchUser());
        }
    }, [isAuthenticated, instance, accounts, user, apiHealth]);

    useEffect(() => {
        fetchVersionInfo(dispatch);
    }, [user]);

    const Layout = () => {
        if (fetchingUser) {
            return apiHealth.backend_available ? <LoginLoader /> : <div className="login-bg" />;
        }
        if (user?.is_active) {
            return (
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <div className="outlet">
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the Outlet. Reload the page.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                        </div>
                    }
                >
                    <Outlet className="outlet" />
                </ErrorBoundary>
            );
        }
        return <RegisterUser />;
    };

    return (
        <>
            <AuthenticatedTemplate>
                <Giro3DProvider>
                    <Router>
                        <Routes>
                            <Route exact path="/" element={<Layout />}>
                                <Route path="project/">
                                    <Route path="" element={<Project />} />
                                    <Route path=":id" element={<Project />} />
                                    <Route path=":id/:slug" element={<Project />} />
                                </Route>
                                <Route path="library/">
                                    <Route path="" element={<LibraryPage />} />
                                    <Route path="collection/:id" element={<LibraryPage />} />
                                    <Route path="collection/:id/:slug" element={<LibraryPage />} />
                                </Route>
                                <Route path="admin" element={<Admin />} />
                                <Route path="" element={<Overview />} />
                            </Route>
                        </Routes>
                    </Router>
                </Giro3DProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Router>
                    <Login />
                </Router>
            </UnauthenticatedTemplate>
        </>
    );
};

function App() {
    return (
        <Provider store={store}>
            <ApiHealthCheckAlert />
            <MainContent />
            <ErrorModal />
        </Provider>
    );
}

export default App;
