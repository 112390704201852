import type { Point } from 'geojson';
import LineBuilder from 'giro3d_extensions/layers/lines/LineBuilder';
import TubeBuilder from 'giro3d_extensions/layers/lines/TubeBuilder';
import {
    DEFAULT_BOREHOLE_SETTINGS,
    DEFAULT_CABLE_PIPELINE_SETTINGS,
    DEFAULT_TRACKLINE_SETTINGS,
} from 'services/Constants';
import PipelineLayer from 'giro3d_extensions/layers/lines/PipelineLayer';
import GeojsonSource from 'giro3d_extensions/layers/lines/GeojsonSource';
import BoreholeLasSource from 'giro3d_extensions/layers/lines/BoreholeLasSource';
import TracklineLayer from 'giro3d_extensions/layers/lines/TracklineLayer';
import CableLayer from 'giro3d_extensions/layers/lines/CableLayer';
import BoreholeLayer from 'giro3d_extensions/layers/lines/BoreholeLayer';

import { TrackDatasetProperties, LasDatasetProperties } from 'types/Dataset';
import LineLayer from 'giro3d_extensions/layers/lines/LineLayer';
import { SourceFile } from 'types/SourceFile';
import LayerBuilder from './LayerBuilder';

/**
 * Base class for builders that create line (or curve)-based layers, such as cables, pipelines, boreholes...
 */
abstract class LineLayerBuilder<TLayer extends LineLayer> extends LayerBuilder<
    TrackDatasetProperties | LasDatasetProperties,
    TLayer
> {
    protected async createGeojsonSource(sourceFile: SourceFile): Promise<GeojsonSource> {
        const source = new GeojsonSource({
            url: this.buildUrl(sourceFile.links.download),
            crsIn: this.getDatasetProjectionAsString(),
            crsOut: this.getInstanceCrs(),
        });

        return source;
    }

    protected async createLasSource(sourceFile: SourceFile): Promise<BoreholeLasSource> {
        const source = new BoreholeLasSource({
            location: sourceFile.geometry as Point,
            url: this.buildUrl(sourceFile.links.download),
            crsIn: this.getDatasetProjectionAsString(),
            crsOut: this.getInstanceCrs(),
            properties: this.getDatasetProperties() as LasDatasetProperties,
        });

        return source;
    }
}

export class TracklineBuilder extends LineLayerBuilder<TracklineLayer> {
    protected override async buildLayer(sourceFile: SourceFile) {
        return new TracklineLayer({
            instance: this.getInstance(),
            dispatch: this._dispatch,
            layerManager: this._layerManager,
            createEntityOnInit: true,
            datasetId: this.getDatasetId(),
            datasetType: this.getDatasetType(),
            sourceFileId: sourceFile.id,
            getFootprint: () => sourceFile.geometry,
            source: await this.createGeojsonSource(sourceFile),
            builder: new LineBuilder({
                thickness: DEFAULT_TRACKLINE_SETTINGS.THICKNESS,
            }),
            readableName: this.getDatasetName(),
            hostView: this._hostView,
        });
    }
}

export class PipelineBuilder extends LineLayerBuilder<PipelineLayer> {
    protected override async buildLayer(sourceFile: SourceFile) {
        return new PipelineLayer({
            instance: this.getInstance(),
            dispatch: this._dispatch,
            layerManager: this._layerManager,
            createEntityOnInit: true,
            datasetId: this.getDatasetId(),
            datasetType: this.getDatasetType(),
            sourceFileId: sourceFile.id,
            getFootprint: () => sourceFile.geometry,
            source: await this.createGeojsonSource(sourceFile),
            builder: new TubeBuilder({
                radius: DEFAULT_CABLE_PIPELINE_SETTINGS.RADIUS,
                radialSegments: DEFAULT_CABLE_PIPELINE_SETTINGS.RADIAL_SEGMENTS,
                allowSubsampling: false,
            }),
            readableName: this.getDatasetName(),
            hostView: this._hostView,
        });
    }
}

export class CableBuilder extends LineLayerBuilder<CableLayer> {
    protected override async buildLayer(sourceFile: SourceFile) {
        return new CableLayer({
            instance: this.getInstance(),
            dispatch: this._dispatch,
            layerManager: this._layerManager,
            createEntityOnInit: true,
            datasetId: this.getDatasetId(),
            datasetType: this.getDatasetType(),
            sourceFileId: sourceFile.id,
            getFootprint: () => sourceFile.geometry,
            source: await this.createGeojsonSource(sourceFile),
            builder: new TubeBuilder({
                radius: DEFAULT_CABLE_PIPELINE_SETTINGS.RADIUS,
                radialSegments: DEFAULT_CABLE_PIPELINE_SETTINGS.RADIAL_SEGMENTS,
                allowSubsampling: false,
            }),
            readableName: this.getDatasetName(),
            hostView: this._hostView,
        });
    }
}

export class BoreholeBuilder extends LineLayerBuilder<BoreholeLayer> {
    protected override async buildLayer(sourceFile: SourceFile) {
        return new BoreholeLayer({
            instance: this.getInstance(),
            dispatch: this._dispatch,
            layerManager: this._layerManager,
            createEntityOnInit: false,
            datasetId: this.getDatasetId(),
            datasetType: this.getDatasetType(),
            sourceFileId: sourceFile.id,
            getFootprint: () => sourceFile.geometry,
            source: await this.createLasSource(sourceFile),
            builder: new TubeBuilder({
                radius: DEFAULT_BOREHOLE_SETTINGS.RADIUS,
                radialSegments: DEFAULT_BOREHOLE_SETTINGS.RADIAL_SEGMENTS,
                allowSubsampling: false,
            }),
            readableName: this.getDatasetName(),
            hostView: this._hostView,
        });
    }
}
