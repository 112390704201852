import type ElevationRange from '@giro3d/giro3d/core/ElevationRange';
import ColorLayer from '@giro3d/giro3d/core/layer/ColorLayer';
import GeoTIFFFormat from '@giro3d/giro3d/formats/GeoTIFFFormat';
import GiroColorMap from '@giro3d/giro3d/core/layer/ColorMap';
import ColorMapMode from '@giro3d/giro3d/core/layer/ColorMapMode';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import TiledImageSource from '@giro3d/giro3d/sources/TiledImageSource';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import { transformExtent } from 'ol/proj';
import Extent from '@giro3d/giro3d/core/geographic/Extent';
import ElevationLayer, { ElevationLayerOptions } from '@giro3d/giro3d/core/layer/ElevationLayer';
import Interpretation from '@giro3d/giro3d/core/layer/Interpretation';

import { MosaicDatasetProperties } from 'types/Dataset';

import { GlobalCache } from '@giro3d/giro3d/core/Cache';
import { DefaultQueue } from '@giro3d/giro3d/core/RequestQueue';
import LayerBuilder from './LayerBuilder';
import DosApi from '../../services/DosApi';
import MosaicWMTSLayer from '../layers/raster/MosaicWMTSLayer';
import ElevationMosaicWMTSLayer from '../layers/raster/ElevationMosaicWMTSLayer';

const parser = new WMTSCapabilities();
const getTilegridExtent = (wmtsCap, layer: string, datasetCRS: string) => {
    const layers = wmtsCap.Contents.Layer;
    const l = layers.find((elt) => elt.Identifier === layer);
    const wgs84bbox = l.WGS84BoundingBox;
    return transformExtent(wgs84bbox, 'EPSG:4326', datasetCRS);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Capabilities = any;

async function fetchWMTSCapabilitiesOnce(
    id: string,
    rescale: string,
    tileFormat: string,
    returnMask: string
): Promise<Capabilities> {
    const key = `${id}-WMTSCapabilities-${rescale}-${tileFormat}-${returnMask}`;
    const cached = GlobalCache.get(key);
    if (cached) {
        return cached;
    }

    const request = async () =>
        DosApi.fetchWMTSCapabilities(id, rescale, tileFormat, returnMask).then((text) => parser.read(text));

    const result = await DefaultQueue.enqueue({ id: key, request });

    GlobalCache.set(key, result);

    return result;
}

class MosaicWMTSBuilder extends LayerBuilder<MosaicDatasetProperties, ElevationMosaicWMTSLayer | MosaicWMTSLayer> {
    private async loadGiro3dLayer<TLayer>(): Promise<TLayer> {
        let rescale = '';
        let returnMask = '';
        let tileFormat = '';
        const properties = this.getDatasetProperties();
        const useTiff = properties.bands === 1;

        if (properties.bands !== 1 && this.isDatasetElevation()) {
            throw new Error('Multi band cogs cannot be used as elevation');
        }

        if (useTiff) {
            // set return_mask to false to get tif tiles with nodata values.
            rescale = '';
            tileFormat = '&tile_format=tif';
            returnMask = '&return_mask=false';
        } else if (properties.max && properties.min) {
            // If not elevation, single-band tiles can be used as color but need to be rescaled to fit within the png data range.
            rescale = `&rescale=${properties.min},${properties.max}`;
        }
        const capabilities = await fetchWMTSCapabilitiesOnce(this.getDatasetId(), rescale, tileFormat, returnMask);
        const tmsIdentifier = capabilities.Contents.TileMatrixSet[0].Identifier;
        // Force uppercase. See #1649.
        capabilities.Contents.TileMatrixSet[0].SupportedCRS =
            capabilities.Contents.TileMatrixSet[0].SupportedCRS.toUpperCase();
        const tmsCRS = capabilities.Contents.TileMatrixSet[0].SupportedCRS;
        const datasetCRS = this.getDatasetProjectionAsString().toUpperCase();
        if (datasetCRS !== tmsCRS) {
            throw new Error(
                `TileMatrixSet and dataset has different projections ${tmsCRS} ${datasetCRS}. dos-web does not support reprojection.`
            );
        }
        const options = optionsFromCapabilities(capabilities, {
            layer: 'mosaic',
            matrixSet: tmsIdentifier,
        });
        options.crossOrigin = 'same-origin';
        const extent = getTilegridExtent(capabilities, 'mosaic', datasetCRS);
        // Authorization headers are set by Giro3d as we're using a tiled source
        const format = properties.bands === 1 ? new GeoTIFFFormat() : undefined;
        const datasetGeometry = await this.getDatasetGeometry();
        const containsFn = this.getContainsFn(datasetGeometry);
        const source = new TiledImageSource({ source: new WMTS(options), format, containsFn });

        const minmax =
            properties.max != null && properties.min != null ? { min: properties.min, max: properties.max } : undefined;

        const layerOptions: ElevationLayerOptions = {
            name: this.getDatasetId(),
            interpretation: Interpretation.Raw,
            source,
            minmax,
            noDataOptions: {
                replaceNoData: true,
            },
            extent: new Extent(datasetCRS, {
                'west': extent[0],
                'east': extent[2],
                'south': extent[1],
                'north': extent[3],
            }).as(this.getInstanceCrs()),
        };

        if (useTiff) {
            layerOptions.colorMap = new GiroColorMap([], 0, 0, ColorMapMode.Elevation);
        }

        let result: ElevationLayer | ColorLayer;
        if (this.isDatasetElevation()) {
            result = new ElevationLayer(layerOptions);
        } else {
            result = new ColorLayer(layerOptions);
        }

        result.userData.datasetId = this.getDatasetId();

        return result as TLayer;
    }

    override async build() {
        const layer = await this.buildLayer();

        return [layer];
    }

    override async buildLayer() {
        const id = this.getDatasetId();
        const fileOutlinesLoader = async () => DosApi.fetchDatasetSourcefiles(id);
        const readableName = this.getDatasetName();
        const datasetType = this.getDatasetType();

        const properties = this.getDatasetProperties();
        const minmax: ElevationRange =
            properties.max != null && properties.min != null ? { min: properties.min, max: properties.max } : undefined;

        const footprint = await this.getDatasetFootprint();

        if (this.isDatasetElevation()) {
            return new ElevationMosaicWMTSLayer({
                layerManager: this._layerManager,
                readableName,
                datasetType,
                dispatch: this._dispatch,
                instance: this.getInstance(),
                datasetId: this.getDatasetId(),
                minmax,
                datasetProjection: this.getDatasetProjectionAsString(),
                getFootprint: () => footprint,
                createLayer: () => this.loadGiro3dLayer(),
                fileOutlinesLoader,
                supportsColormap: true,
                supportsMasks: true,
                hostView: this._hostView,
            });
        }
        if (properties.bands === 1 && properties.max && properties.min) {
            return new MosaicWMTSLayer({
                layerManager: this._layerManager,
                readableName,
                datasetType,
                dispatch: this._dispatch,
                instance: this.getInstance(),
                datasetId: this.getDatasetId(),
                minmax,
                datasetProjection: this.getDatasetProjectionAsString(),
                getFootprint: () => footprint,
                createLayer: () => this.loadGiro3dLayer(),
                fileOutlinesLoader,
                supportsColormap: true,
                supportsMasks: false,
                hostView: this._hostView,
            });
        }
        return new MosaicWMTSLayer({
            layerManager: this._layerManager,
            readableName,
            datasetType,
            dispatch: this._dispatch,
            instance: this.getInstance(),
            datasetId: this.getDatasetId(),
            datasetProjection: this.getDatasetProjectionAsString(),
            getFootprint: () => footprint,
            createLayer: () => this.loadGiro3dLayer(),
            fileOutlinesLoader,
            supportsColormap: false,
            supportsMasks: false,
            hostView: this._hostView,
        });
    }
}

export default MosaicWMTSBuilder;
