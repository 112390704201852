import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { deleteApiKey, fetchApiKeys, generateAPIKey } from '../../redux/actions';
import { getApiKeys } from '../../redux/selectors';

const ApiKey = () => {
    const dispatch = useDispatch();

    const apiKeys = useSelector(getApiKeys);

    const MODAL_STATE = {
        INFORMATION: 'information',
        REQUEST: 'request',
        DISPLAY: 'display',
    };
    const [modalState, setModalState] = useState(null);
    const [apiKey, setApiKey] = useState();

    const openModal = () => {
        dispatch(fetchApiKeys());
        setModalState(MODAL_STATE.INFORMATION);
        setApiKey();
    };

    const closeModal = () => {
        setModalState(null);
        setApiKey();
    };

    const requestApiKey = () => {
        setModalState(MODAL_STATE.REQUEST);
        dispatch(generateAPIKey()).then((data) => {
            setApiKey(data);
            setModalState(MODAL_STATE.DISPLAY);
        });
    };

    const copyKey = () => {
        const copyText = document.getElementById('apiKey');
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(copyText.value);
        closeModal();
    };

    const modalContent = () => {
        switch (modalState) {
            case MODAL_STATE.DISPLAY:
                return (
                    <>
                        <ModalBody>
                            <div className="form-group">
                                <label className="form-label" htmlFor="apiKey">
                                    Your API Key is:
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={`${apiKey.prefix}.${apiKey.key}`}
                                    readOnly
                                    id="apiKey"
                                />
                            </div>
                            <p>This key will never be shown again so copy it now.</p>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="pane-button large highlight" onClick={copyKey}>
                                Copy to clipboard and close
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return (
                    <>
                        <ModalBody>
                            <p>
                                API Keys are to be used with our SDK and other prgrams besides the SCOPE web client to
                                access the SCOPE API.
                                <br />
                                API keys currently grant all priviliges that your user account possesses.
                            </p>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Prefix</th>
                                        <th>Created at</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apiKeys.map((key) => (
                                        <tr key={key.id}>
                                            <td>{key.prefix}</td>
                                            <td>{key.created_at}</td>
                                            <td>
                                                <Button
                                                    className="borderless red"
                                                    onClick={() => dispatch(deleteApiKey(key.id))}
                                                >
                                                    <i className="fal fa-trash-can" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={requestApiKey}
                                disabled={modalState === MODAL_STATE.REQUEST}
                            >
                                Generate API Key
                            </button>
                        </ModalFooter>
                    </>
                );
        }
    };

    return (
        <>
            <button type="button" className="pane-button large" onClick={openModal}>
                API Keys
            </button>
            <Modal isOpen={modalState !== null} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>API Keys</ModalHeader>
                {modalContent()}
            </Modal>
        </>
    );
};

export default ApiKey;
