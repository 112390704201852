// React
import { useDispatch } from 'react-redux';

// selector
import { useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import * as layoutSlice from 'redux/layout';
import * as giro3dSlice from 'redux/giro3d';

// Redux
import useResizeObserver, { ObservedSize } from 'use-resize-observer';
import { useRef } from 'react';
import { Rect } from 'flexlayout-react';

// Components
import Giro3dControls from './Giro3DControls';
import { DATASETS_STATES } from '../../services/Constants';

import LineFollowerMenu from './lineFollower/LineFollowerMenu';
import Coordinates from './StatusBar/Coordinates';
import HoveredItemIndicator from './StatusBar/HoveredItemIndicator';

const Giro3DUI = ({ inspectorRef, giro3dControlsRef }) => {
    const dispatch = useDispatch();
    const uiRef: React.MutableRefObject<HTMLDivElement> = useRef();

    function onResize(size: ObservedSize) {
        const parentElement = uiRef.current.offsetParent as HTMLDivElement;
        dispatch(
            layoutSlice.setCrop(
                new Rect(
                    Number(parentElement.style.left.replace(/[a-zA-Z]/g, '')),
                    Number(parentElement.style.top.replace(/[a-zA-Z]/g, '')) - 24,
                    size.width,
                    size.height
                )
            )
        );
    }

    useResizeObserver<HTMLDivElement>({
        ref: uiRef,
        onResize,
    });

    const coordinatesShown = useAppSelector(giro3dSlice.getCoordinatesShown);
    const axisCompassShown = useAppSelector(giro3dSlice.getAxisCompassShown);

    const project = useAppSelector(datasetsSlice.currentProject);
    const allDatasets = useAppSelector(
        datasetsSlice.getProjectDatasets,
        (a, b) => (a.length === 0) === (b.length === 0) // We only care if there are datasets
    );
    const loadingState = useAppSelector(datasetsSlice.getState);

    const leftOpen = useAppSelector(layoutSlice.getLeftOpen);
    const trayOpen = useAppSelector(layoutSlice.getTrayOpen);
    const rightOpen = useAppSelector(layoutSlice.getRightOpen);

    const loading = loadingState === DATASETS_STATES.LOADING || undefined;

    return (
        <div className="giro3d-ui" ref={uiRef}>
            {loading && (
                <div className="map-container-loader">
                    <div className="dot-flashing" />
                </div>
            )}
            {(!project?.geometry || allDatasets?.length === 0) && loadingState === DATASETS_STATES.LOADED && (
                <div className="map-container-loader">No datasets to show</div>
            )}

            <div className="giro3d-inspector" ref={inspectorRef} />

            <div className="pane-controls">
                <button
                    type="button"
                    title={`${leftOpen ? 'Close' : 'Open'} left pane`}
                    className="pane-toggle left"
                    onClick={() => dispatch(layoutSlice.leftOpen(!leftOpen))}
                >
                    <i className={`fal fa-chevron-${leftOpen ? 'left' : 'right'}`} />
                </button>
                <button
                    type="button"
                    title={`${rightOpen ? 'Close' : 'Open'} right pane`}
                    className="pane-toggle right"
                    onClick={() => dispatch(layoutSlice.rightOpen(!rightOpen))}
                >
                    <i className={`fal fa-chevron-${rightOpen ? 'right' : 'left'}`} />
                </button>
            </div>

            <div className="giro3d-lower-ui">
                <Giro3dControls compassRef={giro3dControlsRef} hidden={!axisCompassShown || loading} />
                <div>
                    <div>
                        <LineFollowerMenu />
                    </div>
                    <div>
                        {coordinatesShown ? (
                            <div className="statusbar-stack">
                                <HoveredItemIndicator />
                                <div className="map-statusbar">
                                    <Coordinates />
                                </div>
                            </div>
                        ) : null}
                        <button
                            type="button"
                            title={`${trayOpen ? 'Close' : 'Open'} tray pane`}
                            className="pane-toggle bottom"
                            onClick={() => dispatch(layoutSlice.trayOpen(!trayOpen))}
                        >
                            <i className={`fal fa-chevron-${trayOpen ? 'down' : 'up'}`} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Giro3DUI;
