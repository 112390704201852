// eslint-disable-next-line import/no-cycle
import { NOTIFICATIONS, ACTIVE_NOTIFICATION, UPDATE_NOTIFICATION } from '../actionTypes';

const initialState = {
    list: undefined,
    active: null,
};

function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case NOTIFICATIONS:
            return {
                ...state,
                list: payload,
            };
        case ACTIVE_NOTIFICATION:
            return {
                ...state,
                active: payload,
            };
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                list: [...state.list.filter((x) => x.id !== payload.id), payload],
            };
        default:
            return state;
    }
}

export default reducer;
