import { Button, UncontrolledTooltip } from 'reactstrap';
import Project, { ProjectWithOlFeature } from 'types/Project';
import overviewMapService from 'services/OverviewMapService';
import { PROJECT_STATES } from 'services/Constants';
import { Feature } from 'ol';
import ToggleSwitch from 'components/ToggleSwitch';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import { OVERVIEW_MAP_LOADED } from 'redux/actionTypes';
import InlineDropMenu from '../InlineDropMenu';

export enum PROJECT_ACTION {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    DATASETS = 'datasets',
    OPEN = 'open',
}

const MAX_DEFAULT_SIZE = 500000;

function getIcon(state: PROJECT_STATES, feature: Feature, oversize: boolean): string {
    if (!feature) return 'fas fa-location-pin-slash';

    switch (state) {
        case PROJECT_STATES.LOADED:
            if (oversize) return 'fas fa-square-dashed';
            return '';
        case PROJECT_STATES.LOADING:
            return 'fas fa-spinner fa-pulse';
        case PROJECT_STATES.LOADING_FAILED:
            return 'fas fa-exclamation-triangle';
        default:
            return 'fas fa-exclamation-triangle';
    }
}

function projectListItemStatus(project: ProjectWithOlFeature, oversize: boolean): string {
    switch (project.olstate) {
        case PROJECT_STATES.LOADING:
            return 'Project is currently being loaded into the view.';
        case PROJECT_STATES.LOADING_FAILED:
            return `Loading data has failed: ${project.olstate_msg}`;
        case PROJECT_STATES.LOADED:
            if (oversize) return 'Project area is too large to show by default';
            return 'Project has no location data';
        default:
            return `Unknown state: ${project.olstate} ${project.olstate_msg}`;
    }
}

const ProjectListItem = (props: {
    project: ProjectWithOlFeature;
    setAction: (action: PROJECT_ACTION) => void;
    setCurrentProject: (project: Project) => void;
    highlight: boolean;
}) => {
    const { project, setAction, setCurrentProject, highlight } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [visible, setVisible] = useState(true);
    const [oversize, setOversize] = useState(false);

    useEffect(() => {
        if (project.olstate === PROJECT_STATES.LOADED && project.olFeature)
            overviewMapService.setItemVisible(project, visible);
    }, [visible, project.olFeature]);

    useEffect(() => {
        if (project.olFeature) {
            const extent = project.olFeature.getGeometry().getExtent();
            if (extent[2] - extent[0] > MAX_DEFAULT_SIZE || extent[1] - extent[3] > MAX_DEFAULT_SIZE) {
                setVisible(false);
                setOversize(true);
            }
        }
    }, [project.olFeature]);

    const toProjectView = () => {
        const slug = props.project.name
            .toString()
            .normalize('NFD') // break accented characters into components
            .replace(/[\u0300-\u036f]/g, '') // remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') // spaces to dashes
            .replace(/&/g, '-and-') // ampersand to and
            .replace(/[^\w-]+/g, '') // remove non-words
            .replace(/--+/g, '-') // collapse multiple dashes
            .replace(/^-+/, '') // trim starting dash
            .replace(/-+$/, ''); // trim ending dash;
        navigate(`/project/${project.id}/${slug}`);
        dispatch({ type: OVERVIEW_MAP_LOADED, payload: undefined });
    };

    const goToFeature = (element) => {
        if (element.olFeature !== null) overviewMapService.goToItem(element, undefined);
        else overviewMapService.unselectItem(null, undefined);
    };

    const icon = getIcon(project.olstate, project.olFeature, oversize);

    return (
        <li className={highlight ? 'highlight' : ''}>
            <div>
                <label onDoubleClick={toProjectView}>
                    <button type="button" onClick={() => goToFeature(project)}>
                        {project.name}
                    </button>
                </label>
                {icon ? (
                    <>
                        <Button className="borderless indicator">
                            <i className={icon} id={`project-indicator-${project.id}`} />
                        </Button>
                        <UncontrolledTooltip target={`project-indicator-${project.id}`}>
                            {projectListItemStatus(project, oversize)}
                        </UncontrolledTooltip>
                    </>
                ) : null}
                <InlineDropMenu>
                    <Button
                        className="borderless green"
                        title="Edit Project"
                        onClick={() => {
                            setCurrentProject(project);
                            setAction(PROJECT_ACTION.EDIT);
                        }}
                    >
                        <i className="fas fa-pen" />
                    </Button>
                    <Button
                        className="borderless red"
                        title="Delete Project"
                        onClick={() => {
                            setCurrentProject(project);
                            setAction(PROJECT_ACTION.DELETE);
                        }}
                    >
                        <i className="fas fa-trash-can" />
                    </Button>
                </InlineDropMenu>
                <ToggleSwitch
                    id={`${project.id}-visible`}
                    checked={visible}
                    onChange={(e) => setVisible(e.target.checked)}
                    disabled={project.olstate !== PROJECT_STATES.LOADED}
                />
            </div>
        </li>
    );
};

export default ProjectListItem;
