import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

import { Form, Formik } from 'formik';
import * as serializer from 'services/serializer';
import BaseInput from 'components/forms/BaseInput';
import { useAppDispatch, useAppSelector } from 'store';
import { SerializedState } from 'types/serialization/View';
import ApiErrors from 'services/ApiErrors';
import Checkbox from 'components/Checkbox';
import * as datasetsSlice from 'redux/datasets';
import { createView, deleteView, loadView, showError, updateProject, updateView } from 'redux/actions';
import * as giro3dSlice from 'redux/giro3d';
import DosApi from 'services/DosApi';
import InlineDropMenu from '../../InlineDropMenu';
import { useModelContext } from '../Project';

const ViewActions = ({ view, project, doLoadView, doEditView, doDeleteView, doCopyLink }) => (
    <InlineDropMenu>
        <Button className="borderless light-blue" title="Open View" onClick={doLoadView}>
            <i className="fas fa-eye" />
        </Button>
        <Button className="borderless light-blue" title="Copy View Link" onClick={doCopyLink}>
            <i className="fas fa-link" />
        </Button>
        {project.user_permissions.update_project ? (
            <>
                <Button className="borderless green" title="Edit View" onClick={doEditView}>
                    <i className="fas fa-pen" />
                </Button>
                {project.default_view_id !== view.id ? (
                    <Button className="borderless red" title="Delete View" onClick={doDeleteView}>
                        <i className="fas fa-trash-can" />
                    </Button>
                ) : null}
            </>
        ) : null}
    </InlineDropMenu>
);

const ViewMenu = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const project = useAppSelector(datasetsSlice.currentProject);
    const loadedView = useAppSelector(giro3dSlice.getLoadedView);
    const model = useModelContext();

    const [views, setViews] = useState<SerializedState[]>(null);
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState<SerializedState>();

    enum MODAL {
        PROMPT = 'prompt',
        PROCESSING = 'processing',
        CONFIRMATION = 'confirmation',
        ERROR = 'error',
    }
    const [deleteModalState, setModal] = useState<MODAL>(null);
    const [deleting, setDeleting] = useState<SerializedState>();
    const [errorText, setErrorText] = useState();

    const state = useAppSelector((s) => s);

    const [copyConfirm, setCopyConfirm] = useState(false);

    const createUrl = (view) => {
        const slug = view.name
            .toString()
            .normalize('NFD') // break accented characters into components
            .replace(/[\u0300-\u036f]/g, '') // remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') // spaces to dashes
            .replace(/&/g, '-and-') // ampersand to and
            .replace(/[^\w-]+/g, '') // remove non-words
            .replace(/--+/g, '-') // collapse multiple dashes
            .replace(/^-+/, '') // trim starting dash
            .replace(/-+$/, ''); // trim ending dash;

        // searchParams.toString will encode the / before the slug so we make our own searchParam string
        // We also remove all others search params as we assume this link is only for the view
        return `${window.location.origin + location.pathname}?view=${view.id}/${slug}`;
    };

    const copyUrl = (view) => {
        navigator.clipboard.writeText(createUrl(view));
        setCopyConfirm(true);
        setTimeout(() => setCopyConfirm(false), 2000);
    };

    const sortViews = (unsortedViews) =>
        unsortedViews
            .map((v) => ({ ...v, updated_at: new Date(v.updated_at) }))
            .sort((a, b) => {
                if (a.id === project.default_view_id) return -1;
                if (b.id === project.default_view_id) return 1;
                return b.updated_at - a.updated_at;
            });

    useEffect(() => {
        setViews(null);
        DosApi.fetchViews(project.id)
            .then((_views) => setViews(sortViews(_views)))
            .catch((err) => showError(dispatch, err));
    }, []);

    if (views === null)
        return (
            <ModalBody>
                <Spinner animation="border" />
            </ModalBody>
        );

    if (creating)
        return (
            <div className="tabContent">
                <Formik
                    initialValues={{
                        name: '',
                        project_id: project.id,
                        view: undefined,
                        saveTab: false,
                        setDefault: false,
                    }}
                    onSubmit={(values) => {
                        values.project_id = project.id;
                        values.view = serializer.serialize(state, model, values.saveTab);
                        dispatch(createView(values)).then((newView) => {
                            setViews(sortViews([...views, newView]));
                            copyUrl(newView);
                            setCreating(false);
                        });
                    }}
                    validate={(values) => {
                        const errors: { name?: string } = {};
                        if (!values.name) errors.name = 'Required';
                        return errors;
                    }}
                    enableReinitialize
                >
                    {({ values, isSubmitting, setFieldValue }) => (
                        <Form className="sideform">
                            <BaseInput name="name" label="View Name" titled />
                            <Checkbox
                                id="saveTab"
                                label="Save Views tab"
                                checked={values.saveTab}
                                onChange={(e) => setFieldValue('saveTab', e.target.checked)}
                            />
                            <Checkbox
                                id="setDefault"
                                label="Set Default View"
                                checked={values.setDefault}
                                onChange={(e) => setFieldValue('setDefault', e.target.checked)}
                            />
                            <hr />
                            <div className="input-row">
                                <button type="button" className="pane-button" onClick={() => setCreating(null)}>
                                    Cancel
                                </button>
                                <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                    Create
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );

    if (editing)
        return (
            <div className="tabContent">
                <Formik
                    initialValues={{
                        name: editing?.name,
                        overwrite_view: false,
                        id: editing?.id,
                        project_id: editing?.project_id,
                        view: undefined,
                        saveTab: false,
                        setDefault: project.default_view_id === editing?.id,
                    }}
                    onSubmit={(values) => {
                        values.id = editing.id;
                        values.project_id = project.id;
                        if (values.overwrite_view) values.view = serializer.serialize(state, model, values.saveTab);
                        dispatch(updateView(values)).then((newView) => {
                            setViews(sortViews([...views.filter((v) => v.id !== newView.id), newView]));
                            if (values.setDefault && project.default_view_id !== editing?.id)
                                DosApi.updateProject(project.id, { ...project, default_view_id: newView.id }).then(
                                    (p) => updateProject(dispatch, p)
                                );
                            setEditing(null);
                        });
                    }}
                    validate={(values) => {
                        const errors: { name?: string } = {};
                        if (!values.name) errors.name = 'Required';
                        return errors;
                    }}
                    enableReinitialize
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <BaseInput name="name" label="View name" />
                            <Checkbox
                                id="overwrite_view"
                                checked={values.overwrite_view}
                                onChange={(e) => setFieldValue('overwrite_view', e.currentTarget.checked)}
                                name="overwrite_view"
                                label="Overwrite view with current settings"
                            />
                            <Checkbox
                                id="saveTab"
                                label="Save Views tab"
                                checked={values.saveTab}
                                onChange={(e) => setFieldValue('saveTab', e.target.checked)}
                            />
                            <Checkbox
                                id="setDefault"
                                label="Set Default View"
                                checked={values.setDefault}
                                onChange={(e) => setFieldValue('setDefault', e.target.checked)}
                                disabled={project.default_view_id === editing?.id}
                            />
                            <hr />
                            <div className="input-row">
                                <button type="button" className="pane-button" onClick={() => setEditing(null)}>
                                    Cancel
                                </button>
                                <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                    Update
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );

    const deleteModalContent = () => {
        switch (deleteModalState) {
            case MODAL.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    setModal(MODAL.PROCESSING);
                                    dispatch(deleteView(deleting))
                                        .then(() => {
                                            setViews(views.filter((v) => v.id !== deleting.id));
                                            setModal(MODAL.CONFIRMATION);
                                            setTimeout(() => {
                                                setModal(undefined);
                                                setDeleting(undefined);
                                            }, 2000);
                                        })
                                        .catch((err) => {
                                            setErrorText(ApiErrors.getErrorMessage(err));
                                            setModal(MODAL.ERROR);
                                        });
                                }}
                            >
                                Yes, Delete this View
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                onClick={() => {
                                    setDeleting(undefined);
                                    setModal(undefined);
                                }}
                            >
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case MODAL.PROCESSING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting View...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case MODAL.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">View deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case MODAL.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="warning"
                                onClick={() => {
                                    setDeleting(null);
                                    setModal(null);
                                }}
                            >
                                OK
                            </Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="tabContent">
            <div className="input-row">
                <div className="grow" />
                <button
                    type="button"
                    className="pane-button highlight"
                    title="Create View"
                    onClick={() => setCreating(true)}
                >
                    <i className="fas fa-plus" />
                </button>
            </div>
            <ul className="list">
                {views.map((view) => (
                    <li key={view.id} className={view.id === loadedView ? 'highlight' : ''}>
                        <div>
                            <label>
                                {view.id === project.default_view_id ? <i className="fas fa-globe" /> : null}
                                {view.name}
                            </label>
                            <ViewActions
                                view={view}
                                project={project}
                                doLoadView={() => dispatch(loadView(view.project_id, view.id, dispatch))}
                                doEditView={() => setEditing(view)}
                                doDeleteView={() => {
                                    setDeleting(view);
                                    setModal(MODAL.PROMPT);
                                }}
                                doCopyLink={() => copyUrl(view)}
                            />
                        </div>
                    </li>
                ))}
            </ul>

            <Modal isOpen={copyConfirm} keyboard={false} centered className="modal-confirm">
                <ModalHeader />
                <ModalBody>
                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                    <span className="big-modal-text">Link copied</span>
                </ModalBody>
                <ModalFooter />
            </Modal>

            <Modal isOpen={deleting !== undefined} keyboard={false} centered className="modal-confirm">
                {deleteModalContent()}
            </Modal>
        </div>
    );
};

export default ViewMenu;
