import { DatasetId } from 'types/common';
import TabHeader from 'components/flexLayout/TabHeader';
import { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsInstance } from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import giro3dService from 'services/Giro3dService';
import LineLayer from 'giro3d_extensions/layers/lines/LineLayer';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { PopoverBody } from 'reactstrap';
import TriState, { STATE } from 'components/dropdown/TriState';
import { useEventBus } from 'EventBus';
import * as datasetsSlice from 'redux/datasets';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Input from 'components/dropdown/Input';
import Title from 'components/dropdown/Title';
import Button from 'components/dropdown/Button';
import Toggle from 'components/dropdown/Toggle';
import { LAYER_STATES } from 'services/Constants';
import { doLoadDataset } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { hasAttributes } from 'types/LayerState';

type AxisSettings = {
    min?: number;
    max?: number;
    invert: boolean;
};

type Props = {
    tabId: string;
    config: {
        properties: { [k: string]: STATE };
        datasetId: DatasetId;
        zoom?: { start: number; end: number };
        legend?: boolean;
        axes?: AxisSettings[];
        distanceMode?: boolean;
    };
};

const ChartMenu = (props: Props) => {
    const { tabId, config } = props;

    const eventBus = useEventBus();
    const dispatch = useDispatch();

    const [data, setData] = useState<number[][]>([]);
    const properties = config.properties;

    const dataset = useAppSelector(datasetsSlice.get(config.datasetId));
    const layer = useAppSelector(layersSlice.get(config.datasetId));
    const selectedProperty = hasAttributes(layer)
        ? useAppSelector(layersSlice.getActiveAttribute(layer)).id
        : undefined;

    if (dataset.state !== LAYER_STATES.LOADED) doLoadDataset(dispatch, dataset);

    const generateChartValues = async () => {
        const geometry = await (giro3dService.getLayersForDataset(config.datasetId)[0] as LineLayer)?.getGeometry();
        const propertiesMap = await (giro3dService.getLayersForDataset(config.datasetId)[0] as LineLayer)
            ?.getSource()
            .getPropertyValues();

        if (properties === undefined)
            eventBus.dispatch('update-dataset-config-pane', {
                tabId,
                data: {
                    properties: Object.fromEntries(
                        Array.from(propertiesMap?.keys(), (key) => [
                            key,
                            key === selectedProperty ? STATE.LEFT : STATE.CENTRE,
                        ])
                    ),
                    ...config,
                },
            });

        const sampleCount = geometry ? geometry[0].points.buffer.length / 3 : 0;

        const chartData = [];
        const allProperties = propertiesMap ? Array.from(propertiesMap.values()).map((a) => a[0]) : [];

        for (let i = 0; i < sampleCount; i++) {
            const x = geometry[0].points.buffer[i * 3];
            const y = geometry[0].points.buffer[i * 3 + 1];
            const z = geometry[0].points.buffer[i * 3 + 2];

            const d = Math.sqrt(
                (x - geometry[0].points.buffer[(i - 1) * 3]) ** 2 +
                    (y - geometry[0].points.buffer[(i - 1) * 3 + 1]) ** 2 +
                    (z - geometry[0].points.buffer[(i - 1) * 3 + 2]) ** 2
            );

            chartData[i] = [
                i,
                i !== 0 ? chartData[i - 1][1] + d : 0,
                geometry[0].origin.x + x,
                geometry[0].origin.y + y,
                geometry[0].origin.z + z,
                ...allProperties.map((row) => row[i]),
            ];
        }

        setData(chartData);
    };

    useEffect(() => {
        generateChartValues();
    }, [dataset.state]);

    const chartRef = useRef(null);
    const containerRef = useRef(null);

    const setPropertySide = (side: STATE, property: string) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.properties[property] = side;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setLegend = (open: boolean) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.legend = open;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    // Resize handler for forcing charts update
    const handleResize = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance();
        if (echartsInstance) echartsInstance.resize();
    };
    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(containerRef.current);
            return resizeObserver.disconnect;
        }
        return undefined;
    }, []);

    const options: EChartsOption = {
        darkMode: true,
        animation: false,
        grid: {
            containLabel: true,
            top: 10,
            bottom: 35,
            left: 10,
            right: 10,
        },
        tooltip: {
            trigger: 'axis',
            transitionDuration: 0,
            backgroundColor: '#242a2d', // --background
            borderColor: '#30383b', // --foreground
            borderWidth: 2,
            textStyle: { color: '#eeeeee' }, // --text-high-emphasis
            formatter: (params) => {
                return [
                    `<span style="color:#febf80">Sample</span>: ${params[0].data[0]}`,
                    `<span style="color:#febf80">Distance</span>: ${params[0].data[1]}`,
                    `<span style="color:#cc3333">X</span>: ${params[0].data[2].toFixed(0)}`,
                    `<span style="color:#33cc33">Y</span>: ${params[0].data[3].toFixed(0)}`,
                    `<span style="color:#3333cc">Z</span>: ${params[0].data[4].toFixed(0)}`,
                ]
                    .concat(
                        params
                            .sort((a, b) => {
                                if (a[0] < b[0]) return -1;
                                if (a[0] > b[0]) return 1;
                                return 0;
                            })
                            .map(
                                (param) =>
                                    `<span style="color:${param.color}">${param.seriesName}</span>: ${param.data[5 + param.seriesIndex]}`
                            )
                    )
                    .join('<br/>');
            },
        },
        dataZoom: [
            {
                type: 'slider', // Slider zooming
                xAxisIndex: 0,
                start: config.zoom?.start ?? 0,
                end: config.zoom?.end ?? 100,
                height: 15,
                filterMode: 'weakFilter',
                minSpan: 1,
                bottom: 15,
            },
            {
                type: 'inside', // Mouse wheel zooming
                xAxisIndex: 0,
                filterMode: 'weakFilter',
                minSpan: 1,
            },
        ],
        dataset: {
            source: data,
        },
        xAxis: {
            type: 'value',
            max(value) {
                return Math.ceil(value.max);
            },
        },
        yAxis: [
            {
                type: 'value',
                min: config.axes ? config.axes[0].min : undefined,
                max: config.axes ? config.axes[0].max : undefined,
                inverse: config.axes ? config.axes[0].invert : undefined,
            },
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: { opacity: 0.25 },
                },
                min: config.axes ? config.axes[1].min : undefined,
                max: config.axes ? config.axes[1].max : undefined,
                inverse: config.axes ? config.axes[1].invert : undefined,
            },
        ],
        series: Object.entries(properties ?? {})
            .sort((a, b) => {
                if (a[0] < b[0]) return -1;
                if (a[0] > b[0]) return 1;
                return 0;
            })
            .map((property, index) => {
                return {
                    type: 'line',
                    name: property[0],
                    // echarts doesnt like to disbale series so we just provide no data to hide
                    encode:
                        property[1] === STATE.CENTRE
                            ? { x: -1, y: -1 }
                            : { x: config.distanceMode ? 1 : 0, y: 5 + index },
                    seriesLayoutBy: 'column',
                    large: true,
                    showSymbol: false,
                    yAxisIndex: property[1] === STATE.RIGHT ? 1 : 0,
                };
            }),
    };

    const showMarker = (params) => {
        const heights = (data[params.dataIndex] as number[]).slice(3);
        const datasetIndex = heights.indexOf(Math.max(...heights.filter((h) => h !== null)));
        if (datasetIndex !== -1) {
            giro3dService.updateCoordinates(
                {
                    point: {
                        x: data[params.dataIndex][2],
                        y: data[params.dataIndex][3],
                        z: data[params.dataIndex][4],
                    },
                    picked: true,
                },
                true
            );
        }
    };

    // Use a timeout to reduce updates during zooming
    const zoomTimeout = useRef(null);
    const updateZoom = (params) => {
        if (zoomTimeout.current) clearTimeout(zoomTimeout.current);

        zoomTimeout.current = setTimeout(() => {
            const newConfig = { ...config };
            newConfig.zoom = { start: params.start || params.batch[0].start, end: params.end || params.batch[0].end };
            eventBus.dispatch('update-dataset-config-pane', { tabId, data: newConfig });
        }, 1000);
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();
            chartInstance.on('showTip', showMarker);
            chartInstance.on('dataZoom', updateZoom);
        }
        return () => {
            if (chartRef.current) {
                const chartInstance: EChartsInstance = chartRef.current.getEchartsInstance();
                chartInstance.off('showTip', showMarker);
                chartInstance.off('dataZoom', updateZoom);
            }
        };
    }, [data, config]);

    useEffect(handleResize, [config.legend]);

    const Legend = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance() as EChartsInstance;
        const colors = echartsInstance.getOption().color;
        const series = echartsInstance.getOption().series;

        let colorIndex = 0;

        return (
            <ul className="legends">
                {Object.entries(properties ?? {})
                    .sort((a, b) => {
                        if (a[0] < b[0]) return -1;
                        if (a[0] > b[0]) return 1;
                        return 0;
                    })
                    .map((property) => {
                        let color;
                        const propertySeries = series.find((s) => s.name === property[0]);
                        if (propertySeries.color) color = propertySeries.color;
                        else {
                            color = colors[colorIndex % colors.length];
                            colorIndex += 1;
                        }

                        return (
                            <li
                                key={property[0]}
                                hidden={property[1] === STATE.CENTRE}
                                onMouseEnter={() =>
                                    echartsInstance.dispatchAction({ type: 'highlight', seriesName: property[0] })
                                }
                                onFocus={() =>
                                    echartsInstance.dispatchAction({ type: 'highlight', seriesName: property[0] })
                                }
                                onMouseLeave={() =>
                                    echartsInstance.dispatchAction({ type: 'downplay', seriesName: property[0] })
                                }
                                onBlur={() =>
                                    echartsInstance.dispatchAction({ type: 'downplay', seriesName: property[0] })
                                }
                                style={{ pointerEvents: 'all' }}
                            >
                                <i className="fas fa-circle" style={{ color }} />
                                {property[0]}
                            </li>
                        );
                    })}
            </ul>
        );
    };

    const setAxisMinMax = (side: STATE, min: number, max: number) => {
        const newAxes = props.config.axes
            ? [props.config.axes[0], props.config.axes[1]]
            : [{ invert: false }, { invert: false }];
        const newConfig = { axes: newAxes, ...props.config };
        newConfig.axes[side === STATE.LEFT ? 0 : 1].min = min;
        newConfig.axes[side === STATE.LEFT ? 0 : 1].max = max;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setAxisInvert = (side: STATE, invert: boolean) => {
        const newAxes = props.config.axes
            ? [props.config.axes[0], props.config.axes[1]]
            : [{ invert: false }, { invert: false }];
        const newConfig = { axes: newAxes, ...props.config };
        newConfig.axes[side === STATE.LEFT ? 0 : 1].invert = invert;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setDistanceMode = (distanceMode: boolean) => {
        const newConfig = { ...props.config, distanceMode };

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    return (
        <>
            <TabHeader
                left={
                    <>
                        <HeaderButton
                            popover={{
                                name: 'Toggle Properties',
                                icon: 'fas fa-chart-line',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            {Object.entries(properties ?? {})
                                                .sort((a, b) => {
                                                    if (a[0] < b[0]) return -1;
                                                    if (a[0] > b[0]) return 1;
                                                    return 0;
                                                })
                                                .map((property) => (
                                                    <TriState
                                                        key={property[0]}
                                                        title={property[0]}
                                                        state={property[1]}
                                                        onChange={(v) => setPropertySide(v, property[0])}
                                                    />
                                                ))}
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                        />
                        <HeaderButton
                            popover={{
                                name: 'Axis Settings',
                                icon: 'fas fa-axe',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            <Title title="X Axis" />
                                            <Toggle
                                                checked={config.distanceMode}
                                                title="Use Distance"
                                                onChange={(v: boolean) => setDistanceMode(v)}
                                            />
                                            <Title title="Left Axis" />
                                            <Button
                                                title="Reset"
                                                icon="fas fa-arrow-rotate-left"
                                                onClick={() => {
                                                    setAxisMinMax(STATE.LEFT, undefined, undefined);
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[0].min : undefined}
                                                step={1}
                                                title="Min"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(
                                                        STATE.LEFT,
                                                        v,
                                                        config.axes ? config.axes[0].max : undefined
                                                    );
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[0].max : undefined}
                                                step={1}
                                                title="Max"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(
                                                        STATE.LEFT,
                                                        config.axes ? config.axes[0].min : undefined,
                                                        v
                                                    );
                                                }}
                                            />
                                            <Toggle
                                                checked={config.axes ? config.axes[0].invert : false}
                                                title="Invert"
                                                onChange={(checked) => setAxisInvert(STATE.LEFT, checked)}
                                            />
                                            <hr />
                                            <Title title="Right Axis" />
                                            <Button
                                                title="Reset"
                                                icon="fas fa-arrow-rotate-left"
                                                onClick={() => {
                                                    setAxisMinMax(STATE.RIGHT, undefined, undefined);
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[1].min : undefined}
                                                step={1}
                                                title="Min"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(
                                                        STATE.RIGHT,
                                                        v,
                                                        config.axes ? config.axes[1].max : undefined
                                                    );
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[1].max : undefined}
                                                step={1}
                                                title="Max"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(
                                                        STATE.RIGHT,
                                                        config.axes ? config.axes[1].min : undefined,
                                                        v
                                                    );
                                                }}
                                            />
                                            <Toggle
                                                checked={config.axes ? config.axes[1].invert : false}
                                                title="Invert"
                                                onChange={(checked) => setAxisInvert(STATE.RIGHT, checked)}
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                        />
                        <HeaderButton
                            toggle={{
                                name: 'Show Legend',
                                icon: 'fas fa-list-ul',
                                checked: config.legend,
                                onChange: (v) => setLegend(v),
                            }}
                        />
                    </>
                }
                center={undefined}
                right={undefined}
            />
            <div className="chartContainer" ref={containerRef}>
                {config.legend ? <Legend /> : undefined}
                <ReactECharts ref={chartRef} option={options} style={{ height: '100%', flexGrow: '1' }} />
            </div>
        </>
    );
};
export default ChartMenu;
