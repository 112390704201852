import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import { Formik, Form } from 'formik';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BaseInput from 'components/forms/BaseInput';
import Select from 'components/forms/Select';

import { getOrganizationsForCreatingCollection } from '../../redux/selectors';
import handleApiError from '../../services/Forms';

import { updateCollection } from '../../redux/actions';

const EditCollection = ({ onClose, openCollection }) => {
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(getOrganizationsForCreatingCollection);

    const initialFormState = {
        name: openCollection.name,
        organization_id: {
            value: openCollection.organization_id,
            label: organizations.find((o) => o.id === openCollection.organization_id).display_name,
        },
        startDate: openCollection.start_date,
        endDate: openCollection.end_date,
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    useEffect(() => {
        setCurrentFormState(initialFormState);
    }, [organizations]);

    const validation = (values) => {
        const errors: { name?: string; organization_id?: string } = {};
        if (!values.name) errors.name = 'Required';
        if (!values.organization_id) errors.organization_id = 'Required';
        return errors;
    };

    const editCollection = (values, helpers) => {
        const data: { name: string; organization_id: string; start_date?: string; end_date?: string } = {
            name: values.name,
            organization_id: values.organization_id.value,
        };

        if (values.startDate) data.start_date = values.startDate;
        if (values.endDate) data.end_date = values.endDate;

        dispatch(updateCollection(openCollection.id, data))
            .then(() => onClose())
            .catch((err) => {
                handleApiError(err, helpers);
                helpers.setSubmitting(false);
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={editCollection}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, errors, isSubmitting, setFieldValue }) => (
                <Form>
                    <ModalHeader toggle={onClose}>Edit collection {openCollection.name}</ModalHeader>
                    <ModalBody>
                        <BaseInput name="name" label="Name" titled />
                        <Select
                            name="organization"
                            placeholder="Organization"
                            options={[
                                ...organizations.map((e) => ({
                                    value: e.id,
                                    label: e.display_name,
                                })),
                            ]}
                            value={values.organization_id}
                            setFieldValue={setFieldValue}
                        />
                        <div className="form-group date-range">
                            Start Date
                            <input
                                name="startDate"
                                type="date"
                                value={values.startDate}
                                max={values.endDate}
                                onChange={(e) => setFieldValue('startDate', e.target.value)}
                            />
                            End Date
                            <input
                                name="endDate"
                                type="date"
                                value={values.endDate}
                                min={values.startDate}
                                onChange={(e) => setFieldValue('endDate', e.target.value)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <span className="error-text">{errors.submit}</span>
                        <span className="error-text">{errors.start_date}</span>
                        <span className="error-text">{errors.end_date}</span>
                        <button type="submit" className="pane-button large highlight" disabled={isSubmitting}>
                            Apply <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default EditCollection;
