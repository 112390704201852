export type DialSegment = {
    icon: string;
    title: string;
    action?: () => void;
    id?: string;
};
type DialProps = {
    buttons: Array<DialSegment | undefined>;
};

const Segment = ({ icon, title, action, id, index }) => {
    if (icon === undefined || title === undefined) return null;
    return (
        <div className={`segmentwrapper segment${index} ${action === undefined ? 'nohover' : ''}`}>
            <button type="button" className="segment" onClick={action} title={title} disabled={action === undefined}>
                <div className="inner">
                    <div>
                        <i className={`fal ${icon}`} id={id} />
                    </div>
                </div>
            </button>
            <div className="outer">
                <div>
                    <label htmlFor={id}>{title}</label>
                </div>
            </div>
        </div>
    );
};

const Dial = ({ buttons }: DialProps) => {
    const segments = [];
    for (let i = 0; i < 8; i++) {
        segments.push(
            <Segment
                icon={buttons[i]?.icon}
                title={buttons[i]?.title}
                action={buttons[i]?.action}
                id={buttons[i]?.id}
                index={i}
                key={`dial-segment-${i}`}
            />
        );
    }

    return (
        <div className="squarewrapper">
            <div className="square">
                <div className="csscircle circle8">{segments}</div>
            </div>
        </div>
    );
};

export default Dial;
