import { useDispatch } from 'react-redux';
import { HasOpacity, LayerState } from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Slider from 'components/dropdown/Slider';

export type Props = { layer: LayerState & HasOpacity };

function OpacitySetting(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();

    const opacity = useAppSelector(layersSlice.getOpacity(layer));

    function changeLayerOpacity(percentage: number) {
        const value = Number.isFinite(percentage) ? percentage / 100 : 0;
        dispatch(layersSlice.setOpacity({ layer, value }));
    }

    return (
        <>
            <Slider
                title="Opacity"
                icon="fas fa-eye-slash"
                min={0}
                max={100}
                step={1}
                value={opacity * 100}
                onChange={(v) => changeLayerOpacity(v)}
                unit="%"
            />
            <hr />
        </>
    );
}

export default OpacitySetting;
