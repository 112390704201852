import * as THREE from 'three';
import CameraControls from 'camera-controls';
import type Instance from '@giro3d/giro3d/core/Instance';
import Controls, { GetBoundingBoxCallback } from './Controls';
import GetPointAtCallback from './GetPointAtCallback';

const tmpbox3Extent = new THREE.Box3();
const keys = { LEFT: 'ArrowLeft', UP: 'ArrowUp', RIGHT: 'ArrowRight', BOTTOM: 'ArrowDown' };

CameraControls.install({ THREE });

/**
 * Object to handle camera in the view
 */
class SeismicControls extends Controls {
    /**
     * Constructor
     * @param instance Giro3D Instance object
     * @param getPointAt Callback to get the point coordinates from a mouse event
     * @param getBoundingBox Callback to get the bounding box to look at
     */
    constructor(instance: Instance, getPointAt: GetPointAtCallback, getBoundingBox: GetBoundingBoxCallback) {
        super(instance, getPointAt, getBoundingBox);

        this.cameraControls.minZoom = 0; // Unlimited zoom

        // The following is a unqiue 'mode' and replaces setMode

        // Do some clean-up
        this._unregisterEventListeners();

        // Register new interactions
        // We try to use native camera-controls interactions, but we have to re-implement some
        // (keyboard, monkey-patching for mousewheel, etc.)
        this.cameraControls.smoothTime = 0.25;
        this.cameraControls.draggingSmoothTime = 0.01; // Setting this to 0 sometimes traps the camera

        this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
        this.cameraControls.mouseButtons.right = CameraControls.ACTION.NONE;
        this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        this.cameraControls.mouseButtons.middle = CameraControls.ACTION.NONE;

        this._viewportEventsHandlers.wheel = this.onWheel.bind(this);
        this._viewportEventsHandlers.keydown = this.onKeyDownPan.bind(this);

        this._registerEventListeners();
    }

    /**
     * Sets mode for the controls
     * Disabled as minimap cannot change controls
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
    setMode() {}

    /**
     * Event handler to handle Panning on key stroke
     * @param {KeyboardEvent} e Event
     */
    onKeyDownPan(e: KeyboardEvent) {
        let verticalDirection = 0;
        let truckDirectionX = 0;
        switch (e.code) {
            case keys.UP:
                verticalDirection = 1;
                break;

            case keys.BOTTOM:
                verticalDirection = -1;
                break;

            case keys.LEFT:
                truckDirectionX = -1;
                break;

            case keys.RIGHT:
                truckDirectionX = 1;
                break;

            default:
            // do nothing
        }
        if (verticalDirection) this._exec(() => this.cameraControls.elevate(verticalDirection, true));
        if (truckDirectionX) this._exec(() => this.cameraControls.truck(truckDirectionX, 0, true));
    }

    /**
     * Moves camera to look at a specific object.
     * If Orbit mode, that position becomes the new pivot point
     * @param {THREE.Vector3} lookAt Position to look at
     * @param {boolean} enableTransition Enables transition
     */
    moveTo(lookAt: THREE.Vector3, enableTransition = false) {
        const bbox = this.getBoundingBox();

        if (!bbox) {
            return;
        }

        this._exec(() => {
            this.setInteractionPoint(lookAt);
            return this.cameraControls.moveTo(
                lookAt.x,
                lookAt.y,
                tmpbox3Extent.max.z,
                enableTransition && this._canTransition()
            );
        });
    }

    /**
     * Place camera to look at a specific object
     * @param {Vector3} position
     * @param {Vector3} lookAt
     * @param {boolean} enableTransition
     * @returns {Promise<void>}
     */
    lookAt(position: THREE.Vector3, lookAt: THREE.Vector3, enableTransition = false): Promise<void> {
        const bbox = this.getBoundingBox();

        if (!bbox) {
            return Promise.resolve();
        }

        const promise = this.cameraControls.setLookAt(
            position.x,
            position.y,
            position.z,
            lookAt.x,
            lookAt.y,
            lookAt.z,
            enableTransition && this._canTransition()
        );
        this.clock.getDelta();
        this.cameraControls.dispatchEvent({ type: 'update' });

        return promise;
    }
}

export default SeismicControls;
