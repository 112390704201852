// React
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader, Popover, PopoverBody } from 'reactstrap';

import { SourceFile } from 'types/SourceFile';

// Redux
import { useAppSelector } from 'store';
import { LAYER_DATA_TYPES, LAYER_STATES, SOURCE_FILE_STATES, ORDER_MODE } from 'services/Constants';
import { supportedTypesLabels, supportedDataTypesLabels } from 'services/Labels';
import { useEventBus } from 'EventBus';
import { setClickedDataset, pollForDatasets, pollForSourcefiles } from 'redux/actions';
import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

// API
import DosApi from 'services/DosApi';

// Components
import ToggleSwitch from 'components/ToggleSwitch';
import useInterval from 'services/useInterval';
import { canToggleSourceFiles, hasAttributes, hasSeismicPlane } from 'types/LayerState';
import { DatasetId } from 'types/common';
import { organizeLines, groupName } from 'services/seismicSorter';
import Select, { Option } from 'components/Select';
import ToleranceInput from 'components/dropdown/ToleranceInput';
import TextInput from 'components/dropdown/TextInput';
import ErrorBoundary from '../../../ErrorBoundary';
import SourceFileListItem from './SourceFileListItem';
import DatasetSettingsPopover from '../DatasetSettingsPopover';
import MosaicFileOutlineSetting from '../datasetSettings/MosaicSettings';

import UploadFilesModalForm from '../../forms/UploadFilesModalForm';

import SourceFileTable from '../SourceFileTable';
import MosaicOverviewTable from '../MosaicOverviewTable';
import InlineDropMenu from '../../../InlineDropMenu';
import RemoveDataset from '../RemoveDataset';
import DatasetIndicator from '../DatasetIndicator';

type Props = {
    datasetId: DatasetId;
};

const InspectDatasetMenu = (props: Props) => {
    const dispatch = useDispatch();
    const eventBus = useEventBus();

    const datasetId = props.datasetId;
    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(datasetId), (l, r) => {
        const left = l.reduce((acc, value) => {
            if (acc[value.state]) acc[value.state] += 1;
            else acc[value.state] = 1;
            return acc;
        }, {});
        const right = r.reduce((acc, value) => {
            if (acc[value.state]) acc[value.state] += 1;
            else acc[value.state] = 1;
            return acc;
        }, {});
        return JSON.stringify(left) === JSON.stringify(right);
    });
    const uploading = useAppSelector(datasetsSlice.getUploading(datasetId));

    const layerState = useAppSelector(layersSlice.get(datasetId));
    const isVisible = useAppSelector(layersSlice.isVisibleSelf(layerState));
    const disabled = dataset.state !== LAYER_STATES.LOADED && dataset.state !== LAYER_STATES.ACTIVE;
    const canToggleSourceFile = canToggleSourceFiles(layerState);

    const [settingsOpen, setSettingsOpen] = useState(false);

    const inlineDirection = hasSeismicPlane(layerState)
        ? useAppSelector(layersSlice.getInlineDirection(layerState))
        : 0;
    const crosslineDirection = hasSeismicPlane(layerState)
        ? useAppSelector(layersSlice.getCrosslineDirection(layerState))
        : 0;
    const inlineTolerance = hasSeismicPlane(layerState)
        ? useAppSelector(layersSlice.getInlineTolerance(layerState))
        : 0;
    const crosslineTolerance = hasSeismicPlane(layerState)
        ? useAppSelector(layersSlice.getCrosslineTolerance(layerState))
        : 0;
    const inlineTerm = hasSeismicPlane(layerState) ? useAppSelector(layersSlice.getInlineTerm(layerState)) : '';
    const crosslineTerm = hasSeismicPlane(layerState) ? useAppSelector(layersSlice.getCrosslineTerm(layerState)) : '';
    const lineOrder = hasSeismicPlane(layerState)
        ? useAppSelector(layersSlice.getLineOrderMode(layerState))
        : ORDER_MODE.FILE;

    const [manageFilesModal, setManageFilesModal] = useState(false);
    const [manageOverviewModal, setManageOverviewModal] = useState(false);
    const [uploadFilesModal, setUploadFilesModal] = useState(false);
    const [seismicSettingsOpen, setSeismicSettingsOpen] = useState(false);

    const [attriubtesVisible, setAttributesVisible] = useState(false);

    const toggleUploadFilesModal = () => {
        setUploadFilesModal(!uploadFilesModal);
    };
    const toggleManageFilesModal = () => {
        setManageFilesModal(!manageFilesModal);
    };
    const toggleManageOverviewModal = () => {
        setManageOverviewModal(!manageOverviewModal);
    };
    const toggleSeismicSettings = () => {
        setSeismicSettingsOpen(!seismicSettingsOpen);
    };
    const onDatasetFileUploadClose = () => {
        toggleUploadFilesModal();
        dispatch(pollForDatasets([dataset]));
    };
    const selectDataset = () => {
        dispatch(setClickedDataset(datasetId));
        eventBus.dispatch('highlight-layer', { layer: datasetId });
    };

    const onEditSourceFile = (sourceFile: SourceFile) => {
        dispatch(setClickedDataset(datasetId, sourceFile.id));
        toggleManageFilesModal();
    };

    const setVisible = (value: boolean) => {
        dispatch(layersSlice.setVisibility({ layer: layerState, value }));
    };

    function goToLayer() {
        eventBus.dispatch('go-to-layer', { layer: layerState.datasetId });
    }

    useEffect(() => {
        if (datasetId && !sourcefiles)
            DosApi.fetchDatasetSourcefiles(datasetId)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                })
                .catch((err) => console.log(err));
    }, [datasetId, sourcefiles]);

    useInterval(() => {
        const sourcefilesInProcess = sourcefiles.filter((s) =>
            [
                SOURCE_FILE_STATES.CONVERTING,
                SOURCE_FILE_STATES.DELETING,
                SOURCE_FILE_STATES.UPLOADING,
                SOURCE_FILE_STATES.INGESTING,
                SOURCE_FILE_STATES.INGESTED,
                SOURCE_FILE_STATES.VALIDATING,
            ].includes(s.state)
        );
        if (sourcefilesInProcess.length !== 0) dispatch(pollForSourcefiles(dataset.id, sourcefilesInProcess));
    }, 5000);

    if (dataset === null) return <div className="tabContent">No dataset selected</div>;

    const SeismicLineGroups = () => {
        const organizedLines =
            lineOrder === ORDER_MODE.LINE
                ? organizeLines(sourcefiles, inlineDirection, crosslineDirection, inlineTolerance, crosslineTolerance)
                : groupName(sourcefiles, inlineTerm, crosslineTerm);

        const [inlineOpen, setInlineOpen] = useState(true);
        const [crosslineOpen, setCrosslineOpen] = useState(true);
        const [otherOpen, setOtherOpen] = useState(true);

        return (
            <>
                <li>
                    <div className="dropdown">
                        <label htmlFor="inline-dropdown">
                            <span>Inline ({organizedLines.inline.length})</span>
                            <input
                                type="checkbox"
                                id="inline-dropdown"
                                onChange={(e) => setInlineOpen(e.target.checked)}
                                checked={inlineOpen}
                            />
                        </label>
                    </div>
                    <ul>
                        {inlineOpen
                            ? organizedLines.inline?.map((sourceFile) => (
                                  <SourceFileListItem
                                      key={sourceFile.id}
                                      sourceFile={sourceFile}
                                      datasetId={datasetId}
                                      onEditSourceFile={onEditSourceFile}
                                      canToggleVisibility={canToggleSourceFile}
                                  />
                              ))
                            : null}
                    </ul>
                </li>
                <li>
                    <div className="dropdown">
                        <label htmlFor="crossline-dropdown">
                            <span>Crossline ({organizedLines.crossline.length})</span>
                            <input
                                type="checkbox"
                                id="crossline-dropdown"
                                onChange={(e) => setCrosslineOpen(e.target.checked)}
                                checked={crosslineOpen}
                            />
                        </label>
                    </div>
                    <ul>
                        {crosslineOpen
                            ? organizedLines.crossline?.map((sourceFile) => (
                                  <SourceFileListItem
                                      key={sourceFile.id}
                                      sourceFile={sourceFile}
                                      datasetId={datasetId}
                                      onEditSourceFile={onEditSourceFile}
                                      canToggleVisibility={canToggleSourceFile}
                                  />
                              ))
                            : null}
                    </ul>
                </li>
                <li>
                    <div className="dropdown">
                        <label htmlFor="other-dropdown">
                            <span>Other ({organizedLines.other.length})</span>
                            <input
                                type="checkbox"
                                id="other-dropdown"
                                onChange={(e) => setOtherOpen(e.target.checked)}
                                checked={otherOpen}
                            />
                        </label>
                    </div>
                    <ul>
                        {otherOpen
                            ? organizedLines.other?.map((sourceFile) => (
                                  <SourceFileListItem
                                      key={sourceFile.id}
                                      sourceFile={sourceFile}
                                      datasetId={datasetId}
                                      onEditSourceFile={onEditSourceFile}
                                      canToggleVisibility={canToggleSourceFile}
                                  />
                              ))
                            : null}
                    </ul>
                </li>
            </>
        );
    };
    const orderOptions = [
        { label: 'None', value: ORDER_MODE.FILE },
        { label: 'Line Direction', value: ORDER_MODE.LINE },
        { label: 'File Name', value: ORDER_MODE.NAME },
    ];

    return (
        <div className="tabContent fixedHeight">
            <div className="inspector-title">
                <span>{dataset.name}</span>
                <div className="grow" />
                <DatasetIndicator id={datasetId} dataset={dataset} />
                <InlineDropMenu id={`dataset-inspect-actions-${datasetId}`}>
                    {dataset.user_permissions.update_dataset && <RemoveDataset id={datasetId} dataset={dataset} />}
                    <Button
                        className="borderless yellow"
                        id={`dataset-inspect-goToLayer-${datasetId}`}
                        title={`${dataset.name} settings`}
                        onClick={() => {
                            goToLayer();
                        }}
                        disabled={dataset.state !== LAYER_STATES.LOADED}
                    >
                        <i className="fas fa-location-arrow" />
                    </Button>
                    <Button
                        className="borderless yellow"
                        id={`dataset-inspect-settings-${datasetId}`}
                        title={`${dataset.name} settings`}
                        onClick={() => {
                            selectDataset();
                            setSettingsOpen(true);
                        }}
                        disabled={dataset.state !== LAYER_STATES.LOADED}
                    >
                        <i className="fas fa-gear" />
                    </Button>
                </InlineDropMenu>
                <ToggleSwitch
                    id={`dataset-inspect-visible-${datasetId}`}
                    checked={isVisible}
                    onChange={(e) => setVisible(e.target.checked)}
                    disabled={disabled}
                />
            </div>
            <ErrorBoundary
                dispatch={dispatch}
                fallback={
                    <span className="error-fallback-message">
                        <i className="fal fa-exclamation-triangle icon-red" />
                        An error occured in the sourcefile list.
                        <i className="fal fa-exclamation-triangle icon-red" />
                    </span>
                }
            >
                <div className="scrollable">
                    {/* Dataset info */}
                    <div>Metadata</div>
                    <table>
                        <tbody>
                            {/* <tr>
                            <td>Owner</td>
                            <td>
                                {organizations
                                    ? organizations.find((e) => e.id === dataset.organization_id)
                                        ?.display_name
                                    : 'Loading...'}
                            </td>
                        </tr> */}
                            <tr>
                                <td>Projection</td>
                                <td colSpan={2}>EPSG:{dataset.projection}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td colSpan={2}>{supportedTypesLabels[dataset.type]}</td>
                            </tr>
                            <tr>
                                <td>Data type</td>
                                <td colSpan={2}>{supportedDataTypesLabels[dataset.datatype]}</td>
                            </tr>
                            {hasAttributes(layerState) ? (
                                <>
                                    <tr>
                                        <td colSpan={3}>
                                            <button
                                                type="button"
                                                className="attributes-dropdown"
                                                onClick={() => setAttributesVisible(!attriubtesVisible)}
                                            >
                                                Attributes
                                                <i
                                                    className={`fas ${attriubtesVisible ? 'fa-chevron-down' : 'fa-chevron-up'}`}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                    {attriubtesVisible
                                        ? layerState.attributes.map((attribute) => (
                                              <tr key={attribute.id}>
                                                  <td>{attribute.name}</td>
                                                  <td>{attribute.min}</td>
                                                  <td>{attribute.max}</td>
                                              </tr>
                                          ))
                                        : null}
                                </>
                            ) : null}
                        </tbody>
                    </table>
                    {/* Files list */}
                    <div>
                        <div>Files</div>
                        <div className="buttonRow">
                            <Button
                                className="borderless light-blue"
                                id={`dataset-inspect-upload-${datasetId}`}
                                title="Upload files"
                                onClick={toggleUploadFilesModal}
                            >
                                <i className="fas fa-upload" />
                            </Button>
                            <Button
                                className="borderless green"
                                id={`dataset-inspect-manage-${datasetId}`}
                                title="Manage files"
                                onClick={toggleManageFilesModal}
                            >
                                <i className="fas fa-pen" />
                            </Button>
                            {dataset.datatype === LAYER_DATA_TYPES.MOSAIC ? (
                                <>
                                    <MosaicFileOutlineSetting dataset={dataset} />
                                    <Button
                                        className="borderless yellow"
                                        id={`dataset-inspect-overviews-${datasetId}`}
                                        title="Manage overviews"
                                        onClick={toggleManageOverviewModal}
                                    >
                                        <i className="fas fa-panorama" />
                                    </Button>
                                </>
                            ) : null}
                            {hasSeismicPlane(layerState) ? (
                                <>
                                    <div className="grow" />
                                    <label htmlFor="orderingOptions">Sort by: </label>
                                    <Select
                                        options={orderOptions}
                                        value={orderOptions.find((o) => o.value === lineOrder)}
                                        onChange={(v) => {
                                            dispatch(
                                                layersSlice.setLineOrder({
                                                    layer: layerState,
                                                    value: (v as Option<ORDER_MODE>).value,
                                                })
                                            );
                                        }}
                                        className="orderingOptions"
                                        id="orderingOptions"
                                    />
                                    <Button
                                        className="borderless light-blue"
                                        id={`seismic-settings-${datasetId}`}
                                        title="Seismic Settings"
                                        onClick={toggleSeismicSettings}
                                    >
                                        <i className="fas fa-gear" />
                                    </Button>
                                    <Popover
                                        isOpen={seismicSettingsOpen}
                                        toggle={toggleSeismicSettings}
                                        target={`seismic-settings-${datasetId}`}
                                        hideArrow
                                        trigger="legacy"
                                    >
                                        <PopoverBody>
                                            <ul>
                                                {lineOrder === ORDER_MODE.NAME ? (
                                                    <>
                                                        <TextInput
                                                            title="Inline Search Term"
                                                            value={inlineTerm}
                                                            onChange={(value) => {
                                                                if (hasSeismicPlane(layerState))
                                                                    dispatch(
                                                                        layersSlice.setInlineTerm({
                                                                            layer: layerState,
                                                                            value,
                                                                        })
                                                                    );
                                                            }}
                                                        />
                                                        <TextInput
                                                            title="Crossline Search Term"
                                                            value={crosslineTerm}
                                                            onChange={(value) => {
                                                                if (hasSeismicPlane(layerState))
                                                                    dispatch(
                                                                        layersSlice.setCrosslineTerm({
                                                                            layer: layerState,
                                                                            value,
                                                                        })
                                                                    );
                                                            }}
                                                        />
                                                    </>
                                                ) : null}
                                                {lineOrder === ORDER_MODE.LINE ? (
                                                    <>
                                                        <ToleranceInput
                                                            title="Inline Direction"
                                                            value={inlineDirection}
                                                            min={0}
                                                            max={360}
                                                            step={1}
                                                            unit="°"
                                                            icon="fas fa-arrow-up-long"
                                                            onChange={(value) => {
                                                                if (hasSeismicPlane(layerState))
                                                                    dispatch(
                                                                        layersSlice.setInlineDirection({
                                                                            layer: layerState,
                                                                            value,
                                                                        })
                                                                    );
                                                            }}
                                                            iconRotation={inlineDirection}
                                                            tolerance={inlineTolerance}
                                                            minTolerance={0}
                                                            maxTolerance={90}
                                                            onChangeTolerance={(value) => {
                                                                if (hasSeismicPlane(layerState))
                                                                    dispatch(
                                                                        layersSlice.setInlineTolerance({
                                                                            layer: layerState,
                                                                            value,
                                                                        })
                                                                    );
                                                            }}
                                                        />
                                                        <ToleranceInput
                                                            title="Crossline Direction"
                                                            value={crosslineDirection}
                                                            min={0}
                                                            max={360}
                                                            step={1}
                                                            unit="°"
                                                            icon="fas fa-arrow-up-long"
                                                            onChange={(value) => {
                                                                if (hasSeismicPlane(layerState))
                                                                    dispatch(
                                                                        layersSlice.setCrosslineDirection({
                                                                            layer: layerState,
                                                                            value,
                                                                        })
                                                                    );
                                                            }}
                                                            iconRotation={crosslineDirection}
                                                            tolerance={crosslineTolerance}
                                                            minTolerance={0}
                                                            maxTolerance={90}
                                                            onChangeTolerance={(value) => {
                                                                if (hasSeismicPlane(layerState))
                                                                    dispatch(
                                                                        layersSlice.setCrosslineTolerance({
                                                                            layer: layerState,
                                                                            value,
                                                                        })
                                                                    );
                                                            }}
                                                        />
                                                    </>
                                                ) : null}
                                                {lineOrder === ORDER_MODE.FILE ? <li>No Settings</li> : null}
                                            </ul>
                                        </PopoverBody>
                                    </Popover>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <ul className="list">
                        {lineOrder !== ORDER_MODE.FILE ? (
                            <SeismicLineGroups />
                        ) : (
                            sourcefiles?.map((sourceFile) => (
                                <SourceFileListItem
                                    key={sourceFile.id}
                                    sourceFile={sourceFile}
                                    datasetId={datasetId}
                                    onEditSourceFile={onEditSourceFile}
                                    canToggleVisibility={canToggleSourceFile}
                                />
                            ))
                        )}
                        {sourcefiles?.length === 0 && 'No files yet'}
                    </ul>
                    {(uploading?.length ?? 0) !== 0 ? (
                        <>
                            <div>Uploading ({uploading?.length ?? 0})...</div>
                            <ul className="list">{uploading?.map((f) => <li key={f}>{f}</li>)}</ul>
                        </>
                    ) : null}
                </div>
            </ErrorBoundary>
            <DatasetSettingsPopover
                dataset={dataset}
                target={`dataset-inspect-actions-${datasetId}`}
                isOpen={settingsOpen}
                onToggle={() => setSettingsOpen(false)}
            />
            <Modal className="modal-lg" isOpen={manageFilesModal} toggle={toggleManageFilesModal}>
                <SourceFileTable dataset={dataset} />
            </Modal>
            <Modal className="modal-lg" isOpen={manageOverviewModal} toggle={toggleManageOverviewModal}>
                <ModalHeader>Manage {dataset.name} overviews</ModalHeader>
                <ModalBody>
                    <MosaicOverviewTable dataset={dataset} />
                </ModalBody>
            </Modal>

            <Modal isOpen={uploadFilesModal} toggle={toggleUploadFilesModal}>
                <UploadFilesModalForm dataset={dataset} onClose={onDatasetFileUploadClose} />
            </Modal>
        </div>
    );
};

export default InspectDatasetMenu;
