import React from 'react';
import { Form } from 'reactstrap';
import logo from '../../images/symbol.svg';
import DosApi from '../../services/DosApi';

export function Login() {
    const handleLogin = () => {
        DosApi.apiAcquireToken();
    };

    return (
        <div className="login-bg">
            <Form inline="true" className="login-form">
                <img src={logo} alt="Argeo logo" />
                <div className="login-title">SCOPE</div>
                <div className="login-footer">
                    <button type="button" onClick={() => handleLogin()}>
                        Log In
                    </button>
                </div>
            </Form>
        </div>
    );
}

export function Logout() {
    const handleLogout = () => {
        DosApi.logout();
    };

    return (
        <button type="button" onClick={() => handleLogout()}>
            Log Out
        </button>
    );
}
