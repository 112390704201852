import { nanoid } from '@reduxjs/toolkit';

export type Props = {
    value: number;
    tolerance: number;
    min?: number;
    max?: number;
    minTolerance?: number;
    maxTolerance?: number;
    step: number;
    stepTolerance?: number;
    title: string;
    icon?: string;
    unit?: string;
    onChange: (v: number) => void;
    onBlur?: (v: number) => void;
    onChangeTolerance: (t: number) => void;
    onBlurTolerance?: (t: number) => void;
    iconRotation?: number;
};

function ToleranceInput(props: Props) {
    const id = nanoid();
    return (
        <li>
            <label htmlFor={id} className="cursor">
                <div>
                    <i className={props.icon} style={{ rotate: `${props.iconRotation?.toString()}deg` }} />
                </div>
                <div>
                    <button type="button" id={id}>
                        {props.title}
                    </button>
                </div>
                <div>
                    <input
                        type="number"
                        value={props.value}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        onChange={(e) => props.onChange(e.target.valueAsNumber)}
                        onBlur={(e) => props.onBlur(e.currentTarget.valueAsNumber)}
                    />
                    ±
                    <input
                        type="number"
                        value={props.tolerance}
                        min={props.minTolerance}
                        max={props.maxTolerance}
                        step={props.stepTolerance ?? props.step}
                        onChange={(e) => props.onChangeTolerance(e.target.valueAsNumber)}
                        onBlur={(e) => props.onBlurTolerance(e.currentTarget.valueAsNumber)}
                    />
                    <span>{props.unit}</span>
                </div>
            </label>
        </li>
    );
}

export default ToleranceInput;
