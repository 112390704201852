// Components
import { HasSeismicPlane, LayerState } from 'types/LayerState';
import { useDispatch } from 'react-redux';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Slider from 'components/dropdown/Slider';
import Toggle from 'components/dropdown/Toggle';
import Input from 'components/dropdown/Input';
import Title from 'components/dropdown/Title';

export type Props = {
    layer: LayerState & HasSeismicPlane;
};

const Offset = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const offset = useAppSelector(layersSlice.getSeismicOffset(layer));
    function changeOffset(value: number) {
        dispatch(layersSlice.setSeismicOffset({ layer, value }));
    }

    const period = useAppSelector(layersSlice.getSpeedModuleMs(layer));
    function changePeriod(value: number) {
        dispatch(layersSlice.setSpeedModuleMs({ layer, value }));
    }

    const staticCorrection = useAppSelector(layersSlice.getStaticCorrection(layer));
    function changeStaticCorrection(value: boolean) {
        dispatch(layersSlice.setStaticCorrection({ layer, value }));
    }

    return (
        <>
            <Input
                title="Offset"
                icon="fas fa-up-down"
                value={offset}
                min={-9999}
                max={9999}
                step={1}
                onChange={(v) => changeOffset(v)}
                arrows
            />
            <Input
                title="Period"
                icon="fas fa-wave-pulse"
                value={period}
                min={0}
                max={2000}
                step={1}
                onChange={(v) => changePeriod(v)}
                unit="ms"
            />
            <Title title="This feature is experimental" experimental />
            <Toggle
                checked={staticCorrection}
                title="Static correction"
                onChange={(v) => changeStaticCorrection(v)}
                experimental
            />
        </>
    );
};

// TODO: reenable when giro3d support for layer clipping
// const SegyCrossSectionSetting = ({ giro3dlayer }) => {
//     const [viewCrossSection, setViewCrossSection] = useState(giro3dlayer.getIsViewingCrossSection());
//     const [invertCrossSection, setInvertCrossSection] = useState(giro3dlayer.getIsCrossSectionInverted());
//
//     const changeViewCrossSection = (value) => {
//         setViewCrossSection(value);
//         giro3dlayer.toggleCrossSection(value);
//     };
//
//     const changeInvertCrossSection = (value) => {
//         setInvertCrossSection(value);
//         giro3dlayer.toggleCrossSectionInverted(value);
//     };
//
//     return (
//         <div className="select-group">
//             <label htmlFor="segy_clip" className="col-sm-7 col-form-label">
//                 Cross-section
//                 <input
//                     id="segy_clip"
//                     type="checkbox"
//                     className="form-check-input"
//                     checked={viewCrossSection}
//                     onChange={(e) => changeViewCrossSection(e.target.checked)}
//                 />
//             </label>
//
//             <label htmlFor="segy_clip_invert" className="col-sm-5 col-form-label">
//                 Invert
//                 <input
//                     id="segy_clip_invert"
//                     type="checkbox"
//                     className="form-check-input"
//                     checked={invertCrossSection}
//                     onChange={(e) => changeInvertCrossSection(e.target.checked)}
//                 />
//             </label>
//         </div>
//     );
// };

const IntensityFilter = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();
    const filter = useAppSelector(layersSlice.getIntensityFilter(layer));

    const changeFilter = (value: number) => {
        dispatch(layersSlice.setIntensityFilter({ layer, value }));
    };

    return (
        <Slider
            title="Intensity Filter"
            icon="fas fa-filter"
            min={0}
            max={100}
            step={1}
            value={Math.round(filter * 100)}
            onChange={(v) => changeFilter(v / 100)}
        />
    );
};

const FilterTransparency = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();
    const transparency = useAppSelector(layersSlice.getFilterTransparency(layer));

    const changeTransparency = (value: boolean) => {
        dispatch(layersSlice.setFilterTransparency({ layer, value }));
    };

    return <Toggle title="Filter Transparency" checked={transparency} onChange={(v) => changeTransparency(v)} />;
};

const SeismicSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;

    return (
        <>
            <IntensityFilter key={`datasetsettings-${id}-filter`} layer={layer} />
            <FilterTransparency key={`datasetsettings-${id}-filter-transparency`} layer={layer} />
            <Offset key={`datasetsettings-${id}-offset`} layer={layer} />
            <hr />
        </>
    );
};

export default SeismicSettings;
