import { fromBlob } from 'geotiff';
import { UppyFile } from '@uppy/core';
import JSZip from 'jszip';
import { LAYER_DATA_TYPES, LAYER_TYPES, PROCESSABLE_EXTENSIONS } from './Constants';

type parseResult = {
    name: string;
    datatype: LAYER_DATA_TYPES;
    type: LAYER_TYPES;
    projection: string;
    errors: {
        parse: string[];
        files: string;
    };
};

async function readBandsFromGeotiff(blob) {
    try {
        const image = await (await fromBlob(blob)).getImage();

        return image.getSamplesPerPixel();
    } catch (error) {
        console.error('Error reading GeoTIFF:', error);
        return undefined;
    }
}

async function extractExtensionsFromZip(f): Promise<string[]> {
    return new Promise((resolve, reject) => {
        JSZip.loadAsync(f).then(
            (zip) =>
                resolve(Object.values(zip.files).map((file) => file.name.substring(file.name.lastIndexOf('.') + 1))),
            (e) => reject(e.message)
        );
    });
}

async function determineDatatype(file: File, extension: string): Promise<LAYER_DATA_TYPES> {
    return new Promise((resolve) => {
        switch (extension) {
            case 'tif':
            case 'tiff':
                resolve(
                    readBandsFromGeotiff(file).then((bands) =>
                        bands === 1 ? LAYER_DATA_TYPES.SINGLEBANDCOG : LAYER_DATA_TYPES.MULTIBANDCOG
                    )
                );
                break;
            case 'h5':
            case 'hdf5':
            case 'json':
            case 'geojson':
                resolve(LAYER_DATA_TYPES.VECTOR);
                break;
            case 'las':
                resolve(LAYER_DATA_TYPES.LAS);
                break;
            case 'xyz':
                resolve(LAYER_DATA_TYPES.POINTCLOUD);
                break;
            case 'sgy':
            case 'segy':
                resolve(LAYER_DATA_TYPES.SEGY);
                break;
            case 'zip': {
                resolve(
                    extractExtensionsFromZip(file).then((extensions) => {
                        if (extensions.includes('pnts')) return LAYER_DATA_TYPES.POINTCLOUD;
                        if (extensions.includes('fbx')) return LAYER_DATA_TYPES.OBJECT3D;
                        if (extensions.includes('shx')) return LAYER_DATA_TYPES.VECTOR;
                        return undefined;
                    })
                );
                break;
            }
            default:
                resolve(undefined);
        }
    });
}

function determineType(datatype: LAYER_DATA_TYPES): Promise<LAYER_TYPES> {
    return new Promise((resolve) => {
        switch (datatype) {
            case LAYER_DATA_TYPES.SEGY:
                resolve(LAYER_TYPES.SEISMIC);
                break;
            case LAYER_DATA_TYPES.LAS:
                resolve(LAYER_TYPES.BOREHOLE);
                break;
            default:
                resolve(undefined);
        }
    });
}

async function readCRSFromGeotiff(blob): Promise<string> {
    return new Promise((resolve, reject) => {
        fromBlob(blob)
            .then((tiff) =>
                tiff.getImage().then((image) => {
                    const geoKeys = image.getGeoKeys();
                    // Check if the projection keys exist and return undefined if not found
                    if (geoKeys.ProjectedCSTypeGeoKey || geoKeys.GeographicTypeGeoKey) {
                        resolve(String(geoKeys.ProjectedCSTypeGeoKey || geoKeys.GeographicTypeGeoKey));
                    }
                    resolve(undefined); // Return undefined if no projection is found
                })
            )
            .catch((e) => reject(new Error(`Geotiff error: ${e.message}`)));
    });
}

function determineProjection(file: File, extension: string): Promise<string> {
    return new Promise((resolve, reject) => {
        switch (extension) {
            case 'geojson': {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = (event) => {
                    if (event.currentTarget instanceof FileReader) {
                        const result = String(event.currentTarget.result);
                        const crsProperty = JSON.parse(result).crs?.properties?.name;
                        if (crsProperty) {
                            const parts = crsProperty.split(':');
                            resolve(parts[parts.length - 1]);
                        }
                    }
                    resolve('3857'); // GeoJson standard is 3857, defined CRS is an obselete feature.
                };
                break;
            }
            case 'tiff':
            case 'tif':
                readCRSFromGeotiff(file)
                    .then((crs) => {
                        resolve(crs);
                    })
                    .catch((e) => {
                        reject(e);
                    });
                break;
            default:
                resolve(undefined);
        }
    });
}

async function parseFiles(files: UppyFile[]): Promise<parseResult> {
    const result: parseResult = {
        name: undefined,
        datatype: undefined,
        type: undefined,
        projection: undefined,
        errors: {
            parse: [],
            files: undefined,
        },
    };
    try {
        const file = files[0].data as File;

        const lastDotIndex = file.name.lastIndexOf('.');

        result.name = lastDotIndex !== -1 ? file.name.substring(0, lastDotIndex) : file.name;

        await determineDatatype(file, files[0].extension)
            .then((datatype) => {
                result.datatype = datatype;
            })
            .catch((e) => result.errors.parse.push(`Datatype error: ${e.message}`));
        await determineType(result.datatype)
            .then((type) => {
                result.type = type;
            })
            .catch((e) => result.errors.parse.push(`Type error: ${e.message}`));
        await determineProjection(file, files[0].extension)
            .then((projection) => {
                result.projection = projection;
            })
            .catch((e) => result.errors.parse.push(`Projection error: ${e.message}`));
    } catch (e) {
        result.errors.parse.push(e);
    }

    const extensions = [...new Set(files.map((file) => file.extension))];
    if (extensions.length !== 1) result.errors.files = 'Files must have the same extension';
    else if (!PROCESSABLE_EXTENSIONS.includes(extensions[0]))
        result.errors.files = `File is not processable: ${extensions[0]}`;

    return result;
}

export default parseFiles;
