// React
import { useDispatch, useSelector } from 'react-redux';
import * as graphicsSettingsSlice from 'redux/graphicsSettings';

// Components
import { PopoverBody } from 'reactstrap';
import HeaderButton from 'components/flexLayout/HeaderButton';
import Toggle from 'components/dropdown/Toggle';

function PointCloudSettings() {
    const dispatch = useDispatch();

    const enableEDL = useSelector(graphicsSettingsSlice.isEyeDomeLightingEnabled);
    const enableInpainting = useSelector(graphicsSettingsSlice.isInpaintingEnabled);

    return (
        <HeaderButton
            popover={{
                name: 'Pointcloud Settings',
                icon: 'fas fa-cloud',
                content: (
                    <PopoverBody>
                        <ul>
                            <Toggle
                                title="Eye dome lighting"
                                checked={enableEDL}
                                onChange={(v) => dispatch(graphicsSettingsSlice.enableEyeDomeLighting(v))}
                            />
                            <Toggle
                                title="Inpainting"
                                checked={enableInpainting}
                                onChange={(v) => dispatch(graphicsSettingsSlice.enableInpainting(v))}
                            />
                        </ul>
                    </PopoverBody>
                ),
            }}
        />
    );
}

export default PointCloudSettings;
