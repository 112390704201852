import { shallowEqual } from 'react-redux';
import * as layersSlice from 'redux/layers';
import {
    HasAttributes,
    HasColoringMode,
    HasOverlayColor,
    LayerState,
    hasAttributes,
    hasColoringMode,
} from 'types/LayerState';
import { useAppSelector } from 'store';
import PinnedLegendItem from './PinnedLegendItem';

type PinnableLayer = LayerState & HasAttributes & HasOverlayColor & HasColoringMode;

const PinnedLegendList = () => {
    function shouldDisplay(layer: LayerState): layer is PinnableLayer {
        if (!layer.visible) {
            return false;
        }

        if (!hasColoringMode(layer)) {
            return false;
        }

        if (hasAttributes(layer)) {
            return layer.pinLegend;
        }

        return false;
    }

    const list = useAppSelector(
        layersSlice.filter<PinnableLayer>((l) => shouldDisplay(l)),
        shallowEqual
    );

    return (
        <ul className="tabContent">
            {list.map((layer) => (
                <PinnedLegendItem key={layer.datasetId} layer={layer} />
            ))}
        </ul>
    );
};

export default PinnedLegendList;
