// eslint-disable-next-line import/no-cycle
import { VERSION_INFO } from '../actionTypes';

const initialState = {
    branch: '...',
    hash: '...',
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case VERSION_INFO:
            return {
                ...state,
                branch: payload.branch,
                hash: payload.hash,
                version: payload.version,
            };
        default:
            return state;
    }
}
