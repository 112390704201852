import { DatasetId } from 'types/common';
import { Color } from 'three';

function getDatasetColor(id: DatasetId): Color {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        hash = (hash << 5) - hash + id.charCodeAt(i);
        hash |= 0; // Convert to 32-bit integer
    }
    const hue = (hash >>> 0) / 4294967295;

    return new Color().setHSL(hue, 0.75, 0.75, 'srgb');
}

export function getDatasetColorHex(id: DatasetId): number {
    return getDatasetColor(id).getHex();
}

export function getDatasetColorHexString(id: DatasetId): string {
    return getDatasetColor(id).getHexString();
}
