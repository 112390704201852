import { createContext, useContext } from 'react';
import giro3dService, { Giro3dService } from '../../services/Giro3dService';

const Giro3DContext = createContext(null);

export function useGiro3DContext(): Giro3dService {
    return useContext(Giro3DContext);
}

export default function Giro3DProvider({ children }) {
    return <Giro3DContext.Provider value={giro3dService}>{children}</Giro3DContext.Provider>;
}
