import React from 'react';

import DosApi from '../../services/DosApi';

import Crud from '../forms/Crud';
import BaseInput from '../forms/BaseInput';

const Organizations = () => (
    <Crud
        title="Organizations"
        createTitle="Add new organization"
        editTitle="Edit organization"
        fieldName="display_name"
        fetchService={DosApi.fetchOrganizations}
        createService={DosApi.createOrganization}
        updateService={DosApi.updateOrganization}
        // deleteService={DosApi.deleteOrganization} // disable for now until we figure out how to manage deletion (cascading? delete data from datasets as well? etc.)
    >
        <BaseInput name="display_name" label="Display name" titled />
        <BaseInput name="official_name" label="Official name" titled />
        <BaseInput name="organization_number" label="Organization number" titled />
        <BaseInput name="tenant_id" label="Azure AD tenant ID" titled />
    </Crud>
);

export default Organizations;
