import { Button } from 'reactstrap';
import { editAnnotationState } from 'redux/actions';
import { useAppDispatch } from 'store';
import Annotation, { AnnotationStatus } from 'types/Annotation';

type Props = {
    annotation: Annotation;
    corner?: boolean;
};

function statusColor(status: AnnotationStatus): string {
    switch (status) {
        case 'archived':
            return 'red';
        case 'in_progress':
            return 'yellow';
        case 'complete':
            return 'green';
        default:
            return '';
    }
}

function statusIcon(status: AnnotationStatus): string {
    switch (status) {
        case 'archived':
            return 'fas fa-circle-minus';
        case 'in_progress':
            return 'fas fa-circle-pause';
        case 'complete':
            return 'fas fa-circle-check';
        default:
            return '';
    }
}

const Status = (props: Props) => {
    const dispatch = useAppDispatch();

    const status = props.annotation.status;

    return (
        <div className="centre-drop-menu">
            <Button className={`borderless ${statusColor(status)} full-color`} id="annotation-status" title="Status">
                <i className={statusIcon(status)} />
            </Button>
            {props.annotation.user_permissions.manage ? (
                <div className={`centre-drop-content ${props.corner ? 'top-right' : ''}`} style={{ width: '7rem' }}>
                    <Button
                        className={`borderless yellow full-color ${status === 'in_progress' ? 'fw-bold' : ''}`}
                        onClick={() => dispatch(editAnnotationState(props.annotation, 'in_progress'))}
                    >
                        In progress
                    </Button>
                    <hr className="centre-drop-menu-divider " />
                    <Button
                        className={`borderless green full-color ${status === 'complete' ? 'fw-bold' : ''}`}
                        onClick={() => dispatch(editAnnotationState(props.annotation, 'complete'))}
                    >
                        Completed
                    </Button>
                    <hr className="centre-drop-menu-divider" />
                    <Button
                        className={`borderless red full-color ${status === 'archived' ? 'fw-bold' : ''}`}
                        onClick={() => dispatch(editAnnotationState(props.annotation, 'archived'))}
                    >
                        Archived
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default Status;
