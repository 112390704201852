import { Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getHelpMessage } from '../redux/selectors';
import { HELP_HIDE } from '../redux/actionTypes';

const Help = () => {
    const dispatch = useDispatch();
    const help = useSelector(getHelpMessage);

    const hide = () => dispatch({ type: HELP_HIDE });

    return (
        <div className="argeo_help_container">
            <Alert className="argeo_help alert-dismissible" isOpen={!!help} color="dark">
                {help}
                <button type="button" className="btn-close" aria-label="Close" onClick={hide} />
            </Alert>
        </div>
    );
};

export default Help;
