// eslint-disable-next-line import/no-cycle
import { ERROR_SHOW, ERROR_HIDE } from '../actionTypes';

function errorReducer(state = { message: null }, action) {
    const { type, payload } = action;

    switch (type) {
        case ERROR_SHOW:
            return {
                ...state,
                error: payload,
            };
        case ERROR_HIDE:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export default errorReducer;
