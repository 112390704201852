import * as THREE from 'three';
import { GlobalCache } from '@giro3d/giro3d/core/Cache';
import Source, { delay } from './Source';
import DosApi from '../../../services/DosApi';
import SeismicPlaneRegion from './SeismicPlaneRegion';

/**
 * @typedef {import('./Source').ReadRequest} ReadRequest
 */

/**
 * @typedef {object} VdsProperties
 * @property {number} numTraces The number of traces in the VDS file.
 * @property {number} numSamples The number of samples in the VDS file.
 * @property {number} lods The number of LODs in the VDS file.
 * @property {number} chunkSize The size of a chunk in the VDS file.
 */

/**
 * Loads data from a remote vds endpoint.
 */
export default class VdsSource extends Source {
    /**
     * @param {string} sourcePath The relative source file path to the seismic file.
     * @param {VdsProperties} properties Properties of vds file.
     * @param {number} heightMeters The height of the dataset, in meters.
     */
    constructor(sourcePath, properties, heightMeters) {
        super();
        this.sourcePath = sourcePath;

        /** @type {VdsProperties} */
        this.vdsCapabilities = properties;

        /** @type {{ lod: number, resolution: number }[]} */
        this.resolutions = [];

        for (let i = 0; i < this.levels; i++) {
            const pixels = this.height / 2 ** i;
            const pixelSize = heightMeters / pixels;
            this.resolutions.push({ resolution: pixelSize, lod: i });
        }
        this.resolutions.reverse();
    }

    /**
     * The width in pixels of the seismic raster.
     */
    get width() {
        return this.vdsCapabilities.numTraces;
    }

    /**
     * The height in pixels of the seismic raster.
     */
    get height() {
        return this.vdsCapabilities.numSamples;
    }

    get chunkSize() {
        return this.vdsCapabilities.chunkSize;
    }

    get levels() {
        return this.vdsCapabilities.lods;
    }

    getTileSize(lod) {
        return this.vdsCapabilities.chunkSize * 2 ** lod;
    }

    getLod(resolution) {
        for (let i = 0; i < this.resolutions.length; i++) {
            if (resolution >= this.resolutions[i].resolution) {
                return this.resolutions[i].lod;
            }
        }

        return 0;
    }

    /**
     * Convert the plane region into a VDS trace/sample region, with a margin applied.
     * @param {SeismicPlaneRegion} region The plane region.
     * @returns {{ startTrace: number, numTraces: number, startSample: number, numSamples: number, adjustedRegion: SeismicPlaneRegion }} The VDS regions (in samples/traces) and the adjusted plane region.
     */
    getVdsRegion(region) {
        const rawStartTrace = Math.ceil(region.u * (this.width - 1));
        const rawStartSample = Math.ceil(region.v * (this.height - 1));
        const rawNumTraces = Math.ceil(region.width * this.width);
        const rawNumSamples = Math.ceil(region.height * this.height);

        // The margin is required to reduce visual artifacts at the edges of tiles.
        // In other word, the texture must be slightly bigger than the tile so that the pixels
        // that are on the edges of the tile are not on the edge of the texture.
        const MARGIN = 5;
        const startTrace = Math.max(0, rawStartTrace - MARGIN);
        const startSample = Math.max(0, rawStartSample - MARGIN);
        const lastTrace = Math.min(startTrace + rawNumTraces + MARGIN * 2, this.width - 1);
        const lastSample = Math.min(startSample + rawNumSamples + MARGIN * 2, this.height - 1);
        const numTraces = lastTrace - startTrace;
        const numSamples = lastSample - startSample;

        const u = startTrace / (this.width - 1);
        const v = startSample / (this.height - 1);
        const w = numTraces / (this.width - 1);
        const h = numSamples / (this.height - 1);

        const adjustedRegion = new SeismicPlaneRegion(u, v, w, h);

        return {
            startTrace,
            startSample,
            numTraces,
            numSamples,
            adjustedRegion,
        };
    }

    /**
     * @param {ReadRequest} request The request.
     * @returns {Promise<{ texture: THREE.DataTexture, adjustedRegion: SeismicPlaneRegion }>} The texture and adjusted region.
     */
    async read(request) {
        request.signal.throwIfAborted();
        await delay(100);
        request.signal.throwIfAborted();

        // Let's find the LOD that matches the most closely the requested resolution
        const resolution = request.tileSizeMeters.height / request.outputSize.height;
        const lod = this.getLod(resolution);

        const { startTrace, numTraces, startSample, numSamples, adjustedRegion } = this.getVdsRegion(request.region);

        const key = `${this.datasetId}-${lod}-${startTrace}-${startSample}-${numTraces}-${numSamples}`;
        let intensityData = GlobalCache.get(key);

        if (!intensityData) {
            const imageBlob = await DosApi.fetchVDSSlice(
                this.sourcePath,
                lod,
                startTrace,
                numTraces,
                startSample,
                numSamples,
                request.signal
            );
            intensityData = new Uint8ClampedArray(imageBlob);
            GlobalCache.set(key, intensityData, { size: intensityData.byteLength });
        }

        request.signal.throwIfAborted();

        const texture = new THREE.DataTexture(
            intensityData,
            Math.ceil(numTraces / 2 ** lod),
            Math.ceil(numSamples / 2 ** lod),
            THREE.RedFormat,
            THREE.UnsignedByteType
        );
        texture.needsUpdate = true;

        // When the texture is uploaded to the GPU, get rid of the buffer on the CPU side.
        // This way, the only place where this buffer exists is the cache (if the cache is enabled).
        // When the cache decides to remove it, it's gone forever and memory is freed.
        texture.onUpdate = function onUpdate() {
            texture.image.data = null;
        };

        return { texture, adjustedRegion };
    }
}
