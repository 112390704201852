// eslint-disable-next-line import/no-cycle
import { HEALTHCHECK_TYPES } from '../actionTypes';

const initialState = {
    healthcheck_ongoing: false,
    backend_available: true, // assume backend is available initially
    msg: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case HEALTHCHECK_TYPES.SUCCESS:
            return {
                ...state,
                backend_available: payload.backend_available,
                msg: payload.msg,
                healthcheck_ongoing: false,
            };
        case HEALTHCHECK_TYPES.ERROR:
            return {
                ...state,
                backend_available: payload.backend_available,
                msg: payload.msg,
            };
        case HEALTHCHECK_TYPES.REQUEST:
            return {
                ...state,
                healthcheck_ongoing: true,
            };
        default:
            return state;
    }
}
