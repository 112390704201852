// React
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from 'store';
import ProjectsMenu from 'components/giro3d/ProjectsPane';

import { PAGE } from 'services/Constants';
import DashboardMenu from 'components/dashboard/DashboardMenu';
import DashboardPane from 'components/dashboard/DashboardPane';
import { getProjects, getProjectsStatus } from 'redux/selectors';
import { OVERVIEW_MAP_LOADED, PROJECTS } from 'redux/actionTypes';
import CreateProject from 'components/giro3d/CreateProject';
import { Modal } from 'reactstrap';
import * as overviewSlice from 'redux/overview';
import {
    loadOverviewMap,
    unloadOverviewMap,
    selectPage,
    fetchProjects,
    fetchCollections,
    loadProjects,
    fetchOrganizations,
    // loadCollections,
} from '../../redux/actions';

// Components
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.min.css';

import Map from './Map';

const Overview = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const projects = useAppSelector(getProjects);
    const projectsStatus = useAppSelector(getProjectsStatus);
    const selection = useAppSelector(overviewSlice.getSelection);

    const [creatingProject, setCreatingProject] = useState(false);

    const mapRef = useRef(null);

    const toProjectView = () => {
        const slug = projects
            .find((p) => p.id === selection.item.id)
            .name.toString()
            .normalize('NFD') // break accented characters into components
            .replace(/[\u0300-\u036f]/g, '') // remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') // spaces to dashes
            .replace(/&/g, '-and-') // ampersand to and
            .replace(/[^\w-]+/g, '') // remove non-words
            .replace(/--+/g, '-') // collapse multiple dashes
            .replace(/^-+/, '') // trim starting dash
            .replace(/-+$/, ''); // trim ending dash;
        navigate(`/project/${selection.item.id}/${slug}`);
        dispatch({ type: OVERVIEW_MAP_LOADED, payload: undefined });
    };

    // Map creation
    useEffect(() => {
        if (!mapRef.current) return null;
        dispatch(loadOverviewMap(mapRef.current, navigate));
        dispatch(fetchProjects());
        dispatch(fetchCollections());
        dispatch(selectPage(PAGE.OVERVIEW));
        return () => {
            dispatch(unloadOverviewMap());
        };
    }, [dispatch, navigate]);

    // Selector projects + collections, load in map
    useEffect(() => {
        if (projectsStatus === PROJECTS.SUCCESS) {
            dispatch(loadProjects(projects));
        }
    }, [projectsStatus]);

    useEffect(() => {
        document.title = 'Overview | SCOPE';
        dispatch(fetchOrganizations());
    }, []);

    return (
        <div className="dashboard">
            <DashboardMenu>
                <button type="button" onClick={() => setCreatingProject(true)}>
                    Create New Project
                </button>
            </DashboardMenu>
            <div className="dashboard-area">
                <div className="dashboard-column" style={{ flex: '0 1 25rem' }}>
                    <DashboardPane title="Projects" childClassName="tabContent fixed">
                        <ProjectsMenu />
                    </DashboardPane>
                    <DashboardPane
                        collapse
                        chin={
                            <button
                                type="button"
                                className="pane-button large highlight"
                                disabled={selection.type !== 'project'}
                                onClick={toProjectView}
                            >
                                Open Project
                            </button>
                        }
                    />
                </div>
                <div className="dashboard-column" style={{ flex: '4 1 50rem' }}>
                    <DashboardPane viewport>
                        <Map mapRef={mapRef} />
                    </DashboardPane>
                </div>
            </div>
            <Modal isOpen={creatingProject} toggle={() => setCreatingProject(false)}>
                <CreateProject endCreate={() => setCreatingProject(false)} />
            </Modal>
        </div>
    );
};

export default Overview;
