// React
import { useAppDispatch, useAppSelector } from 'store';

import * as datasetsSlice from 'redux/datasets';
import { useMountEffect } from 'components/utils';
import { loadMinimap, unloadMinimap } from 'redux/actions';

const Minimap = ({ minimapRef, inspectorRef }) => {
    const dispatch = useAppDispatch();
    const project = useAppSelector(datasetsSlice.currentProject);

    useMountEffect(
        () => {
            dispatch(loadMinimap(minimapRef.current, inspectorRef.current, project));
        },
        () => dispatch(unloadMinimap())
    );

    return <div className="giro3d-minimap-container" ref={minimapRef} />;
};

export default Minimap;
