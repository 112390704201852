import { HasArrows, LayerState } from 'types/LayerState';

import * as layersSlice from 'redux/layers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import Toggle from 'components/dropdown/Toggle';
import Input from 'components/dropdown/Input';

export type Props = {
    layer: LayerState & HasArrows;
};

const Spacing = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const arrowSpacing = useAppSelector(layersSlice.getArrowSpacing(layer));
    function changeArrowSpacing(value: number) {
        dispatch(layersSlice.setArrowSpacing({ layer, value }));
    }

    return (
        <Input
            title="Arrow Spacing"
            icon="fas fa-up-down"
            value={arrowSpacing}
            unit="m"
            min={100}
            max={10000}
            step={100}
            onChange={(v) => changeArrowSpacing(v)}
        />
    );
};

const Scale = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const arrowScale = useAppSelector(layersSlice.getArrowScale(layer));
    function changeArrowScale(value: number) {
        dispatch(layersSlice.setArrowScale({ layer, value }));
    }

    return (
        <Input
            title="Arrow Size"
            icon="fas fa-up-right-and-down-left-from-center"
            value={arrowScale}
            min={1}
            max={10}
            step={1}
            onChange={(v) => changeArrowScale(v)}
        />
    );
};

export const ArrowSettings = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const showArrows = useAppSelector(layersSlice.getShowArrows(layer));
    function setShowArrows(value: boolean) {
        dispatch(layersSlice.setShowArrows({ layer, value }));
    }

    return (
        <>
            <Toggle checked={showArrows} title="Show direction" onChange={(v) => setShowArrows(v)} />

            {showArrows ? <Spacing layer={layer} /> : null}
            {showArrows ? <Scale layer={layer} /> : null}
            <hr />
        </>
    );
};

export default ArrowSettings;
