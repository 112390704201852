import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import { selectPage } from 'redux/actions';
import { PAGE } from 'services/Constants';
import DosApi from 'services/DosApi';
import NotificationsMenu from 'components/notification/NotificationsMenu';
import { PropsWithChildren, useState } from 'react';
import { getNotifications, getPage } from 'redux/selectors';
import { Notification } from 'types/Notification';
import ReleaseModal from 'components/releases/ReleasesModal';
import Logo from './Logo';
import { RELEASE_SHOW } from '../../redux/actionTypes';

const getCountMessage = (count: number) => {
    if (count === 0) return undefined;
    if (count > 99) return '#';
    return String(count);
};

const DashboardMenu = ({ children }: PropsWithChildren<unknown>) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const page = useAppSelector(getPage);
    const notifications: Notification[] = useAppSelector(getNotifications);
    const unreadCount = notifications ? notifications.filter((n) => !n.read).length : 0;
    const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

    const setReleaseNotesOpen = () => {
        dispatch({
            type: RELEASE_SHOW,
        });
    };

    return (
        <div className="dashboard-menu">
            <Logo />
            <div className="button-group">
                <button
                    type="button"
                    className={page === PAGE.OVERVIEW ? 'highlight' : ''}
                    onClick={() => {
                        dispatch(selectPage(PAGE.OVERVIEW));
                        navigate('/');
                    }}
                >
                    Projects
                </button>
                <button
                    type="button"
                    className={page === PAGE.LIBRARY ? 'highlight' : ''}
                    onClick={() => {
                        dispatch(selectPage(PAGE.LIBRARY));
                        navigate('/library');
                    }}
                >
                    Library
                </button>
                <button
                    type="button"
                    className={page === PAGE.ADMIN ? 'highlight' : ''}
                    onClick={() => {
                        dispatch(selectPage(PAGE.ADMIN));
                        navigate('/admin');
                    }}
                >
                    Admin
                </button>
            </div>
            <div className="button-group">{children}</div>
            <div className="spacer" />
            <div className="button-group">
                <button type="button" onClick={() => setReleaseNotesOpen()}>
                    Release Notes
                    <ReleaseModal />
                </button>
                <button type="button" onClick={() => setNotificationMenuOpen(true)}>
                    Notifications
                    {unreadCount ? (
                        <div>
                            <span>{getCountMessage(unreadCount)}</span>
                        </div>
                    ) : null}
                    <NotificationsMenu open={notificationMenuOpen} toggle={() => setNotificationMenuOpen(false)} />
                </button>
            </div>
            <div className="button-group">
                <button type="button" onClick={DosApi.logout}>
                    Log Out
                </button>
            </div>
        </div>
    );
};

export default DashboardMenu;
