import { nanoid } from '@reduxjs/toolkit';

export type Props = {
    title: string;
    icon?: string;
    onClick: () => void;
};

function Button(props: Props) {
    const id = nanoid();
    return (
        <li>
            <label htmlFor={id} className="cursor">
                <div>
                    <i className={props.icon} />
                </div>
                <div>
                    <button type="button" id={id} onClick={props.onClick}>
                        {props.title}
                    </button>
                </div>
            </label>
        </li>
    );
}

export default Button;
