// eslint-disable-next-line import/no-cycle
import { PROJECTIONS } from '../actionTypes';

function reducer(
    state = {
        requested: false,
        inError: false,
        result: null,
        status: PROJECTIONS.UNKNOWN,
    },
    action
) {
    switch (action.type) {
        case PROJECTIONS.REQUEST:
            return { ...state, requested: true, status: PROJECTIONS.REQUEST };
        case PROJECTIONS.SUCCESS:
            action.payload.forEach((element) => {
                element.title = `${element.srid} - ${element.title}`;
            });
            return { ...state, requested: false, inError: false, result: action.payload, status: PROJECTIONS.SUCCESS };
        case PROJECTIONS.ERROR:
            return { ...state, requested: false, inError: true, status: PROJECTIONS.ERROR };
        case PROJECTIONS.CLEAR:
            return {
                requested: false,
                inError: false,
                result: null,
                status: PROJECTIONS.UNKNOWN,
            };
        default:
            return state;
    }
}

export default reducer;
