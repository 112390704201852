import { ShapeUtils, Vector2, Vector3 } from 'three';
import Coordinates, { crsIsGeocentric } from '@giro3d/giro3d/core/geographic/Coordinates';
import GeometryObject from './GeometryObject';

export function toGeocentricCoordinates(coords, crs) {
    if (crsIsGeocentric(crs)) return coords;
    return coords.map((c) => new Coordinates(crs, c).as('EPSG:4978').xyz());
}

/**
 * Gets length of each segments.
 * Assumes coordinates are in geocentric CRS.
 *
 * @param {Array<Vector3>|GeometryObject} coords Coordinates
 * @returns {Array<number>} Length for each segment
 */
export function getLength(coords) {
    if (!coords) return null;

    if (coords instanceof GeometryObject) {
        if (coords.coordinates.length < 6) return null;

        const lengths = new Array(coords.coordinates.length / 3 - 1);
        for (let i = 0; i < coords.coordinates.length / 3 - 1; i += 1) {
            lengths[i] = new Vector3(
                coords.coordinates[i * 3 + 0],
                coords.coordinates[i * 3 + 1],
                coords.coordinates[i * 3 + 2]
            ).distanceTo(
                new Vector3(
                    coords.coordinates[(i + 1) * 3 + 0],
                    coords.coordinates[(i + 1) * 3 + 1],
                    coords.coordinates[(i + 1) * 3 + 2]
                )
            );
        }
        return lengths;
    }

    if (coords.length < 2) return null;

    const lengths = new Array(coords.length - 1);
    for (let i = 0; i < coords.length - 1; i += 1) {
        lengths[i] = coords[i].distanceTo(coords[i + 1]);
    }

    return lengths;
}

/**
 * Gets elevation difference for each segments.
 * Assumes coordinates are in geocentric CRS.
 *
 * @param {Array<Vector3>|GeometryObject} coords Coordinates
 * @returns {Array<number>} Length for each segment
 */
export function getElevationDiff(coords) {
    if (!coords) return null;

    if (coords instanceof GeometryObject) {
        if (coords.coordinates.length < 6) return null;

        const elevationDiff = new Array(coords.coordinates.length / 3 - 1);
        for (let i = 0; i < coords.coordinates.length / 3 - 1; i += 1) {
            elevationDiff[i] = coords.coordinates[i * 3 + 2] - coords.coordinates[(i + 1) * 3 + 2];
        }
        return elevationDiff;
    }

    if (coords.length < 2) return null;

    const elevationDiff = new Array(coords.length - 1);
    for (let i = 0; i < coords.length - 1; i += 1) {
        elevationDiff[i] = coords[i].z - coords[i + 1].z;
    }

    return elevationDiff;
}

/**
 * Gets min and max altitudes of a geometry.
 * Assumes coordinates are in geocentric CRS.
 *
 * @param {Array<Vector3>|GeometryObject} coords Coordinates
 * @returns {Array<number>} Min and max
 */
export function getMinMaxAltitudes(coords) {
    let min = +Infinity;
    let max = -Infinity;

    if (!coords) return [min, max];

    if (coords instanceof GeometryObject) {
        for (let i = 0; i < coords.coordinates.length / 3; i += 1) {
            min = Math.min(min, coords.coordinates[i * 3 + 2]);
            max = Math.max(max, coords.coordinates[i * 3 + 2]);
        }
        return [min, max];
    }

    for (let i = 0; i < coords.length; i += 1) {
        min = Math.min(min, coords[i].z);
        max = Math.max(max, coords[i].z);
    }

    return [min, max];
}

/**
 * Gets area of a polygon.
 * Assumes coordinates are in geocentric CRS.
 *
 * @param {Array<Vector2>|GeometryObject} coords Coordinates
 * @returns {number} Area
 */
export function getArea(coords) {
    if (!coords) return null;

    if (coords instanceof GeometryObject) {
        if (coords.coordinates.length < 6) return null;

        const localCoords = new Array(coords.coordinates.length / 3);
        for (let i = 0; i < coords.coordinates.length / 3; i += 1) {
            localCoords[i] = new Vector2(coords.positionsTop[i * 3 + 0], coords.positionsTop[i * 3 + 1]);
        }
        return Math.abs(ShapeUtils.area(localCoords));
    }

    if (coords.length < 2) return null;

    return Math.abs(ShapeUtils.area(coords));
}
