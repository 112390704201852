// eslint-disable-next-line import/no-cycle
import {
    ADD_USERNAMES,
    SET_ADMINISTRATED_MEMBERSHIPS,
    SET_ADMINISTRATED_USERS,
    UPDATE_ADMINISTRATED_MEMBERSHIP,
    ADD_ADMINISTRATED_MEMBERSHIP,
    UPDATE_ADMINISTRATED_USER,
    REMOVE_ADMINISTRATED_MEMBERSHIP,
    SET_MEMBERSHIP_REQUESTS,
    REMOVE_MEMBERSHIP_REQUEST,
    QUEUE_USERNAMES,
} from '../actionTypes';

const initialState = {
    requestedUsernames: [],
    usernames: [],
    memberships: [],
    users: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case QUEUE_USERNAMES:
            return {
                ...state,
                requestedUsernames: [
                    ...state.requestedUsernames,
                    ...payload.filter((userId) => !state.requestedUsernames.includes(userId)),
                ],
            };
        case ADD_USERNAMES:
            return {
                ...state,
                usernames: [...state.usernames, ...payload],
                usernamesQueue: state.requestedUsernames.filter(
                    (userId) => !payload.map((user) => user.id).includes(userId)
                ),
            };
        case SET_ADMINISTRATED_MEMBERSHIPS:
            return {
                ...state,
                memberships: payload,
            };
        case UPDATE_ADMINISTRATED_MEMBERSHIP:
            payload.user = state.memberships.filter((membership) => membership.id === payload.id)[0].user;
            return {
                ...state,
                memberships: [...state.memberships.filter((membership) => membership.id !== payload.id), payload],
            };
        case ADD_ADMINISTRATED_MEMBERSHIP:
            return {
                ...state,
                memberships: [...state.memberships, payload],
            };
        case REMOVE_ADMINISTRATED_MEMBERSHIP:
            return {
                ...state,
                memberships: state.memberships.filter((membership) => membership.id !== payload.id),
            };
        case SET_ADMINISTRATED_USERS:
            return {
                ...state,
                users: payload,
            };
        case UPDATE_ADMINISTRATED_USER:
            return {
                ...state,
                users: [...state.users.filter((user) => user.id !== payload.id), payload],
            };
        case SET_MEMBERSHIP_REQUESTS:
            return {
                ...state,
                requests: payload,
            };
        case REMOVE_MEMBERSHIP_REQUEST:
            return {
                ...state,
                requests: state.requests.filter((request) => request.id !== payload.id),
            };
        default:
            return state;
    }
}
