import { UppyFile } from '@uppy/core';
import { useMountEffect } from 'components/utils';
import { useEventBus } from 'EventBus';
import { useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import UppyService from 'services/UppyService';
import Dataset from 'types/Dataset';

const UploadingInfo = (props: { dataset: Dataset; onClose }) => {
    const eventBus = useEventBus();

    const [files, setFiles] = useState<UppyFile<Record<string, unknown>, Record<string, unknown>>[]>([]);

    const updateUppy = () => {
        setFiles(UppyService.getUploading()[props.dataset.id]?.getFiles() ?? undefined);
    };

    function subscribe() {
        eventBus.subscribe('uppy-change', updateUppy);
    }

    function unsubscribe() {
        eventBus.unsubscribe('uppy-change', updateUppy);
    }

    useMountEffect(subscribe, unsubscribe);

    if (files)
        return (
            <>
                <ModalBody>
                    <div>Uploading files for {props.dataset.name}</div>
                    <div className="upload-list">
                        {files.map((file) => (
                            <div key={`file-${file.id}`}>
                                <label htmlFor={file.id}>{file.name}</label>
                                <progress id={file.id} value={file.progress.percentage} max={100}>
                                    {file.progress.percentage}%
                                </progress>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <span>Ongoing uploads will continue, check the uploads menu for their status</span>
                    <button type="button" className="pane-button large" onClick={props.onClose}>
                        Close
                    </button>
                </ModalFooter>
            </>
        );

    return (
        <>
            <ModalBody className="centred">
                <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                <span className="big-modal-text">Upload completed</span>
            </ModalBody>
            <ModalFooter>
                <span>Ongoing uploads will continue, check the uploads menu for their status</span>
                <button type="button" className="pane-button large" onClick={props.onClose}>
                    Close
                </button>
            </ModalFooter>
        </>
    );
};

export default UploadingInfo;
