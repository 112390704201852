// eslint-disable-next-line import/no-cycle
import { USER_TYPES, REGISTER_USER_TYPES } from '../actionTypes';

const reducer = (state = { accessToken: null, user: undefined }, action) => {
    const { type, payload, status } = action;
    switch (type) {
        case USER_TYPES.SUCCESS:
        case REGISTER_USER_TYPES.SUCCESS:
            return {
                ...state,
                user: payload,
            };
        case USER_TYPES.NOT_FOUND:
            return {
                ...state,
                user: null,
            };
        case USER_TYPES.ERROR:
            return {
                ...state,
                user: status >= 500 ? undefined : null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
