import { SourceFile } from 'types/SourceFile';
import SeismicPlaneBuilder from './SeismicPlaneBuilder';
import SeismicPlane2dLayer from '../layers/seismic/SeismicPlane2dLayer';

export default class SeismicPlane2dBuilder extends SeismicPlaneBuilder<SeismicPlane2dLayer> {
    protected override buildLayer(sourceFile: SourceFile): Promise<SeismicPlane2dLayer> {
        const layer = new SeismicPlane2dLayer(this.getParams(sourceFile));

        return Promise.resolve(layer);
    }
}
