import { Color, CatmullRomCurve3, Mesh, MeshLambertMaterial, BufferAttribute, DoubleSide, MathUtils } from 'three';
import { AbstractGeometryBuilder, CreateOptions } from './GeometryBuilder';
import VariableRadiusTubeGeometry from './VariableRadiusTubeGeometry';

const tmpColor = new Color();
const WHITE = new Color('white');
const RENDER_ORDER = 1;

type Tube = Mesh<VariableRadiusTubeGeometry, MeshLambertMaterial>;

/**
 * Creates tube geometries along curves.
 */
export default class TubeBuilder extends AbstractGeometryBuilder<Tube> {
    radius: number;

    readonly radialSegments: number;

    readonly allowSubsampling: boolean;

    constructor(params: { radius: number; radialSegments: number; allowSubsampling: boolean }) {
        super();
        this.radius = params.radius;
        this.radialSegments = params.radialSegments;
        this.allowSubsampling = params.allowSubsampling;
    }

    // eslint-disable-next-line class-methods-use-this
    applySolidColor(object: Tube, color: Color): void {
        const geometry = object.geometry;

        const material = object.material;
        material.vertexColors = false;
        material.color = color;
        geometry.deleteAttribute('color');
    }

    // eslint-disable-next-line class-methods-use-this
    setBrightness(object: Tube, brightness: number) {
        object.material.emissive = WHITE;
        object.material.emissiveIntensity = brightness;
    }

    // eslint-disable-next-line class-methods-use-this
    applyVertexColors(object: Tube, colorFunc: (pointIndex: number, target: Color) => Color): void {
        const geometry = object.geometry;

        const material = object.material as MeshLambertMaterial;
        material.vertexColors = true;
        material.color = WHITE;

        const vertexCount = geometry.getAttribute('position').count;

        const hasColorAttribute = geometry.hasAttribute('color');

        const buffer = hasColorAttribute
            ? geometry.getAttribute('color')
            : new BufferAttribute(new Float32Array(vertexCount * 4), 4);

        const pointCount = object.userData.pointCount;

        for (let pointIndex = 0; pointIndex < pointCount; pointIndex++) {
            const color = colorFunc(pointIndex, tmpColor);
            for (
                let vertexIndex = pointIndex * (this.radialSegments + 1);
                vertexIndex < (pointIndex + 1) * (this.radialSegments + 1);
                vertexIndex++
            ) {
                buffer.setXYZW(vertexIndex, color.r, color.g, color.b, 1);
            }
        }

        buffer.needsUpdate = true;

        if (!hasColorAttribute) {
            geometry.setAttribute('color', buffer);
        }
    }

    setRadius(factor: number): void {
        this.radius = factor;
    }

    create(opts: CreateOptions): Tube {
        // Let's create a tube whose number of subdivisions are as close as possible
        // to the number of data points, while taking into account the radius of the tube.
        // The bigger the radius, the smaller the number of subdivisions, to avoid funny looking
        // tubes.
        const pointCount = opts.coordinates.points.length;
        const path = new CatmullRomCurve3(opts.coordinates.points.toArray());
        let subdivisions: number;
        if (this.allowSubsampling) {
            const length = path.getLength();
            const rawSubdivisions = Math.round(length / (2 * this.radius));
            const MIN_SUBDIVISIONS = 2;
            subdivisions = MathUtils.clamp(rawSubdivisions, MIN_SUBDIVISIONS, pointCount);
        } else {
            subdivisions = pointCount;
        }

        const defaultRadiusFn = () => this.radius;
        const radiusFn = (index: number) => opts.radiusFn(index) * this.radius;

        const tube = new VariableRadiusTubeGeometry(
            path,
            subdivisions,
            opts.radiusFn ? radiusFn : defaultRadiusFn,
            this.radialSegments,
            false
        );

        tube.computeBoundingBox();
        tube.computeBoundsTree();

        const material = new MeshLambertMaterial({
            vertexColors: false,
            transparent: opts.opacity < 1,
            opacity: opts.opacity,
            side: DoubleSide,
            color: opts.color,
        });

        const mesh: Tube = new Mesh(tube, material) as Tube;
        mesh.renderOrder = RENDER_ORDER;
        mesh.position.copy(opts.coordinates.origin);
        mesh.updateMatrixWorld(true);
        mesh.userData.pointCount = pointCount;
        mesh.name = 'TubeBuilder';

        return mesh;
    }

    // eslint-disable-next-line class-methods-use-this
    showInFront(object: Tube, inFront: boolean): void {
        const material = object.material;

        if (inFront) {
            object.renderOrder = 9999;
            material.transparent = true;
            material.depthTest = false;
        } else {
            object.renderOrder = RENDER_ORDER;
            material.transparent = material.opacity < 1;
            material.depthTest = true;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    dispose(object: Tube): void {
        object.geometry.dispose();
        object.material.dispose();

        object.removeFromParent();
    }
}
