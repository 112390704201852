export default `
varying vec2 vUv;
#include <clipping_planes_pars_vertex>
void main() {
    #include <begin_vertex>
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    #include <project_vertex>
    #include <clipping_planes_vertex>
}
`;
