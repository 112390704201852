import React from 'react';
import { checkForUsernames } from '../redux/actions';

function formatMentions(content, dispatch, usernames) {
    const matches = [
        ...content.matchAll(
            /@\(([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}) \| .*?\)/g
        ),
    ];
    dispatch(checkForUsernames(matches.map((match) => match[1])));
    const inbetweens = [
        content.split(
            /@\([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12} \| .*?\)/g
        ),
    ];
    const finalContent = [inbetweens[0][0]];
    for (let index = 0; index < matches.length; index++) {
        const tagged = usernames ? usernames.find((x) => x.id === matches[index][1]) : null;
        finalContent.push(
            tagged ? (
                <mark key={`mark-${index}`}>{`@${tagged.given_name} ${tagged.family_name}`}</mark>
            ) : (
                matches[index][0]
            )
        );
        finalContent.push(inbetweens[0][index + 1]);
    }
    return finalContent;
}

export default formatMentions;
