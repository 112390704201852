import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Table } from 'reactstrap';

import * as datasetsSlice from 'redux/datasets';
import { showError, unloadDataset, fetchAndLoadDataset, fetchProject } from 'redux/actions';
import DosApi from 'services/DosApi';

const ProjectDatasets = ({ project }) => {
    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);
    const [collections, setCollections] = useState(null);
    const [nullCollection, setNullCollection] = useState(null);
    const [datasets, setDatasets] = useState({});
    const projectDatasets = useSelector(datasetsSlice.getProjectDatasets);
    const projectDatasetIds = projectDatasets.map((d) => d.id);
    const [openCollections, setOpenCollections] = useState([]);

    useEffect(() => {
        Promise.all([DosApi.fetchCollections(), DosApi.fetchAllDatasets()])
            .then(([_collections, _datasets]) => {
                const collectionsById = {};
                const datasetsByCollection = {};
                const uncollectedDatasets = [];

                for (const collection of _collections) {
                    collectionsById[collection.id] = collection;
                    datasetsByCollection[collection.id] = [];
                }

                const overviewIds = _datasets.filter((d) => !!d.overview_id).map((d) => d.overview_id);
                for (const dataset of _datasets) {
                    if (overviewIds.includes(dataset.id)) continue;
                    if (collectionsById[dataset.collection_id])
                        datasetsByCollection[dataset.collection_id].push(dataset);
                    if (dataset.collection_id === null) uncollectedDatasets.push(dataset);
                }

                for (const id of Object.keys(datasetsByCollection)) {
                    if (datasetsByCollection[id].length === 0) delete collectionsById[id];
                }

                setCollections(collectionsById);
                setNullCollection(uncollectedDatasets);
                setDatasets(datasetsByCollection);
                setLoaded(true);
            })
            .catch((err) => showError(dispatch, err));
    }, []);

    const setOpen = (collectionId, open) => {
        if (open) setOpenCollections([...openCollections.filter((id) => id !== collectionId), collectionId]);
        else setOpenCollections(openCollections.filter((id) => id !== collectionId));
    };

    const changeStatus = (dataset) => {
        const datasetId = dataset.id;
        if (projectDatasetIds.includes(datasetId)) {
            DosApi.unrelateProjectDataset(project.id, datasetId)
                .then(() => {
                    unloadDataset(dispatch, dataset);
                })
                .catch((error) => showError(dispatch, error));
        } else {
            DosApi.relateProjectDataset(project.id, datasetId)
                .then(() => {
                    if (project.geometry) {
                        dispatch(fetchAndLoadDataset(datasetId));
                    } else {
                        // First addition of a dataset will require setting
                        // project geometry for the first time and we need to
                        // force reload the project and trigger giro3d reload.
                        dispatch(fetchProject(project.id, true));
                    }
                })
                .catch((error) => showError(dispatch, error));
        }
    };

    if (!loaded) return <Spinner animation="border" />;

    return (
        <Table size="sm" className="project-dataset-table">
            <thead>
                <tr>
                    <th>Collection</th>
                    <th>Dataset</th>
                    <th>Included</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(collections).map((collectionId) => (
                    <React.Fragment key={collectionId}>
                        <tr key={collectionId}>
                            <td
                                rowSpan={openCollections.includes(collectionId) ? datasets[collectionId].length + 1 : 1}
                            >
                                {collections[collectionId].name}
                            </td>
                            <td />
                            <td>
                                <label htmlFor={`${collectionId}-dropdown`} className="data-group-label dropdown-arrow">
                                    {
                                        datasets[collectionId].filter((dataset) =>
                                            projectDatasetIds.includes(dataset.id)
                                        ).length
                                    }
                                    /{datasets[collectionId].length}
                                    <input
                                        type="checkbox"
                                        id={`${collectionId}-dropdown`}
                                        checked={openCollections.includes(collectionId)}
                                        onChange={(e) => setOpen(collectionId, e.target.checked)}
                                    />
                                    <div className="arrow" />
                                </label>
                            </td>
                        </tr>
                        {openCollections.includes(collectionId)
                            ? datasets[collectionId].map((dataset) => (
                                  <tr key={`${dataset.id}-${collectionId}`}>
                                      <td>
                                          {dataset.name}{' '}
                                          {dataset.projection === project.projection ? (
                                              <i className="fal fa-grid-3" />
                                          ) : null}
                                      </td>
                                      <td>
                                          <label className="toggle-switch" htmlFor={`dataset-include-${dataset.id}`}>
                                              <input
                                                  type="checkbox"
                                                  id={`dataset-include-${dataset.id}`}
                                                  checked={projectDatasetIds.includes(dataset.id)}
                                                  onChange={() => changeStatus(dataset)}
                                              />
                                              <span className="toggle-slider" />
                                          </label>
                                      </td>
                                  </tr>
                              ))
                            : null}
                    </React.Fragment>
                ))}
                {nullCollection.length !== 0 ? (
                    <tr key="noCollection">
                        <td rowSpan={openCollections.includes(null) ? nullCollection.length + 1 : 1}>No Collection</td>
                        <td />
                        <td>
                            <label htmlFor="null-dropdown" className="data-group-label dropdown-arrow">
                                {nullCollection.filter((dataset) => projectDatasetIds.includes(dataset.id)).length}/
                                {nullCollection.length}
                                <input
                                    type="checkbox"
                                    id="null-dropdown"
                                    checked={openCollections.includes(null)}
                                    onChange={(e) => setOpen(null, e.target.checked)}
                                />
                                <div className="arrow" />
                            </label>
                        </td>
                    </tr>
                ) : null}
                {openCollections.includes(null)
                    ? nullCollection.map((dataset) => (
                          <tr key={`${dataset.id}-noCollection`}>
                              <td>{dataset.name}</td>
                              <td>
                                  <label className="toggle-switch" htmlFor={`dataset-include-${dataset.id}`}>
                                      <input
                                          type="checkbox"
                                          id={`dataset-include-${dataset.id}`}
                                          checked={projectDatasetIds.includes(dataset.id)}
                                          onChange={() => changeStatus(dataset)}
                                      />
                                      <span className="toggle-slider" />
                                  </label>
                              </td>
                          </tr>
                      ))
                    : null}
            </tbody>
        </Table>
    );
};

export default ProjectDatasets;
