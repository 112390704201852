/**
 * Utils functions to build actionTypes, reducers and action generators for async actions.
 *
 * Every time we have an async operations, we actually want the following things:
 *
 * - an action fired when the operation starts
 * - an action fired when it succeeds
 * - or an action fired when it fails
 *
 * And we also want the state to reflect this, to encourage us to give feedbacks to user in the UI.
 *
 * These methods helps by giving us a frame to work with, a standard format for
 * the state and actions, and by dealing with errors in a standard way. Meaning
 * that once we are used to it, we don't have to think about it any more
 */
import { AuthError } from '@azure/msal-browser';
// eslint-disable-next-line import/no-cycle
import { startHealthCheck } from './actions';

/**
 * Build an action generator from a function that returns a promise.
 *
 * Ex usage:
 *
 * // RETICULATE_SPLINE_TYPES imported from actionTypes.js
 * // reticulateSplinesApi constructed from makeApiCall (imported from dosApi.js for instance)
 * const reticulateSplines = buildActionGeneratorFromFn(RETICULATE_SPLINE_TYPES, reticulateSplinesApi)
 *
 * // in components or in action generators
 * dispatch(reticulateSplines(data));
 *
 *
 * @param {object} TYPES - object returned from buildActionTypes
 * @param {Function} apiFn  a function that returns a promise
 *
 * @return {function} an action generator that will dispatch the REQUEST action
 * when called, dispatch the SUCCESS action when the promise resolve, or an
 * ERROR action if it is rejected
 */
export default function buildActionGeneratorFromFn(TYPES, apiFn) {
    function query(...args) {
        return (dispatch) => {
            dispatch({ type: TYPES.REQUEST });
            return apiFn(...args)
                .then((result) => {
                    dispatch({ type: TYPES.SUCCESS, status: 200, payload: result });
                    // return the result to be able to use it
                    return result;
                })
                .catch((error) => {
                    // Handle 404 separately, not an error
                    if (error.response?.status === 404) {
                        dispatch({ type: TYPES.NOT_FOUND, payload: error.response.data, message: error.message });
                        return;
                    }
                    if (error.response?.status === 401) {
                        dispatch({
                            type: TYPES.ERROR,
                            status: 401,
                            payload: error.response.data,
                            message: error.message,
                        });
                        return;
                    }
                    if (error instanceof AuthError) {
                        // This happens when acquiring token fails from MSAL, not from the API (see #1749)
                        // Don't start healthcheck
                        dispatch({ type: TYPES.ERROR, status: 401, message: error.message });
                        throw error;
                    }

                    // Start healthcheck to try and recover
                    dispatch(startHealthCheck());

                    // TODO recognise axios errors and react accordingly:
                    // - 401 or 403 would dispatch an action that'll reset the authent
                    // - 500 would display an error about backend being down
                    // - etc...
                    // Here, only other properties would be added to the dispatched action
                    // It's the job of reducers to catch this error and change the state accordingly.
                    dispatch({
                        type: TYPES.ERROR,
                        status: error.message === 'Network Error' ? 503 : error.response?.status,
                        message: error.message,
                    });
                    throw error;
                });
        };
    }

    return query;
}
