import { Button } from 'reactstrap';

export interface Props {
    id?: string;
    children: unknown | unknown[];
}

const InlineDropMenu: React.FC<Props> = ({ children, id }) => (
    <div id={id} className="inline-drop-menu">
        <Button className="borderless tan">
            <i className="fas fa-ellipsis" />
        </Button>
        <div className="inline-drop-content">
            {children}
            <Button className="borderless tan">
                <i className="fas fa-ellipsis" />
            </Button>
        </div>
    </div>
);

export default InlineDropMenu;
