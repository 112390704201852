import { useAppDispatch, useAppSelector } from 'store';
import { DatasetId } from 'types/common';
import { getDatasetColorHexString } from 'services/DatasetColors';
import { Vector2 } from 'three';
import TabHeader from 'components/flexLayout/TabHeader';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { PopoverBody } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import { updateElevationProfile } from 'redux/actions';
import { LAYER_DATA_TYPES, LAYER_TYPES } from 'services/Constants';
import Button from 'components/dropdown/Button';
import Slider from 'components/dropdown/Slider';
import Toggle from 'components/dropdown/Toggle';
import ReactECharts, { EChartsInstance } from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import giro3dService from 'services/Giro3dService';
import * as datasetsSlice from '../../../redux/datasets';
import * as layersSlice from '../../../redux/layers';

type Props = {
    points: Vector2[];
    elevations;
    tabId: string;
};

const ElevationProfile = (props: Props) => {
    const dispatch = useAppDispatch();
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);
    const layers = useAppSelector(layersSlice.all);

    const validDatasets = datasets.filter(
        (dataset) =>
            [LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(dataset.type) &&
            dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
            layers.find((layer) => layer.datasetId === dataset.id).visible
    );

    const { points, elevations, tabId } = props;

    if (!points) return null;

    const length = Math.sqrt(
        (points[1].x - points[points.length - 1].x) ** 2 + (points[1].y - points[points.length - 1].y) ** 2
    );
    const [newSamples, setNewSamples] = useState(points.length);
    const [viewedDatasetIds, setViewedDatasetIds] = useState(Object.keys(elevations));

    useEffect(() => {
        setViewedDatasetIds(viewedDatasetIds.filter((id) => layers.find((layer) => layer.datasetId === id).visible));
    }, [layers]);

    const generateChartValues = () => {
        const sampleCount = points.length;

        const xStep = points[1].x - points[0].x;
        const yStep = points[1].y - points[0].y;
        const dStep = Math.sqrt(xStep ** 2 + yStep ** 2);

        const data = [];
        const ids = Object.keys(elevations);

        for (let i = 0; i < sampleCount; i++) {
            data[i] = [dStep * i, points[i].x, points[i].y];
            ids.forEach((id) => data[i].push(elevations[id][i]));
        }

        return data;
    };

    const ids = Object.keys(elevations);

    const data = generateChartValues();
    const datasetName: Record<DatasetId, string> = {};
    ids.forEach((key) => {
        datasetName[key] = datasets.find((dataset) => dataset.id === key).name;
    });

    const chartRef = useRef(null);
    const containerRef = useRef(null);

    // Resize handler for forcing charts update
    const handleResize = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance();
        if (echartsInstance) echartsInstance.resize();
    };
    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(containerRef.current);
            return resizeObserver.disconnect;
        }
        return undefined;
    }, []);

    const options: EChartsOption = {
        animation: false,
        grid: {
            containLabel: true,
            top: 10,
            bottom: 35,
            left: 10,
            right: 10,
        },
        tooltip: {
            trigger: 'axis',
            transitionDuration: 0,
            backgroundColor: '#242a2d', // --background
            borderColor: '#30383b', // --foreground
            borderWidth: 2,
            textStyle: { color: '#eeeeee' }, // --text-high-emphasis
            // formatter: (params) => {
            //     const hoveredData = params[0].data; // Get data for the hovered point
            //     // --primary, --X, --Y
            //     return `
            //       <span style="color:#febf80">Distance: </span>${hoveredData[0].toFixed(0)}<br/>
            //       <span style="color:#cc3333">X: </span>${hoveredData[1].toFixed(0)}<br/>
            //       <span style="color:#33cc33">Y: </span>${hoveredData[2].toFixed(0)}<br/>
            //       ${ids.map((id, index) => `<span style="color:#${getDatasetColorHexString(id)}">${datasetName[id]}: </span>${hoveredData[3 + index]?.toFixed(0) ?? '-'}`).join('<br/>')}
            //     `;
            // },
            formatter: (params) => {
                return [
                    `<span style="color:#febf80">Distance</span>: ${params[0].axisValueLabel}`,
                    `<span style="color:#cc3333">X</span>: ${params[0].data[0].toFixed(0)}`,
                    `<span style="color:#33cc33">Y</span>: ${params[0].data[1].toFixed(0)}`,
                    `<span style="color:#3333cc">Z</span>: ${params[0].data[2].toFixed(0)}`,
                ]
                    .concat(
                        params.map(
                            (param) =>
                                `<span style="color:${param.color}">${param.seriesName}</span>: ${param.data[3 + param.seriesIndex]?.toFixed(2)}`
                        )
                    )
                    .join('<br/>');
            },
        },
        dataZoom: [
            {
                type: 'slider', // Slider zooming
                xAxisIndex: 0,
                start: 0,
                end: 100,
                height: 15,
                filterMode: 'weakFilter',
                minSpan: 1,
                bottom: 15,
            },
            {
                type: 'inside', // Mouse wheel zooming
                xAxisIndex: 0,
                filterMode: 'weakFilter',
                minSpan: 1,
            },
        ],
        dataset: {
            source: data,
        },
        xAxis: {
            type: 'value',
            name: 'Distance',
            max(value) {
                return Math.ceil(value.max);
            },
        },
        yAxis: {
            type: 'value', // Single y-axis for all datasets
            name: 'Height',
            max(value) {
                const digits = Math.round(Math.log10(value.max - value.min));
                return Math.ceil(value.max / 10 ** digits) * 10 ** digits;
            },
            min(value) {
                const digits = Math.round(Math.log10(value.max - value.min));
                return Math.floor(value.min / 10 ** digits) * 10 ** digits;
            },
        },
        series: ids.map((id, index) => ({
            type: 'line',
            smooth: true,
            name: datasetName[id],
            encode: { x: 0, y: index + 3 },
            color: `#${getDatasetColorHexString(id)}`,
            show: viewedDatasetIds.includes(id),
            seriesLayoutBy: 'column',
            showSymbol: false,
        })),
    };

    const showMarker = (params) => {
        const heights = (data[params.dataIndex] as number[]).slice(3);
        const datasetIndex = heights.indexOf(Math.max(...heights.filter((h) => h !== null)));
        if (datasetIndex !== -1) {
            giro3dService.updateCoordinates(
                {
                    point: {
                        x: data[params.dataIndex][1],
                        y: data[params.dataIndex][2],
                        z: heights[datasetIndex],
                    },
                    picked: true,
                    color: `#${getDatasetColorHexString(ids[datasetIndex])}`,
                },
                true
            );
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();
            chartInstance.on('showTip', showMarker);
        }
        return () => {
            if (chartRef.current) {
                const chartInstance: EChartsInstance = chartRef.current.getEchartsInstance();
                chartInstance.off('showTip', showMarker);
            }
        };
    }, [data]);

    const [legend, setLegend] = useState(false);

    const Legend = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance() as EChartsInstance;
        const series = echartsInstance.getOption().series;

        return (
            <ul className="legends">
                {series.map((propertySeries) => {
                    return (
                        <li
                            key={propertySeries.name}
                            onMouseEnter={() =>
                                echartsInstance.dispatchAction({ type: 'highlight', seriesName: propertySeries.name })
                            }
                            onFocus={() =>
                                echartsInstance.dispatchAction({ type: 'highlight', seriesName: propertySeries.name })
                            }
                            onMouseLeave={() =>
                                echartsInstance.dispatchAction({ type: 'downplay', seriesName: propertySeries.name })
                            }
                            onBlur={() =>
                                echartsInstance.dispatchAction({ type: 'downplay', seriesName: propertySeries.name })
                            }
                            style={{ pointerEvents: 'all' }}
                        >
                            <i className="fas fa-circle" style={{ color: propertySeries.color }} />
                            {propertySeries.name}
                        </li>
                    );
                })}
            </ul>
        );
    };

    useEffect(handleResize, [legend]);

    return (
        <>
            <TabHeader
                left={
                    <>
                        <HeaderButton
                            popover={{
                                name: 'Datasets',
                                icon: 'fas fa-layer-group',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            {validDatasets.map((dataset) => (
                                                <Toggle
                                                    key={dataset.id}
                                                    title={dataset.name}
                                                    checked={viewedDatasetIds.includes(dataset.id)}
                                                    onChange={(v) =>
                                                        setViewedDatasetIds(
                                                            v
                                                                ? [...viewedDatasetIds, dataset.id]
                                                                : viewedDatasetIds.filter((id) => id !== dataset.id)
                                                        )
                                                    }
                                                />
                                            ))}
                                            <Button
                                                title="Recalculate"
                                                icon="fas fa-calculator"
                                                onClick={() => {
                                                    dispatch(
                                                        updateElevationProfile(
                                                            points[0].toArray(),
                                                            points[points.length - 1].toArray(),
                                                            validDatasets.filter((d) =>
                                                                viewedDatasetIds.includes(d.id)
                                                            ),
                                                            newSamples,
                                                            tabId
                                                        )
                                                    );
                                                }}
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                            key="datasets"
                        />
                        <HeaderButton
                            popover={{
                                name: 'Resolution & Samples',
                                icon: 'fas fa-ruler-horizontal',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            <Slider
                                                title="Samples"
                                                value={newSamples}
                                                min={10}
                                                max={1000}
                                                step={10}
                                                onChange={(v) => setNewSamples(v)}
                                            />
                                            <Slider
                                                title="Resolution"
                                                value={Number.parseFloat((length / newSamples).toPrecision(3))}
                                                min={length / 1000}
                                                max={length / 10}
                                                step={0.1}
                                                onChange={(v) => setNewSamples(Math.round(length / v))}
                                            />
                                            <Button
                                                title="Recalculate"
                                                icon="fas fa-calculator"
                                                onClick={() =>
                                                    dispatch(
                                                        updateElevationProfile(
                                                            points[0].toArray(),
                                                            points[points.length - 1].toArray(),
                                                            validDatasets.filter((d) =>
                                                                viewedDatasetIds.includes(d.id)
                                                            ),
                                                            newSamples,
                                                            tabId
                                                        )
                                                    )
                                                }
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                            key="samples"
                        />
                        <HeaderButton
                            toggle={{
                                name: 'Show Legend',
                                icon: 'fas fa-list-ul',
                                checked: legend,
                                onChange: (v) => setLegend(v),
                            }}
                        />
                    </>
                }
                center={undefined}
                right={undefined}
            />
            <div ref={containerRef} style={{ height: '100%' }}>
                <div className="chartContainer" ref={containerRef}>
                    {legend ? <Legend /> : undefined}
                    <ReactECharts ref={chartRef} option={options} style={{ height: '100%', flexGrow: '1' }} />
                </div>
            </div>
        </>
    );
};
export default ElevationProfile;
