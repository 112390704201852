import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useState } from 'react';
import ToolbarButton from 'components/ToolbarButton';
import ProfileContent from './ProfileContent';

const Profile = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <ToolbarButton
                title="Profile"
                iconName="fad fa-user invert"
                id="profile-menu"
                onClick={() => setOpen(!open)}
            />
            <Modal isOpen={open} toggle={() => setOpen(!open)}>
                <ModalHeader>Profile</ModalHeader>
                <ModalBody>
                    <ProfileContent />
                </ModalBody>
            </Modal>
        </>
    );
};

export default Profile;
