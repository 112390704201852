import { useState } from 'react';
import ReactSelect, { components, OptionsOrGroups, GroupBase, MultiValue, MultiValueProps } from 'react-select';

export type Option<T> = {
    value: T;
    label: string;
};

export type Props<T> = {
    value?: Option<T>[] | Option<T>;
    options: OptionsOrGroups<Option<T>, GroupBase<Option<T>>>;
    isMulti?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    autoFocus?: boolean;
    multiValueCounterMessage?: string;
    pluralMultiValueCounterMessage?: string;
    placeholder?: string;
    onChange: (value: Option<T> | MultiValue<Option<T>>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    className?: string;
    disabled?: boolean;
    id?: string;
};

const CheckboxOption = (props) => (
    <components.Option {...props}>
        {props.isSelected ? (
            <i className="fal fa-square-check multiselect-checkbox checked" />
        ) : (
            <i className="fal fa-square multiselect-checkbox" />
        )}
        {props.data.label}
    </components.Option>
);

const GroupHeading = (props) => {
    const [open, setOpen] = useState(false);

    if (props.selectProps.isMulti) {
        const count = props.data.options.filter((opt) =>
            props.selectProps.value.map((v) => v.value).includes(opt.value)
        ).length;

        let icon;

        if (count === 0) icon = 'fal fa-square';
        else if (props.data.options.length === count) icon = 'fal fa-square-check';
        else icon = 'fal fa-square-minus';

        const groupToggle = () => {
            const currentValues = props.selectProps.value;

            props.selectProps.onChange(
                props.data.options.length === count
                    ? currentValues.filter((val) => !props.data.options.map((opt) => opt.value).includes(val.value))
                    : currentValues.concat(
                          props.data.options.filter((opt) => !currentValues.map((val) => val.value).includes(opt.value))
                      )
            );
        };

        return (
            <components.GroupHeading {...props}>
                <button type="button" className={`group-toggle ${count === 0 ? '' : 'checked'}`} onClick={groupToggle}>
                    <i className={icon} />
                </button>
                <button type="button" onClick={() => setOpen(!open)}>
                    {props.data.label}
                </button>
                <input id={`${props.id}-dropdown`} type="checkbox" checked={open} onChange={() => setOpen(!open)} />
            </components.GroupHeading>
        );
    }

    return (
        <components.GroupHeading {...props}>
            <button type="button" onClick={() => setOpen(!open)}>
                <i className={open ? 'fas fa-chevron-down' : 'fas fa-chevron-up'} />
                {props.data.label}
            </button>
            <input id={`${props.id}-dropdown`} type="checkbox" checked={open} onChange={() => setOpen(!open)} />
        </components.GroupHeading>
    );
};

const MultiValueCounterBuilder = (multiValueCounterMessage: string, pluralMultiValueCounterMessage: string) => {
    const single = multiValueCounterMessage;
    const plural =
        multiValueCounterMessage === 'Selected'
            ? multiValueCounterMessage
            : pluralMultiValueCounterMessage ?? `${multiValueCounterMessage}s`;

    const MultiValueCounter = (props: MultiValueProps) => {
        if (props.index !== 0) return null;
        const { length } = props.getValue();
        return <div className="option-label">{`${length === 1 ? single : plural} (${length})`}</div>;
    };
    return MultiValueCounter;
};

const Select = <T,>({
    value,
    options,
    isMulti = false,
    isSearchable = false,
    isClearable = false,
    autoFocus = false,
    multiValueCounterMessage = 'Selected',
    pluralMultiValueCounterMessage,
    placeholder,
    onChange,
    onBlur,
    className,
    disabled,
    id,
}: Props<T>) => (
    <ReactSelect
        id={id}
        options={options}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        autoFocus={autoFocus}
        menuIsOpen={autoFocus ? true : undefined}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={`select ${className}`}
        hideSelectedOptions={false}
        components={{
            Option: isMulti ? CheckboxOption : components.Option,
            MultiValue: MultiValueCounterBuilder(multiValueCounterMessage, pluralMultiValueCounterMessage),
            GroupHeading,
        }}
        unstyled
        classNames={{
            control: () => 'select-control',
            valueContainer: () => 'select-value-container',
            dropdownIndicator: () => 'select-dropdown-indicator',
            input: () => 'select-input',
            menu: () => 'select-menu',
            menuList: () => 'select-menu-list',
            option: (state) => `select-option ${state.isSelected ? 'selected' : ''}`,
            groupHeading: () => 'select-group-heading',
            group: () => 'select-group',
        }}
        value={value}
        isDisabled={disabled}
        menuPlacement="auto"
    />
);

export default Select;
