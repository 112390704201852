// eslint-disable-next-line import/no-cycle
import { RELEASE_SHOW, RELEASE_HIDE } from '../actionTypes';

const persistedState = localStorage.getItem('scope.release')
    ? JSON.parse(localStorage.getItem('scope.release'))
    : { releaseShown: null, releaseShow: false };

function releaseReducer(state = persistedState, action) {
    const { type } = action;
    const previous = state;
    switch (type) {
        case RELEASE_SHOW:
            state = {
                ...state,
                releaseShow: true,
            };
            break;
        case RELEASE_HIDE:
            state = {
                ...state,
                releaseShow: false,
                releaseShown: new Date().toISOString(),
            };
            break;
        default:
            break;
    }
    if (previous !== state) {
        localStorage.setItem('scope.release', JSON.stringify(state));
    }
    return state;
}

export default releaseReducer;
