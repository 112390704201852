import { nanoid } from '@reduxjs/toolkit';
import ColorSelector from 'components/giro3d/settingsMenu/inputs/ColorSelector';
import { Color } from 'three';

export type Props = {
    color: Color;
    title: string;
    onChange: (color: Color) => void;
};

function ColorPicker(props: Props) {
    const id = nanoid();
    return (
        <li>
            <label htmlFor={id}>
                <div />
                <div>
                    <label htmlFor={id}>{props.title}</label>
                </div>
                <ColorSelector color={props.color} onChange={props.onChange} />
            </label>
        </li>
    );
}

export default ColorPicker;
