import { useEventBus } from 'EventBus';
import { useState } from 'react';
import { useMountEffect } from 'components/utils';
import { LoadingStatus } from 'types/LoadingStatus';

const InstanceLoadingIndicator = () => {
    const eventBus = useEventBus();
    const [progress, setProgress] = useState<number | null>(null);

    const onStatusUpdated = (arg: { status: LoadingStatus }) => {
        const { status } = arg;
        if (status.loading) {
            setProgress(status.progress);
        } else {
            setProgress(null);
        }
    };

    const mount = () => {
        eventBus.subscribe('instance-loading-status-updated', onStatusUpdated);
    };

    const unmount = () => {
        eventBus.unsubscribe('instance-loading-status-updated', onStatusUpdated);
    };

    useMountEffect(mount, unmount);

    if (progress !== null && progress < 1) {
        return (
            <>
                <i className="fas fa-spinner fa-pulse" />
                <span className="mx-2">{Math.round(progress * 100)}%</span>
            </>
        );
    }
    return null;
};

export default InstanceLoadingIndicator;
