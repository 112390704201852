import { useDispatch } from 'react-redux';
import { HasRadius, LayerState } from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Input from 'components/dropdown/Input';

export type Props = { layer: LayerState & HasRadius };

function RadiusSetting(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();

    const radius = useAppSelector(layersSlice.getRadius(layer));
    function changeRadius(value: number) {
        dispatch(layersSlice.setRadius({ layer, value }));
    }

    return (
        <>
            <Input
                title="Radius"
                icon="fas fa-waveform-lines"
                min={0}
                max={100}
                step={0.5}
                value={radius}
                onChange={(v) => changeRadius(v)}
            />
            <hr />
        </>
    );
}
export default RadiusSetting;
