import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { PAGE } from 'services/Constants';
import {
    getCanUserAccessAdminPage,
    getCanUserAccessRequests,
    getMembershipRequests,
    getUser,
} from '../../redux/selectors';

import Users from './Users';
import Organizations from './Organizations';
import AccessRequests from './AccessRequests';
import { selectPage } from '../../redux/actions';
import ErrorBoundary from '../ErrorBoundary';
import ApiKeys from './ApiKeys';
import Toolbar from './Toolbar';

const TABS = {
    USERS: 'users',
    REQUESTS: 'requests',
    ORGANIZATIONS: 'organizations',
    API_KEYS: 'apiKeys',
};

const Admin = () => {
    const user = useSelector(getUser);
    const adminAccess = useSelector(getCanUserAccessAdminPage);
    const requestAccess = useSelector(getCanUserAccessRequests);

    if (!adminAccess) return <div className="admin">No Access</div>;

    const dispatch = useDispatch();

    const requests = useSelector(getMembershipRequests);

    useEffect(() => {
        document.title = 'Admin | SCOPE';
        dispatch(selectPage(PAGE.ADMIN));
    }, []);

    const [currentActiveTab, setCurrentActiveTab] = useState(TABS.USERS);
    const goToTab = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    };

    return (
        <div className="admin-space">
            <Toolbar />
            <div className="admin">
                <div className="tab-menu">
                    <div className="tab-menu-header">
                        <div className="full-page-title">Admin</div>
                    </div>
                    <Nav>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: currentActiveTab === TABS.USERS,
                                })}
                                onClick={() => goToTab(TABS.USERS)}
                            >
                                <i className="fas fa-users" />
                                <span>Users</span>
                            </NavLink>
                        </NavItem>
                        {requestAccess ? (
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: currentActiveTab === TABS.REQUESTS,
                                    })}
                                    onClick={() => goToTab(TABS.REQUESTS)}
                                >
                                    <div className="admin-bubble-icon">
                                        <i className="fas fa-key" />
                                        {requests && requests.length !== 0 ? (
                                            <div className="notification-bubble">
                                                <i className="fas fa-circle" />
                                                <span>{requests.length}</span>
                                            </div>
                                        ) : null}
                                    </div>
                                    <span>Access Requests</span>
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {user.is_admin ? (
                            <>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: currentActiveTab === TABS.ORGANIZATIONS,
                                        })}
                                        onClick={() => goToTab(TABS.ORGANIZATIONS)}
                                    >
                                        <i className="fas fa-briefcase" />
                                        <span>Organizations</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: currentActiveTab === TABS.API_KEYS,
                                        })}
                                        onClick={() => goToTab(TABS.API_KEYS)}
                                    >
                                        <i className="fas fa-key-skeleton-left-right" />
                                        <span>API Keys</span>
                                    </NavLink>
                                </NavItem>
                            </>
                        ) : null}
                    </Nav>
                </div>
                <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId={TABS.USERS}>
                        <ErrorBoundary
                            dispatch={dispatch}
                            fallback={
                                <span className="error-fallback-message">
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                    An error occured in this tab.
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                </span>
                            }
                        >
                            <Users />
                        </ErrorBoundary>
                    </TabPane>
                    {requestAccess ? (
                        <TabPane tabId={TABS.REQUESTS}>
                            <ErrorBoundary
                                dispatch={dispatch}
                                fallback={
                                    <span className="error-fallback-message">
                                        <i className="fal fa-exclamation-triangle icon-red" />
                                        An error occured in this tab.
                                        <i className="fal fa-exclamation-triangle icon-red" />
                                    </span>
                                }
                            >
                                <AccessRequests />
                            </ErrorBoundary>
                        </TabPane>
                    ) : null}
                    {user.is_admin ? (
                        <>
                            <TabPane tabId={TABS.ORGANIZATIONS}>
                                <ErrorBoundary
                                    dispatch={dispatch}
                                    fallback={
                                        <span className="error-fallback-message">
                                            <i className="fal fa-exclamation-triangle icon-red" />
                                            An error occured in this tab.
                                            <i className="fal fa-exclamation-triangle icon-red" />
                                        </span>
                                    }
                                >
                                    <Organizations />
                                </ErrorBoundary>
                            </TabPane>
                            <TabPane tabId={TABS.API_KEYS}>
                                <ErrorBoundary
                                    dispatch={dispatch}
                                    fallback={
                                        <span className="error-fallback-message">
                                            <i className="fal fa-exclamation-triangle icon-red" />
                                            An error occured in this tab.
                                            <i className="fal fa-exclamation-triangle icon-red" />
                                        </span>
                                    }
                                >
                                    <ApiKeys />
                                </ErrorBoundary>
                            </TabPane>
                        </>
                    ) : null}
                </TabContent>
            </div>
        </div>
    );
};

export default Admin;
