import { PANE } from 'services/Constants';
import Notifications from 'components/notification/Notifications';
import Profile from 'components/authentication/Profile';
import HomeButton from 'components/HomeButton';
import ToolbarButton from 'components/ToolbarButton';
import UploadsMenu from 'components/uploads/UploadsMenu';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useState } from 'react';

const Toolbar = () => {
    const [uploadsOpen, setUploadsOpen] = useState(false);

    return (
        <div className="toolbar">
            <HomeButton />
            <hr />
            <div className="spacer" />
            <hr />
            <div className="button-set">
                <ToolbarButton
                    title="Uploads"
                    iconName="fad fa-upload"
                    id={PANE.UPLOADS}
                    onClick={() => setUploadsOpen(true)}
                />
                <Modal isOpen={uploadsOpen} toggle={() => setUploadsOpen(false)}>
                    <ModalHeader>Uploads</ModalHeader>
                    <ModalBody>
                        <UploadsMenu />
                    </ModalBody>
                </Modal>
                <Notifications />
                <Profile />
            </div>
        </div>
    );
};

export default Toolbar;
