import { EventMap, useEventBus } from 'EventBus';
import { useMountEffect } from 'components/utils';
import { useEffect, useState } from 'react';

import { Tooltip } from 'reactstrap';
import * as giro3dSlice from 'redux/giro3d';
import * as drawToolSlice from 'redux/drawTool';
import { LAYER_TYPES } from 'services/Constants';
import { useAppSelector } from 'store';
import HoveredItem, { HoverableType } from 'types/HoveredItem';
import { useGiro3DContext } from './Giro3DContext';

function canShowTooltip(hoveredType: HoverableType): boolean {
    switch (hoveredType) {
        case LAYER_TYPES.BATHYMETRY:
            return false;
        default:
            return true;
    }
}

const TooltipLabel = () => {
    const context = useGiro3DContext();
    const [item, setItem] = useState<HoveredItem>(null);
    const [element, setElement] = useState<HTMLDivElement>(null);
    const [show, setShow] = useState(false);
    const [position, setPosition] = useState<{ x: number; y: number }>(null);
    let timeout: NodeJS.Timeout = null;

    const contextMenu = useAppSelector(giro3dSlice.getContextMenu, (a, b) => a.open === b.open);
    const drawTool = useAppSelector(drawToolSlice.getTool);

    const eventBus = useEventBus();

    function onHoveredItemChanged(arg: EventMap['hovered-item']) {
        if (arg.item == null) {
            setShow(false);
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        } else if (arg.item !== item) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(() => setShow(true), 300);
        }

        setItem(arg.item);
        setPosition(arg.location);

        const domElement = context.getInstance()?.domElement;

        if (domElement) {
            if (arg.item && canShowTooltip(arg.item.itemType)) {
                domElement.style.cursor = 'pointer';
            } else {
                domElement.style.cursor = 'default';
            }
        }
    }

    function mount() {
        const elt = document.createElement('div');
        elt.style.position = 'absolute';
        elt.style.pointerEvents = 'none';
        document.body.appendChild(elt);
        setElement(elt);

        eventBus.subscribe('hovered-item', onHoveredItemChanged);
    }

    function unmount() {
        element?.remove();
        setElement(null);
        eventBus.unsubscribe('hovered-item', onHoveredItemChanged);
    }

    useEffect(() => {
        if (position) {
            element.style.top = `${position.y - 10}px`;
            element.style.left = `${position.x}px`;
        }
    }, [position]);

    useMountEffect(mount, unmount);

    if (!drawTool && !contextMenu.open && item && canShowTooltip(item.itemType) && position && element && show) {
        return (
            <Tooltip
                placement="top"
                className="pe-none"
                target={element}
                isOpen={item != null}
                fade={false}
                style={{ pointerEvents: 'none' }}
            >
                <span className="submenu-tooltip pe-none">{item.name}</span>
            </Tooltip>
        );
    }

    return null;
};

export default TooltipLabel;
