// eslint-disable-next-line import/no-cycle
import { ADD_PROJ4 } from '../actionTypes';

function reducer(
    state = {
        list: [],
    },
    action
) {
    const { type, payload } = action;
    switch (type) {
        case ADD_PROJ4:
            return { ...state, list: [...state.list, ...payload] };
        default:
            return state;
    }
}

export default reducer;
