// eslint-disable-next-line import/no-cycle
import {
    MEASUREMENT_COORDINATES,
    MEASUREMENT_RESULT,
    OVERVIEW_MAP_LOADED,
    OVERVIEW_MAP_RESOLUTION,
    OVERVIEW_MAP_FEATURE_INFO,
} from '../actionTypes';

const initialState = {
    map: undefined,
    measurementCoords: undefined,
    measurementResult: undefined,
    resolution: undefined,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case OVERVIEW_MAP_LOADED:
            return {
                ...state,
                map: payload,
            };
        case MEASUREMENT_COORDINATES:
            return {
                ...state,
                measurementCoords: payload,
            };
        case MEASUREMENT_RESULT:
            return {
                ...state,
                measurementResult: payload,
            };
        case OVERVIEW_MAP_RESOLUTION:
            return {
                ...state,
                resolution: payload,
            };
        case OVERVIEW_MAP_FEATURE_INFO:
            return {
                ...state,
                featureInfo: payload,
            };
        default:
            return state;
    }
}
