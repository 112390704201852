import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import DosApi from '../../../services/DosApi';
import { unloadDataset, showError } from '../../../redux/actions';
import * as datasetsSlice from '../../../redux/datasets';

const findOverviews = (rootDataset, allDatasets, overviews = {}) => {
    overviews[rootDataset.id] = rootDataset;
    if (rootDataset.overview_id) {
        const overview = allDatasets.filter((ds) => ds.id === rootDataset.overview_id)[0];
        // when an overview is deleted, the root dataset will be in an incosistent state since the overview_id is set to null in the backend, but not updated in the
        if (overview) {
            findOverviews(overview, allDatasets, overviews);
        }
    }
    return overviews;
};

const MosaicOverviewTable = (props) => {
    const { dataset } = props;
    const allDatasets = useSelector(datasetsSlice.getProjectDatasets);
    const overviews = findOverviews(dataset, allDatasets);
    const dispatch = useDispatch();
    const [openOverviewModal, setOpenOverviewModal] = useState(false);
    const [openDeleteOverviewModal, setOpenDeleteOverviewModal] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [currentDataset, setCurrentDataset] = useState(undefined);

    const isConverting = () => Object.values(overviews).some((o) => o.state === 'converting');

    const toggleDeleteOverviewModal = (ds) => {
        setCurrentDataset(ds);
        setOpenDeleteOverviewModal(!openDeleteOverviewModal);
    };

    const toggleCreateOverviewModal = (ds) => {
        setCurrentDataset(ds);
        setOpenOverviewModal(!openOverviewModal);
    };

    const onCreateOverview = (ds) => {
        // let overviewId;
        setIsSendingRequest(true);
        DosApi.createMosaicOverview(ds.id)
            .then((overviewDataset) => {
                // overviewId = overviewDataset.id;
                dispatch(datasetsSlice.addDataset(overviewDataset));
            })
            .then(() =>
                // update root dataset since overview_id is now null
                DosApi.fetchDataset(ds.id)
            )
            .then((data) => {
                console.log(data);
                data.doRender = ds.id === dataset.id;
                dispatch(datasetsSlice.updateDataset(data));
            })
            // TODO: See if this needs to update dataset state to trigger polling
            .then(() => setIsSendingRequest(false))
            .catch((err) => showError(dispatch, err));
        toggleCreateOverviewModal(undefined);
    };

    const onDeleteOverview = (ds) => {
        setIsSendingRequest(true);
        const rootDataset = Object.values(overviews).filter((o) => o.overview_id === ds.id)[0];
        DosApi.deleteDataset(ds.id)
            .then(() => unloadDataset(dispatch, ds))
            .then(() =>
                // update root dataset since overview_id is now null
                DosApi.fetchDataset(rootDataset.id)
            )
            .then((data) => {
                data.doRender = rootDataset.id === dataset.id;
                dispatch(datasetsSlice.updateDataset(data));
            })
            .then(() => setIsSendingRequest(false))
            .catch((err) => showError(dispatch, err));
        toggleDeleteOverviewModal(undefined);
    };

    const createOverviewButton = (ds) => {
        if (ds.id !== dataset.id) {
            // Secondary overviews will currently fail since the backend currently creates one single cog for the entire root dataset.
            return null;
        }
        if (isConverting() || isSendingRequest) {
            return (
                <Button color="primary" onClick={() => toggleCreateOverviewModal(ds)} disabled>
                    {' '}
                    <i className="fas fa-spinner fa-pulse" />
                    Create Overview{' '}
                </Button>
            );
        }
        return (
            <Button color="primary" onClick={() => toggleCreateOverviewModal(ds)}>
                {' '}
                Create Overview{' '}
            </Button>
        );
    };

    const deleteButton = (ds) => {
        if (ds.id === dataset.id) {
            return null;
        }
        if (isConverting() || isSendingRequest) {
            return (
                <Button
                    color="danger"
                    id={`overviewbtn-delete-${ds.id}`}
                    title={`Delete ${ds.name}`}
                    onClick={() => toggleDeleteOverviewModal(ds)}
                    disabled
                >
                    <i className="fal fa-trash-alt" />
                </Button>
            );
        }
        return (
            <Button
                color="danger"
                id={`overviewbtn-delete-${ds.id}`}
                title={`Delete ${ds.name}`}
                onClick={() => toggleDeleteOverviewModal(ds)}
            >
                <i className="fal fa-trash-alt" />
            </Button>
        );
    };

    const renderTableRow = (ds) => {
        const zoomCol = !ds.properties?.minZoom ? null : `${ds.properties?.minZoom}-${ds.properties?.maxZoom}`;
        const numFilesCol = !ds.properties?.numFiles ? null : `${ds.properties?.numFiles}`;
        return (
            <tr key={`mosaicOverviewTable-row-${ds.id}`}>
                <td>{ds.name}</td>
                <td>{ds.state}</td>
                <td>{zoomCol}</td>
                <td>{numFilesCol}</td>
                <td>{createOverviewButton(ds)}</td>
                <td>{deleteButton(ds)}</td>
            </tr>
        );
    };

    const createOverviewModal = () => {
        if (!currentDataset) {
            return null;
        }
        return (
            <Modal
                isOpen={openOverviewModal}
                toggle={() => toggleCreateOverviewModal(undefined)}
                keyboard={false}
                centered
            >
                <ModalBody>
                    {`Are you sure you want to create a new overview for ${currentDataset.name}?
                        This will delete any existing overviews for this dataset.`}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => onCreateOverview(currentDataset)}>
                        Yes
                    </Button>
                    <Button color="secondary" onClick={() => toggleCreateOverviewModal(undefined)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    const deleteOverviewModal = () => {
        if (!currentDataset) {
            return null;
        }
        return (
            <Modal
                isOpen={openDeleteOverviewModal}
                toggle={() => toggleDeleteOverviewModal(undefined)}
                keyboard={false}
                centered
            >
                <ModalBody>{`Are you sure you want to delete ${currentDataset.name}?`}</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => onDeleteOverview(currentDataset)}>
                        Delete
                    </Button>
                    <Button color="secondary" onClick={() => toggleDeleteOverviewModal(undefined)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    return (
        <>
            <Table size="sm" title="Mosaics">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>State</th>
                        <th>Zoom</th>
                        <th># Files</th>
                        <th>Create overview?</th>
                        <th>delete?</th>
                    </tr>
                </thead>
                <tbody>{overviews === null ? null : Object.values(overviews).map((o) => renderTableRow(o))}</tbody>
            </Table>
            {createOverviewModal()}
            {deleteOverviewModal()}
        </>
    );
};

export default MosaicOverviewTable;
