import { nanoid } from '@reduxjs/toolkit';
import { InputHTMLAttributes } from 'react';

function SettingInput(props: InputHTMLAttributes<HTMLInputElement> & { unit?: string }) {
    const id = nanoid();
    return (
        <li>
            <div>
                <span>{props.title}</span>
                <tr className="settings-input">
                    <td>
                        <input title="floorLevel" id={id} type="number" value={props.value} {...props} />
                    </td>
                    <td>{props.unit}</td>
                </tr>
            </div>
        </li>
    );
}

export default SettingInput;
