import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from './redux/reducers/index';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        // TODO we currently must disable those middlewares because
        // we are still putting non-serializable/mutable legacy stuff in the store
        // (for example we put the entire overview OpenLayers Map in the store).
        // However, those middlewares are very useful to ensure the quality and consistency
        // of our state, so we should enable them ASAP.
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type GetState = typeof store.getState;

/**
 * A hook that is typed to match the store's state.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * A hook that is typed to match the store's dispatch.
 */
export const useAppDispatch: () => Dispatch = useDispatch;

export default store;
