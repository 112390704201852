// React
import { useDispatch, useSelector } from 'react-redux';
import { PopoverBody } from 'reactstrap';
import * as gridSlice from 'redux/grid';

import { toHex } from 'types/common';

// Components
import HeaderButton from 'components/flexLayout/HeaderButton';
import Select from 'components/dropdown/Select';
import ColorPicker from 'components/dropdown/ColorPicker';
import Slider from 'components/dropdown/Slider';
import Input from 'components/dropdown/Input';
import { Option } from 'components/Select';
import Toggle from '../../dropdown/Toggle';

import { TICKS_PRESETS } from '../../../services/Constants';

function GridSetting() {
    const dispatch = useDispatch();

    const ticks = useSelector(gridSlice.getTicks);
    const ceiling = useSelector(gridSlice.getCeilingLevel);
    const showCeiling = useSelector(gridSlice.getCeilingVisibility);
    const showSides = useSelector(gridSlice.getSideVisibility);
    const floor = useSelector(gridSlice.getFloorLevel);
    const color = useSelector(gridSlice.getColor);
    const opacity = useSelector(gridSlice.getOpacity);
    const visible = useSelector(gridSlice.isVisible);
    const showLabels = useSelector(gridSlice.getLabelVisibility);

    const changeFloorLevel = (value: number) => {
        if (value < ceiling) {
            dispatch(gridSlice.setFloorLevel(value));
        }
    };

    const changeCeilingLevel = (value: number) => {
        if (value > floor) {
            dispatch(gridSlice.setCeilingLevel(value));
        }
    };

    const changeTicks = (value: gridSlice.Ticks) => {
        dispatch(gridSlice.setTicks(value));
    };

    return (
        <HeaderButton
            toggle={{
                name: 'Grid Visibility',
                icon: 'fas fa-ruler-combined',
                checked: visible,
                onChange: (v) => dispatch(gridSlice.setVisibility(v)),
            }}
            popover={{
                name: 'Grid Settings',
                content: (
                    <PopoverBody>
                        <ul>
                            <Toggle
                                title="Vertical Grid"
                                checked={showSides}
                                onChange={(v) => dispatch(gridSlice.setSidesVisibility(v))}
                            />
                            <Toggle
                                title="Ceiling Grid"
                                checked={showCeiling}
                                onChange={(v) => dispatch(gridSlice.setCeilingVisibility(v))}
                            />
                            <Toggle
                                title="Show Labels"
                                checked={showLabels}
                                onChange={(v) => dispatch(gridSlice.setLabelsVisibility(v))}
                            />
                            <ColorPicker
                                title="Color"
                                color={color}
                                onChange={(c) => dispatch(gridSlice.setColor(toHex(c)))}
                            />
                            <Slider
                                value={opacity * 100}
                                title="Opacity"
                                icon="fas fa-eye-slash"
                                min={0}
                                max={100}
                                step={1}
                                onChange={(v) => dispatch(gridSlice.setOpacity(v / 100))}
                                unit="%"
                            />
                            <Input
                                value={parseFloat(floor?.toFixed(2)) ?? 0}
                                title="Floor Level"
                                onChange={(v) => changeFloorLevel(v)}
                                step={1}
                                onBlur={() => changeFloorLevel(ceiling)}
                                unit="m"
                                icon="fas fa-border-top"
                                min={-100000}
                                max={100000}
                            />
                            <Input
                                value={parseFloat(ceiling?.toFixed(2)) ?? 0}
                                title="Ceiling Level"
                                onChange={(v) => changeCeilingLevel(v)}
                                step={1}
                                onBlur={() => changeCeilingLevel(ceiling)}
                                unit="m"
                                icon="fas fa-border-bottom"
                                min={-100000}
                                max={100000}
                            />
                            <Select
                                title="X Ticks"
                                value={{ label: `${ticks?.x}m`, value: ticks?.x }}
                                onChange={(v) =>
                                    changeTicks({ x: (v as Option<number>).value, y: ticks?.y ?? 0, z: ticks?.z ?? 0 })
                                }
                                options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({
                                    label: `${x}m`,
                                    value: x,
                                }))}
                                className="narrow"
                            />
                            <Select
                                title="Y Ticks"
                                value={{ label: `${ticks?.y}m`, value: ticks?.y }}
                                onChange={(v) =>
                                    changeTicks({ x: ticks?.x ?? 0, y: (v as Option<number>).value, z: ticks?.z ?? 0 })
                                }
                                options={TICKS_PRESETS.sort((a, b) => a - b).map((y) => ({
                                    label: `${y}m`,
                                    value: y,
                                }))}
                                className="narrow"
                            />
                            <Select
                                title="Z Ticks"
                                value={{ label: `${ticks?.z}m`, value: ticks?.z }}
                                onChange={(v) =>
                                    changeTicks({ x: ticks?.x ?? 0, y: ticks?.y ?? 0, z: (v as Option<number>).value })
                                }
                                options={TICKS_PRESETS.sort((a, b) => a - b).map((z) => ({
                                    label: `${z}m`,
                                    value: z,
                                }))}
                                className="narrow"
                            />
                        </ul>
                    </PopoverBody>
                ),
            }}
        />
    );
}

export default GridSetting;
