import { React, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Spinner } from 'reactstrap';

import { isRegistering, isFetchingUser, getUser, getApiHealthCheck, getUserEligible } from '../../redux/selectors';
import { registerUser, userEligible } from '../../redux/actions';

import logo from '../../images/symbol.svg';
import { Logout } from '../login/Login';

const RegisterUser = () => {
    const dispatch = useDispatch();

    // Fetching user info for the form:
    const registering = useSelector(isRegistering);
    const fetchingUser = useSelector(isFetchingUser);
    const user = useSelector(getUser);
    const isWorking = registering || fetchingUser;
    const apiHealth = useSelector(getApiHealthCheck);
    const userIsEligible = useSelector(getUserEligible);

    useEffect(() => {
        if (!isWorking && userIsEligible.eligible === undefined) {
            dispatch(userEligible());
        }
    }, [isWorking, userIsEligible]);

    const HandleSubmit = () => {
        dispatch(registerUser());
    };

    const RequestAccess = () => (
        <button type="button" className="pane-button large" onClick={() => HandleSubmit()}>
            Request access as a new user
        </button>
    );

    const PendingActivation = () => (
        <>
            <p>You are registered and awaiting an admin to give you access</p>
            <p>Please come back at a later time</p>
            <div className="login-footer">
                <Logout />
            </div>
        </>
    );

    const NoValidOrganization = () => (
        <>
            <p>Your identity provider is not currently linked to an active organization</p>
            <p>
                Make sure that you are using the correct account to login, or contact Argeo if you think this is an
                error
            </p>
            <div className="login-footer">
                <Logout />
            </div>
        </>
    );

    const IsWorking = () => <Spinner animation="border" />;

    const FormOrInfo = () => {
        let toReturn = <NoValidOrganization />;

        if (user) {
            toReturn = <PendingActivation />;
        } else if (userIsEligible.eligible === true) {
            toReturn = <RequestAccess />;
        }

        return toReturn;
    };

    return (
        <div className="login-bg">
            {apiHealth?.backend_available === false ? null : (
                <Form inline="true" className="login-form">
                    <img src={logo} alt="Argeo logo" />
                    {isWorking ? <IsWorking /> : <FormOrInfo />}
                </Form>
            )}
        </div>
    );
};

export default RegisterUser;
