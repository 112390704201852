import * as giro3dSlice from 'redux/giro3d';
// React
import { useState } from 'react';

import { useAppSelector } from 'store';
import { useEventBus } from 'EventBus';
import { getService } from 'ServiceContainer';
// Redux
import { useMountEffect } from 'components/utils';
import BaseGiro3dService from 'services/BaseGiro3dService';
import { HostView } from 'giro3d_extensions/layers/Layer';

import { getSeismicViewEnabled } from 'redux/selectors';

// Components
import Help from '../Help';

import TooltipLabel from './TooltipLabel';
import Grid from './Grid';
import LineFollowerModal from './lineFollower/LineFollowerModal';
import LineFollower from './lineFollower/LineFollower';
import DrawToolSwap from './DrawToolSwap';

const Giro3D = ({ giro3dRef }) => {
    const eventBus = useEventBus();
    const mainView = getService('MainViewManager');

    const giro3dReady = useAppSelector(giro3dSlice.isInitialized);
    const seismicViewEnabled = useAppSelector<boolean>(getSeismicViewEnabled);

    const [contextLost, setContextLost] = useState<boolean>(false);

    const onContextLost = (options: { service: BaseGiro3dService }) => {
        if (options.service.view === HostView.MainView) {
            setContextLost(true);
        }
    };

    const onContextRestored = (options: { service: BaseGiro3dService }) => {
        if (options.service.view === HostView.MainView) {
            setContextLost(false);
        }
    };

    const subscribe = () => {
        eventBus.subscribe('rendering-context-lost', onContextLost);
        eventBus.subscribe('rendering-context-restored', onContextRestored);
    };

    const unsubscribe = () => {
        eventBus.unsubscribe('rendering-context-lost', onContextLost);
        eventBus.unsubscribe('rendering-context-restored', onContextRestored);
    };

    useMountEffect(subscribe, unsubscribe);

    return (
        <div className="project">
            {contextLost && (
                <div className="map-container-loader">
                    <div style={{ width: '19rem' }}>
                        <h5 className="row mb-3">
                            <span>
                                <i className="fal fa-exclamation-triangle icon-yellow" /> Failed to display 3D view.
                            </span>
                        </h5>

                        <div className="row mb-3 w-100">
                            <button
                                type="button"
                                className="pane-button large"
                                title="Reload project"
                                onClick={() => window.location.reload()}
                            >
                                <i className="fal fa-arrow-rotate-left" /> Reload project
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <TooltipLabel />
            <div className="giro3d">
                <div className="giro3d-container" ref={giro3dRef} hidden={seismicViewEnabled} />

                <LineFollowerModal />
                <LineFollower />

                {giro3dReady ? <Grid instance={mainView.getInstance()} /> : null}

                <DrawToolSwap />
                <Help />
            </div>
        </div>
    );
};

export default Giro3D;
