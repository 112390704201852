import type HoveredItem from 'types/HoveredItem';

export default interface IsHoverable {
    isHoverable: true;

    /**
     * Changes the style of the element when it is hovered.
     */
    hover(hover: boolean): void;

    /**
     * Gets info on hover.
     */
    getHoverInfo(): HoveredItem;
}

export function isHoverable(obj: unknown): obj is IsHoverable {
    return (obj as IsHoverable)?.isHoverable;
}
