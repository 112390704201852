const TabHeader = (props: { left; center; right }) => {
    return (
        <div className="tabHeader">
            <div>{props.left}</div>
            <div>{props.center}</div>
            <div>{props.right}</div>
        </div>
    );
};

export default TabHeader;
