import { nanoid } from '@reduxjs/toolkit';

export enum STATE {
    LEFT = 'left',
    CENTRE = 'centre',
    RIGHT = 'right',
}

export type Props = {
    state: STATE;
    title: string;
    onChange: (newState: STATE) => void;
    experimental?: boolean;
};

function TriState(props: Props) {
    const id = nanoid();
    return (
        <li className={props.experimental ? 'experimental' : ''}>
            <label>
                <div />
                <div>
                    <label htmlFor={id}>{props.title}</label>
                </div>
                <div className={`tri-state ${props.state}`}>
                    <span className={`pip ${props.state}`} />
                    <button type="button" onClick={() => props.onChange(STATE.LEFT)} aria-label="Left" />
                    <button type="button" onClick={() => props.onChange(STATE.CENTRE)} aria-label="Centre" />
                    <button type="button" onClick={() => props.onChange(STATE.RIGHT)} aria-label="Right" />
                </div>
            </label>
        </li>
    );
}

export default TriState;
