import { useDispatch } from 'react-redux';
import ReactSlider from 'react-slider';

import { HasDraping, LayerState } from 'types/LayerState';

import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

import { CSSProperties } from 'react';
import type ElevationRange from '@giro3d/giro3d/core/ElevationRange';
import { useAppSelector } from 'store';
import Toggle from 'components/dropdown/Toggle';
import Input from 'components/dropdown/Input';

export type Props = {
    layer: LayerState & HasDraping;
};

const DrapingSettings = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const isDraped = useAppSelector(layersSlice.isDraped(layer));
    function setDraped(value: boolean) {
        dispatch(layersSlice.setDraping({ layer, value }));
    }

    const zOffset = useAppSelector(layersSlice.getUndrapedZOffset(layer));
    function setZOffset(value: number) {
        dispatch(layersSlice.setUndrapedZOffset({ layer, value }));
    }

    const enableClippingRange = useAppSelector(layersSlice.isClippingRangeEnabled(layer));
    function setEnableClippingRange(value: boolean) {
        dispatch(layersSlice.setEnableClippingRange({ layer, value }));
    }

    const maxRange = useAppSelector(datasetsSlice.totalElevationRange);

    const clippingRange = useAppSelector(layersSlice.getClippingRange(layer));
    function setClippingRange(value: ElevationRange) {
        dispatch(layersSlice.setClippingRange({ layer, value }));
    }

    const min = Math.round(Math.max(clippingRange.min, maxRange.min));
    const max = Math.round(Math.min(clippingRange.max, maxRange.max));

    return (
        <>
            <Toggle title="Drape on Bathymetry" checked={isDraped} onChange={(v) => setDraped(v)} />

            {/* If the dataset is not draped, we can change its elevation */}
            {!isDraped ? (
                <Input
                    title="Elevation"
                    value={zOffset}
                    onChange={(v) => setZOffset(v)}
                    step={1}
                    unit="m"
                    min={-100000}
                    max={100000}
                />
            ) : null}

            {/* If the dataset is draped, we can enable clipping range */}
            {isDraped ? (
                <>
                    <Toggle
                        title="Clipping Range"
                        checked={enableClippingRange}
                        onChange={(v) => setEnableClippingRange(v)}
                    />
                    {enableClippingRange ? (
                        <>
                            <li>
                                <label>
                                    <div />
                                    <div className="legend-limits">
                                        <span>{`${Math.round(maxRange.min)}m`}</span>
                                        <span>{`${Math.round(maxRange.max)}m`}</span>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="color-bar">
                                    <div />
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="slider-thumb"
                                        trackClassName="slider-track"
                                        value={[min, max]}
                                        min={Math.round(maxRange.min)}
                                        max={Math.round(maxRange.max)}
                                        ariaLabel={['Lower thumb', 'Upper thumb']}
                                        ariaValuetext={(state: { valueNow: number }) => `Thumb value ${state.valueNow}`}
                                        renderTrack={(prop: { style: CSSProperties }, state: { index: number }) => {
                                            switch (state.index) {
                                                case 1:
                                                    return (
                                                        <div
                                                            className="elevation-slider-active"
                                                            style={{ ...prop.style }}
                                                        />
                                                    );
                                                case 0:
                                                case 2:
                                                    return (
                                                        <div
                                                            className="elevation-slider-inactive"
                                                            style={{ ...prop.style }}
                                                        />
                                                    );
                                                default:
                                                    return null;
                                            }
                                        }}
                                        step={1}
                                        minDistance={1}
                                        pearling
                                        onChange={(data: [number, number]) => {
                                            setClippingRange({ min: data[0], max: data[1] });
                                        }}
                                    />
                                </label>
                            </li>
                            <Input
                                title="Min"
                                value={min}
                                step={1}
                                min={Math.round(maxRange.min)}
                                max={Math.round(maxRange.max)}
                                onChange={(v) => {
                                    setClippingRange({
                                        min: v,
                                        max,
                                    });
                                }}
                                unit="m"
                            />
                            <Input
                                title="Max"
                                value={max}
                                step={1}
                                min={Math.round(maxRange.min)}
                                max={Math.round(maxRange.max)}
                                onChange={(v) => {
                                    setClippingRange({
                                        min,
                                        max: v,
                                    });
                                }}
                                unit="m"
                            />
                        </>
                    ) : null}
                </>
            ) : null}
            <hr />
        </>
    );
};

export default DrapingSettings;
