import { Info } from './Tips';

interface Props {
    id: string;
    label: string;
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    value?: string;
    info?: string;
    name?: string;
}

const Checkbox = (props: Props) => (
    <div className="input-row">
        <label className="checkbox" htmlFor={props.id}>
            <input type="checkbox" {...props} />
            <span>{props.label}</span>
        </label>
        {props.info ? <Info id={`info-${props.id}`} message={props.info} /> : null}
    </div>
);

export default Checkbox;
