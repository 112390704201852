import { ColoringMode, HasBorehole, HasColoringMode, LayerState } from 'types/LayerState';

import * as layersSlice from 'redux/layers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import Toggle from 'components/dropdown/Toggle';

export type Props = {
    layer: LayerState & HasBorehole & HasColoringMode;
};

export const BoreholeSettings = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const variableRadii = useAppSelector(layersSlice.getHasVariableRadii(layer));

    const coloringMode = useAppSelector(layersSlice.getCurrentColoringMode(layer));

    function setVariableRadii(value: boolean) {
        dispatch(layersSlice.setVariableRadii({ layer, value }));
    }

    if (coloringMode !== ColoringMode.Colormap) {
        return null;
    }

    if (coloringMode !== ColoringMode.Colormap) {
        return null;
    }

    return <Toggle checked={variableRadii} title="Variable radii" onChange={(v) => setVariableRadii(v)} />;
};

export default BoreholeSettings;
