import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import VectorSource from '@giro3d/giro3d/sources/VectorSource';
import ColorLayer from '@giro3d/giro3d/core/layer/ColorLayer';
import { Style, Fill, Stroke } from 'ol/style';

import { SourceFile } from 'types/SourceFile';
import { ScopeElevationLayer } from 'types/common';
import RasterLayer, { ConstructorParams as BaseConstructorParams } from './RasterLayer';

type FileOutlineLoader = () => Promise<SourceFile[]>;

export interface ConstructorParams extends BaseConstructorParams<ScopeElevationLayer> {
    /**
     * File outlines loader
     */
    fileOutlinesLoader: FileOutlineLoader;

    datasetProjection: string;
}

export default class ElevationMosaicWMTSLayer extends RasterLayer<ScopeElevationLayer> {
    fileOutlinesLoader: FileOutlineLoader;

    private fileOutlinesLayer: ColorLayer;

    private readonly fileDict: Record<string, SourceFile>;

    private readonly datasetProjection: string;

    files: SourceFile[];

    /**
     * Creates an instance of ElevationMosaicWMTSLayer.
     * @param {object} params The parameters
     * @param {Function} params.fileOutlinesLoader File outlines loader
     */
    constructor(params: ConstructorParams) {
        super(params);

        this.datasetProjection = params.datasetProjection;
        // callback function
        this.fileOutlinesLoader = params.fileOutlinesLoader;
        this.fileOutlinesLayer = undefined;

        this.fileDict = {};

        this.assignInitialValues();
    }

    async doSetVisibility(value: boolean) {
        await super.doSetVisibility(value);
        if (this.fileOutlinesLayer) {
            this.fileOutlinesLayer.visible = value;
            this._giro3dInstance.notifyChange(this.fileOutlinesLayer);
        }
        this.notifyLayerChange();
    }

    isShowingFileOutlines() {
        return this.getVisibility() && this.getFileOutlinesVisiblity();
    }

    async loadFileOutlines(refresh) {
        if (this.fileOutlinesLayer && !refresh) {
            this.setFileOutlinesVisibility(true);
            return Promise.resolve();
        }
        this.removeFileOutlinesLayer();
        this.files = await this.fileOutlinesLoader();
        const features = this._buildFileFeatures();
        this.buildFileOutlinesLayer(features);
        return this.addFileOutlinesToMap();
    }

    private removeFileOutlinesLayer() {
        if (this.fileOutlinesLayer) {
            this._layerManager.removeLayer(this.fileOutlinesLayer);
            this.fileOutlinesLayer = undefined;
        }
    }

    private addFileOutlinesToMap() {
        return this._layerManager.addLayerOnMapsContaining(this.fileOutlinesLayer, this.layer);
    }

    private getStyle(fileId: string) {
        const state = this.fileDict[fileId].state;
        if (state === 'active') {
            return new Style({
                fill: new Fill({
                    color: 'rgba(103,206,142,0.3)',
                }),
                stroke: new Stroke({ color: 'rgba(103,206,142,1)' }),
            });
        }
        return new Style({
            fill: new Fill({
                color: 'red',
            }),
            stroke: new Stroke({ color: 'red' }),
        });
    }

    private _buildFileFeatures() {
        const features = [];
        for (const file of this.files) {
            this.fileDict[file.id] = file;
            const name = file.source;
            const id = file.id as string;
            if (file.geometry.type === 'Polygon') {
                const polygon = file.geometry.coordinates;
                const feature = new Feature({
                    geometry: new Polygon(polygon),
                    properties: {
                        id,
                        name,
                    },
                });
                feature.setStyle(this.getStyle(id));
                features.push(feature);
            } else if (file.geometry.type === 'MultiPolygon') {
                const multiPolygon = file.geometry.coordinates;
                const feature = new Feature({
                    geometry: new MultiPolygon(multiPolygon),
                    properties: {
                        id,
                        name,
                    },
                });
                feature.setStyle(this.getStyle(id));
                features.push(feature);
            } else {
                console.log('WARNING: unsupported geometry type:', file.geometry.type);
            }
        }
        return features;
    }

    private buildFileOutlinesLayer(features) {
        const id = `${this.layer.id}-fileOutlines`;

        this.fileOutlinesLayer = new ColorLayer({
            name: id,
            source: new VectorSource({
                data: features,
                dataProjection: this.datasetProjection,
                style: null,
            }),
        });

        this.fileOutlinesLayer.userData.datasetId = this.datasetId;
    }

    private getFileOutlinesVisiblity() {
        if (this.fileOutlinesLayer) {
            return this._layerManager.getVisibility(this.fileOutlinesLayer);
        }
        return false;
    }

    setFileOutlinesVisibility(value: boolean) {
        if (this.fileOutlinesLayer) {
            this._layerManager.setVisibility(this.fileOutlinesLayer, value);
        }
    }

    setOpacity(opacity: number) {
        this.settings.opacity = opacity;
        if (this.initialized) {
            this._layerManager.setOpacity(this.get3dElement(), opacity);
        }
    }
}
