import { LayerState, HasAmplitude, ColoringMode, HasColoringMode, HasAttributes } from 'types/LayerState';

// Components
import { useDispatch } from 'react-redux';
import * as layersSlice from 'redux/layers';
import { GEOJSON_AMPLITUDE_MODE } from 'services/Constants';
import { useAppSelector } from 'store';
import Toggle from 'components/dropdown/Toggle';
import Select from 'components/dropdown/Select';
import Input from 'components/dropdown/Input';
import { Option } from 'components/Select';

type LayerType = LayerState & HasAmplitude & HasColoringMode & HasAttributes;

const AmplitudeRange = (props: { layer: LayerType; attributeId: string }) => {
    const { layer, attributeId } = props;
    const dispatch = useDispatch();

    const spatialAmplitudeRange = useAppSelector(layersSlice.getAmplitudeRange(layer));
    const changeSpatialAmplitudeRange = (value: number) => {
        dispatch(layersSlice.setAmplitudeRange({ layer, value, attributeId }));
    };

    return (
        <Input
            title="Spatial Amplitude"
            icon="fas fa-waveform-lines"
            value={spatialAmplitudeRange}
            min={0}
            max={200}
            step={1}
            onChange={(v) => changeSpatialAmplitudeRange(v)}
        />
    );
};

const AmplitudeMode = (props: { layer: LayerType; attributeId: string }) => {
    const { layer, attributeId } = props;
    const dispatch = useDispatch();

    const amplitudeMode = useAppSelector(layersSlice.getAmplitudeMode(layer));
    const changeAmplitudeMode = (value: GEOJSON_AMPLITUDE_MODE) => {
        dispatch(layersSlice.setAmplitudeMode({ layer, value, attributeId }));
    };

    return (
        <Select<GEOJSON_AMPLITUDE_MODE>
            title="Amplitude Mode"
            icon="fas fa-xmark"
            value={{
                value: amplitudeMode,
                label: amplitudeMode,
            }}
            options={[
                { value: GEOJSON_AMPLITUDE_MODE.NORMALIZED_SIGNAL, label: GEOJSON_AMPLITUDE_MODE.NORMALIZED_SIGNAL },
                {
                    value: GEOJSON_AMPLITUDE_MODE.FROM_MEAN_DEVIATION,
                    label: GEOJSON_AMPLITUDE_MODE.FROM_MEAN_DEVIATION,
                },
                { value: GEOJSON_AMPLITUDE_MODE.FROM_MAX_DEVIATION, label: GEOJSON_AMPLITUDE_MODE.FROM_MAX_DEVIATION },
                { value: GEOJSON_AMPLITUDE_MODE.FROM_MIN_DEVIATION, label: GEOJSON_AMPLITUDE_MODE.FROM_MIN_DEVIATION },
            ]}
            onChange={(v) => changeAmplitudeMode((v as Option<GEOJSON_AMPLITUDE_MODE>).value)}
        />
    );
};

export type Props = {
    layer: LayerType;
};

export const AmplitudeSettings = (props: Props) => {
    const { layer } = props;
    const mode = useAppSelector(layersSlice.getCurrentColoringMode(layer));
    const activeAttribute = useAppSelector(layersSlice.getActiveAttribute(layer));
    const dispatch = useDispatch();

    if (!activeAttribute) {
        return null;
    }

    const showAmplitude = useAppSelector(layersSlice.getShowAmplitude(layer));
    const changeIsShowingAmplitude = (value: boolean) => {
        dispatch(layersSlice.setShowAmplitude({ layer, value, attributeId: activeAttribute.id }));
    };

    if (mode !== ColoringMode.Colormap) {
        return null;
    }

    return (
        <>
            <Toggle checked={showAmplitude} title="Show amplitude" onChange={(v) => changeIsShowingAmplitude(v)} />
            {showAmplitude ? <AmplitudeMode layer={layer} attributeId={activeAttribute.id} /> : null}
            {showAmplitude ? <AmplitudeRange layer={layer} attributeId={activeAttribute.id} /> : null}
            <hr />
        </>
    );
};

export default AmplitudeSettings;
