type LoadingStatus = {
    loading: boolean;
    progress: number;
};

function equals(a: LoadingStatus, b: LoadingStatus): boolean {
    if (!a.loading && !b.loading) {
        return true;
    }

    if (a.loading !== b.loading) {
        return false;
    }

    return Math.abs(a.progress - b.progress) < 0.01;
}

export { LoadingStatus, equals };
