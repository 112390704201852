import * as datasetsSlice from 'redux/datasets';
import * as giro3dSlice from 'redux/giro3d';
import * as layersSlice from 'redux/layers';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Dataset from 'types/Dataset';
import { useAppSelector } from 'store';
import { shallowEqual, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import ToggleSwitch from 'components/ToggleSwitch';
import { CONTROLS_MODE } from 'services/Controls';
import { PlayState } from 'types/common';
import HelpPanel from 'components/forms/HelpPanel';
import { useEffect } from 'react';
import Select from 'components/forms/Select';
import BaseInput from 'components/forms/BaseInput';

const LineFollowerModal = () => {
    const dispatch = useDispatch();

    const datasets: Dataset[] = useAppSelector(datasetsSlice.getRenderableDatasets, shallowEqual);
    const cameraSettings: giro3dSlice.FollowCamera = useAppSelector(giro3dSlice.getFollowCamera);
    const pathLayers = useAppSelector(layersSlice.getPathedLayers);
    const loadedPathLayers = pathLayers.filter((l) => l.pathLoaded);
    const loadedPathDatasets = datasets.filter((d) => loadedPathLayers.map((l) => l.datasetId).includes(d.id));
    const loadedPathDatasetsOptions = loadedPathDatasets.map((dataset) => ({ label: dataset.name, value: dataset.id }));

    const closeModal = () => dispatch(giro3dSlice.setFollowCameraModal(false));

    const validate = (values) => {
        if (!values.dataset) return { dataset: 'Dataset is required' };
        return {};
    };

    const onSubmit = async (values) => {
        const id = values.dataset.value || cameraSettings.datasetId;
        const newCameraSettings: giro3dSlice.FollowCamera = {
            state: PlayState.Pause,
            modal: false,
            datasetId: id,
            rotateCamera: values.rotateCamera,
            fixedDepth: values.fixedDepth,
            depth: values.depth,
            altitude: values.altitude,
            speed: values.speed,
            progress: values.progress,
        };
        dispatch(giro3dSlice.setControlsMode(CONTROLS_MODE.FOLLOW));
        dispatch(giro3dSlice.setFollowCamera(newCameraSettings));
    };

    useEffect(() => {
        dispatch(giro3dSlice.setFollowCameraState(PlayState.Pause));
    }, [cameraSettings.modal]);

    const unloadedDatasetCount = pathLayers.length - loadedPathLayers.length;

    return (
        <Modal isOpen={cameraSettings.modal}>
            <ModalHeader toggle={closeModal}>Line Follower Settings</ModalHeader>
            <Formik
                enableReinitialize
                initialValues={{ ...cameraSettings, dataset: datasets.find((d) => d.id === cameraSettings.datasetId) }}
                validate={validate}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <Select name="dataset" options={loadedPathDatasetsOptions} setFieldValue={setFieldValue} />
                            {unloadedDatasetCount !== 0 ? (
                                <HelpPanel>
                                    There {unloadedDatasetCount === 1 ? 'is' : 'are'} {unloadedDatasetCount} other
                                    dataset
                                    {unloadedDatasetCount === 1 ? '' : 's'} that need to be loaded to be used here.
                                </HelpPanel>
                            ) : null}
                            <div className="form-group">
                                Rotate with Line Direction
                                <ToggleSwitch
                                    id="rotateCamera"
                                    checked={values.rotateCamera}
                                    onChange={(e) => setFieldValue('rotateCamera', e.target.checked)}
                                />
                            </div>
                            <div className="form-group">
                                Fixed Depth
                                <ToggleSwitch
                                    id="fixedDepth"
                                    checked={values.fixedDepth}
                                    onChange={(e) => setFieldValue('fixedDepth', e.target.checked)}
                                />
                            </div>
                            {values.fixedDepth ? (
                                <BaseInput name="depth" label="Depth" titled type="number" />
                            ) : (
                                <BaseInput name="altitude" label="Altitude" titled type="number" />
                            )}
                            <BaseInput name="speed" label="Auto-scroll speed" titled type="number" />
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="pane-button large highlight">
                                Apply
                            </button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default LineFollowerModal;
