import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { Rect } from 'flexlayout-react';
import { RootState } from 'store';
import { View } from 'types/serialization/View';

export type State = {
    leftOpen: boolean;
    rightOpen: boolean;
    trayOpen: boolean;
    rect: Rect;
};

const initialState: State = {
    leftOpen: false,
    rightOpen: false,
    trayOpen: false,
    rect: undefined,
};

const self = (store: RootState) => store.layout;

// Selectors
export const getLeftOpen = createSelector(self, (s) => s.leftOpen);
export const getRightOpen = createSelector(self, (s) => s.rightOpen);
export const getTrayOpen = createSelector(self, (s) => s.trayOpen);
export const getRect = createSelector(self, (s) => s.rect);

const slice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        leftOpen: (state, action: PayloadAction<boolean>) => {
            state.leftOpen = action.payload;
        },
        rightOpen: (state, action: PayloadAction<boolean>) => {
            state.rightOpen = action.payload;
        },
        trayOpen: (state, action: PayloadAction<boolean>) => {
            state.trayOpen = action.payload;
        },
        setRect: (state, action: PayloadAction<Rect>) => {
            state.rect = action.payload;
        },
        loadView: (state, action: PayloadAction<View>) => {
            const layout = action.payload.layout;
            if (layout) {
                state.leftOpen = layout.leftOpen;
                state.rightOpen = layout.rightOpen;
                state.trayOpen = layout.trayOpen;
            }
        },
        reset: () => initialState,
    },
});

export const reducer = slice.reducer;

// Actions
export const leftOpen = slice.actions.leftOpen;
export const rightOpen = slice.actions.rightOpen;
export const trayOpen = slice.actions.trayOpen;
export const reset = slice.actions.reset;
export const setCrop = slice.actions.setRect;
export const loadView = slice.actions.loadView;
