import type Instance from '@giro3d/giro3d/core/Instance';
import Coordinates from '@giro3d/giro3d/core/geographic/Coordinates';
import Extent from '@giro3d/giro3d/core/geographic/Extent';
import ColorLayer from '@giro3d/giro3d/core/layer/ColorLayer';
import { CustomContainsFn } from '@giro3d/giro3d/sources/ImageSource';
import VectorSource from '@giro3d/giro3d/sources/VectorSource';
import OpenLayersUtils from '@giro3d/giro3d/utils/OpenLayersUtils';
import type { Geometry } from 'geojson';
import { toOpenLayersGeometry } from 'geojsonUtils';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import store, { RootState } from 'store';
import { Color, Group, Vector3 } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { DatasetId, GeometryWithCRS, SourceFileId } from 'types/common';
import StateObserver from './layers/StateObserver';

enum Properties {
    datasetId = 'datasetId',
    sourceFileId = 'sourceFileId',
    color = 'color',
    fill = 'fill',
    brightness = 'brightness',
}

function getStyle(feature: Feature): Style {
    const [r0, g0, b0] = feature.get(Properties.color);
    const showFill = feature.get(Properties.fill);
    const brightness = feature.get(Properties.brightness);

    let color: Color;

    if (brightness) {
        color = new Color(r0, g0, b0).offsetHSL(0, 0, brightness);
    } else {
        color = new Color(r0, g0, b0);
    }

    const baseWidth = 2;

    const r = Math.round(color.r * 255);
    const g = Math.round(color.g * 255);
    const b = Math.round(color.b * 255);

    const strokeColor = `rgb(${r}, ${g}, ${b})`;
    const fillColor = `rgba(${r}, ${g}, ${b}, 0.2)`;

    return new Style({
        zIndex: brightness > 0 ? 999 : 0,
        stroke: new Stroke({
            color: strokeColor,
            width: brightness ? baseWidth * 2 : baseWidth,
        }),
        fill: showFill ? new Fill({ color: fillColor }) : null,
    });
}

export type AddFootprintOptions = {
    id: string;
    datasetId: DatasetId;
    sourceFileId: SourceFileId;
    footprint: GeometryWithCRS | Geometry;
    color: Color;
    label: string;
    labelPosition: Vector3;
    displayFill: boolean;
    displayLabels: boolean;
};

export type UpdateFootprintOptions = {
    id: string;
    displayFill: boolean;
    displayLabels: boolean;
};

export type PickedFootprint = {
    dataset: DatasetId;
    sourceFile: SourceFileId;
};

function createFootprintLayer(extent: Extent, containsFn: CustomContainsFn): ColorLayer {
    const name = 'footprint';
    const layer = new ColorLayer({
        name,
        extent,
        source: new VectorSource({
            data: [],
            containsFn,
            dataProjection: extent.crs,
            style: getStyle,
        }),
    });
    // By default there is nothing in the layer, so don't
    // waste CPU time by processing an empty layer.
    layer.visible = false;

    return layer;
}

function createLabelElement(text: string, color: Color): HTMLElement {
    const result = document.createElement('div');
    result.className = 'sourceFile-label';
    const r = color.r * 255;
    const g = color.g * 255;
    const b = color.b * 255;

    const cssColor = `rgba(${r}, ${g}, ${b}, 1)`;

    result.style.color = cssColor;
    result.style.borderColor = cssColor;
    result.innerText = text;
    return result;
}

/**
 * Manages footprints on a color layer.
 */
export default class FootprintManager {
    private readonly _footprintLayer: ColorLayer;
    private readonly _footprintLayerSource: VectorSource;
    private readonly _footprintItems: Map<string, { feature: Feature; labelObject: CSS2DObject; extent: Extent }>;
    private readonly _instance: Instance;
    private readonly _labelRoot: Group;
    private readonly _stateObserver: StateObserver<RootState>;
    private _requestedFrame: number | null = null;

    private readonly _pendingFeatures: Feature[] = [];

    constructor(instance: Instance, extent: Extent) {
        this._footprintLayer = createFootprintLayer(extent, this.contains.bind(this));
        this._footprintLayerSource = this._footprintLayer.source as VectorSource;
        this._footprintItems = new Map();
        this._instance = instance;
        this._labelRoot = new Group();
        this._labelRoot.name = 'footprint labels';
        this._instance.add(this._labelRoot);
        this._stateObserver = new StateObserver(store.getState, store.subscribe);
        this._stateObserver.triggerAllListeners();
    }

    get layer() {
        return this._footprintLayer;
    }

    private contains(tileExtent: Extent) {
        let result = false;
        this._footprintItems.forEach(({ extent }) => {
            if (extent.intersectsExtent(tileExtent)) {
                result = true;
            }
        });

        return result;
    }

    private requestFeatureUpdate(feature: Feature) {
        // To avoid updating the source very frequently, which could cause
        // rendering issues, let's group all the features to update and trigger
        // a single update for the next animation frame.
        this._pendingFeatures.push(feature);
        if (this._requestedFrame != null) {
            cancelAnimationFrame(this._requestedFrame);
        }
        this._requestedFrame = requestAnimationFrame(() => {
            this._footprintLayerSource.updateFeature(...this._pendingFeatures);
            this._pendingFeatures.length = 0;
            this._requestedFrame = null;
        });
    }

    updateFootprint(options: UpdateFootprintOptions) {
        const item = this._footprintItems.get(options.id);
        if (item) {
            const { feature, labelObject } = item;
            const fill = feature.get(Properties.fill);
            labelObject.visible = options.displayLabels;
            if (fill !== options.displayFill) {
                feature.set(Properties.fill, options.displayFill);
                this.requestFeatureUpdate(feature);
            }
        }
    }

    setBrightness(id: string, brightness: number) {
        const item = this._footprintItems.get(id);
        if (item) {
            const { feature } = item;
            const current = feature.get(Properties.brightness);
            if (current !== brightness) {
                feature.set(Properties.brightness, brightness);
                this.requestFeatureUpdate(feature);
            }
        }
    }

    pick(coord: Coordinates): PickedFootprint[] {
        const converted = coord.as(this._footprintLayerSource.dataProjection);
        const olCoord = [converted.x, converted.y];

        // We perform OpenLayers picking directly on the OL source.
        const olSource = this._footprintLayerSource.source;
        const features = olSource.getFeaturesAtCoordinate(olCoord);

        const result: PickedFootprint[] = features.map((f) => {
            const picked: PickedFootprint = {
                dataset: f.get(Properties.datasetId),
                sourceFile: f.get(Properties.sourceFileId),
            };
            return picked;
        });

        return result;
    }

    async addFootprint(options: AddFootprintOptions) {
        if (!this._footprintLayer) {
            return;
        }

        if (this._footprintItems.has(options.id)) {
            return;
        }

        const { id, footprint, color, label, labelPosition, sourceFileId, datasetId, displayLabels } = options;
        const geometry = toOpenLayersGeometry(footprint);
        const feature = new Feature({ geometry });
        const olExtent = geometry.getExtent();
        // Let's use a small margin to account for the thickness of the stroke style.
        const MARGIN = 0.1;
        const extent = OpenLayersUtils.fromOLExtent(olExtent, this._instance.referenceCrs).withRelativeMargin(MARGIN);

        feature.set('id', id);
        feature.set(Properties.sourceFileId, sourceFileId);
        feature.set(Properties.datasetId, datasetId);
        feature.set(Properties.color, [color.r, color.g, color.b]);
        feature.set(Properties.fill, options.displayFill);

        this._footprintLayerSource.source.addFeature(feature);

        const labelObject = new CSS2DObject(createLabelElement(label, color));
        labelObject.name = label;
        labelObject.position.copy(labelPosition);
        this._labelRoot.add(labelObject);
        labelObject.visible = displayLabels;
        labelObject.updateMatrixWorld();

        this._footprintItems.set(id, { feature, labelObject, extent });

        this.updateLayerVisiblity();

        this.requestFeatureUpdate(feature);
    }

    private updateLayerVisiblity() {
        this._footprintLayer.visible = this._footprintItems.size > 0;
    }

    removeFootprint(id: string) {
        if (!this._footprintLayer) {
            return;
        }

        if (this._footprintItems.has(id)) {
            const { feature, labelObject } = this._footprintItems.get(id);
            this._footprintItems.delete(id);
            this._footprintLayerSource.source.removeFeature(feature);
            this._labelRoot.remove(labelObject);
            this.updateLayerVisiblity();
            this.requestFeatureUpdate(feature);
        }
    }
}
