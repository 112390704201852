import { Actions, DockLocation, DropInfo, Model, Node, TabNode, TabSetNode } from 'flexlayout-react';
import { PANE, PANE_PROPERTIES, PANE_TYPE, POSITION } from 'services/Constants';
import { Dispatch } from 'store';
import * as layoutSlice from 'redux/layout';
import { PopoverBody, PopoverHeader, Popover } from 'reactstrap';
import Button from 'components/dropdown/Button';
import { EventBus } from 'EventBus';
import { useState } from 'react';

export function allowDropBuilder(getSide: (node: Node) => POSITION) {
    return function allowDrop(dragNode: Node, dropInfo: DropInfo) {
        // If the drop area isn't on the panes list don't drop
        const side = getSide(dropInfo.node);
        if (!PANE_PROPERTIES[(dragNode as TabNode).getComponent()].positions.includes(side)) return false;

        // Allow dropping into existing tabsets
        if (dropInfo.location === DockLocation.CENTER) return true;

        // But in cases where we will split...

        // If the tabset is empty, don't split
        const dropNodeChildren = dropInfo.node.getChildren();
        if (dropNodeChildren.length === 0) return false;

        // If the tabset only contains the dragged tab, don't split
        if (dropNodeChildren.length === 1 && dropInfo.node === dragNode.getParent()) return false;

        // Only split on the correct axis
        switch (side) {
            case POSITION.LEFT:
            case POSITION.RIGHT:
                return dropInfo.location === DockLocation.TOP || dropInfo.location === DockLocation.BOTTOM;
            case POSITION.TRAY:
                return dropInfo.location === DockLocation.LEFT || dropInfo.location === DockLocation.RIGHT;
            default:
                return false;
        }
    };
}

export function openSide(side: POSITION, dispatch: Dispatch) {
    switch (side) {
        case POSITION.LEFT:
            dispatch(layoutSlice.leftOpen(true));
            break;
        case POSITION.RIGHT:
            dispatch(layoutSlice.rightOpen(true));
            break;
        case POSITION.TRAY:
            dispatch(layoutSlice.trayOpen(true));
            break;
        default:
            break;
    }
}

export function findFirstNodeWithComponent(node: Node, componentName: string, config?): TabNode | undefined {
    if (
        node.getType() === 'tab' &&
        (node as TabNode).getComponent() === componentName &&
        (node as TabNode).getConfig() === config
    )
        return node as TabNode;

    for (const child of node.getChildren()) {
        const result = findFirstNodeWithComponent(child, componentName, config);
        if (result) return result;
    }

    return undefined;
}

export function onModelChangeBuilder(getSideNode: (position: POSITION, m: Model) => Node) {
    return function onModelChange(newModel: Model) {
        const left = getSideNode(POSITION.LEFT, newModel);
        if (left?.getType() === 'row') {
            const empty = left.getChildren().find((tabset) => tabset.getChildren().length === 0);
            if (empty) newModel.doAction(Actions.deleteTabset(empty.getId()));
        }

        const right = getSideNode(POSITION.RIGHT, newModel);
        if (right?.getType() === 'row') {
            const empty = right.getChildren().find((tabset) => tabset.getChildren().length === 0);
            if (empty) newModel.doAction(Actions.deleteTabset(empty.getId()));
        }

        const tray = getSideNode(POSITION.TRAY, newModel);
        if (tray?.getType() === 'row') {
            const empty = tray.getChildren().find((tabset) => tabset.getChildren().length === 0);
            if (empty) newModel.doAction(Actions.deleteTabset(empty.getId()));
        }
    };
}

export function tabSetPlaceHolder() {
    return (
        <div className="tabset-placeholder">
            <div className="placeholder-tabset-information">
                <span>
                    Use the <i className="fas fa-plus" /> button to add new tabs...
                </span>
                <i className="fas fa-arrow-up-right pointer" />
            </div>
        </div>
    );
}

export function getLastValidTabset(node: Node) {
    if (node.getType() === 'tabset' && (node as TabSetNode).getClassNameTabStrip() !== 'tabless') return node;
    const children = node.getChildren();

    for (let i = children.length - 1; i >= 0; i--) {
        const tabSet = getLastValidTabset(children[i]);
        if (tabSet) return tabSet;
    }

    return null;
}

export function AddNewTabButton(props: { node: Node; side: POSITION; eventBus: EventBus }) {
    const { node, side, eventBus } = props;
    const [open, setOpen] = useState(false);
    return (
        <>
            <button
                key="addTab"
                id={`add-${node.getId().replace(/#/g, '')}`}
                type="button"
                className="newTabButton"
                title="Open New Tab"
                aria-label="Open New Tab"
                onClick={() => setOpen(true)}
            />
            <Popover
                key="addDropdown"
                target={`add-${node.getId().replace(/#/g, '')}`}
                placement="bottom-end"
                fade={false}
                hideArrow
                isOpen={open}
                trigger="legacy"
                toggle={() => setOpen(false)}
            >
                <PopoverHeader />
                <PopoverBody>
                    <ul>
                        {Object.entries(PANE_PROPERTIES).find(
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            ([_key, value]) => value.positions.includes(side) && value.type === PANE_TYPE.SINGLE
                        ) ? (
                            <>
                                {Object.entries(PANE_PROPERTIES)
                                    .filter(
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        ([_key, value]) =>
                                            value.defaultPosition === side && value.type === PANE_TYPE.SINGLE
                                    )
                                    .map(([key, value]) => {
                                        return (
                                            <Button
                                                key={key}
                                                title={value.name}
                                                onClick={() => {
                                                    eventBus.dispatch('create-pane', {
                                                        paneType: key as PANE,
                                                        tabsetId: node.getId(),
                                                        showExisting: true,
                                                    });
                                                    setOpen(false);
                                                }}
                                            />
                                        );
                                    })}
                                <hr />
                                {Object.entries(PANE_PROPERTIES)
                                    .filter(
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        ([_key, value]) =>
                                            value.defaultPosition !== side &&
                                            value.positions.includes(side) &&
                                            value.type === PANE_TYPE.SINGLE
                                    )
                                    .map(([key, value]) => {
                                        return (
                                            <Button
                                                key={key}
                                                title={value.name}
                                                onClick={() => {
                                                    eventBus.dispatch('create-pane', {
                                                        paneType: key as PANE,
                                                        tabsetId: node.getId(),
                                                        showExisting: true,
                                                    });
                                                    setOpen(false);
                                                }}
                                            />
                                        );
                                    })}
                            </>
                        ) : (
                            <li>There are no tabs to add here.</li>
                        )}
                    </ul>
                </PopoverBody>
            </Popover>
        </>
    );
}
